import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Pagination,
    Chip,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { ProjectConfig } from 'Global';
import Box from 'elements/Box';
import 'assets/css/main.css';
import { Add, DeleteOutlined, RemoveRedEye } from '@mui/icons-material';
import AlertDialog from 'elements/AlertDialog';
import { NotesForm } from './NotesForm';

export function NotesList( props: any ) {

    const {} = props;

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [list, setList] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [page, setPage] = useState<number>(searchParams.get('page') ? Number(searchParams.get('page')) : 1);

    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number>(0);
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    useEffect(() => { 
        loadData();
    }, []);

    useEffect(() => { 
        loadData();
    }, [page]);

    const loadData = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_notes_boards_list',
                params: {
                    page: page,
                }
            }
        })
        .then((response) => {
            console.log(response.data);
            setList(response.data.list);
            setTotalPages(response.data.total_pages);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const handleDelete = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: 'delete_notes_board',
                board_id: selectedId,
            }
        })
        .then((response) => {
            console.log(response.data);
            loadData();
            setSelectedId(0);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setOpenDelete(false);
            setLoading(false);
        })
    }

    return (
        <>
            <div
                id="notesContainer"
                className="moduleContainer"
            >
                <div className="moduleWrapper">
                    <div className="moduleHeader buttons_top">
                        <h2>NOTES</h2>

                        <div className="moduleButtons">
                            {/* <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                onClick={() => {
                                    setOpenAdd(true);
                                }}
                            >
                                Add
                            </Button> */}
                        </div>   
                    </div>

                    <div className="moduleContent">
                        <Box className="box100">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>State</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {list.map((item: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.board_name}</TableCell>
                                        <TableCell>
                                            <Chip
                                                label={item.status_id === '1' ? 'Active' : 'Inactive'}
                                                color={item.status_id === '1' ? 'primary' : 'secondary'}
                                                variant="outlined"
                                            />
                                        </TableCell>
                                        <TableCell sx={{minWidth: '100px'}} align='right'>
                                            <IconButton
                                                color="secondary"
                                                size="small"
                                                onClick={() => {
                                                    navigate(`edit/${item.board_id}`);
                                                }}
                                            >
                                                <RemoveRedEye />
                                            </IconButton>

                                            <IconButton
                                                color="secondary"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedId(item.board_id);
                                                    setOpenDelete(true);
                                                }}
                                            >
                                                <DeleteOutlined />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            {totalPages > 1 &&
                            <Pagination
                                color='primary'
                                sx={{ "& .MuiPagination-ul ": {justifyContent: 'center'} }}
                                count={totalPages}
                                page={page}
                                onChange={(event, value) => {
                                    setPage(value);
                                    setSearchParams({ page: value.toString() });
                                }}
                            />
                            }
                        </Box>
                    </div>
                </div>
            </div>

            <AlertDialog
                open={openDelete}
                setOpen={setOpenDelete}
                subtitle="Are you sure you want to delete this board?"
                send={handleDelete}
            />

            <Dialog
                open={openAdd}
                onClose={() => {
                    setOpenAdd(false);
                }}
                fullWidth
                maxWidth="sm"
            >
                <NotesForm
                    setLoading={setLoading}
                    setOpen={setOpenAdd}
                    onSubmit={loadData}
                />
            </Dialog>
        </>
    );
}