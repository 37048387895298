import React, { Fragment, useEffect, useState } from 'react';
import { Autocomplete, Dialog, DialogContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete';
import axios from 'axios';

import { ProjectConfig } from 'Global';
import ic_dots from 'icons/ic_dots.svg';

import 'assets/css/main.css';

interface ITaskList {
    inputValue?: string,
    task_id?: number,
    task_name: string,
};

const defaultValues = {
    task_name: "",
    task_id: null,
}

export default function AutocompleteTask( props: any ) {

    const {
        project_id = null,
        handleChange = (value: any) => {},
        ...autocompliteProps
    } = props;

    delete autocompliteProps.setLoading;

    const [list, setList] = useState<any[]>([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => { props.setLoading(loading) } , [loading]);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (project_id) {
            loadData();
        }
    }, [project_id]);

    const filter = createFilterOptions<ITaskList>();

    const loadData = () => {
        const params: any = project_id ? { project_id: project_id } : {full_list: true};
        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'task_list',
                params: {
                    ...params,
                }
            }
        })
        .then((response) => {
            console.log(response.data);
            setList(response.data.list);
        })
        .catch((err) => {
            console.log(err.data);
        })
    }

    return (
        <>
            <Autocomplete
                {...autocompliteProps}
                id="autocomplete-projects"
                options={list}
                getOptionLabel={(option: any) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.task_name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option: any) => {
                    return (
                        <li {...props} key={option.task_id}>
                            <span className='autocomplete_task_name'>{option.task_name}</span>
                        </li>
                    );
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // const { inputValue } = params;
                    // Suggest the creation of a new value
                    // const isExisting = options.some((option) => inputValue === option.project_name);
                    // if (inputValue !== '' && !isExisting) {
                    //     filtered.push({
                    //         inputValue,
                    //         task_name: `Add "${inputValue}"`,
                    //     });
                    // }

                    return filtered;
                }}
                freeSolo
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        handleChange({
                            task_id: null,
                            task_name: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        // addProduct(newValue.inputValue);
                    } else {
                        handleChange({
                            task_id: newValue.task_id,
                            task_name: newValue.task_name,
                        });
                    }
                }}
                renderInput={(params) => (
                    <TextField 
                        {...params}
                        label="Tasks"
                        InputProps={{
                            ...params.InputProps,
                            style: {
                                fontSize: '12px',
                            }
                        }}
                    />
                )}
            />
        </>
    );
}