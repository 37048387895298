import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link } from 'react-router-dom';

import ic_menu from 'icons/ic_menu.svg';
import LogoCasoBox from 'icons/LogoCasoBox.svg';

export default function MainMenu( props: any ) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /** PARCHE */
  const isAdmin = () => {
    return localStorage.getItem('role_id') === "1";
  }

  return (
    <div className='menu_button_container'>
      <Button
        id="menu_button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img src={ic_menu}></img>
      </Button>
      
      <Menu
        id="MainDropMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* <MenuItem >
          <ListItemIcon>
          <img src={ic_menu}></img>
          </ListItemIcon>
          <Link href="/">Dashboard</Link>
        </MenuItem> */}

        {isAdmin() ? (
          <div>

            <MenuItem onClick={handleClose} component={Link} to='/'>Dashboard</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/clients'>Clients</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/projects'>Projects</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/inventory'>Inventory</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/suppliers'>Suppliers</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/estimate-products'>Estimate Products</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/users'>Users</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/admin-workers-timing'>Workers Timing</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/payments'>Payments</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/tasks'>Tasks</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/schedule'>Schedule</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/orders'>Orders</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/workforce'>Workforce</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/points'>Points</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/payroll'>Payroll</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/appliance'>Appliance</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/machinery'>Machinery</MenuItem>
          </div>
        ) : (
          <div>
            <MenuItem onClick={handleClose} component={Link} to='/'>Dashboard</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='worker-timing'>Worker Timing</MenuItem>
          </div>
        )}
      </Menu>

      <Button
        id="menu_button"
        component={Link}
        to='/files'
      >
        <img src={LogoCasoBox}></img>
      </Button>
    </div>
  );
}
