import React, { useContext, useEffect, useMemo, useRef, useState} from 'react';

import { IService, QuotesContext } from '../QuoteContextProvider';
import { CircularProgress, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Typography, debounce } from '@mui/material';
import { Circle, ContentCopyOutlined, Delete, DeleteOutline, ExpandLess, ExpandMore, MoreVert } from '@mui/icons-material';
import QuoteCategoryBox from './QuoteCategoryBox';

const defaultValues: IService = {
    name: '',
    serviceType: '',
    collapseService: false,
};

export default function QuoteServiceBox ( props: any ) {

    const {
        getAlternateView,
        categories,
        serviceDict,
        costDict,
        quoteCost,
        updateService,
        setSelectedDeleteService,
        setOpenDeleteService,
        loadingFields,
        loadingFieldsDict,
        duplicateService,
    } = useContext(QuotesContext);

    const {
        viewType = "Quote", // This component is for the quote view by default
        serviceIndex,
    } = props;

    const [service, setService] = useState<IService>(defaultValues);
    const [alternateView, setAlternateView] = useState<string>('');

    const [actionsButtonAnchorElement, setActionsButtonAnchorElement] = useState<null | HTMLElement>(null);
    const [openContextActionsMenu, setOpenContextActionsMenu] = useState<boolean>(false);

    const refServiceNameHandler = useRef<any>({});

    const [updatedServiceName, setUpdatedServiceName] = useState<boolean>(true);

    useEffect(() => {
        if (props) {
            setAlternateView(getAlternateView(viewType));
            setService({
                ...serviceDict[serviceIndex],
            });

            setUpdatedServiceName(true);
        };
    }, [props]);

    const onChangeServiceName = () => {
        handleValueChange(service.name, 'name');
    }

    useEffect(() => {
        refServiceNameHandler.current = onChangeServiceName;
    }, [onChangeServiceName]);

    const overrideServiceName = () => {
        if (updatedServiceName) return;

        refServiceNameHandler.current?.();
    }

    const handleValueChange = (value: any, field: string) => {
        const newServiceChanges = {
            [field]: value,
        };

        updateService(newServiceChanges, serviceIndex);
    }

    useEffect(() => {
        if (actionsButtonAnchorElement !== null) {
            setOpenContextActionsMenu(true);
        }
    }, [actionsButtonAnchorElement]);

    useEffect(() => {
        if (!openContextActionsMenu)
            setActionsButtonAnchorElement(null)
    }, [openContextActionsMenu]);

    return (
        <>
            <div
                className={"QuoteService "+(service.collapseService ? "serviceClose" : "serviceOpen")}
            >
                <div className={"QuoteServiceCategory type_"+service.serviceType}>
                    <IconButton
                        aria-label="Expand"
                        size="small"
                        style={{color: "#fff"}}
                        onClick={() => {
                            handleValueChange(!service.collapseService, 'collapseService');
                        }}
                    >
                        {service.collapseService ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>

                    <span className="QuoteServiceText">
                        {service.serviceType}
                    </span>
                        
                </div>

                    
                <div className="QuoteServiceItems">
                    <div className="QuoteServiceHeader">
                        <div className="QuoteServiceHeaderLeft">
                            <span className="QuoteServiceName">
                                <TextField
                                    id="service_name"
                                    label="Service Name"
                                    value={service.name}
                                    fullWidth
                                    variant="standard"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {!updatedServiceName && false ?
                                                    <Tooltip title={"Press enter or leave the field to confirm"}>
                                                        <Circle
                                                            color="secondary"
                                                            className='field_badge'
                                                        />
                                                    </Tooltip>
                                                    :
                                                    null
                                                }
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={(e:any) => {
                                        setService({
                                            ...service,
                                            name: e.target.value,
                                        });

                                        setUpdatedServiceName(false);
                                    }}
                                    onBlur={overrideServiceName}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            overrideServiceName();
                                        }
                                    }}
                                />
                            </span>
                        </div>

                        <div className="QuoteServiceHeaderRight">
                            <div className="QuoteServiceTotal">
                                <Typography variant="body1" component="h5" sx={{fontWeight: 500}}>
                                {loadingFields && loadingFieldsDict[serviceIndex] ?
                                        <CircularProgress color="secondary" size="1rem" /> :
                                        <>${(costDict[serviceIndex].total_cost || 0).toLocaleString()}</>
                                    }
                                </Typography>

                                {(
                                    costDict[serviceIndex].percentage &&
                                    costDict[serviceIndex].percentage > 0
                                ) && (
                                <Tooltip title="With profit" placement="bottom" arrow followCursor>
                                    <Typography variant="overline" component="p">
                                        {loadingFields && loadingFieldsDict[serviceIndex] ?
                                            <CircularProgress color="secondary" size="0.7rem" /> :
                                            <>
                                                ${
                                                    (
                                                        (costDict[serviceIndex].total_cost || 0) *
                                                        (1 + costDict[serviceIndex].percentage / 100)
                                                    ).toLocaleString()
                                                }
                                            </>
                                        }
                                    </Typography>
                                </Tooltip>
                                )}
                            </div>
                            
                            <div className="QuoteServiceDelete">
                                <IconButton
                                    aria-label="Expand"
                                    size="small"
                                    onClick={(e: any) => {
                                        setActionsButtonAnchorElement(e.currentTarget);
                                    }}
                                >
                                    <MoreVert />
                                </IconButton>
                            </div>
                        </div>
                    </div>

                    {service.serviceType !== '' &&
                    categories[service.serviceType].map((categoryItem:any, categoryIndex:any) => (
                        <div key={categoryIndex}>
                            <QuoteCategoryBox
                                serviceIndex={serviceIndex}
                                categoryItem={categoryItem}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <Menu
                anchorEl={actionsButtonAnchorElement}
                open={openContextActionsMenu}
                onClose={() => setOpenContextActionsMenu(false)}
            >
                <MenuItem onClick={() => {
                    duplicateService(serviceIndex);
                    setOpenContextActionsMenu(false);
                }}>
                    <ContentCopyOutlined />
                    Duplicate
                </MenuItem>

                <MenuItem onClick={() => {
                    setSelectedDeleteService(serviceIndex);
                    setOpenDeleteService(true);
                    setOpenContextActionsMenu(false);
                }}>
                    <DeleteOutline />
                    Delete
                </MenuItem>
            </Menu>
        </>
    )
}