import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { IconButton } from '@mui/material';
import ic_dots from 'icons/ic_dots.svg';


export default function AutocompleteWorkforce(props: any) {


  const {
    dotsClick,
    setActiveItemParams = () => { },
    setOpenWorkforceEditor,
    setWorkforceCreatorName,
    productAsObj = false,
  } = props;
  
  const [loading, setLoading] = useState(false);

  /* =================================================
    WORKFORCE AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface IWorkforceList {
      inputValue?: string,
      workforce_id?: number,
      hour_cost: number,
      workforce_name: string,
    };

    const filter = createFilterOptions<IWorkforceList>();

    //SETTER
    const [workforceList, setWorkforceList] = useState<any[]>([]);
    const [addedTrigger, setAddedTrigger] = useState<boolean>(props.addedTrigger? props.addedTrigger : false)
    
    useEffect(() => {
      loadData();
    }, []);

    useEffect(() => {
      if (props.addedTrigger) setAddedTrigger(props.addedTrigger);
    }, [props.addedTrigger]);

    useEffect(() => {
      if (addedTrigger) {
        loadData();
        setAddedTrigger(false);
      }
    }, [addedTrigger]);


    const loadData = () => {
      axios.get(ProjectConfig.api_url,{
        params: {
          request: 'workforce_list',
          params: {
            full_list: true,
          }
        }
      })
        .then((response) => {
          // console.log(response.data);
          setWorkforceList(response.data.workforce_list);
          
          setLoading(true)
            
        })
        .catch((err) => {
          console.log(err.data);
        })
    }
    /* ================================================= 
    ===================================================*/


  return (
    <Autocomplete
    value={props.workforce_name}
    onChange={(event, newValue) => {
      if (typeof newValue === 'string') {
        props.setWorkforce({
          workforce_name: newValue,
        });
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        setActiveItemParams({
          workforce_name: "",
          workforce_id: null,
          hour_cost: null,
          serviceIndex: props.serviceIndex,
          itemIndex: props.itemIndex,
        })
        setOpenWorkforceEditor(true);
        setWorkforceCreatorName(newValue.inputValue);
      } else {

        if (productAsObj) {
          props.setWorkforce({
            workforce_name: newValue.workforce_name,
            workforce_id: newValue.workforce_id,
            hour_cost: newValue.hour_cost,
          });
        } else {
          props.setWorkforce(
            newValue.workforce_name,
            newValue.workforce_id,
            newValue.hour_cost,
            props.serviceIndex,
            props.itemIndex
          );
        }
        
      }
      
    }}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);

      const { inputValue } = params;
      // Suggest the creation of a new value
      const isExisting = options.some((option) => inputValue === option.workforce_name);
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          inputValue,
          workforce_name: `Add "${inputValue}"`,
          hour_cost: 0,
        });
      }

      return filtered;
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    fullWidth
    className='autocomplete_workforce'
    options={workforceList}
    getOptionLabel={(option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.workforce_name;
    }}
    renderOption={
      (props, option) => 
      <li {...props} key={option.workforce_id}>
        <span className='autocomplete_option_small'>{option.workforce_name}</span>
      </li>
    }
    sx={{ width: 300 }}
    freeSolo
    renderInput={(params) => (
      <TextField 
      {...params} 
      label="Task" 
      InputLabelProps={{
          style: { fontSize: '12px' }
      }}
      size='small'
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <IconButton 
            className="autocomplete_ic_dots"
            onClick={()=>{
              dotsClick(true);
              setActiveItemParams({
                workforce_name: "",
                workforce_id: null,
                hour_cost: null,
                serviceIndex: props.serviceIndex,
                itemIndex: props.itemIndex,
              })



            }}
            
          ><img src={ic_dots}></img></IconButton>
        ),
        style: {
          fontSize: '12px',
        }
      }}/>
    )}
    
  />
  );
}
