import React, { useState } from 'react';
import { Button, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Box from 'elements/Box';
import PerformanceDialog from './PerformanceDialog';
import AlertDialog from 'elements/AlertDialog';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { useSnackbar } from 'notistack';
import EmptyElement from 'elements/EmptyElement';


export default function PerformancePointsList( props: any ) {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    const {
        setLoading,
        list,
        page,
        totalPages,
        setPage,
        user_id,
        loadPointsList
    } = props;

    

    const [openDialog, setOpenDialog] = useState(false);

    const [activeItemId, setActiveItemId] = useState<any>(null);

    const [openAlert, setOpenAlert] = useState(false);



    /*=========================================================
    DELETE ITEM
    =========================================================*/

    const handleDelete = (id:number) => {
        setOpenAlert(true);
        setActiveItemId(id);
        
    }

    const deleteItem = () => {

        axios.post(ProjectConfig.api_url, null, 
        {
            params: {
                request: 'delete_user_point',
                user_point_id: activeItemId,
            }
        })
    
        .then((response) => {
            console.log(response.data);
            enqueueSnackbar('Item deleted', {variant: 'error'});
            loadPointsList()
            
        })
        .catch((err) => {
            console.log(err.data);
        })
    }

    /*=========================================================*/

    
    return (
        <>
            <div className='moduleContainer'>
                <div className='moduleWrapper'>
                    <div className="box_header buttons_top">
            
                        <h1>Points</h1>
            
                        <div className="buttons_right" style={{display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setOpenDialog(true);
                                }}
                                startIcon={<AddIcon />}
                            >
                                Add Points
                            </Button>
                        </div>
            
                    </div>

                    <div id="point_list_container" className="flex_container">
                        <Box id='pointsListBox' title="Points" subtitle="List"  >
        
                        {list.length > 0 ? (
                        <>

                            <Table stickyHeader aria-label="sticky table"> 
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>DESCRIPTION</TableCell>
                                        <TableCell>POINTS</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                    {list.map((item: any) => {
                                        return (
                                            <TableRow 
                                                key={item.user_point_id}
                                                hover
                                                role="checkbox"
                                            >
                                                <TableCell>
                                                    {item.user_point_id}
                                                </TableCell>
                        
                                                <TableCell>
                                                    <div className='flexTd'>
                                                        <span className='flexTd_bold'>{new Date(item.date).toLocaleDateString()}</span>
                                                        
                                                        <span>{item.description}</span>
                                                    </div>
                                                </TableCell>
                        
                                                <TableCell>
                                                    {item.points}
                                                </TableCell>
                        
                                                <TableCell align='right' style={{width: '100px'}}>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            setOpenAlert(true);
                                                            setActiveItemId(item.user_point_id);
                                                            
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>

                                                </TableCell>
                                                
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>

                            {totalPages > 1 && 
                            <Pagination
                                count={totalPages}
                                page={Number(page)}
                                onChange={(event, value) => {
                                    setPage(value);
                                }} 
                                color="primary"
                                style={{"marginTop": "20px","alignSelf": "center"}}
                            />
                            }
                        </>
                        ) : (
                            <EmptyElement 
                                title="Without points"
                                subtitle="The user has no points yet"
                             />
                          )}
                        </Box>
                    </div>
                </div>
            </div>

            <PerformanceDialog
                open={openDialog}
                setOpen={setOpenDialog}
                setLoading={setLoading}
                user_id={user_id}
                user_point_id={activeItemId}
                loadPointsList={loadPointsList}
            />


            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle="Are you sure you want to delete this record?"
                send={deleteItem}
            />
        </>
    )
}