import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { Chip, IconButton, Popover, Tooltip } from '@mui/material';
import ic_dots from 'icons/ic_dots.svg';
import { DocumentScannerOutlined, Preview } from '@mui/icons-material';
import FilesBrowser from 'components/Files/FilesBrowser';



export default function AutocompleteProduct(props: any) {

  const {
    dotsClick,
    setActiveItemParams = () => { },
    productAsObj = false,
    infoToShow = undefined,
    withImageViewerButton = false,
  } = props;
  
  const [loading, setLoading] = useState(false); 

  const addProduct = props.addProduct;

  /* =================================================
    PRODUCT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface IProductList {
      inputValue?: string,
      product_id?: number,
      sale_price: number,
      stone_fields: any,
      product_name: string,
      disabled?: boolean,
      caso_code?: string,
      product_description?: string,
    };

    const [item, setItem] = useState<any>({});
    const [addedTrigger, setAddedTrigger] = useState<boolean>(props.addedTrigger? props.addedTrigger : false)

    const [openGalleryPopover, setOpenGalleryPopover] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const filter = createFilterOptions<IProductList>();

    //SETTER
    const [productList, setProductList] = useState<any[]>([]);
    
    useEffect(() => {
      loadData();
    }, []);

    useEffect(() => {
      loadData();
    }, [props.category_filter]);

    useEffect(() => {
      if (props.addedTrigger) setAddedTrigger(props.addedTrigger);
    }, [props.addedTrigger]);

    useEffect(() => {
      if (addedTrigger) {
        loadData();
        setAddedTrigger(false);
      }
    }, [addedTrigger]);
        

    const loadData = () => {
      axios.get(ProjectConfig.api_url,
        {
          params: {
            request: 'product_list',
            params: {
              category: props.category_filter,
              full_list: true,
              hide_groups: props.hide_groups,
          }
        }
      })
        .then((response) => {
          // console.log(response.data)
          setProductList(response.data.products);
          setLoading(true)
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
    /* ================================================= 
    ===================================================*/

    useEffect(() => {
      if (props.product_name) {
        setItem({
          ...item,
          product_name: props.product_name,
        });
      }
    }, [props]);

    useEffect(() => {
      if (productList.length > 0 && props.product_name) {
        const product = productList.find((product: any) => product.product_name === props.product_name);
        
        if (product) {
          setItem({
            ...product,
            product_name: product.product_name,
          });
          getFolderId(product.product_id);
        }
      }
    }, [productList, props.product_name]);

    useEffect(() => {
      if (anchorEl !== null) {
        setOpenGalleryPopover(true);
      }
    }, [anchorEl]);

    useEffect(() => {
      if (!openGalleryPopover) {
        setAnchorEl(null);
      }
    }, [openGalleryPopover]);

    const getFolderId = (product_id: number) => {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "product_details",
            product_id: [product_id]
          },
        })
        .then((response) => {
          // console.log(response.data)
          
          if (response.data.length > 0) {
            setItem((prevState: any) => {
              return {
                ...prevState,
                folder_id: response.data[0].folder_id,
              };
            });
          }
          
        })
        .catch((err) => {
          console.log(err.data);
        });

    }

  return (
    <>
      <Autocomplete
      
        disabled = {props.disabled? props.disabled : false}
        // value={props.product_name}
        value={'' + props.product_name}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            props.setProduct({
              product_name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            addProduct(newValue.inputValue);

          } else {

            if (productAsObj) {
              props.setProduct({
                product_name: newValue.product_name,
                product_id: newValue.product_id,
                sale_price: newValue.sale_price,
                caso_code: newValue.caso_code,
                product_description: newValue.product_description,
                stone_fields: newValue.stone_fields,
              });
            } else {
              props.setProduct(
                newValue.product_name,
                newValue.product_id,
                newValue.sale_price,
                newValue.stone_fields,
                newValue.caso_code,
                props.serviceIndex,
                props.category,
                props.itemIndex,
                newValue.product_type
              );
            }
            
          }
          
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.product_name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              product_name: `Add "${inputValue}"`,
              sale_price: 0,
              stone_fields: [],
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        fullWidth
        id="autocomplete_product"
        className='autocomplete_products'
        options={productList}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.product_name;
        }}
        renderOption={
          (props, option) =>
          <li {...props} key={option.product_type + option.product_id + option.product_name}>
            <Tooltip title={option.product_description} placement="top-start">
              <div className='liAutocomplete_product'>
                <span className='autocomplete_product_name'>{option.product_name}</span>

                {option.caso_code &&
                <span className='autocomplete_product_code'>{option.caso_code}</span>
                }
              </div>
            </Tooltip>
          </li>
        }
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => {
          const renderInput = (
            <TextField 
              {...params}
              fullWidth
              label="Product" 
              size='small'
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    <IconButton 
                    className="autocomplete_ic_dots"
                    onClick={()=>{
                      dotsClick(true);

                      if (setActiveItemParams) {
                        setActiveItemParams({
                          product_name: "",
                          product_id: null,
                          sale_price: 0,
                          stone_fields: [],
                          caso_code: "",
                          serviceIndex: props.serviceIndex,
                          category: props.category,
                          itemIndex: props.itemIndex,
                          product_type: "",
                        })
                      }
                    }}
                    
                  >
                    <img src={ic_dots}></img>
                  </IconButton>

                  {(withImageViewerButton && item.product_id && item.folder_id!=0 && item.folder_id!=undefined) &&
                  <IconButton
                    className="autocomplete_ic_dots ic_autocomplete_button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    {/* <DocumentScannerOutlined /> */}
                    <Preview />
                  </IconButton>
                  }
                  
                  <span className='chip_code'>{props.caso_code}</span>
                  </React.Fragment>
                ),
                style: {
                  fontSize: '12px',
                }
              }}/>
          );

          const render =
            infoToShow === undefined ?
            renderInput :
            <Tooltip title={infoToShow} placement="bottom" arrow>
              {renderInput}
            </Tooltip>;

        return render;
        }}
      />

      {(withImageViewerButton && item.product_id && item.folder_id!=0 && item.folder_id!=undefined) &&
      <Popover
        open={openGalleryPopover}
        onClose={() => setOpenGalleryPopover(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <FilesBrowser
          setLoading={setLoading}
          folder_id={item.folder_id} 
          view_type="slide_gallery"
        />
      </Popover>
      }
    </>
  );
}
