import React, { useEffect, useState } from "react";
import { DeleteOutlineOutlined, Edit } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import AlertDialog from "elements/AlertDialog";
import Box from "elements/Box";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import "assets/css/main.css"
import axios from "axios";
import { ProjectConfig } from "Global";
import { useSnackbar } from "notistack";

export default function PointsList( props: any ) {

    /*=========================================================
    SNACKBAR
    =========================================================*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    //=======================================================//

    const navigate = useNavigate();
    
    useEffect(() => {props.setLoading(false);}, []);
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const [list, setList] = useState<any []>([]);
    const [pointsId, setPointsId] = useState("null");
    const [page, setPage] = useState(searchParams.get("page") || 1);
    const [totalPages, setTotalPages] = useState(1);

    const [openAlert, setOpenAlert] = useState(false);

    useEffect(() => {
        setLoading(false);
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [page]);

    const loadData = () => {
        setLoading(true);
        axios.get(ProjectConfig.api_url, {
            params: {
                request: "get_points_list",
                params: {
                    page: page,
                }
            },
        })
        .then((response) => {
            console.log(response.data);
            setList(response.data.list);
            setTotalPages(response.data.total_pages);
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleDelete = () => {
        axios.post(ProjectConfig.api_url, null,{
            params: {
                request: 'delete_points',
                point_id: pointsId,
            }
        })
        .then((response) => {
            console.log(response.data);
            enqueueSnackbar('Points deleted successfully.', {variant: 'success'});
            loadData();
        })
        .catch((err) => {
            console.log(err.data);
            enqueueSnackbar('Error deleting points.', {variant: 'error'});
        });
    }

    return (
        <div id='pointsList' className='moduleContent'>
            
            <div className='buttons_top'>
                <div></div>
                <Button
                    component={Link}
                    to="add"
                    variant="contained"
                >
                    New Points
                </Button>
            </div>

            <div id="point_list_container" className="flex_container">
                
                <Box id='pointsListBox' title="Points" subtitle="List"  >
                    {loading && <div id='loader'><CircularProgress /></div>}
                    
                    <Table stickyHeader aria-label="sticky table"> 
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>DESCRIPTION</TableCell>
                                <TableCell>POINTS</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        
                        <TableBody>
                            {list.map((item,i) => (
                                <TableRow 
                                    key={i}
                                    hover
                                    role="checkbox"
                                >
                                    <TableCell>
                                        {item.point_id}
                                    </TableCell>

                                    <TableCell>
                                        {item.description}
                                    </TableCell>

                                    <TableCell>
                                        {item.points}
                                    </TableCell>

                                    <TableCell
                                        sx={{ width: 200, textAlign: 'end', paddingRight: 3}}
                                    >
                                        <Button
                                            component={Link}
                                            to={`edit/${item.point_id}`}
                                            variant="contained"
                                            color="primary"
                                            startIcon={<Edit />}
                                        >
                                            Edit
                                        </Button>
                                        <IconButton
                                            aria-label="delete"
                                            color="primary"
                                            onClick={() => {
                                                setPointsId(item.point_id);
                                                setOpenAlert(true);
                                            }}>
                                            <DeleteOutlineOutlined />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                
                    <Pagination
                        count={totalPages}
                        page={Number(page)}
                        onChange={(event, value) => {
                            setPage(value);
                            navigate(
                                {
                                    pathname: "/points",
                                    search: `?page=${value}`,
                                },
                            );
                        }} 
                        color="primary"
                        style={{"marginTop": "20px","alignSelf": "center"}}
                    />

                </Box>
        
            </div>

            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle="Are you sure you want to delete this client?"
                send={handleDelete}
            />
        </div>
    )
}