import {
  Autocomplete,
  Badge,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Switch,
  SwitchProps,
  TextField,
  Tooltip,
} from "@mui/material";
import AutocompleteProduct from "../../utils/AutoCompleteProduct";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Fragment, useContext, useEffect, useState } from "react";
import Box from "elements/Box";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { PriorityHigh } from "@mui/icons-material";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function QuoteExplodedView(props: any) {

  const {
    setOpenProductList,
    services,
    setServices,
    updateQuoteText,
    setActiveItemParams,
    setOpenDialogServiceCreator,
    explodedItemAnchor,
    setExplodedItemAnchor,
    showView,
    setStoneWorkforceCost,
    fixNumberValue,
  } = props;

  const [openSectionItemCreator, setOpenSectionItemCreator] = useState(false);
  const [openSectionCreator, setOpenSectionCreator] = useState(false);
  const [sectionName, setSectionName] = useState("");

  const handleDeleteMeasure = (
    serviceIndex: any,
    category: any,
    itemIndex: any,
    measureIndex: any
  ) => {
    const newServices = [...props.services];
    newServices[serviceIndex][category].products[itemIndex].measures.splice(
      measureIndex,
      1
    );

    let newMeasure = newServices[serviceIndex][category].products[itemIndex];

    if (category.category_var === "stone") {
      newMeasure.sq_ft = newMeasure.measures.reduce((subTotal: number, measure: any) => subTotal + (measure.sq_ft * measure.quantity), 0);
      setStoneWorkforceCost(serviceIndex, itemIndex);
    }

    props.setServices(newServices);
    props.updateProductQuantity(serviceIndex, category, itemIndex);
  };

  const handleAddMeasure = (
    serviceIndex: any,
    category: any,
    itemIndex: any
  ) => {
    const newServices = [...props.services];
    newServices[serviceIndex][category].products[itemIndex].measures.push({
      measure: "",
      quantity: 0,
      description: "",
      sheets: 0,
      sq_ft: 0,
    });
    props.setServices(newServices);
  };

  const handleAddSection = () => {
    props.setSectionList([...props.sectionList, { section_name: sectionName }]);
    setSectionName("");
  };

  useEffect(() => {
    //SCROLL WINDOW TO ServiceItemAnchor POSITION
    if (explodedItemAnchor) {
      console.log(explodedItemAnchor);
      const element = document.getElementById(explodedItemAnchor);
      element?.scrollIntoView({ behavior: "smooth" }); 
    }
  }, [explodedItemAnchor]);

  /*====================================================
    HANDLE MEASURE
  ====================================================*/
  const handleMeasure = (
    value: any,
    serviceIndex: any,
    category: any,
    itemIndex: any,
    measureIndex: any
  ) => {

    const newServices = [...props.services];
    newServices[serviceIndex][category.category_var].products[
      itemIndex
    ].measures[measureIndex].measure = value;
    props.setServices(newServices);
  };

  /*====================================================
    HANDLE WIDTH
  ====================================================*/
  const handleMeasureWidth = (
    value: any,
    serviceIndex: any,
    category: any,
    itemIndex: any,
    measureIndex: any
  ) => {
    const newServices = [...props.services];
    let newMeasure = newServices[serviceIndex][category.category_var].products[itemIndex];
    
    newMeasure.measures[measureIndex].width = fixNumberValue(value, true, false, true);

    newMeasure.measures[measureIndex] = calcSqFt(newMeasure, serviceIndex, category, itemIndex, measureIndex);
    
    props.setServices(newServices);
  };
  
  /*====================================================
  HANDLE LENGTH
  ====================================================*/
  const handleMeasureLength = (
    value: any,
    serviceIndex: any,
    category: any,
    itemIndex: any,
    measureIndex: any
    ) => {
    const newServices = [...props.services];
    let newMeasure = newServices[serviceIndex][category.category_var].products[itemIndex];
    
    newMeasure.measures[measureIndex].length = fixNumberValue(value, true, false, true);

    newMeasure.measures[measureIndex] = calcSqFt(newMeasure, serviceIndex, category.category_var, itemIndex, measureIndex);

    props.setServices(newServices);
  };
  
  /*====================================================
  CALC SQURE FEET
  ====================================================*/
  const calcSqFt = (measure: any, serviceIndex: number, category: any, itemIndex: number, measureIndex: number) => {
    if(measure.measures[measureIndex].width >= 0 && measure.measures[measureIndex].length >= 0) {
      measure.measures[measureIndex].sq_ft = Math.round((measure.measures[measureIndex].width * measure.measures[measureIndex].length / 144));
      measure.sq_ft = measure.measures.reduce((subTotal: number, measure: any) => subTotal + (measure.sq_ft * measure.quantity), 0);
      setStoneWorkforceCost(serviceIndex, itemIndex);
    } else {
      measure.sq_ft =
        measure.measures
          .filter( (measure: any, index: number) => index != measureIndex)
          .reduce((subTotal: number, measure: any) => subTotal + (measure.sq_ft * measure.quantity), 0);
          
      setStoneWorkforceCost(serviceIndex, itemIndex);
    }
    updateQuoteText(serviceIndex, category, itemIndex)

    return measure.measures[measureIndex];
  }
    
  /*====================================================
  HANDLE QUANTITY
  ====================================================*/
  const handleMeasureQuantity = (
    value: any,
    serviceIndex: any,
    category: any,
    itemIndex: any,
    measureIndex: any
  ) => {
    const newServices = [...props.services];
    let newMeasure = newServices[serviceIndex][category.category_var].products[itemIndex];

    newMeasure.measures[measureIndex].quantity = fixNumberValue(value, false, false, true);

    if (category.category_var === "stone") {
      newMeasure.measures[measureIndex] = calcSqFt(newMeasure, serviceIndex, category.category_var, itemIndex, measureIndex);
    }

    props.setServices(newServices);
  };

  /*====================================================
    HANDLE DESCRIPTION
  ====================================================*/
  const handleMeasureDescription = (
    value: any,
    serviceIndex: any,
    category: any,
    itemIndex: any,
    measureIndex: any
  ) => {
    
    const newServices = [...props.services];
    newServices[serviceIndex][category.category_var].products[
      itemIndex
    ].measures[measureIndex].description = value;
    props.setServices(newServices);
  };

  /*====================================================
    HANDLE SHEETS
  ====================================================*/

  const handleMeasureSheets = (
    value: any,
    serviceIndex: any,
    category: any,
    itemIndex: any,
    measureIndex: any
  ) => {

 


    //if value start with 0 and after has a number, remove 0
    if (value[0] === "0" && value[1] !== ".") {
      value = value.slice(1);
    }

    const decimalRegex = /^\d*\.?\d*$/;

    if (!decimalRegex.test(value)) {
      return;
    }

       //if value is empty set 0
       if (value === "") {
        value = 0;
      }

    // console.log("handleMeasure", value, serviceIndex, category, itemIndex, measureIndex);
    const newServices = [...props.services];
    newServices[serviceIndex][category.category_var].products[
      itemIndex
    ].measures[measureIndex].sheets = value 
    props.setServices(newServices);
    //UPDATE QUANTITY OF PRODUCT
    props.updateProductQuantity(serviceIndex, category.category_var, itemIndex);
  };

  /*====================================================
    HANDLE ADD ITEM TO SECTION
  ====================================================*/

  const handleAddItem = (
    sectionIndex: any,
    serviceIndex: any,
    serviceType: any,
    category_var: any
  ) => {
    const newServices = [...props.services];

    if (serviceType !== "stone") {
      newServices[serviceIndex][category_var].products.push({
        product_id: "",
        product_name: "",
        quantity: 0,
        section: props.sectionList[sectionIndex].section_name,
        sale_price: 0,
        measures: [],
      });
    } else {
      newServices[serviceIndex][category_var].products.push({
        product_id: "",
        product_name: "",
        quantity: 0,
        section: props.sectionList[sectionIndex].section_name,
        sale_price: 0,
        price_with_manufacturing: 0,
        price_without_manufacturing: 0,
        workforce_cost: 0,
        destination: "countertop",
        edge: "",
        finish: "",
        sink: "",
        sq_ft: 0,
        have_workforce: true,
        measures: [],
      });
    }

    props.setServices(newServices);
  };

    /*====================================================
    ====================================================
      RENDER 
    ====================================================
    ====================================================*/
    return (
    <div
        id="quoteExplodedView"
        style={{ display: props.showExplodedView ? "flex" : "none" }}
    >
      
      {props.services.map((service: any, serviceIndex: any) => {
        return (
          <div className={"QuoteService "+(service.serviceOpen ? "serviceOpen" : "serviceClose")} key={serviceIndex}>
            
            <div className={"QuoteServiceCategory type_" + service.type}>

                    <IconButton
                        aria-label="Expand"
                        size="small"
                        //colo white
                        style={{color: "#fff"}}
                        onClick={() => {
                            const newServices = [...services];
                            newServices[serviceIndex].serviceOpen = !newServices[serviceIndex].serviceOpen;
                            setServices(newServices);
                        }}
                    >
                        {service.serviceOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>

              <span className="QuoteServiceText">
                {service.name}
              </span>
                    
            </div>
            <div className="QuoteServiceItems ExplodedViewServieItems">
              <div className="QuoteServiceHeader ">
                <div className="QuoteServiceHeaderLeft">
                    <span className="QuoteServiceName">
                        {service.name}
                    </span>
                </div>
              </div>


              {props.categories[service.type].map(
                (category: any, categoryIndex: any) => {
                  const category_name = category.category_name;
                  const category_var = category.category_var;
                  const category_filter = category.category_filter;
                  const categoryElement = "catIndexQuote"+serviceIndex+"-"+categoryIndex;

                  return (
                    // EXCLUDE CATEGORIES WORKFORCE, PAINT AND HARDWARE

                    category_var !== "workforce" &&
                    category_var !== "paint" &&
                    category_var !== "hardware" && (
                      <Box className="categoryRow explodedCategoryRow" key={categoryIndex}>
                        <div id={"catIndexExploded"+serviceIndex+"-"+categoryIndex} className="explodedCategoryRowHeader">
                          <h2>{category_name}

                            <IconButton
                                aria-label="Expand"
                                size="small"
                                onClick={() => {
                                    showView("quote")
                                    setExplodedItemAnchor(categoryElement);

                                }}
                            >
                                <RequestQuoteIcon />
                            </IconButton>
                          </h2>
                            
                           <Button 
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={(e) => {
                                    const boxContent = e.currentTarget.closest(".box_content");
                                    if (!boxContent) return;
                                    const explodedSections = boxContent.querySelectorAll(".explodedSection");

                                    //toggle class .forceShowSection to all sections
                                    explodedSections.forEach((section: any) => {
                                        section.classList.toggle("forceShowSection");
                                    })
                                }}
                            >
                                Show/Hide All Sections
                            </Button>
                                    
                                

                              


                        </div>

                        <div className="explodedSections">
                          {props.sectionList.map(
                            (section: any, sectionIndex: any) => {

                              //check if service[category_var].products have the section 
                              const haveSection = service[category_var].products.find((item: any) => item.section == section.section_name);

                              return (
                                <div
                                //"explodedSection SwitchGroup" + HAVESECTION
                                  className={"explodedSection SwitchGroup " + (haveSection ? "haveSection" : "notHaveSection")}
                                  key={sectionIndex}
                                >
                                  <h2 className="sectionServiceHeader">
                                    Section: {section.section_name}
                                  </h2>
                                  <div className="sectionServiceItems">
                                    {service[category_var].products.map(
                                      (item: any, itemIndex: any) => {

                                        //if NOT HAVE SECTION NOT SHOW
                                        if (item.section == "") {
                                          return null;
                                        }

                                        const handleMatch = item.quantity == Math.ceil(item.measures.reduce( (subTotal: number, measure: any) => subTotal + (parseFloat(measure.sheets) || 0), 0));

                                        return (
                                          <div
                                            className="explodedItem"
                                            key={itemIndex}
                                          >
                                            {item.section ==
                                              section.section_name && (
                                              <Box
                                                className="sectionProductRow"
                                                key={itemIndex}
                                              >
                                                <div className="sectionProductRowHeader">
                                                  <div className="sectionProductRowHeaderRight">
                                                    <AutocompleteProduct
                                                      category_filter={category.category_filter}
                                                      setProduct={props.setProduct}
                                                      product_name={item.product_name}
                                                      caso_code={item.caso_code}
                                                      category={category.category_var }
                                                      serviceIndex={serviceIndex}
                                                      itemIndex={itemIndex}
                                                      dotsClick={setOpenProductList}
                                                      setActiveItemParams={setActiveItemParams}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="sectionProductRowBody">
                                                  <div className="sectionProductRowBodyFlex">
                                                    <table className="measureRowtable">
                                                      <thead>
                                                        <tr>
                                                          <th style={{width: '200px'}}>Measure</th>
                                                          <th>Quantity</th>
                                                          {service.type === 'stone' && <th>Sq Ft</th>}
                                                          <th>Description</th>
                                                          <th>{service.type !== 'stone' ? "Sheets" : "Slabs"}</th>
                                                          <th></th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {item.measures.map(
                                                          (
                                                            measure: any,
                                                            measureIndex: any
                                                          ) => {
                                                            return (
                                                              <tr
                                                                key={
                                                                  measureIndex
                                                                }
                                                              >
                                                                <td
                                                                  className="measuresTextTd"
                                                                  key={
                                                                    measureIndex
                                                                  }
                                                                >
                                                                  {service.type !== 'stone' ? <TextField
                                                                    value={
                                                                      measure.measure
                                                                    }
                                                                    type="text"
                                                                    size="small"
                                                                    label="Measure"
                                                                    fullWidth
                                                                    inputProps={{
                                                                      style: {
                                                                        textAlign:
                                                                          "center",
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    InputLabelProps={{
                                                                      style: {
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      handleMeasure(
                                                                        e.target
                                                                          .value,
                                                                        serviceIndex,
                                                                        category,
                                                                        itemIndex,
                                                                        measureIndex
                                                                      );
                                                                    }}
                                                                  />
                                                                  :
                                                                  <>
                                                                  
                                                                  <TextField
                                                                    value={
                                                                      measure.width
                                                                    }
                                                                    type="text"
                                                                    size="small"
                                                                    label="Width"
                                                                    fullWidth
                                                                    inputProps={{
                                                                      style: {
                                                                        textAlign:
                                                                          "center",
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    InputLabelProps={{
                                                                      style: {
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      handleMeasureWidth(
                                                                        e.target
                                                                          .value,
                                                                        serviceIndex,
                                                                        category,
                                                                        itemIndex,
                                                                        measureIndex
                                                                      );
                                                                    }}
                                                                  />

                                                                  <TextField
                                                                    value={
                                                                      measure.length
                                                                    }
                                                                    type="text"
                                                                    size="small"
                                                                    label="Length"
                                                                    fullWidth
                                                                    inputProps={{
                                                                      style: {
                                                                        textAlign:
                                                                        "center",
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    InputLabelProps={{
                                                                      style: {
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    onChange={(
                                                                      e
                                                                      ) => {
                                                                        handleMeasureLength(
                                                                          e.target
                                                                          .value,
                                                                          serviceIndex,
                                                                          category,
                                                                          itemIndex,
                                                                          measureIndex
                                                                          );
                                                                        }}
                                                                  />
                                                                  </>
                                                                  }
                                                                </td>

                                                                <td className="MeasureQuantityTd">
                                                                  <TextField
                                                                    value={
                                                                      measure.quantity
                                                                    }
                                                                    type="text"
                                                                    size="small"
                                                                    label="Quantity"
                                                                    fullWidth
                                                                    inputProps={{
                                                                      style: {
                                                                        textAlign:
                                                                          "center",
                                                                        fontSize: 12,
                                                                        width: 30,
                                                                      },
                                                                    }}
                                                                    InputLabelProps={{
                                                                      style: {
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      handleMeasureQuantity(
                                                                        e.target
                                                                          .value,
                                                                        serviceIndex,
                                                                        category,
                                                                        itemIndex,
                                                                        measureIndex
                                                                      );
                                                                    }}
                                                                  />
                                                                </td>

                                                                {service.type === 'stone' &&
                                                                <td className="MeasureQuantityTd">
                                                                  <TextField
                                                                    value={measure.sq_ft * measure.quantity}
                                                                    type="text"
                                                                    size="small"
                                                                    label="Sq Ft"
                                                                    fullWidth
                                                                    inputProps={{
                                                                      style: {
                                                                        textAlign:
                                                                        "center",
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    InputLabelProps={{
                                                                      style: {
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    disabled
                                                                  />
                                                                </td>
                                                                }
                                                                <td>
                                                                  <TextField
                                                                    value={
                                                                      measure.description
                                                                    }
                                                                    type="text"
                                                                    size="small"
                                                                    label="Description"
                                                                    fullWidth
                                                                    inputProps={{
                                                                      style: {
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    InputLabelProps={{
                                                                      style: {
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      //UPDATE PRODUCT DESCRIPTION
                                                                      handleMeasureDescription(
                                                                        e.target
                                                                          .value,
                                                                        serviceIndex,
                                                                        category,
                                                                        itemIndex,
                                                                        measureIndex
                                                                      );
                                                                    }}
                                                                  />
                                                                </td>
                                                                <td className="MeasureQuantityTd">
                                                                  <TextField
                                                                    value={
                                                                      measure.sheets
                                                                    }
                                                                    type="text"
                                                                    size="small"
                                                                    label={service.type !== 'stone' ? "Sheets" : "Slabs"}
                                                                    fullWidth
                                                                    inputProps={{
                                                                      style: {
                                                                        textAlign:
                                                                          "center",
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    InputLabelProps={{
                                                                      style: {
                                                                        fontSize: 12,
                                                                      },
                                                                    }}
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      handleMeasureSheets(
                                                                        e.target
                                                                          .value,
                                                                        serviceIndex,
                                                                        category,
                                                                        itemIndex,
                                                                        measureIndex
                                                                      );
                                                                    }}
                                                                  />
                                                                </td>
                                                                <td className="deleteButtonTd">
                                                                  <Button
                                                                    onClick={() => {
                                                                      handleDeleteMeasure(
                                                                        serviceIndex,
                                                                        category.category_var,
                                                                        itemIndex,
                                                                        measureIndex
                                                                      );
                                                                    }}
                                                                  >
                                                                    <DeleteOutlineOutlinedIcon />
                                                                  </Button>
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      </tbody>
                                                    </table>
                                                    <div className="measureRowQuantity">
                                                      <Tooltip title={ handleMatch ? "" : "Warning: quantity in the quote view and the sum of sheets do not match"}>
                                                        <Badge
                                                          color="secondary"
                                                          badgeContent={<PriorityHigh sx={{width: '12px', height: '12px', m: '0 -6px'}} />}
                                                          invisible={handleMatch}
                                                        >
                                                          <div className="measureRowQuantityValue">
                                                            {item.quantity}
                                                          </div>
                                                        </Badge>
                                                      </Tooltip>
                                                      <div className="measureRowQuantityLabel">
                                                        { "Total " + (service.type !== 'stone' ? "Sheets" : "Slabs")}
                                                      </div>
                                                      <div className="measureRowQuantityPrice">
                                                        $
                                                        {(
                                                          item.sale_price *
                                                          item.quantity
                                                        ).toLocaleString()}
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <Button
                                                    className="measure_add_button"
                                                    variant="outlined"
                                                    onClick={() => {
                                                      handleAddMeasure(
                                                        serviceIndex,
                                                        category.category_var,
                                                        itemIndex
                                                      );
                                                    }}
                                                  >
                                                    Add measure
                                                  </Button>
                                                </div>
                                              </Box>
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>

                                  <Button
                                    className="item_add_button"
                                    onClick={() => {
                                      handleAddItem(
                                        sectionIndex,
                                        serviceIndex,
                                        service.type,
                                        category_var
                                      );
                                    }}
                                  >
                                    Add item
                                  </Button>
                                </div>
                              );
                            }
                          )}
                        </div>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setOpenSectionCreator(true);
                          }}
                        >
                          Add Section
                        </Button>
      
                      </Box>
                    )
                  );
                }
              )}
            </div>
          </div>
        );
      })}

      <Dialog
        open={openSectionCreator}
        onClose={() => setOpenSectionCreator(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent id="addSectionDialog" className="forced_box_editor">
          <div className="DialogHeader">
            <h2>ADD SECTION</h2>
            <span>Enter the section name to add it.</span>
          </div>

          <TextField
            id="standard-basic"
            label="Section name"
            fullWidth
            value={sectionName}
            onChange={(e) => setSectionName(e.target.value)}
          />
          <div className="buttons">
            <Button
              variant="outlined"
              onClick={() => {
                setOpenSectionCreator(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleAddSection();
                setOpenSectionCreator(false);
              }}
            >
              Add
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      

      <Button className="dashedButton" onClick={()=>{setOpenDialogServiceCreator(true)}}>Add service</Button>
    </div>
  );
}
