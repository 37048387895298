import axios from "axios";
import { SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {
  Autocomplete,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  SwitchProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import ic_dots from "icons/ic_dots.svg";
import ic_project_cost from "icons/ic_project_cost.svg";
import ic_design_fee from "icons/ic_design_fee.svg";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SendIcon from "@mui/icons-material/Send";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { ProjectConfig } from "Global";
import { SnackbarProvider, VariantType, useSnackbar, withSnackbar } from 'notistack';
import ProjectHeader from "./utils/ProjectHeader";

const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface Props {
  setSubmenu: any;
}

interface IProductList {
  inputValue?: string;
  estimate_product_id?: number;
  estimate_product_name: string;
  estimate_product_prices?: any;
}

const arrayProducts = [
  {
    estimate_product_id: "",
    estimate_product_name: "",
    measure_type: "linear",
    size: "",
    estimate_product_prices_list: [],
    estimate_product_prices: {
      low: "",
      mid: "",
      high: "",
      design_fee: "",
    },
  },
];


const ProjectEstimates = (props: any) => {

  const {setLoading, setMenuTopLeftContent} = props;

  useEffect(() => {setLoading(false);}, []);

  const theme = useTheme();

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */


  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let { project_id, estimate_id } = useParams();
  /*=======================================
  =======================================*/


  /*=======================================
    DEFAULT VALUES FOR FORMVALUES
  =======================================*/
  const formValuesDefault = {
    project_id: project_id,
    estimate_id: estimate_id? estimate_id : "null",
    estimate_name: "",
    total_low: "",
    total_mid: "",
    total_high: "",
    total_design_fee: 0,
    pdf_level: {
      low: true,
      mid: true,
      high: true,
    },
    pdf_options: {
      budget_op1: true,
      budget_op2: true,
      budget_op3: true,
      design_op1: false,
      design_op2: false,
      design_op3: false,
      design_op4: false,
      design_op5: false,
      design_op6: false,
      design_op7: false,
    },
    products: {},
  };

  /*=======================================
  =======================================*/

  const navigate = useNavigate();

  const [productsGroup, setProductsGroup] = useState<any[]>(arrayProducts);
  const [refreshEstimate, setRefreshEstimate] = useState(0);
  const [formValues, setFormValues] = useState<any>(formValuesDefault);

  const [estimateProductList, setEstimateProductList] = useState<any[]>([]);

  const [open, setOpen] = useState(false);
  const [activeProductForModal, setActiveProductForModal] = useState(0);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sendEmailDialogOpen, setSendEmailDialogOpen] = useState(false);
  const [estimateConfigOpen, setEstimateConfigOpen] = useState(false);

  const handleCloseEstimateConfigDialog = () => {
    setEstimateConfigOpen(false);
  };


  const handleCloseSendEmailDialog = () => {
    setSendEmailDialogOpen(false);
  };

  const handleOpenProductList = (product: any) => {
    setActiveProductForModal(product);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleClickProduct = (i: number, name: String, PricesList: any) => {
    let newArrayProducts = [...productsGroup];
    newArrayProducts[i].estimate_product_name = name;
    newArrayProducts[i].estimate_product_prices_list = PricesList;
    newArrayProducts[i].estimate_product_prices =
      PricesList[parseInt(newArrayProducts[i].measure_type) - 1].prices[
        parseInt(newArrayProducts[i].size) - 1
      ].price_levels;

    setProductsGroup(newArrayProducts);
  };

  /*=======================================
   PUSH NEW PRODUCT TO PRODUCTS GROUP
  =======================================*/
  const pushArrayProducts = () => {
    productsGroup.push({
      estimate_product_id: "",
      estimate_product_name: "",
      measure_type: "linear",
      size: "",
      estimate_product_prices_list: [],
      estimate_product_prices: {
        low: "",
        mid: "",
        high: "",
        design_fee: "",
      },
    });

    setRefreshEstimate(refreshEstimate + 1);
  };
  /*=======================================
  =======================================*/

  /*=======================================
   DELETE  PRODUCT FROM PRODUCTS GROUP
  =======================================*/
  const handleDelete = (i: number) => {
    let newArrayProducts = [...productsGroup];
    newArrayProducts.splice(i, 1);
    setProductsGroup(newArrayProducts);
    setRefreshEstimate(refreshEstimate + 1);
  };
  /*=======================================


  
  /*=================================================
  (AUTOCOMPLETE )GET ESTIMATE PRODUCT LIST
  =================================================*/
  useEffect(() => {
    setLoading(true);
    axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "estimate_product_list",
        },
      })
      .then((response) => {
        setEstimateProductList(response.data.products);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);
  /* ================================================= 
  ===================================================*/

  /*=================================================
  UPDATE formValues WHEN productsGroup CHANGES
  =================================================*/
  useEffect(() => {
    // FIND AND SUM LOW, MID, HIGH PRICES
    let totalLow = 0;
    let totalMid = 0;
    let totalHigh = 0;
    let totalDesignFee = 0;

    productsGroup.forEach((product) => {
      const tempArray = product.estimate_product_prices;

      //if size is not empty"
      if (product.size !== "") {
        const size = parseInt(product.size);

        totalLow += tempArray.low ? parseInt(tempArray.low) * size : 0;
        totalMid += tempArray.mid ? parseInt(tempArray.mid) * size : 0;
        totalHigh += tempArray.high ? parseInt(tempArray.high) * size : 0;
      }

      totalDesignFee += tempArray.design_fee
        ? parseInt(tempArray.design_fee)
        : 0;
    });

    setFormValues({
      ...formValues,
      total_low: totalLow,
      total_mid: totalMid,
      total_high: totalHigh,
      total_design_fee: totalDesignFee,
      products: productsGroup,
    });
  }, [productsGroup]);
  /*=================================================*/

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=================================================
  SUMBIT FORM
  =================================================*/
  const handleSubmit = (callback = (estimate_id = 'null') => {}) => {

    //formdata 
    var formdata = new FormData();
    formdata.append("params", JSON.stringify({
        formdata: formValues,
    }));

    console.log(formValues);
    
    axios
      .post(ProjectConfig.api_url, formdata, {
        params: {
          request: "add_edit_project_estimate",
        },
      })
      .then((response) => {
        console.log(response);

        if (formValues.estimate_id === 'null') {
          navigate(`/projects/${project_id}/estimates/edit/${response.data.estimate_id}`);
          //set estimate_id in setFormValues
          setFormValues({
            ...formValues,
            estimate_id: response.data.estimate_id,
          });
        }
        callback(response.data.estimate_id);
        
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  /*=================================================
  ===================================================*/

  /*=================================================
  GET PROJECT ESTIMATE DETAILS (IF ESTIMATE ID EXISTS)
  =================================================*/

  useEffect(() => {
    if (estimate_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "project_estimate_details",
            estimate_id: estimate_id,
          },
        })
        .then((response) => {
          console.log("response",JSON.parse(response.data.pdf_options)); 
          setProductsGroup(JSON.parse(response.data.estimate_data));
          setFormValues({
            ...formValues,
            ["estimate_name"]: response.data.estimate_name,
            ["pdf_level"]: JSON.parse(response.data.pdf_level),
            ["pdf_options"]: JSON.parse(response.data.pdf_options),
          });
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=================================================
  ===================================================*/

  const ListItemIconStyles = {
    minWidth: 0,
    mr: drawerOpen ? 3 : "auto",
    justifyContent: "center",
  };

  const ListItemButtonStyles = {
    minHeight: 48,
    justifyContent: drawerOpen ? "initial" : "center",
    px: 2.5,
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDownloadEstimate = (estimate_id = 'null') => {
    console.log(estimate_id)
    axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "download_project_estimate_pdf",
          estimate_id: estimate_id,
        },
      })
      .then((response) => {
        console.log(response);
        window.open(response.data, "_blank", "noopener,noreferrer");
      })
      .catch((err) => {
        console.log(err.data);
      });
  };


  const handleSendEmail = (estimate_id = 'null') => {
    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "send_email_project_estimate",
          estimate_id: estimate_id,
        },
      })
      .then((response) => {
        enqueueSnackbar("The email was sent successfully", {
          variant: "success",
          });
          handleCloseSendEmailDialog();
          handleCloseEstimateConfigDialog();
      })
      .catch((err) => {
        console.log(err.data);
      });
  };








  return (
    <div className="contentWithMiniDrawer">
      <div id="project_estimate_editor">
        <ProjectHeader />
        <div className="buttons_top">
          <TextField
            id="estimate_name"
            name="estimate_name"
            label="Estimate Name"
            type="text"
            required
            fullWidth
            value={formValues.estimate_name}
            onChange={handleInputChange}
          />
        </div>

        <div id="project_estimate_products">
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div style={{ outline: "none" }}>
              <Box id="project_list" title="Products" subtitle="List">
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {estimateProductList.map((estimate_product, i) => (
                        <TableRow
                          onClick={() => (
                            handleClickProduct(
                              activeProductForModal,
                              estimate_product.estimate_product_name,
                              JSON.parse(
                                estimate_product.estimate_product_prices
                              )
                            ),
                            handleClose()
                          )}
                          key={i}
                          hover
                          role="checkbox"
                        >
                          <TableCell>
                            {estimate_product.estimate_product_name}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </div>
          </Modal>

          {
            /*=================================================
          ARRAY PRODUCTS MAP
          =================================================*/
            productsGroup.map((product, i) => {
              const filter = createFilterOptions<IProductList>();

              const arrPrices = product.estimate_product_prices;
              const size = parseInt(product.size);

              let key = 0;
              if (size < 21) {
                key = 0;
              } else if (size < 41) {
                key = 1;
              } else if (size < 61) {
                key = 2;
              } else if (size < 81) {
                key = 3;
              } else if (size < 101) {
                key = 4;
              } else if (size < 121) {
                key = 5;
              } else if (size < 141) {
                key = 6;
              } else if (size < 161) {
                key = 7;
              }

              let low = "";
              let mid = "";
              let high = "";
              let design_fee = "";

              //if size is not empty"
              if (product.size !== "" && product.product_name !== "") {
                low = arrPrices.low
                  ? (parseInt(arrPrices.low) * size).toLocaleString()
                  : "";
                mid = arrPrices.mid
                  ? (parseInt(arrPrices.mid) * size).toLocaleString()
                  : "";
                high = arrPrices.high
                  ? (parseInt(arrPrices.high) * size).toLocaleString()
                  : "";

                design_fee = arrPrices.design_fee
                  ? parseInt(arrPrices.design_fee).toLocaleString()
                  : "";
              }

              return (
                <Box id="estimate_product_box" title={"Product #" + (i + 1)}>
                  <IconButton
                    className="btn_delete_corner"
                    aria-label="delete"
                    color="primary"
                    onClick={() => handleDelete(i)}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                  <Autocomplete
                    key={i}
                    value={product.estimate_product_name}
                    onChange={(event, newValue) => {
                      /*================================================
                      USER PRESS ENTER
                      =================================================*/
                      if (typeof newValue === "string") {
                      } else if (newValue && newValue.inputValue) {

                      /*================================================
                      USER CLICKS ADD BUTTON
                      =================================================*/
                      } else {

                      /*================================================
                      USER SELECT ONE OPTION 
                      =================================================*/
                        let newArrayProducts = [...productsGroup];
                        const size = product.size;
                        let key = 0;
                        if (size < 21) {
                          key = 0;
                        } else if (size < 41) {
                          key = 1;
                        } else if (size < 61) {
                          key = 2;
                        } else if (size < 81) {
                          key = 3;
                        } else if (size < 101) {
                          key = 4;
                        } else if (size < 121) {
                          key = 5;
                        } else if (size < 141) {
                          key = 6;
                        } else if (size < 161) {
                          key = 7;
                        }
                        if (newValue) {
                          const jsonPrices = JSON.parse(
                            newValue.estimate_product_prices
                          );

                          newArrayProducts[i].estimate_product_name =
                            newValue.estimate_product_name;
                          newArrayProducts[i].estimate_product_prices_list =
                            jsonPrices;

                          newArrayProducts[i].estimate_product_prices.low =
                            jsonPrices[newArrayProducts[i].measure_type].low;
                          newArrayProducts[i].estimate_product_prices.mid =
                            jsonPrices[newArrayProducts[i].measure_type].mid;
                          newArrayProducts[i].estimate_product_prices.high =
                            jsonPrices[newArrayProducts[i].measure_type].high;

                          newArrayProducts[
                            i
                          ].estimate_product_prices.design_fee =
                            jsonPrices[
                              newArrayProducts[i].measure_type
                            ].design_fee[key].value;
                        } else {
                          newArrayProducts[i].estimate_product_name = "";
                          newArrayProducts[i].estimate_product_prices_list = [];
                          newArrayProducts[i].estimate_product_prices = {
                            low: "",
                            mid: "",
                            high: "",
                            design_fee: "",
                          };
                        }

                        setProductsGroup(newArrayProducts);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.estimate_product_name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          estimate_product_name: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    fullWidth
                    id="product_name"
                    options={estimateProductList}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.estimate_product_name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.estimate_product_name}</li>
                    )}
                    sx={{}}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Product Name"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <IconButton
                              onClick={() => {
                                handleOpenProductList(i);
                              }}
                            >
                              <img src={ic_dots}></img>
                            </IconButton>
                          ),
                        }}
                      />
                    )}
                  />

                  <div className="input_row">
                    <TextField
                      id="size"
                      name="size"
                      label="Size"
                      type="number"
                      fullWidth
                      value={product.size}
                      onChange={(e) => {
                        let newArrayProducts = [...productsGroup];
                        let newSize = parseInt(e.target.value);

                        let key = 0;
                        if (newSize < 21) {
                          key = 0;
                        } else if (newSize < 41) {
                          key = 1;
                        } else if (newSize < 61) {
                          key = 2;
                        } else if (newSize < 81) {
                          key = 3;
                        } else if (newSize < 101) {
                          key = 4;
                        } else if (newSize < 121) {
                          key = 5;
                        } else if (newSize < 141) {
                          key = 6;
                        } else if (newSize < 161) {
                          key = 7;
                        }

                        let jsonPrices =
                          productsGroup?.[i]?.estimate_product_prices_list;

                        newArrayProducts[i].size = e.target.value;
                        // console.log(
                        //   jsonPrices[newArrayProducts[i].measure_type]
                        // );

                        newArrayProducts[i].estimate_product_prices.design_fee =
                          jsonPrices[
                            newArrayProducts[i].measure_type
                          ].design_fee[key].value;

                        setProductsGroup(newArrayProducts);
                      }}
                    />

                    <FormControl fullWidth>
                      <InputLabel id="lbl_product_category">
                        Measurement
                      </InputLabel>
                      <Select
                        labelId="product_category"
                        id="product_category"
                        value={product.measure_type}
                        label="Measurement"
                        onChange={(e) => {
                          if (productsGroup?.[i]?.estimate_product_name) {
                            let newArrayProducts = [...productsGroup];
                            newArrayProducts[i].measure_type = e.target.value;

                            /* READ VARIABLE ESTIMATEPRODUCT, PREVIOUSLY SETTED BY AUTOCOMPLETE AND USE THE PRICES ARRAY*/
                            let jsonPrices =
                              productsGroup?.[i]?.estimate_product_prices_list;

                            newArrayProducts[i].estimate_product_prices.low =
                              jsonPrices[e.target.value].low;
                            newArrayProducts[i].estimate_product_prices.mid =
                              jsonPrices[e.target.value].mid;
                            newArrayProducts[i].estimate_product_prices.high =
                              jsonPrices[e.target.value].high;

                            newArrayProducts[
                              i
                            ].estimate_product_prices.design_fee =
                              jsonPrices[e.target.value].design_fee[key].value;

                            setProductsGroup(newArrayProducts);
                          }
                        }}
                      >
                        <MenuItem value="linear">Linear</MenuItem>
                        <MenuItem value="square">Square</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <Table className="estimates_prices_table">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>
                            <span className="estimates_th estimates_th_low">
                              Low
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="estimates_th estimates_th_mid">
                              Mid
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="estimates_th estimates_th_high">
                              High
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className="estimate_price_bold">
                            Design fee
                          </TableCell>
                          <TableCell
                            colSpan={3}
                            className="tc_estimate_design_fee"
                          >
                            <div className="estimate_design_fee">
                              <span className="estimate_design_fee_value">
                                {"$" + design_fee}
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className="estimate_price_bold">
                            Product Cost
                          </TableCell>
                          <TableCell>
                            <div className="estimate_price_value epv_low">
                              {"$" + low}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="estimate_price_value epv_mid">
                              {"$" + mid}
                            </div>
                          </TableCell>
                          <TableCell className="estimate_price_value epv_high">
                            <div className="estimate_price_value epv_high">
                              {"$" + high}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              );
            })
            /* END MAP ================================================
            ==========================================================*/
          }

          <div
            className="add_product_dotted_button"
            onClick={() => {
              pushArrayProducts();
            }}
          >
            Add Product
          </div>
        </div>
      </div>

      <Dialog open={estimateConfigOpen} onClose={handleCloseEstimateConfigDialog}>
        <DialogContent id="estimate_configuration" className="forced_box_editor">
          <h2>Estimate Configuration</h2>
          <DialogContentText>
            Check the fields you want to send to the client.
          </DialogContentText>

          
          
          <div id="BudgetOptions" className="SwitchGroup">
            <h2>Preliminary Budget Projections</h2>
            <div className="flexSimple">
              <FormControlLabel
                checked={formValues.pdf_options.budget_op1}
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        pdf_options: {
                          ...formValues.pdf_options,
                          budget_op1: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="Millwork"
              />

              <FormControlLabel
                checked={formValues.pdf_options.budget_op2}
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        pdf_options: {
                          ...formValues.pdf_options,
                          budget_op2: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="Stonework"
              />

              <FormControlLabel
                checked={formValues.pdf_options.budget_op3}
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        pdf_options: {
                          ...formValues.pdf_options,
                          budget_op3: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="Specialized hardware"
              />
            </div>
          </div>

          
          <div id="DesignOptions" className="SwitchGroup">
          <h2>Design Phase</h2>
            <FormControlLabel
              checked={formValues.pdf_options.design_op1}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      pdf_options: {
                        ...formValues.pdf_options,
                        design_op1: e.target.checked,
                      },
                    });
                  }}
                />
              }
              label="Design Development and Floor Plan Layout"
            />

            <FormControlLabel
              checked={formValues.pdf_options.design_op2}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      pdf_options: {
                        ...formValues.pdf_options,
                        design_op2: e.target.checked,
                      },
                    });
                  }}
                />
              }
              label="2D Elevation Drawings"
            />

            <FormControlLabel
              checked={formValues.pdf_options.design_op3}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      pdf_options: {
                        ...formValues.pdf_options,
                        design_op3: e.target.checked,
                      },
                    });
                  }}
                />
              }
              label="3D Drawings and Renders"
            />

            <FormControlLabel
              checked={formValues.pdf_options.design_op4}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      pdf_options: {
                        ...formValues.pdf_options,
                        design_op4: e.target.checked,
                      },
                    });
                  }}
                />
              }
              label="Material and Finishes Options (mood board)"
            />

            <FormControlLabel
              checked={formValues.pdf_options.design_op5}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      pdf_options: {
                        ...formValues.pdf_options,
                        design_op5: e.target.checked,
                      },
                    });
                  }}
                />
              }
              label="Project Selection & Information Sheet (material/hardware/plumbing/electrical)
              Project"
            />

            <FormControlLabel
              checked={formValues.pdf_options.design_op6}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      pdf_options: {
                        ...formValues.pdf_options,
                        design_op6: e.target.checked,
                      },
                    });
                  }}
                />
              }
              label="Quote (based on final client design and selections)"
            />

            <FormControlLabel
              checked={formValues.pdf_options.design_op7}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      pdf_options: {
                        ...formValues.pdf_options,
                        design_op7: e.target.checked,
                      },
                    });
                  }}
                />
              }
              label="Project Production and Manufacturing Drawings (including plumbing and
                electrical)"
            />
          </div>

          <h2>Estimate level</h2>
          <DialogContentText>
            Select which estimate level you want to include in the PDF and E-mail.
          </DialogContentText>
          <div id="sendEmailSwitch">
            <FormControlLabel
              checked={formValues.pdf_level.low}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      pdf_level: {
                        ...formValues.pdf_level,
                        low: e.target.checked,
                      },
                    });
                  }}
                />
              }
              label="LOW"
            />

            <FormControlLabel
              checked={formValues.pdf_level.mid}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      pdf_level: {
                        ...formValues.pdf_level,
                        mid: e.target.checked,
                      },
                    });
                  }}
                />
              }
              label="MID"
            />

            <FormControlLabel
              checked={formValues.pdf_level.high}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      pdf_level: {
                        ...formValues.pdf_level,
                        high: e.target.checked,
                      },
                    });
                  }}
                />
              }
              label="HIGH"
            />
          </div>

          <div className="buttons">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleSubmit(handleDownloadEstimate);
              }}
              startIcon={<CloudDownloadOutlinedIcon />}
            >
              Download Estimate
            </Button>

            <Button 
              variant="contained" 
              endIcon={<SendIcon />}
              onClick={() => {
                setSendEmailDialogOpen(true);
              }}>
              Send Email
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={sendEmailDialogOpen} onClose={handleCloseSendEmailDialog}>
        <DialogContent id="estimate_configuration" className="forced_box_editor">
          <h2>Send Email</h2>
          <DialogContentText>
            This action will send an email with the current estimate configuration. Do you want to continue?
          </DialogContentText>
          <div className="buttons">
          <Button
              variant="outlined"
              color="primary"
              onClick={handleCloseSendEmailDialog}
            >
              Cancel
            </Button>

            <Button 
              variant="contained" 
              endIcon={<SendIcon />}
              onClick={() => {
                handleSubmit(handleSendEmail);
              }}>
              Confirm and Send Email
            </Button>
          </div>
        </DialogContent>
        
      </Dialog>

      <Drawer
        variant="permanent"
        open={drawerOpen}
        anchor="right"
        className="miniDrawer"
        PaperProps={{
          style: {
            paddingTop: "55px",
            boxShadow: "0 0 4px #00000026",
            border: "none",
          },
        }}
      >
        <ListItem key="drawerOpenBtn" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
            sx={ListItemButtonStyles}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {!drawerOpen ? <MenuIcon /> : <ChevronRightIcon />}
            </ListItemIcon>
            <ListItemText>
              <h2 style={{ opacity: drawerOpen ? 1 : 0 }}>SUMMARY</h2>
            </ListItemText>
          </ListItemButton>
        </ListItem>

        <div className="drawer_in">
          <div
            className="drawer_content"
            style={{ opacity: drawerOpen ? 1 : 0 }}
          >
            <div className="box_header">
              <span className="subtitle">
                {formValues.products.length} Products
              </span>
            </div>

            <div className="sidebar_body">
              <div className="sidebar_body_row">
                <div className="sidebar_body_row_left">
                  <img src={ic_design_fee}></img>
                  <span className="sidebar_body_row_title">Design Fee</span>
                </div>
                <span className="sidebar_body_row_value">
                  $ {formValues.total_design_fee.toLocaleString()}
                </span>
              </div>

              <div className="sidebar_body_row">
                <div className="sidebar_body_row_left">
                  <span className="estimates_th estimates_th_low">Low End</span>
                </div>
                <span className="sidebar_body_row_value epv_low">
                  {"$" + formValues.total_low.toLocaleString()}
                </span>
              </div>

              <div className="sidebar_body_row">
                <div className="sidebar_body_row_left">
                  <span className="estimates_th estimates_th_mid">Mid End</span>
                </div>
                <span className="sidebar_body_row_value epv_mid">
                  {"$" + formValues.total_mid.toLocaleString()}
                </span>
              </div>

              <div className="sidebar_body_row">
                <div className="sidebar_body_row_left">
                  <span className="estimates_th estimates_th_high">
                    High End
                  </span>
                </div>
                <span className="sidebar_body_row_value epv_high">
                  {"$" + formValues.total_high.toLocaleString()}
                </span>
              </div>
            </div>
          </div>

          <List>
            <ListItem key="save" disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={ListItemButtonStyles}
                onClick={() => {
                  handleSubmit();
                }}
              >
                <ListItemIcon sx={ListItemIconStyles}>
                  <SaveOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="SAVE"
                  sx={{ opacity: drawerOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem key="send" disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={ListItemButtonStyles}
                onClick={() => {
                  setEstimateConfigOpen(true);
                }}
              >
                <ListItemIcon sx={ListItemIconStyles}>
                  <ForwardToInboxOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="SEND EMAIL TO CLIENT"
                  sx={{ opacity: drawerOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
        
      </Drawer>
    </div>
  );
};

export default withSnackbar(ProjectEstimates);
function enqueueSnackbar(arg0: string) {
  throw new Error("Function not implemented.");
}

