import axios from "axios";
import { ProjectConfig } from "Global";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";


export default function ProjectHeader(props: any) {
  
    /*=======================================
        GET ID FROM URL
    =======================================*/
    let { project_id } = useParams();
    if (!project_id) {
        // UNSET LOCALSTORAGE
        localStorage.removeItem('project_id');
        localStorage.removeItem('project_name');
        localStorage.removeItem('client_name');
        localStorage.removeItem('client_id');
    }
    /*=======================================
    =======================================*/
    const [project_detail, setProjectDetail] = useState<any []>([]);
    const [loading, setLoading] = useState(false); 
    
    // check if project_id is set in local storage IF NOT call api to get it
    
    useEffect(() => {
        if (((localStorage.getItem('project_id') === null || localStorage.getItem('project_id') === undefined) || localStorage.getItem('project_id') !== project_id) && project_id !== undefined) 
        {
            console.log("entro ",localStorage.getItem('project_id'))
            setLoading(true)
            axios.get(ProjectConfig.api_url,
            {
            params: {
                request: 'project_detail',
                project_id: project_id
            }
            })
            .then((response) => {
                localStorage.setItem('project_id', response.data.project_id)
                localStorage.setItem('project_name', response.data.project_name)
                localStorage.setItem('client_name', response.data.client_name)
                localStorage.setItem('client_id', response.data.client_id)
            })
            .catch((err) => {
                console.log(err.data);
            })
        }
    }, [])
    
    return (
        <div id="ProjectHeader">
            <div id="ProjectHeaderInfo">
                {project_id && (
                    <>
                        <h1 id='title'><b>PROJECT:</b>{localStorage.getItem('project_name')}</h1>
                        <h2 id="subtitle">{localStorage.getItem('client_name')}</h2>
                    </>
                )}
            </div>
            {props.children && (
                <div id="ProjectHeaderButtons">
                    {props.children}
                </div>
            )}
        </div>
    );
};