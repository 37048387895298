import React, { useContext, useEffect, useState, } from 'react';
import { IService, QuotesContext } from '../QuoteContextProvider';

import { TextField, } from '@mui/material';

const defaultValues: IService = {
    name: '',
    serviceType: '',
};

export default function PreviewServiceBox ( props: any ) {

    const {
        buildUniqueId,
        categories,
        serviceDict,
        costDict,
        quoteCost,
        previewDict,
        updatePreviewItem,
    } = useContext(QuotesContext);

    const {
        serviceIndex,
        key = undefined,
    } = props;

    const [service, setService] = useState<IService>(defaultValues);

    useEffect(() => {
        if (props) {
            setService({
                ...serviceDict[serviceIndex],
            });
        };
    }, [props]);

    const handleValueChange = (
        value: any,
        field: string,
        category: string,
        itemIndex: number,
    ) => {
        const newMeasureChanges = {
            [field]: value,
        };

        updatePreviewItem(newMeasureChanges, serviceIndex, category, itemIndex);
    }

    return (
        <>
            <div key={key} className="QuoteService">
                <div className="QuoteServiceItems">
                    <div className="QuoteServiceHeader">
                        <div className="QuoteServiceHeaderLeft">
                            <span className="QuoteServiceName">{service.name}</span>

                            {/* MAP CATEGORIES (PANELES, PAINT, HARDWARE) */}
                            {service.serviceType !== '' &&
                            categories[service.serviceType].map((categoryItem:any, categoryIndex:any) => {
                                const category = categoryItem.category;
                                const unique_id = buildUniqueId(serviceIndex, category);
                            
                                return (
                                    <div key={categoryIndex} className="quoteTextsContainer">
                                        {category != "workforce" &&
                                        previewDict[unique_id].map((item:any, itemIndex:any) => {
                                            if(item.section === "") { return null}
                                            else {
                                                return (
                                                    <TextField
                                                        key={itemIndex}
                                                        label={item.product_name}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            style: {
                                                                fontSize: '12px',
                                                            }
                                                        }}
                                                        value={item.quote_text}
                                                        inputProps={{
                                                            style: {
                                                                fontSize: '12px',
                                                            }
                                                        }}
                                                        {...(service.serviceType === "stone" ?
                                                            {
                                                                multiline: true,
                                                                maxRows: 5,
                                                            } :
                                                            {
                                                                maxRows: 1,
                                                            }
                                                        )}

                                                        onChange={(e:any) => {
                                                            handleValueChange(
                                                                e.target.value,
                                                                "quote_text",
                                                                category,
                                                                itemIndex,
                                                            )
                                                        }}
                                                    />
                                                )
                                            }
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                        
                        <div className="QuoteServiceHeaderRight">
                            {quoteCost[service.serviceType] && quoteCost[service.serviceType].profit_percentage ?
                                <div className="QuoteServiceTotal">
                                    ${((costDict[serviceIndex].total_cost || 0) * (1 + (quoteCost[service.serviceType].profit_percentage || 0) / 100)).toLocaleString()}
                                </div>
                            :
                                <div className="QuoteServiceTotal">${(costDict[serviceIndex].total_cost || 0).toLocaleString()}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}