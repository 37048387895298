import { Button, Paper, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from "Global";
import { useSnackbar } from "notistack";


 export default function ClientEditor(props: any) {

  const {
    onSubmit,
    setLoading,
    client = null,
  } = props;

  useEffect(() => {setLoading(false);}, []);
  const navigate = useNavigate();


  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  


  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  let {client_id} = useParams();
  client_id = props.client_id ? props.client_id : client_id;
  /*=======================================
  =======================================*/

  const defaultValues = {
      client_id: client_id ? client_id : "null",
      client_name: "",
      client_email: "",
      client_phone: "",
      client_address: ""
  };



  
  const [formValues, setFormValues] = useState(client ? client : defaultValues)

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };



  // IF CLIENT ID IS PROVIDED, GET CLIENT INFO
    useEffect(() => {
      if (client_id) {
        axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "client_details",
              client_id: client_id,
            },
          })
          .then((response) => {
            console.log(response.data);
            setFormValues(response.data);
          })
          .catch((err) => {
            console.log(err.data);
          });
      }
    }, []);

    //===========================================================



 /*====================================
  SUBMIT 
  =====================================*/
  const handleSubmit = () => {
    console.log(formValues);
    // if (!formValues.product_name || !formValues.product_code) {
    //   console.log("Please enter all required fields");
    //   return;
    // }

    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "add_edit_client",
          formdata: formValues,
        },
      })
      .then((response) => {
        console.log("data",response);

        if(onSubmit){
          onSubmit(response.data);
        }
        enqueueSnackbar("Client Saved", { variant: "success" });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /*====================================
  =====================================*/


  return (
    <form>
      <Box id="project_list" className="box_editor" title="Client Information" subtitle="Edit"  >
        <div className='form'>
            <TextField
              id="client_name"
              name="client_name"
              label="Client Name"
              type="text"
              required
              value={formValues.client_name}
              onChange={handleInputChange}
            />

            <TextField 
              id="client_email"
              name="client_email"
              label="Email"
              type="Address"
              required
              value={formValues.client_email}
              onChange={handleInputChange}
            />
            <div className="input_row">
              <TextField 
                id="client_phone"
                name="client_phone"
                label="Phone"
                type="email"
                required
                value={formValues.client_phone}
                onChange={handleInputChange}
              />
              <TextField 
                id="client_address"
                name="client_address"
                label="Address"
                type="text"
                required
                value={formValues.client_address}
                onChange={handleInputChange}
              />
            </div>
          </div>
        <div className='buttons'>
          <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Back</Button>
          <Button onClick={handleSubmit} variant="contained" className="button_1">Submit</Button>
        </div>
      </Box>

      



    </form>

    
  );
};


