import React from 'react';
import { CircularProgress } from '@mui/material';

import 'assets/css/main.css';

export default function LoadingCircle( props: any ) {

    const { loading } = props;
    
    return (
        <div id='customLoader' className={!loading? 'loaderHide' : 'loaderView'}>
          <CircularProgress />
        </div>
    )
}