import React, { useContext, } from 'react';
import { QuotesContext } from '../QuoteContextProvider';
import FilesBrowser from 'components/Files/FilesBrowser';
import { SaveOutlined } from '@mui/icons-material';

export default function ExplodedDrawerContent ( props: any ) {

    const {
        folderDict,
    } = useContext(QuotesContext);
    
    return (
        <>
            <div id="explodedViewImages">
                {/* MAP IMAGES */}
                {Object.keys(folderDict).map((serviceIndex: any, keyToRender: number) => {
                    const service = folderDict[serviceIndex];
                    
                    return (
                        <div key={keyToRender}>
                            <h2>{service.name}</h2>
                            <div className="drawerImagesContainer">

                                {service.folder_id != 0 && (
                                    <FilesBrowser
                                        folder_id={service.folder_id} 
                                        view_type="thumbnail"
                                        setLoading={() => {}}
                                        with_drawer={false}
                                    />
                                )}
                                {service.folder_id == 0 &&
                                    <div className='msg_save_quote'>
                                        <SaveOutlined />
                                        <p>Save the quote in order to upload files</p>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    );

}