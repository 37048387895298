import React from "react";

import Box from "elements/Box";
import { FiberManualRecord } from "@mui/icons-material";
import { Button } from "@mui/material";

import 'assets/css/main.css';

interface IProgressHeader {
    id: number
    title: string;
    progress: number;
    image: any;
    total_tasks: number;
    completed_tasks: number;
}


interface IProgressData {
}

export default function ProjectProgress( props: any ) {
    const {
        data,
        workedHours,
        workersAssigned,
        daysFromStart,
        daysUntilDeadline,
    } = props;

    function listTasksOptionsRow() {
        return (
            <div className="displayer_row">
                <div className="status-guide graph_row">
                    <div className="list_task_state">
                        <FiberManualRecord
                            className="list_task_state_icon assigned"
                        />
                        <span>Assigned</span>
                    </div>

                    <div className="list_task_state">
                        <FiberManualRecord
                            className="list_task_state_icon unassigned"
                        />
                        <span>Not assigned</span>
                    </div>
                </div>
                <Button
                    variant="text"
                    size="small"
                    color="secondary"
                    sx={{textTransform: 'none', fontSize: '10px', padding: '0px'}}
                    onClick={() => {}}
                >
                    See details
                </Button>
            </div>
        );
    }

    return (
        <>
            <div className="progress_container graph_column">
                <div className="progress_row">
                </div>
            </div>

            <div className="graph_row" id="indicators">
                {data.indicators.map((item: IProgressHeader, index: number) => (
                    <div key={index} className="graph_top_container">
                        <div className="graph_top_progress_bar">
                            <div style={{width: item.progress + '%'}} />
                        </div>

                        <Button
                            onClick={() => {
                                console.log(item.id);
                            }}
                            component={Box}
                            className='graph_top_boxes'
                        >
                            <div className="graph_top_icon">
                                {item.image}
                            </div>
                            <div className="graph_top_title">{item.title}</div>
                            <div className="graph_top_number">{item.progress}%</div>
                            <div className="graph_top_text">{item.completed_tasks} / {item.total_tasks}</div>
                        </Button>
                    </div>
                ))}
            </div>

            <div className="graph_row" id="worktime_indicators">
                <Box className='graph_top_boxes primary_box'>
                    <div id="graph_days_start">{daysFromStart} Days from start</div>
                    <div id="graph_days_deadline">{daysUntilDeadline} days left until deadline</div>
                </Box>
                <Box className='graph_top_boxes'>
                    <div className="graph_top_number">{workedHours}</div>
                    <div className="graph_top_text">Hours Worked</div>
                </Box>
                <Box className='graph_top_boxes'>
                    <div className="graph_top_number">{workersAssigned}</div>
                    <div className="graph_top_text">Workers Assigned</div>
                </Box>
            </div>

            <div className="graph_row" id="list_task_dashboard">
                <Box
                    className='box100'
                    title="List of task"
                    subtitle={listTasksOptionsRow()}
                >
                    {data.indicators.map((item: any, index: number) => {
                        return (
                        <div key={index} className="input_row hidden_scroll_row_container">
                            <div className="displayer_row_text">
                                <span>{item.title}</span>
                            </div>

                            <div className="input_row task_list_row hidden_scroll_row_box">
                                {data.tasks[item.id].completed.map((task: any, index: number) => (
                                    <div key={index} className="task_list_row_item completed">
                                        <div className="task_list_row_item_name">
                                            <span>{task.task_name}</span>
                                        </div>

                                        <FiberManualRecord
                                            className={"list_task_state_icon" + (task.assigned ? " assigned" : " unassigned")}
                                        />
                                    </div>
                                ))}
                                {data.tasks[item.id].pending.map((task: any, index: number) => (
                                    <div key={index} className="task_list_row_item pending">
                                        <div className="task_list_row_item_name">
                                            <span>{task.task_name}</span>
                                        </div>

                                        <FiberManualRecord
                                            className={"list_task_state_icon" + (task.assigned ? " assigned" : " unassigned")}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )})}
                </Box>
            </div>
        </>
    );
};