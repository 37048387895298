import { Button, Paper, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from "Global";
import { useSnackbar } from "notistack";


 export default function SupplierEditor(props: any) {

  const {setLoading, onSubmit, name} = props;

  useEffect(() => {setLoading(false);}, []);
  const navigate = useNavigate();


  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  


  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  let {supplier_id} = useParams();
  supplier_id = props.supplier_id ? props.supplier_id : supplier_id;
  /*=======================================
  =======================================*/


  // SELECT `supplier_id`, `name`, `tin`, `address`, `email`, `phone`, `contact_person`, `payment_terms`, `payment_method`, `supplier_deleted` FROM `suppliers` WHERE 1


  const defaultValues = {
      supplier_id: supplier_id? supplier_id: "null",
      name: name? name: "",
      email: "",
      phone: "",
      address: "",
      tin: "",
      contact_person: "",
      payment_terms: "",
      payment_method: ""
  };
 

  
  const [formValues, setFormValues] = useState(defaultValues)

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };



  // IF CLIENT ID IS PROVIDED, GET CLIENT INFO
    useEffect(() => {
      if (supplier_id) {
        axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "supplier_details",
              supplier_id: supplier_id,
            },
          })
          .then((response) => {
            console.log(response.data);
            setFormValues(response.data);
          })
          .catch((err) => {
            console.log(err.data);
          });
      }
    }, []);

    //===========================================================



 /*====================================
  SUBMIT 
  =====================================*/
  const handleSubmit = () => {
    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "add_edit_supplier",
          formdata: formValues,
        },
      })
      .then((response) => {
        enqueueSnackbar("Supplier Saved", { variant: "success" });

        if(onSubmit) {
          onSubmit(response.data);
        }
        else{
          navigate(-1)
        }
        
        console.log(response);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  /*====================================
  =====================================*/




  return (
    <form>
      <Box id="project_list" className="box_editor" title="Supplier Information" subtitle="Edit"  >
        <div className='form'>
          <div className="input_row">
              <TextField 
                id="name"
                name="name"
                label="Supplier Name"
                type="text"
                fullWidth
                required
                value={formValues.name}
                onChange={handleInputChange}
              />

              <TextField
                  id="tin"
                  name="tin"
                  label="TIN"
                  type="text"
                  fullWidth
                  required
                  value={formValues.tin}
                  onChange={handleInputChange}
                />
            </div>

            <TextField 
              id="email"
              name="email"
              label="Email"
              type="email"
              required
              value={formValues.email}
              onChange={handleInputChange}
            />
            <div className="input_row">
              

            </div>
            
            <h2>Contact</h2>

            <TextField
                id="contact_person"
                name="contact_person"
                label="Contact Person"
                type="text"
                required
                value={formValues.contact_person}
                onChange={handleInputChange}
              />

            <div className="input_row">


              <TextField 
                id="phone"
                name="phone"
                label="Phone"
                type="text"
                fullWidth
                required
                value={formValues.phone}
                onChange={handleInputChange}
              />
              <TextField 
                id="address"
                name="address"
                label="Address"
                type="text"
                fullWidth
                required
                value={formValues.address}
                onChange={handleInputChange}
              />

              

            </div>

            <TextField
                id="payment_terms"
                name="payment_terms"
                label="Payment Terms"
                type="text"
                required
                value={formValues.payment_terms}
                onChange={handleInputChange}
              />

              <TextField
                id="payment_method"
                name="payment_method"
                label="Payment Method"
                type="text"
                required
                value={formValues.payment_method}
                onChange={handleInputChange}
              />
          </div>
        <div className='buttons'>
          <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Back</Button>
          <Button onClick={handleSubmit} variant="contained" className="button_1">Submit</Button>
        </div>
      </Box>

      



    </form>

    
  );
};


