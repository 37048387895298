import { Autocomplete, Button, createFilterOptions, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from 'icons/ic_dots.svg';
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import ProjectHeader from "components/Projects/utils/ProjectHeader";
import ACProjectList from "elements/ACProjectList";
import AutocompleteProduct from "components/Projects/utils/AutoCompleteProduct";
import moment from "moment";
import AutocompleteMulti from "elements/AutocompleteMulti";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";

export default function OutgoingEditor (props: any) {

  const {
    setLoading,
    setMenuTopLeftContent,
    with_set_top_menu = false,
  } = props;
  
  useEffect(() => {setLoading(false);}, []);

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let { project_id, outgoing_id } = useParams();


  /*=======================================
  =======================================*/

  const navigate = useNavigate();
  const defaultValues: any = {
    outgoing_id: outgoing_id? outgoing_id : "null",
    project_id: project_id? project_id : 0,
    outgoing_date: null,
    products: [],
    users: [],
  };

  

  const [formValues, setFormValues] = useState(defaultValues)
  const [checkCurrentTime, setCheckCurrentTime] = useState(false)


  const setUsers = (items: any) => {
    setFormValues({
      ...formValues,
      users: items,
    });
  }



  const setProduct = (product_name: any, productId: any, sale_price: any, stone_fields: any, caso_code: any, serviceIndex: any, category: any, itemIndex: any, product_type: any) => {
    
    console.log("newProducts: ",itemIndex);
    const newProducts = formValues.products;
    newProducts[itemIndex].product_name = product_name;
    newProducts[itemIndex].product_id = productId;
    setFormValues({...formValues, products: newProducts});

}



  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
   
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    
  };

  const handleSubmit = () => {

    setLoading(true);
    
    axios.post(ProjectConfig.api_url, formValues,
      {
        params: {
          request: 'add_edit_outgoing',
        }
      })
        .then((response) => {
          console.log("res: ",response.data);
          enqueueSnackbar(response.data.message, {variant: 'success'})
          navigate(-1);
          setLoading(false)
        })
        .catch((err) => {
          console.log(err.data);
        })
        
  };



  /**/

  useEffect(() => {
    if (outgoing_id) {
      loadData();
    }
  }, []);

  const loadData = () => {
    setLoading(true);
      
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "get_outgoing_details",
            outgoing_id: outgoing_id,
          },
        })
        .then((response) => {
          console.log(response.data);
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          setLoading(false);
        });
  }


  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  

    /*=======================================
    PUSH NEW ITEM 
    =======================================*/
    const handleAddItem = () => {
        
      const newProducts = [...formValues.products];
      newProducts.push({
          product_id: "",
          product_name: "",
          quantity: 0,
      });
      setFormValues({
        ...formValues,
        products: newProducts,
      });


  }; 
  /*=======================================
  =======================================*/


  //delete product row
  const handleDeleteProduct = (index: any) => {
    const newProducts = [...formValues.products];
    newProducts.splice(index, 1);
    setFormValues({
        ...formValues,
        products: newProducts,
    });
  }


  return (
    <div>
      <ProjectHeader />
      
    
    <form>
      <Box id="order_editor" className="box_editor" title="Outgoing" subtitle="Edit"  >
        <div className='form'>
          
          <ACProjectList onChange={handleInputChange} value={formValues.project_id}/>
 

          <div className="input_row">
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDateTimePicker
                  label="Create date"
                  inputFormat="MMMM DD, yyyy hh:mm A"
                  disableMaskedInput={true}
                  value={formValues.outgoing_date}
                  disabled={checkCurrentTime}
                  //minutes step
                  minutesStep={5}
                  
                  InputProps={{
                    endAdornment: (
                      <EventIcon />
                    ),
                  }}
                  showToolbar={false} 
                  onChange={(newValue) => {
                    setFormValues({
                      ...formValues,
                      ["outgoing_date"]: (newValue).format('YYYY-MM-DD HH:mm')
                    });
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
              
              {/* check CURRENT TIME */}
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.current_time}
                    onChange={(e) => {
                      setCheckCurrentTime(e.target.checked)
                      setFormValues({
                        ...formValues,
                        ["current_time"]: e.target.checked
                      });

                    }}
                    name="current_time"
                    color="primary"
                  />
                }
                label="Current time"
              />

          </div>

          <h2>Workers Assigned</h2>

          <AutocompleteMulti 
            setSelectedItems={setUsers}
            selectedItems={formValues.users}
          />
          


          <div className='input_group'>
            <h2>Products</h2>
            {/* //map products */}
            {formValues.products.map((product: any, index: any) => (
              <div className="order_product_row" key={index}>
                <AutocompleteProduct 
                    category_filter="'panel','wood','paint','hardware'" 
                    setProduct={setProduct} 
                    product_name={product.product_name}
                    category=""
                    serviceIndex={0}
                    itemIndex={index}
                />
                <TextField 
                  label="Quantity" 
                  fullWidth
                  size='small'
                  InputLabelProps={{
                      style: { fontSize: '12px' }
                  }}
                  value={product.quantity}
                  inputProps={{
                      style: {
                          fontSize: '12px',
                      }
                  }}
                  onChange={
                    (e) => {
                        const newProducts = [...formValues.products];
                        newProducts[index].quantity = e.target.value;
                        setFormValues({
                            ...formValues,
                            products: newProducts,
                        });
                    }
                  }
                  
                />
                <IconButton aria-label="delete"  color="primary" onClick={() => handleDeleteProduct(index)}>
                  <DeleteOutlineIcon />
                </IconButton>
                
              </div>
            ))}

              <Button 
                className="item_add_button"
                onClick={()=>{
                    handleAddItem()
                }}>
                Add item
              </Button>
          </div>
          
          

        </div>
        <div className='buttons'>
          <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" className="button_1">Submit</Button>
        </div>
      </Box>

      
        


    </form>
    </div>
    
  );
};
