import { Button } from '@mui/material';
import { Link, NavLink, useLocation } from 'react-router-dom';
import InventoryIcon from '@mui/icons-material/Inventory';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';

export default function InventorySubMenu() {

  const checkActiveMenu = (href: string) => {
    if(window.location.pathname == href){
      return true;
    }else{
      return false;
    }
  }
  
  return (
    <>
      <Button
        className="btn btn-primary"
        component={Link}
        to={`/inventory`}
        color={checkActiveMenu(`/inventory`)? 'primary' : 'secondary'}
        size='small'
        startIcon={<InventoryIcon />}
      >
        INVETORY
      </Button>

      <Button
        className="btn btn-primary"
        component={Link}
        to={`/inventory/incoming`}
        color={checkActiveMenu(`/inventory/incoming`)? 'primary' : 'secondary'}
        size='small'
        startIcon={<FileUploadIcon />}
        disabled
      >
        INCOMING
      </Button>

      <Button
        className="btn btn-primary"
        component={Link}
        to={`/inventory/outgoing`}
        color={checkActiveMenu(`/inventory/outgoing`)? 'primary' : 'secondary'}
        size='small'
        startIcon={<DownloadIcon />}
      >
        OUTGOING
      </Button>
    
    </>
  );
}


