import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IFabricationQuoteView, QuotesContext } from '../QuoteContextProvider';
import { CircularProgress, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select, Switch, SwitchProps, TextField, Tooltip, debounce, styled } from '@mui/material';
import { Circle } from '@mui/icons-material';

const defaultValues: IFabricationQuoteView = {
    have_workforce: false,
    sq_ft: 0,
    destination: '',
    edge: '',
    finish: '',
    sink: 0,
    workforce_cost: 0,
};

const IOSSwitch =
    styled(
        (props: SwitchProps) => (
            <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
        )
    )(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                    opacity: 1,
                    border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                color: theme.palette.mode === "light" ?
                    theme.palette.grey[100] :
                    theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
        },
        "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
        },
        "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                duration: 500,
            }),
        },
    }));

export default function QuoteFabricationRow ( props: any ) {

    const {
        buildUniqueId,
        fixNumberValue,
        calcQuoteViewWorkforceCost,
        fabricationDict,
        updateFabrication,
        setLoadingFields,
    } = useContext(QuotesContext);

    const {
        serviceIndex,
        category,
        itemIndex,
        key = undefined,
    } = props;

    const [item, setItem] = useState<IFabricationQuoteView>(defaultValues);
    const refSqFtHandler = useRef<any>();
    const refEdgeHandler = useRef<any>();
    const refFinishHandler = useRef<any>();
    const refSinkHandler = useRef<any>();

    const [updatedSqFt, setUpdatedSqFt] = useState<boolean>(true);
    const [updatedEdge, setUpdatedEdge] = useState<boolean>(true);
    const [updatedFinish, setUpdatedFinish] = useState<boolean>(true);
    const [updatedSink, setUpdatedSink] = useState<boolean>(true);

    useEffect(() => {
        if (props) {
            const fabricationList = fabricationDict[buildUniqueId(serviceIndex, category)];
            if (fabricationList) {
                updateItem(fabricationList[itemIndex]);
            } else {
                initValues();
            }
        }
    }, [props]);

    const initValues = () => {
        setItem({...defaultValues});
        setUpdatedSqFt(true);
        setUpdatedEdge(true);
        setUpdatedFinish(true);
        setUpdatedSink(true);
        setLoadingFields(false);
    }

    const updateItem = (updatedItem: IFabricationQuoteView) => {
        if (!updatedSqFt && updatedItem.sq_ft != item.sq_ft) return;

        if (!updatedEdge && updatedItem.edge != item.edge) return;

        if (!updatedFinish && updatedItem.finish != item.finish) return;

        if (!updatedSink && updatedItem.sink != item.sink) return;

        setItem((prevItem) => {
            return {
                ...prevItem,
                ...updatedItem,
            }
        });

        setUpdatedSqFt(true);
        setUpdatedEdge(true);
        setUpdatedFinish(true);
        setUpdatedSink(true);
        setLoadingFields(false);
    }

    const onChangeSqFt = () => {
        handleValueChange(item.sq_ft, 'sq_ft');
        handleValueChange(item.workforce_cost, 'workforce_cost');
    };

    const onChangeEdge = () => {
        handleValueChange(item.edge, 'edge');
    };

    const onChangeFinish = () => {
        handleValueChange(item.finish, 'finish');
    };

    const onChangeSink = () => {
        handleValueChange(item.sink, 'sink');
        handleValueChange(item.workforce_cost, 'workforce_cost');
    };

    useEffect(() => {
        refSqFtHandler.current = onChangeSqFt;
    }, [onChangeSqFt]);

    useEffect(() => {
        refEdgeHandler.current = onChangeEdge;
    }, [onChangeEdge]);

    useEffect(() => {
        refFinishHandler.current = onChangeFinish;
    }, [onChangeFinish]);

    useEffect(() => {
        refSinkHandler.current = onChangeSink;
    }, [onChangeSink]);

    const overrideSqFt = () => {
        if (updatedSqFt) return;

        refSqFtHandler.current?.();
    }

    const overrideEdge = () => {
        if (updatedEdge) return;

        refEdgeHandler.current?.();
    }

    const overrideFinish = () => {
        if (updatedFinish) return;

        refFinishHandler.current?.();
    }

    const overrideSink = () => {
        if (updatedSink) return;

        refSinkHandler.current?.();
    }

    useEffect(() => {
        if (!item.have_workforce) return;
        setItem((prevItem) => { return calcQuoteViewWorkforceCost(prevItem) } );
    }, [item.sq_ft, item.sink]);

    const handleValueChange = (value: any, field: string) => {
        const newFabricationChanges = {
            [field]: value,
        };

        updateFabrication(newFabricationChanges, serviceIndex, category, itemIndex);
    }

    return (
        <>
            <tr key={key} className="stoneAuxFields">
                <td colSpan={3}>
                    <div className="stone_aditional_fields">
                        <FormControlLabel
                            checked={item.have_workforce}
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    
                                    onChange={(e: any) => {
                                        const newFabricationChanges = (e.target.checked ?
                                            calcQuoteViewWorkforceCost({
                                                ...item,
                                                have_workforce: e.target.checked,
                                                // sq_ft: 0,
                                            })
                                        :
                                            {
                                                ...item,
                                                have_workforce: e.target.checked,
                                                // sq_ft: 0,
                                                workforce_cost: 0,
                                            }
                                        );

                                        updateFabrication(
                                            newFabricationChanges,
                                            serviceIndex,
                                            category,
                                            itemIndex
                                        );
                                    }}
                                    
                                />
                            }
                            label={<span className="switchLabel">Fabrication</span>}
                            
                        />

                        {item.have_workforce &&
                        <TextField
                            label="Sq Ft"
                            size='small'
                            sx={{ maxWidth: 70 }}
                            value={item.sq_ft}
                            InputLabelProps={{
                                style: { fontSize: '12px' }
                            }}
                            InputProps={{
                                style: {
                                    fontSize: '12px',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!updatedSqFt && true ?
                                            <Tooltip title={"Press enter or leave the field to confirm"}>
                                                <Circle
                                                    color="secondary"
                                                    className='field_badge'
                                                />
                                            </Tooltip>
                                            :
                                            null
                                        }
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                setItem((prevState: IFabricationQuoteView) => ({
                                    ...prevState,
                                    sq_ft: fixNumberValue(e.target.value, false, false, true),
                                }));
    
                                setUpdatedSqFt(false);
                            }}
                            onBlur={overrideSqFt}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    overrideSqFt();
                                }
                            }}
                        />
                        }

                        <FormControl>
                            <InputLabel id="lbl_product_category"> Destination </InputLabel>

                            <Select
                                labelId="product_category"
                                id="product_category"
                                size='small'
                                sx={{ fontSize: 12 }}
                                value={item.destination}
                                label="Measurement"
                                onChange={(e) => {
                                    const newFabricationChanges =
                                        (item.have_workforce ?
                                            calcQuoteViewWorkforceCost({
                                                ...item,
                                                destination: e.target.value,
                                            })
                                        :
                                            {
                                                ...item,
                                                destination: e.target.value,
                                            }
                                        );

                                    updateFabrication(
                                        newFabricationChanges,
                                        serviceIndex,
                                        category,
                                        itemIndex
                                    );
                                }}
                            >
                                <MenuItem value="countertop">Countertop</MenuItem>
                                <MenuItem value="backsplash">Backsplash</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            label="Edge"
                            size='small'
                            sx={{ maxWidth: 70 }}
                            value={item.edge}
                            InputLabelProps={{
                                style: { fontSize: '12px' }
                            }}
                            InputProps={{
                                style: {
                                    fontSize: '12px',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!updatedEdge && true ?
                                            <Tooltip title={"Press enter or leave the field to confirm"}>
                                                <Circle
                                                    color="secondary"
                                                    className='field_badge'
                                                />
                                            </Tooltip>
                                            :
                                            null
                                        }
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => {
                                setItem((prevState: IFabricationQuoteView) => ({
                                    ...prevState,
                                    edge: e.target.value,
                                }));

                                setUpdatedEdge(false);
                            }}
                            onBlur={overrideEdge}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    overrideEdge();
                                }
                            }}
                        />

                        <TextField
                            label="Finish"
                            size='small'
                            sx={{ maxWidth: 70 }}
                            value={item.finish}
                            InputLabelProps={{
                                style: { fontSize: '12px' }
                            }}
                            InputProps={{
                                style: {
                                    fontSize: '12px',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!updatedFinish && true ?
                                            <Tooltip title={"Press enter or leave the field to confirm"}>
                                                <Circle
                                                    color="secondary"
                                                    className='field_badge'
                                                />
                                            </Tooltip>
                                            :
                                            null
                                        }
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => {
                                setItem((prevState: IFabricationQuoteView) => ({
                                    ...prevState,
                                    finish: e.target.value,
                                }));

                                setUpdatedFinish(false);
                            }}
                            onBlur={overrideFinish}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    overrideFinish();
                                }
                            }}
                        />


                        <TextField
                            label="Sink"
                            size='small'
                            sx={{ maxWidth: 70 }}
                            value={item.sink}
                            InputLabelProps={{
                                style: { fontSize: '12px' }
                            }}
                            InputProps={{
                                style: {
                                    fontSize: '12px',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!updatedSink && true ?
                                            <Tooltip title={"Press enter or leave the field to confirm"}>
                                                <Circle
                                                    color="secondary"
                                                    className='field_badge'
                                                />
                                            </Tooltip>
                                            :
                                            null
                                        }
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => {
                                setItem((prevState: IFabricationQuoteView) => ({
                                    ...prevState,
                                    sink: fixNumberValue(e.target.value, false, false, true),
                                }));

                                setUpdatedSink(false);
                            }}
                            onBlur={overrideSink}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    overrideSink();
                                }
                            }}
                        />
                    </div>
                </td>
                {item.have_workforce &&
                <td colSpan={2} className="align_right">
                    <div className="label_workforce">
                        <span className="thSpan" >Workforce</span>
                    </div>
                    
                    ${(item.workforce_cost || 0).toLocaleString()}
                </td>
                }
                
            </tr>
        </>
    )

}