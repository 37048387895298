import {
  Button,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Switch,
  SwitchProps,
  TextField,
} from "@mui/material";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {ProjectConfig} from "Global";
import ProductForm from "./ProductForm";
import SideDrawer from "elements/SideDrawer";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import AddIcon from '@mui/icons-material/Add';
import { display } from "@mui/system";
import { useSnackbar } from "notistack";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";

export default function ProductEditor (props: any) {

  const {
    setLoading,
    popupItemParams = () => {},
    setProduct,
    onSubmit,
    setOpenProductEditor,
    productValues = null,
    productAsObj = false,
    forcePopupAppearance = false,
  } = props;
  
  useEffect(() => {
    if(!popupItemParams || typeof popupItemParams === 'function'){
      setLoading(false);
    }
  }, []);
  
  const navigate = useNavigate();
  let { product_id, products_group_id } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));


  const arrayProducts = [
    {
      product_id: null,
      product_name: "",
      caso_code: "",
      supplier_code: "",
      category: "",
      product_stock: "",
      minimum_stock: "",
      supplier_id: "",
      supplier_name: "",
      product_description: "",
      unit_price: 0,
      landed_cost: 0,
      percentage_to_land: 0,
      sale_price: 0,
      manual_sale_price: false,
      group_quantity: 1,
      product_status: "",
      stone_fields: {
        price_with_manufacturing: 0,
        price_without_manufacturing: 0,
        width: 0,
        length: 0,
        square_feet: 0,
        price_per_square_feet: 0,
      },
      wood_dimensions: []
    },
  ];

  const formValuesDefault = {
    products_group_id: null,
    products_group_name: "",
    products_group_description: "",
    products_group_category: "panel",
    products_group_caso_code: "",
    products_group_sale_price: 0,
    products_group_manual_sale_price: false,
    products: arrayProducts,
    is_group: false,
  };


  const [formValues, setFormValues] = useState<any>(formValuesDefault);


  /* =================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
  interface ISupplierList {
    inputValue?: string;
    supplier_id?: number;
    name: string;
  }
  const filter = createFilterOptions<ISupplierList>();

  //setSupplierList
  const [supplier_list, setSupplierList] = useState<any[]>([]);
  

  useEffect(() => {
    axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "supplier_list",
          params: {
            full_list: true,
          }
        },
      })
      .then((response) => {

        console.log("res aut",response.data);
        setSupplierList(response.data.list);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);
  /* ================================================= 
    ===================================================*/


  /*====================================
  GET PRODUCT 
  =====================================*/
    useEffect(() => {



      if (product_id) {
        axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "product_details",
              product_id: [product_id]
            },
          })
          .then((response) => {
            console.log(response.data)
            
            //UPDATE ONLY PRODUCTS
            setFormValues({ ...formValues, products: response.data });
            
          })
          .catch((err) => {
            console.log(err.data);
          });
      }
    }, []);




  /*====================================
  GET GROUP 
  =====================================*/
  useEffect(() => {
    if (products_group_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "products_group_details",
            products_group_id: products_group_id,
          },
        })
        .then((response) => {
          console.log(response.data)
          
          //UPDATE ALL VALUES
          setFormValues({ ...response.data });
          
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  

  /*====================================
  CHECK SUBMIT
  =====================================*/
  const handleCheckForm = () => {
    let errors = false;

    //CHECK GROUP PRODUCTS
    for (let product of formValues.products) {
      if (product.product_name === "") {
        enqueueSnackbar("Product name is required", { variant: 'error' });
        errors = true;
        break;
      }
      if (product.category === "") {
        enqueueSnackbar("Category is required, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
      continue;
      if (product.product_stock === "") {
        enqueueSnackbar("Product stock is required, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
      if (product.minimum_stock === "") {
        enqueueSnackbar("Minimum stock is required, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
      if (product.supplier_id === "") {
        enqueueSnackbar("Supplier is required, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
      if (product.product_description === "") {
        enqueueSnackbar("Product description is required, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
      if (product.unit_price === "") {
        enqueueSnackbar("Unit price is required, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
      if (product.unit_price <= 0) {
        enqueueSnackbar("Unit price is required to be a positive number, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
      if (product.percentage_to_land === "") {
        enqueueSnackbar("Percentage to land is required, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
      if (product.percentage_to_land <= 0) {
        enqueueSnackbar("Percentage to land is required to be a positive number, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
      if (product.percentage_to_land >= 100) {
        enqueueSnackbar("Percentage to land is required to be less than 100, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
      if (product.sale_price === "") {
        enqueueSnackbar("Sale price is required, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
      if (product.sale_price <= 0) {
        enqueueSnackbar("Sale price is required to be a positive number, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
      if (product.sale_price <= product.unit_price) {
        enqueueSnackbar("Sale price is required to be greater than unit price, check the product " + product.product_name, { variant: 'error' });
        errors = true;
        break;
      }
    }

    //CHECK GROUP
    if (formValues.is_group) {
      if (formValues.products_group_name === "") {
        enqueueSnackbar("Group name is required", { variant: 'error' });
        errors = true;
      }
      if (formValues.products_group_category === "") {
        enqueueSnackbar("Group category is required", { variant: 'error' });
        errors = true;
      }
      if (formValues.product_group_caso_code  === "") {
        enqueueSnackbar("Group CASO code is required", { variant: 'error' });
        errors = true;
      }
    }

    return errors;

  }



  /*====================================
  SUBMIT 
  =====================================*/
  const handleSubmit = () => {
    console.log("formValues", formValues);

    if (productValues !== null) {
      formValues.products[0].category = productValues.category;
    };

    formValues.products = formValues.products.map((product: any, index: number) => {
      return {
        ...product,
        product_name: product.product_name.replaceAll("'", "\\'"),
        product_description: product.product_description.replaceAll("'", "\\'"),
      };
    });

    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "add_edit_product",
          formdata: formValues,
        },
      })
      .then((response) => {
        console.log('Aqui',response.data);
        enqueueSnackbar(response.data.message, { variant: 'success' });

        if(!onSubmit){

          if(formValues.is_group){
            if (window.location.href.endsWith('add')) {
              navigate(`/inventory/group/${response.data.products_group_id}`);
              // navigate(-1);
            } else window.location.reload();
            setFormValues({ ...response.data.details });
          }
          else{
            if (window.location.href.endsWith('add')) {
              navigate(`/inventory/edit/${response.data.details[0].product_id}`);
              // navigate(-1);
            } else window.location.reload();
            setFormValues({ ...formValues, products: response.data.details });
          }
        }
        else{
          onSubmit(response.data);
        }

        
        //navigate(-1);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  /*====================================
  =====================================*/

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const updateProduct = (product: any, index: any) => {
    const products = [...formValues.products];
    products[index] = product;
    setFormValues({ ...formValues, products });
  };




  /*====================================================
    DRAWER
    ==================================================== */
    const [drawerOpen, setDrawerOpen] = useState(false);

    
    const ListItemIconStyles = {
        minWidth: 0,
        mr: drawerOpen ? 3 : "auto",
        justifyContent: "center",
    };

    const ListItemButtonStyles = {
    minHeight: 48,
    justifyContent: drawerOpen ? "initial" : "center",
    px: 2.5,
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
      };
    
      const handleDrawerClose = () => {
        setDrawerOpen(false);
      };
    /*====================================================
    END DRAWER
    ==================================================== */

    const handleFormChange = (e: { target: { name: any; value: any } }) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
    };

    //HANDLE SELECT PRODUCT CATEGORY
    const handleGroupCategoryChange = (event: SelectChangeEvent) => {
      setFormValues({
        ...formValues,
        products_group_category: event.target.value,
      });
    };


  /*=======================================
   PUSH NEW PRODUCT TO PRODUCTS GROUP
  =======================================*/
  const pushArrayProducts = () => {
    const products = [...formValues.products];
    products.push({
      product_id: null,
      product_name: "",
      caso_code: "",
      supplier_code: "",
      category: "",
      product_stock: "",
      minimum_stock: "",
      supplier_id: "",
      supplier_name: "",
      product_description: "",
      unit_price: 0,
      landed_cost: 0,
      percentage_to_land: 0,
      sale_price: 0,
      manual_sale_price: false,
      group_quantity: 1,
      product_status: "",
      stone_fields: {
        price_with_manufacturing: 0,
        price_without_manufacturing: 0,
        width: 0,
        length: 0,
        square_feet: 0,
        price_per_square_feet: 0,
      },
      wood_dimensions: []
    });
    setFormValues({ ...formValues, products });
  };
  /*=======================================
  =======================================*/


  /*===================================================
  PRODUCT GROUP UPDATE
  ====================================================*/
  const updateGroup = () => {
    if(!formValues.products_group_manual_sale_price){
      let price = 0;
      formValues.products.map((product: any) => {
        price += product.unit_price * product.group_quantity;
      }
      );
      setFormValues({ ...formValues, products_group_sale_price: price });
    }
  };


  useEffect(() => {
  if((popupItemParams && typeof popupItemParams !== 'function') || forcePopupAppearance) {
    setDrawerOpen(true);
  }
  }, [popupItemParams]);
    

  return (
    <div className="contentWithMiniDrawer">
      
      <div className="products_area">
        <Button
          onClick={() => {
            if(setOpenProductEditor){
              setOpenProductEditor(false);
            }
            else{
              navigate(-1);
            }
          }}
          variant="outlined"
          className="button_2"
        >
          Back to products list
        </Button>
        {formValues.products && (
          <>
          {formValues.products.map((product: any, index: any) => {
            
            return(
              <ProductForm 
                productValues = {
                  productValues === null ?
                  {...product} :
                  // {...product, folder_id: 420} :
                  { ...product, ...productValues}
                }
                updateProduct = {updateProduct}
                supplier_list = {supplier_list}
                index = {index}
                key = {index}
                is_group = {formValues.is_group}
                formValues = {formValues}
                setFormValues = {setFormValues}
                setLoading = {setLoading}
                popupItemParams = {popupItemParams}
              />
            )
          })}
          </>
        )}

          {formValues.is_group && (
            <div className='addButtonCointainer'>
              <IconButton className="item_add_button" color="primary" aria-label="add-item" size="small" onClick={()=>{
                  pushArrayProducts();
                }}>
                <AddIcon />
              </IconButton>
            </div>
          )}
      </div>
      
      
      <SideDrawer
          drawerOpen={drawerOpen}
          label = "Product"
          handleDrawerOpen = {handleDrawerOpen}
          handleDrawerClose = {handleDrawerClose}
          key = "sideDrawer"
          popup = {
            popupItemParams &&
            typeof popupItemParams !== 'function' ?
              true :
              ( forcePopupAppearance ?
                true :
                false
              )
          }
          
      >
        <div className="drawer_in">
            <div className="drawer_content" style={{ opacity: drawerOpen ? 1 : 0 }}>
              <div className="sidebar_body">
                <h2>Group Settings</h2>
                {!formValues.is_group ? (
                  <Fragment>
                    <span>You are editting a single product</span>
                    <Button
                      onClick={() => setFormValues({ ...formValues, is_group: true })}
                      variant="outlined"
                      className="button_2"
                    >
                      Create a group
                    </Button>
                  </Fragment>
                )
                :
                (
                  <Fragment>
                    <span>You are editting a group of products</span>
                    <TextField
                      id="products_group_name"
                      name="products_group_name"
                      label="Group Name"
                      type="text"
                      required
                      fullWidth
                      value={formValues.products_group_name}
                      onChange={handleFormChange}
                    />
                    <TextField
                      id="products_group_description"
                      name="products_group_description"
                      label="Group Description"
                      multiline
                      type="text"
                      required
                      fullWidth
                      value={formValues.products_group_description}
                      onChange={handleFormChange}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="lbl_product_category">Group Category</InputLabel>
                      <Select
                        labelId="products_group_category"
                        id="products_group_category"
                        value={formValues.products_group_category}
                        label="Category"
                        onChange={handleGroupCategoryChange}
                      >
                        <MenuItem value={"panel"}>Panels and adhesives</MenuItem>
                        <MenuItem value={"paint"}>Paint</MenuItem>
                        <MenuItem value={"hardware"}>Hardware</MenuItem>
                        <MenuItem value={"wood"}>Wood</MenuItem>
                        <MenuItem value={"stone"}>Stone</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField 
                      id="products_group_caso_code"
                      name="products_group_caso_code"
                      label="Caso Code"
                      type="text"
                      required
                      fullWidth
                      value={formValues.products_group_caso_code}
                      onChange={handleFormChange}
                    />
                    
                    <Fragment>
                      <TextField
                        id="products_group_sale_price"
                        name="products_group_sale_price"
                        label="Sale Price"
                        type="number"
                        required
                        fullWidth
                        onChange={handleFormChange}
                        value={formValues.products_group_sale_price}
                        disabled={!formValues.products_group_manual_sale_price}
                      />
                      <FormControlLabel
                        checked={formValues.products_group_manual_sale_price}
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            defaultChecked
                            onChange={(e) => {
                              setFormValues({ ...formValues, products_group_manual_sale_price: e.target.checked });
                            }}
                          />
                        }
                        label="Manual Price"
                      />
                    </Fragment>

                    
                        



                  </Fragment>
                )
              }
              
              </div>
            </div>
        </div>

        <List>
          <ListItem key="save" disablePadding sx={{ display: "block" }}>
              <ListItemButton
                  sx={ListItemButtonStyles}
                  onClick={() => {
                    if(!handleCheckForm()) {
                      handleSubmit();
                    }
                  }}
              >
                  <ListItemIcon sx={ListItemIconStyles}>
                  <SaveOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                  primary="SAVE"
                  sx={{ opacity: drawerOpen ? 1 : 0 }}
                  />
              </ListItemButton>
          </ListItem>
      </List>
      </SideDrawer>
      
        

      
    </div>
    
  );
};
