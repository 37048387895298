import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, Dialog, DialogContent, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import ic_panels from 'icons/ic_panels.svg';
import ic_paint from 'icons/ic_paint.svg';
import ic_hardware from 'icons/ic_hardware.svg';
import ic_wood from 'icons/ic_wood.svg';
import ic_stone from 'icons/ic_stone.svg';
import LanIcon from '@mui/icons-material/Lan';
import TopBar from 'elements/TopBar';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AlertDialog from 'elements/AlertDialog';
import ProductEditor from './ProductEditor';
import StockPending from 'components/Projects/Dashboard/StockPending';
import { set } from 'date-fns';
import AppAvatar from 'elements/AppAvatar';



const fakeApiRemnants: any[] = [
  {
    remnant_id: '1',
    product_id: '273',
    product_name: 'AUTUMNUS DARK HONED 2cm',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 1,
  },
  {
    remnant_id: '2',
    product_id: '273',
    product_name: 'AUTUMNUS DARK HONED 2cm',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 2,
  },
  {
    remnant_id: '3',
    product_id: '273',
    product_name: 'AUTUMNUS DARK HONED 2cm',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 3,
  },
  {
    remnant_id: '4',
    product_id: '273',
    product_name: 'AUTUMNUS DARK HONED 2cm',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 4,
  },
  {
    remnant_id: '5',
    product_id: '273',
    product_name: 'AUTUMNUS DARK HONED 2cm',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 5,
  },
  {
    remnant_id: '6',
    product_id: '273',
    product_name: 'AUTUMNUS DARK HONED 2cm',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 6,
  },
  {
    remnant_id: '7',
    product_id: '273',
    product_name: 'AUTUMNUS DARK HONED 2cm',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 7,
  },
  {
    remnant_id: '8',
    product_id: '273',
    product_name: 'AUTUMNUS DARK HONED 2cm',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 8,
  },
  {
    remnant_id: '9',
    product_id: '273',
    product_name: 'AUTUMNUS DARK HONED 2cm',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 9,
  },
];

const categories_filter_base = {
    panel: false,
    paint: false,
    hardware: false,
    wood: false,
    stone: false
}

const ProductList = (props: any) => {

  const {
    popupItemParams,
    setProduct,
    setOpenPopup,
    setOpenProductEditor,
    setLoading,
    addOption = true,
    withStockPending = false,
    setMenuTopLeftContent,
    productAsObj = false,
  } = props;

  useEffect(() => {setLoading(false);}, []);

  /*=========================================================
  FILTERS
  =========================================================*/
  const filters = [
    {type: "text", label: "Product name", name: "product_name", value: ""},
    {type: "text", label: "Caso Code", name: "caso_code", value: ""},
  ]
  const [applyFilters, setApplyFilters] = useState({});
  const [filtersValues, setFiltersValues] = useState<any>({});

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
  }
  /*=========================================================*/

  const [orderBy, setOrderBy] = useState('product_name');
  const [order, setOrder] = useState('ASC');

  const [openGroupProducts, setOpenGroupProducts] = useState(false);
  const [groupProducts, setGroupProducts] = useState<any[]>([]);

  const setTopMenu = () => {
    if(!setMenuTopLeftContent) return;
    setMenuTopLeftContent({
      child:
        <menu id='project_menu'>
          <a href='/inventory'>Inventory</a>
          <a href='/inventory/incoming'>Incoming inventory</a>
          <a href='/inventory/outgoing'>Outgoing inventory</a>
        </menu>
    })
  };

  const [product_list, setProductList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);
  const [panel_value, setPanelValue] = useState(0);
  const [paint_value, setPaintValue] = useState(0);
  const [hardware_value, setHardwareValue] = useState(0);
  const [wood_value, setWoodValue] = useState(0);
  const [stone_value, setStoneValue] = useState(0);
  const [total_value, setTotalValue] = useState(0);
  
  const [categories_filter, setCategories] = useState<any>(categories_filter_base);

  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(searchParams.get('page') ? parseInt(searchParams.get('page')!) : 1);


  const handleSort = (property: any) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };
  

  /*=========================================================
  LOAD LIST            
  =========================================================*/
  useEffect(() => {
    loadData();
  }, []);
  
  useEffect(() => {
    loadData();
  }, [page, filtersValues])
  
  const loadData = () => {
    setLoading(true)
    
    //convert categories_filter to string
    let categories_filter_string = "";
    for (const [key, value] of Object.entries(categories_filter)) {
      if(value){
        categories_filter_string += "'"+ key + "',";
      }
    }
    categories_filter_string = categories_filter_string.slice(0, -1);
    
    if(popupItemParams){
      console.log(popupItemParams)
      categories_filter_string = "'"+popupItemParams.category+"'";
    }
    
    const withCategory = props.withoutCategory ? {} : {category: categories_filter_string};

    console.log(props.withoutCategory,{
      ...withCategory,
      page: page,
      filters: (filtersValues ? filtersValues : undefined),
      order_by: orderBy,
      order: order,
    })
  
    axios.get(ProjectConfig.api_url,
    {
      params: {
        request: 'product_list',
        params: {
          ...withCategory,
          filters: (filtersValues ? filtersValues : undefined),
          page: page,
          order_by: orderBy,
          order: order,
        }
      }
    })
    .then((response) => {
      console.log(response.data);
      setProductList(response.data.products)
      setTotalPages(response.data.total_pages)
      
      //SET VALUES
      setPanelValue(response.data.panel_value)
      setPaintValue(response.data.paint_value)
      setHardwareValue(response.data.hardware_value)
      setWoodValue(response.data.wood_value)
      setStoneValue(response.data.stone_value)
      setTotalValue(response.data.total_value)
    })
    .catch((err) => {
      console.log(err.data);
    })
    .finally(() => {
      setLoading(false);
    });
  }
  /*=========================================================*/

  const location = useLocation();
  useEffect(() => {
    setTopMenu();
  }, [location]);
  
  useEffect(() => {
    if (page > 1) {
      setPage(1);
      setSearchParams({...searchParams, page: '1'});
    } else {
      loadData();
    }
  }, [categories_filter, filtersValues, orderBy, order]);

  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (product_id:number, product_type: string) => {
    setActiveId(product_id);
    setActiveType(product_type);
    setOpenAlert(true);
  }

  const deleteItem = () => {
    axios.post(ProjectConfig.api_url, null, 
      {
        params: {
          request: 'delete_product',
          product_id: activeId,
          product_type: activeType
        }
      })
  
      .then((response) => {
        console.log(response.data);
        loadData();
        setOpenAlert(false);
        
      })
      .catch((err) => {
        console.log(err.data);
      })
    }

  /*=========================================================*/





  const loadGroupProducts = (products_group_id:any) => {
    axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'group_product_list',
          params: {
            products_group_id: products_group_id,
          }
        }
      })
        .then((response) => {
          console.log(response.data);
          setGroupProducts(response.data.products)
        })
        .catch((err) => {
          console.log(err.data);
        })
        

    setOpenGroupProducts(true);
  }


  const [openAlert, setOpenAlert] = useState(false);
  const [activeId, setActiveId] = useState<any>(null);
  const [activeType, setActiveType] = useState<any>(null);








  const [stockPending, setStockPending] = useState<any>([]);

  useEffect(() => {
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'get_stock_pending',
          project_id: 0
        }
      })
        .then((response) => {
          console.log(response.data);
          setStockPending(response.data);
          setLoading(false)
        })
        .catch((err) => {
          console.log(err.data);
        })
        
    
  }, [])


  /*=========================================================
  STONE REMNANTS
  =========================================================*/
  const [isOnlyStoneFilter, setIsOnlyStoneFilter] = useState(false);
  const [showStoneRemnants, setShowStoneRemnants] = useState<boolean>(false);
  const [remnantList, setRemnantList] = useState<any[]>([]);
  
  useEffect(() => {
    const stoneFilter = categories_filter.stone;
    if (stoneFilter) {
      let onlyStone = true;
      const keys = Object.keys(categories_filter);
      
      for (let i = 0; i < keys.length; i++) {
        const key: any = keys[i];
        if(key !== 'stone' && categories_filter[key] && categories_filter[key] === true) {
          onlyStone = false;
          break;
        }
      };
      setIsOnlyStoneFilter(onlyStone);
    } else {
      setIsOnlyStoneFilter(false);
    }
  }, [categories_filter]);

  useEffect(() => {
    setShowStoneRemnants(false);
  }, [isOnlyStoneFilter]);

  useEffect(() => {
    if (showStoneRemnants) {
      loadRemnants();
    } else {
      setRemnantList([]);
    }
  }, [showStoneRemnants]);

  const loadRemnants = () => {
    setLoading(true);
    setRemnantList([...fakeApiRemnants]);
    setLoading(false);
  };

  /*=========================================================
  END STONE REMNANTS
  =========================================================*/




  
  return (
    <div id='ProductList'>

    <TopBar
      title='PRODUCTS'
      filters={filters}
      setFilters={setFilters}
      setLoading={props.setLoading}
      filtersValues={filtersValues}
      setFiltersValues={setFiltersValues}
      setApplyFilters={setApplyFilters}
    />
      
      
    <div className='buttons_top'>
      {addOption && (
        <>
        {!popupItemParams && (
          <Button component={Link} to="add" variant="contained">
          New Product
        </Button>
        )}

        {popupItemParams && (
          <Button onClick={() => {
            setOpenProductEditor(true);
          }} variant="contained">
            New product
          </Button>
        )}
        </>
      )}
    </div>

    <div id="product_list_flex">
    <div id="product_list_container"> 
      {!popupItemParams && (
      <div id='product_list_header'>
          <Button 
            className={"product_list_header_item box " + (categories_filter.panel ? "category_active" : "")}
            color='primary'
            sx={{color: '#6b6b6b', backgroundColor: '#ffffff'}}
            onClick={() => {
              const new_categories = {...categories_filter};
              new_categories.panel = !new_categories.panel;
              setCategories(new_categories);
            }}
          >
            <img src={ic_panels}></img>
            <h3>Panels & Adhesives</h3>
            <span>${panel_value.toLocaleString()}</span>
          </Button>
          <Button 
            className={"product_list_header_item box " + (categories_filter.paint ? "category_active" : "")}
            color='primary'
            sx={{color: '#6b6b6b', backgroundColor: '#ffffff'}}
            onClick={() => {
              const new_categories = {...categories_filter};
              new_categories.paint = !new_categories.paint;;
              setCategories(new_categories);
            }}
          >
            <img src={ic_paint}></img>
            <h3>Paint</h3>
            <span>${paint_value.toLocaleString()}</span>
          </Button>
          <Button 
            className={"product_list_header_item box " + (categories_filter.hardware ? "category_active" : "")}
            color='primary'
            sx={{color: '#6b6b6b', backgroundColor: '#ffffff'}}
            onClick={() => {
              const new_categories = {...categories_filter};
              new_categories.hardware = !new_categories.hardware;;
              setCategories(new_categories);
            }}
          >
            <img src={ic_hardware}></img>
            <h3>Hardware</h3>
            <span>${hardware_value.toLocaleString()}</span>
          </Button>
          <Button 
            className={"product_list_header_item box " + (categories_filter.wood ? "category_active" : "")}
            color='primary'
            sx={{color: '#6b6b6b', backgroundColor: '#ffffff'}}
            onClick={() => {
              const new_categories = {...categories_filter};
              new_categories.wood = !new_categories.wood;;
              setCategories(new_categories);
            }}
          >
            <img src={ic_wood}></img>
            <h3>Wood</h3>
            <span>${wood_value.toLocaleString()}</span>
          </Button>
          <Button 
            className={"product_list_header_item box " + (categories_filter.stone ? "category_active" : "")}
            color='primary'
            sx={{color: '#6b6b6b', backgroundColor: '#ffffff'}}
            onClick={() => {
              const new_categories = {...categories_filter};
              new_categories.stone = !new_categories.stone;;
              setCategories(new_categories);
            }}
          >
            <img src={ic_stone}></img>
            <h3>Stone</h3>
            <span>${stone_value.toLocaleString()}</span>
          </Button>

          <div 
            className='product_list_header_item box total_inventory'
            color='primary'
          >
            <h3>INVENTORY VALUE</h3>
            <span>${total_value.toLocaleString()}</span>
          </div>
        </div>
        )}
        <div id="product_list_table" className="flex_container">
          <Box id='product_list' title="" subtitle=""  >
            <FiltersApplied
              applyFilters={applyFilters}
              setApplyFilters={setApplyFilters}
              filtersValues={filtersValues}
              setFiltersValues={setFiltersValues}
            />

            {isOnlyStoneFilter && (
              <div>
                {showStoneRemnants ? (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    onClick={() => {
                      setShowStoneRemnants(false);
                    }}
                  >
                    Hide stone remnants
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    onClick={() => {
                      setShowStoneRemnants(true);
                    }}
                  >
                    Show stone remnants
                  </Button>
                )}
              </div>
            )}

            {showStoneRemnants ? (
              <>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className='thSorter'>
                      <TableCell> Stone Name </TableCell>
                      <TableCell> Length </TableCell>
                      <TableCell> Width </TableCell>
                      <TableCell> Thickness </TableCell>
                      <TableCell> Quantity </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {remnantList.map((remnant: any, remnantIndex: number) => (
                      <TableRow key={remnantIndex}>
                        <TableCell>
                          <Link
                            className="link_1"
                            to={"/inventory/edit/" + remnant.product_id}
                          >
                            {remnant.product_name}
                          </Link>
                        </TableCell>
                        <TableCell> {remnant.length}</TableCell>
                        <TableCell> {remnant.width}</TableCell>
                        <TableCell> {remnant.thickness}</TableCell>
                        <TableCell> {remnant.quantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            ) : (
              <>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table"> 
                    <TableHead>
                      <TableRow className='thSorter'>
                        <TableCell>
                          <Button
                            variant='text'
                            sx={{color: '#6b6b6b'}}
                            onClick={() => {
                              handleSort('product_name')
                            }}
                              
                          >
                            Product Name / Code
                            <KeyboardArrowDownIcon className={orderBy === 'product_name' ? 'active' + order : ''}/>
                          </Button>
                        </TableCell>
                        <TableCell>Stock</TableCell>
                        <TableCell>Min Stock </TableCell>
                        <TableCell>Landing price</TableCell>
                        <TableCell>
                          <Button
                            variant='text'
                            sx={{color: '#6b6b6b'}}
                            onClick={() => {
                              handleSort('sale_price')
                            }}
                          >
                            Sale Price
                            <KeyboardArrowDownIcon className={orderBy === 'sale_price' ? 'active' + order : ''}/>
                          </Button>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {product_list.map((product,i) => {
                          const product_type = product.product_type;

                          const groupProductsButton = product_type !== "product" ? (
                            <Button
                              variant="outlined"
                              color="primary"
                              size='small'
                              onClick={() => {
                                loadGroupProducts(product.product_id);
                              }}
                            >
                              Products
                            </Button>
                          ) : null;


                        

                          
                          const link =
                          !popupItemParams? (
                            product_type === "product" ? (
                              <Link
                                className="link_1"
                                to={"/inventory/edit/" + product.product_id}
                              >
                                <AppAvatar name={product.product_name} image={product.image} />
                                {product.product_name}
                              </Link>
                            ) : (
                              <Link
                                className="link_1"
                                to={"/inventory/group/" + product.product_id}
                              >
                                {/* TIP */}
                                <Tooltip title="This is a group of products.">
                                  <LanIcon />
                                </Tooltip>
                                {product.product_name}
                              </Link>
                            ))
                            : <Button
                                variant="text"
                                color="primary"
                                size='small'
                                onClick={() => {
                                  if (productAsObj) {
                                    setProduct({
                                      product_name: product.product_name,
                                      product_id: product.product_id,
                                      sale_price: product.sale_price,
                                      caso_code: product.caso_code,
                                      product_description: product.product_description,
                                      stone_fields: product.stone_fields,
                                    })
                                  } else {
                                    setProduct(
                                      product.product_name,
                                      product.product_id,
                                      product.sale_price,
                                      product.stone_fields,
                                      product.caso_code,
                                      popupItemParams.serviceIndex,
                                      popupItemParams.category,
                                      popupItemParams.itemIndex,
                                      product.product_type
                                    );
                                  }
      
                                  setOpenPopup(false);

                                }}
                              >
                                {product_type === "product" ? (
                                  <AppAvatar name={product.product_name} image={product.image} />
                                ) : null}
                                {product.product_name}
                              </Button>
                          ;
                            
                            

                          


                          return(
                          !product.products_group_id && (
                          <TableRow 
                            
                            key={i}
                            hover
                            role="checkbox"
                          >
                            <TableCell>
                              <div className='td_2_rows'>
                                <span className='td_2_rows_bold'>{link}</span>
                                {product.caso_code && <span className='chip_code'>{product.caso_code}</span>}
                                {groupProductsButton}
                              </div>
                              
                            </TableCell>
                            <TableCell>{product.product_stock}</TableCell>
                            <TableCell>{product.minimum_stock}</TableCell>
                            <TableCell>
                              {product.landed_cost}
                            </TableCell>
                            <TableCell>
                                {"$"+ product.sale_price.toLocaleString()}
                            </TableCell>
                            <TableCell> 
                              <IconButton aria-label="delete"  color="primary" onClick={() => {
                                    handleDelete(product.product_id, product.product_type)
                                  }}>
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                          </TableCell>
                          </TableRow>
                          )
                        )})}
                    </TableBody>
                  </Table>
                </TableContainer>
                {total_pages > 1 &&(
                  <Pagination
                    count={total_pages}
                    page={page}
                    onChange={(event, value) => {
                      setPage(value)
                      setSearchParams({...searchParams, page: value.toString()})
                    }} 
                    color="primary"
                    style={{"marginTop": "20px","alignSelf": "center"}}
                  />
                )}
              </>
            )}
          </Box>
  
        </div>
      </div>
      
      {withStockPending &&
      <div id="dashboard_right_column">
        <StockPending data={stockPending}/>
      </div>
      }


      
    </div>

        <Dialog open={openGroupProducts} onClose={() => setOpenGroupProducts(false)}> 
          <DialogContent className='forced_box_editor'>
            <h2 className='h2_dialog'>Products in this group</h2>
            <p className='card_subtitle'>Products that are part of this group.</p>

            <TableContainer>
              <Table stickyHeader aria-label="sticky table"> 
                <TableHead>
                  <TableRow className='thSorter'>
                    <TableCell>Product Name / Code</TableCell>
                    <TableCell>Required quantity</TableCell>

        
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupProducts.map((product, i) => {
                      const product_type = product.product_type;

                      return(
                      !product.products_group_id && (
                      <TableRow 
                        
                        key={i}
                        hover
                        role="checkbox"
                      >
                        <TableCell>
                          <div className='td_2_rows'>
                            <span className='td_2_rows_bold'>{product.product_name}</span>
                            {product.caso_code && <span className='chip_code'>{product.caso_code}</span>}
                          </div>
                        </TableCell>
                        <TableCell style={{textAlign:'right'}}>{product.group_quantity}</TableCell>

                      </TableRow>
                      )
                    )})}
                </TableBody>
              </Table>
            </TableContainer>

          


            <div className='buttons'>
              <Button variant="contained" onClick={() => {
                setOpenGroupProducts(false);
              }}>Close</Button>
            </div>
          </DialogContent>
        </Dialog>



        


        <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle="Are you sure you want to delete this product?"
                send={deleteItem}
            />
            
    </div>
  );
};



export default ProductList;