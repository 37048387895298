import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import Box from 'elements/Box';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { ProjectConfig } from 'Global';

const defaultValues = {
    point_id: "null",
    description: "",
    points: 0,
}

export default function PointsEditor( props: any ) {

    const { points_id = 'null' } = useParams()

    /*====================================
    enqueSnackbar
    =====================================*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    //====================================//

    const [formValues, setFormValues] = useState({...defaultValues})

    useEffect(() => {
        if (points_id && points_id !== 'null') {
            loadData();
        }
    }, []);

    const navigate = useNavigate();
    
    const loadData = () => {
        axios.get(ProjectConfig.api_url, {
            params: {
                request: "get_points_details",
                point_id: points_id,
            }
        })
        .then((response) => {
            console.log(response.data);
            setFormValues(response.data);
        })
        .catch((err) => {
            console.log(err);
        });
    };


    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleCheckForm = () => {
        let errors = false;
        if (!formValues.description || !formValues.points) {
            enqueueSnackbar("Please enter all required fields", { variant: "error" });
            errors = true;
        }
        return errors;
    };
            

    /*====================================
    SUBMIT 
    =====================================*/
    const handleSubmit = () => {
        console.log(formValues);

        axios.post(ProjectConfig.api_url, {...formValues, point_id: points_id}, {
            params: {
                request: "add_edit_point",
            }
        })
        .then((response) => {
            enqueueSnackbar("Points Saved", { variant: "success" });
            navigate('/points', {replace: true})
            console.log("data",response.data);
        })
        .catch((err) => {
            console.log(err);
        });
    };
    /*====================================
    =====================================*/
    
    return (
        <form>
            <Box id="points_list" className="box_editor" title="Points" subtitle="Edit"  >
                <div className='form'>
                    <TextField 
                        id="points_description"
                        name="description"
                        label="Description"
                        type="text"
                        required
                        value={formValues.description}
                        onChange={handleInputChange}
                    />

                    <TextField 
                        id="points_points"
                        name="points"
                        label="Points"
                        type="number"
                        required
                        value={formValues.points}
                        onChange={handleInputChange}
                    />
                </div>

                <div className='buttons'>
                    <Button
                        onClick={() => {
                            navigate('/points', {replace: true})
                        }}
                        variant="outlined"
                        className="button_2"
                    >
                        Back
                    </Button>
                    
                    <Button
                        onClick={() => {
                            if(handleCheckForm()) return;
                            handleSubmit();
                        }}
                        variant="contained"
                        className="button_1"
                    >
                        Submit
                    </Button>
                </div>
            </Box>

            



        </form>
    );
}