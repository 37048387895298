import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { Chip } from '@mui/material';



export default function AutocompleteMulti(props: any) {

  const {
    setSelectedItems,
    selectedItems,
    limit = undefined,
    ...autocompliteProps
  } = props;

  const [user_list, setUserList] = useState<any[]>([]);

  

  /*==================================================
    GET LIST
    ===================================================*/
    useEffect(() => {
      
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'user_list',
          params: {
            full_list: 1,
            minimal: true,
          }
        }
      })
        .then((response) => {
          console.log(response.data)
          setUserList(response.data.user_list)
          
        })
        .catch((err) => {
          console.log(err.data);
        })
    }, [])
    /*==================================================
    ===================================================*/


    
  return (
    <Autocomplete
      {...autocompliteProps}
      multiple 
      disabled={limit? selectedItems.length >= limit : false}
      // isOptionEqualToValue={(option, value) => option.user_id == value.user_id.toString()}
      limitTags={2}
      id="multiple-limit-tags"
      options={user_list}
      getOptionLabel={(option: any) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderInput={(params) => (
        <TextField {...params} label={limit == 1 ? "Worker" : "Workers"} placeholder="Assign Worker" />
      )}
      onChange={(event, value) => {
        setSelectedItems(value);
      }
      }
      sx={{ width: '500px' }}
      value={selectedItems}
      renderTags={(tagValue: any[], getTagProps) => {
        console.log(tagValue)
        return tagValue.map((option, index) => (
          <Chip
            label={option.name}
            {...getTagProps({ index })}
            disabled={false}
          />
        ))
      }}
    />
  );
}
