import React, { useEffect, useState } from 'react';
// import { Gantt, Toolbar } from 'devextreme-react';
import { GanttScaleType } from 'devextreme/ui/gantt';
import Gantt, { Column, Toolbar, Editing, Item, Tasks, Validation } from 'devextreme-react/gantt';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { Button, Dialog } from '@mui/material';
import 'devextreme/dist/css/dx.light.css';

import "../../../node_modules/devexpress-gantt/dist/dx-gantt.min.css";
import "assets/css/main.css";
import "./styles/css/schedule.css";

export default function GanttView ( props: any ) {
    const {
        data,
        setOpenTaskEditor = () => {},
        setSelectedTaskId = () => {},
        rangeDate = {start: new Date(), end:new Date((new Date().setMonth(new Date().getMonth() + 1))), months_quantity: 1},
        adjustHeight = false,
    } = props;

    const [currentScale, setCurrentScale] = useState<GanttScaleType>('days');

    useEffect (() => {
        if (Number(rangeDate.months_quantity) >= 3) {
            setCurrentScale('weeks');
        } else {
            setCurrentScale('days');
        }
    }, [props.rangeDate]);

    return (
        <>
        <Gantt
            startDateRange={rangeDate.start}
            endDateRange={rangeDate.end}
            taskListWidth={300}
            scaleType={currentScale}
            
            height={(adjustHeight && data.length < 10) ? (data.length > 0 ? data.length * 38 : 100) + 105 + 46 + 10 : 700}
            onTaskEditDialogShowing={(e: any) => {
                e.cancel = true;
                if(e.key > 0) {
                    setSelectedTaskId(e.key);
                    setOpenTaskEditor(true);
                }
            }}

        >
            <Tasks dataSource={data} />

            <Toolbar>
                <Item name="zoomIn" />
                <Item name="zoomOut" />
            </Toolbar>

            <Column dataField="title" caption="Tasks" width={300} />

            <Validation autoUpdateParentTasks />
        </Gantt>
        </>
    );
};