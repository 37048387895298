import React, { useContext, useEffect, useState} from 'react';

import { IService, QuotesContext } from '../QuoteContextProvider';
import { Button, IconButton } from '@mui/material';
import { ExpandLess, ExpandMore, RequestQuote } from '@mui/icons-material';
import Box from 'elements/Box';
import ExplodedProductBox from './ExplodedProductBox';

const defaultValues: IService = {
    name: '',
    serviceType: '',
    collapseService: false,
    collapseSections: true,
};

export default function ExplodedServiceBox ( props: any ) {

    const {
        getAlternateView,
        sectionList,
        sectionDict,
        categories,
        serviceDict,
        setOpenNewSection,
        updateService,
        setViewType,
        setExplodedItemAnchor,
        createItemInSection,
    } = useContext(QuotesContext);

    const {
        viewType = "Exploded", // This component is for the exploded view by default
        serviceIndex,
        key = undefined,
    } = props;

    const [service, setService] = useState<IService>(defaultValues);
    const [alternateView, setAlternateView] = useState<string>('');

    useEffect(() => {
        if (props) {
            setAlternateView(getAlternateView(viewType));
            setService({
                ...serviceDict[serviceIndex],
            });
        };
    }, [props]);

    const handleValueChange = (value: any, field: string) => {
        const newServiceChanges = {
            [field]: value,
        };

        updateService(newServiceChanges, serviceIndex);
    }

    return (
        <>
            <div key={key} className={"QuoteService "+(service.collapseService ? "serviceClose" : "serviceOpen")}>
                <div className={"QuoteServiceCategory type_" + service.serviceType}>
                    <IconButton
                        aria-label="Expand"
                        size="small"
                        style={{color: "#fff"}}
                        onClick={() => {
                            handleValueChange(!service.collapseService, 'collapseService');
                        }}
                    >
                        {service.collapseService ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>

                    <span className="QuoteServiceText">
                        {service.name}
                    </span>
                </div>
            
                <div className="QuoteServiceItems ExplodedViewServieItems">
                    <div className="QuoteServiceHeader ">
                        <div className="QuoteServiceHeaderLeft">
                            <span className="QuoteServiceName">
                                {service.name}
                            </span>
                        </div>
                    </div>

                    {service.serviceType !== '' &&
                    categories[service.serviceType].map((categoryItem: any, categoryIndex: any) => {
                        const category_name = categoryItem.category_name;
                        const category = categoryItem.category;
                        const category_element_id = "catIndex"+alternateView+serviceIndex+"-"+category;
                        const alternative_category_element_id = `catIndex${viewType}${serviceIndex}-${category}`;

                        return (
                            category !== "workforce" &&
                            category !== "paint" &&
                            category !== "hardware" && (
                                <Box key={key} className="categoryRow explodedCategoryRow">
                                    <div id={category_element_id} className="explodedCategoryRowHeader">
                                        <h2>{category_name}
                            
                                            <IconButton
                                                aria-label="Expand"
                                                size="small"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    setViewType("quote")
                                                    setExplodedItemAnchor(alternative_category_element_id);
                                                }}
                                            >
                                                <RequestQuote />
                                            </IconButton>
                                        </h2>
                                        
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={(e) => {
                                                handleValueChange(!service.collapseSections, 'collapseSections');
                                            }}
                                        >
                                            Show/Hide All Sections
                                        </Button>
                                    </div>

                                    <div className="explodedSections">
                                        {sectionList.map((section: any, sectionIndex: number) => {
                                            const productListWhole = sectionDict[section.section_name];

                                            if (!productListWhole) return null;

                                            const productList = productListWhole
                                                .filter((item: any) => item.category === category && item.serviceIndex === serviceIndex);

                                            if (productList.length === 0 && service.collapseSections) return null;

                                            return (
                                                <div key={key} className="explodedSection SwitchGroup haveSection">
                                                    <h2 className="sectionServiceHeader">
                                                        Section: {section.section_name}
                                                    </h2>
                                                    
                                                    <div className="sectionServiceItems">
                                                        {productList.map((item: any, keyToRender: number) => (
                                                            <ExplodedProductBox
                                                                serviceIndex={serviceIndex}
                                                                category={category}
                                                                itemIndex={item.itemIndex}
                                                                serviceType={service.serviceType}
                                                                key={keyToRender}
                                                            />
                                                        ))}
                                                    </div>

                                                    <Button
                                                        className="item_add_button"
                                                        onClick={() => {
                                                            createItemInSection(
                                                                section.section_name,
                                                                serviceIndex,
                                                                category,
                                                            );
                                                        }}
                                                    >
                                                        Add item
                                                    </Button>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            setOpenNewSection(true);
                                        }}
                                    >
                                        Add Section
                                    </Button>
                                </Box>
                            )
                        );
                    })}
                </div>
            </div>
        </>
    )

}