import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    TextField,
    Autocomplete,
    Button,
    IconButton,
    createFilterOptions,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';

import Box from 'elements/Box';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ic_dots from 'icons/ic_dots.svg';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Event } from '@mui/icons-material';
import ClientEditor from 'components/Clients/ClientEditor';
import { useSnackbar } from 'notistack';

interface IClientList {
    inputValue?: string,
    client_id?: number,
    client_name: string,
    client_email?: string,
    client_phone?: string,
    client_address?: string,
};

const defaultValues = {
    project_id: 'null',
    project_name: '',
    client_id: '',
    client_name: '',
    project_start_date: '',
    project_deadline: '',
    project_status: '1',
};

export default function ProjectConfigurator( props: any ) {

    const {} = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    /*=======================================
    GET ID FROM URL
    =======================================*/
    const { project_id } = useParams();
    /*=======================================
    =======================================*/

    const [list, setList] = useState<IClientList[]>([]);
    const [formValues, setFormValues] = useState({...defaultValues});
    const [client, setClient] = useState<IClientList | null>(null);

    const [openEditorClient, setOpenEditorClient] = useState(false);

    const filter = createFilterOptions<IClientList>();



    useEffect(() => {
        loadData();
        loadList();
    }, []);

    const loadData = () => {
        setLoading(true)
        
        axios.get(ProjectConfig.api_url,
        {
        params: {
            request: 'project_detail',
            project_id: project_id
        }
        })
        .then((response) => {
            console.log("project_detail",response.data);

            setFormValues({...response.data});
            setClient({client_id: response.data.client_id, client_name: response.data.client_name});
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const loadList = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'client_list',
                full_list: true,
            }
        })
        .then((response) => {
            console.log(response.data);
            setList(response.data.list)
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    const handleOpenClientEditor = () => {
        if (!formValues.client_id) {
            enqueueSnackbar("Please enter a client", { variant: 'error' });
            return;
        }
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: "client_details",
                client_id: formValues.client_id,
            },
        })
        .then((response) => {
            console.log(response.data);
            setClient(response.data);
            setOpenEditorClient(true);
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleSubmit = () => {
        if (!formValues.project_name || !formValues.client_id) {
            enqueueSnackbar("Please enter a project name and client", { variant: 'error' });
            return;
        }
      
        if (new Date(formValues.project_start_date).getTime() > new Date(formValues.project_deadline).getTime()) {
            enqueueSnackbar("Project start date cannot be after project deadline", { variant: 'error' });
            return;
        }

        setLoading(true);
      
        axios.post(ProjectConfig.api_url, formValues, {
            params: {
                request: 'edit_project',
            }
        })
        .then((response) => {
            console.log(response.data); 
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const onSubmitClient = (response: any) => {
        console.log(response);
        setClient(response);
        setFormValues({
            ...formValues,
            client_id: response.client_id.toString(),
            client_name: response.client_name,
        });
        setOpenEditorClient(false);
    }

    return (
        <>
            <div>
                <form>
                    <Box id="project_editor" className="box_editor" title="" subtitle="Edit" >
                        <div className='form' style={{minWidth: '400px'}}>
                            <div className='input_group'>
                                <TextField 
                                    id="project_name"
                                    name="project_name"
                                    label="Project Name"
                                    type="text"
                                    required
                                    fullWidth
                                    value={formValues.project_name}
                                    onChange={handleInputChange}
                                />
                                </div>
                            <div className='input_group'>
                            
                                <Autocomplete
                                    value={client}

                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setClient({
                                                client_name: newValue,
                                            });
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            setClient({
                                                client_name: newValue.inputValue,
                                            });
                                            setOpenEditorClient(true);
                                        } else {
                                            setClient(newValue);

                                            if (newValue) {
                                                if (newValue?.client_id!=null) {
                                                    setFormValues({
                                                        ...formValues,
                                                        client_id: newValue.client_id.toString(),
                                                        client_name: newValue.client_name,
                                                    });
                                                } else {
                                                    setFormValues({
                                                        ...formValues,
                                                        client_id: "",
                                                        client_name: newValue.client_name,
                                                    });
                                                }
                                            }
                                        }
                                    }}

                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.client_name);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                client_name: `Add "${inputValue}"`,
                                            });
                                        }

                                        return filtered;
                                    }}

                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    fullWidth
                                    id="free-solo-with-text-demo"
                                    options={list}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.client_name;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.client_name}</li>}
                                    sx={{ width: 300 }}
                                    freeSolo
                                    renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        label="Client Name" 
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                            <IconButton 
                                                onClick={handleOpenClientEditor}
                                                
                                            ><img src={ic_dots}></img></IconButton>
                                            )
                                        }}
                                    />
                                    )}
                                    
                                />
                            </div>

                            <div className="input_row">
                                <TextField
                                    id="project_status"
                                    name="project_status"
                                    label="Project status"
                                    select
                                    fullWidth
                                    value={formValues.project_status}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="0">Active</MenuItem>
                                    <MenuItem value="1">Paused</MenuItem>
                                    <MenuItem value="2">Closed</MenuItem>
                                    <MenuItem value="3">Discarded</MenuItem>
                                </TextField>
                            </div>

                            <div className="input_row">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        label="Start date"
                                        inputFormat="MMMM DD, yyyy"
                                        value={formValues.project_start_date}
                                        InputProps={{
                                        endAdornment: (
                                            <Event />
                                        ),
                                        }}
                                        showToolbar={false}
                                        closeOnSelect 
                                        onChange={(newValue: any) => {
                                        if (newValue) {
                                            setFormValues({
                                            ...formValues,
                                            ["project_start_date"]: (newValue).format('YYYY-MM-DD')
                                            });
                                        }
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterMoment}>

                                <MobileDatePicker
                                    label="Deadline"
                                    inputFormat="MMMM DD, yyyy"
                                    disabled={!formValues?.project_start_date}
                                    value={formValues.project_deadline}
                                    InputProps={{
                                    endAdornment: (
                                        <Event />
                                    ),
                                    }}
                                    showToolbar={false}
                                    closeOnSelect 
                                    onChange={(newValue: any) => {
                                    if (newValue) {
                                        setFormValues({
                                        ...formValues,
                                        ["project_deadline"]: (newValue).format('YYYY-MM-DD')
                                        });
                                    }
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />

                                </LocalizationProvider>
                            </div>
                        </div>
                    
                    <div className='buttons'>
                        <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">CLOSE</Button>
                        <Button onClick={handleSubmit} variant="contained" className="button_1">Submit</Button>
                    </div>
                    </Box>

                    <Dialog
                        open={openEditorClient}
                        onClose={() => setOpenEditorClient(false)}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogContent>
                        <div className="flex">
                            <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell>Client Name</TableCell>
                                <TableCell>Client Email</TableCell>
                                <TableCell>Client Address</TableCell>
                                <TableCell>Client Phone</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {list.map((client: any) => (
                                <TableRow
                                    hover
                                    key={client.client_id}
                                    onClick={() => {
                                    setClient({
                                        client_name: client.client_name,
                                    });

                                    setFormValues({
                                        ...formValues,
                                        ["client_id"]: client.client_id.toString()
                                    });

                                    setOpenEditorClient(false);
                                    }}
                                >
                                    <TableCell>{client.client_name}</TableCell>
                                    <TableCell>{client.client_email}</TableCell>
                                    <TableCell>{client.client_address}</TableCell>
                                    <TableCell>{client.client_phone}</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </div>
                        </DialogContent>
                    </Dialog>

                    



                </form>
            </div>

            
        </>
    );
};