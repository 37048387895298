import { Autocomplete, Button, createFilterOptions, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from 'icons/ic_dots.svg';
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import AutocompleteMulti from "elements/AutocompleteMulti";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


 //TODAY DATE FORMATED MM/DD/YYYY
 const today = new Date('2014-08-18T21:11:54')

  interface Props {
    account_user_id?: any,
  }


  export default function UserEditor (props: any) {
    useEffect(() => {props.setLoading(false);}, []);

  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  let {user_id } = useParams();
  user_id = props.account_user_id ? props.account_user_id : user_id;
  console.log('user_id', props.account_user_id);
  /*=======================================
  =======================================*/


  

  const navigate = useNavigate();
  const defaultValues: any = {
    user_id: user_id? user_id : "null",
    project_id: "",
    name: "",
    image: "",
    email: "",
    password: "",
    nib: "",
    regular_cost: "",
    extra_cost: "",
    actual_cost: "",
    client_cost: "",
    role_id: "",
    is_foreigner: false,
    contact_person:{
      name: "",
      phone: "",
      relationship: "",
    },
    foreigner_info:{
      passport: "",
      work_permit: "",
      work_permit_expire: null,
    },
    is_active: true,

  };
  


  const [loading, setLoading] = useState(false); 
  const [formValues, setFormValues] = useState(defaultValues)
  const [roles_list, setRolesList] = useState<any []>([]);
  const [files, setFiles] = useState<any>([]);

  /*=================================================
    GET ROLE LIST
  =================================================*/
  useEffect(() => {
    axios.get(ProjectConfig.api_url,
    {
      params: {
        request: 'role_list',
      }
    })
    .then((response) => {
      setRolesList(response.data)
    })
    .catch((err) => {
      console.log(err.data);
    })
  }, [])
  /*=================================================
  =================================================*/


  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleContactPersonChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      contact_person: {
        ...formValues.contact_person,
        [name]: value,
      }
    });
  };

  const handleForeignerChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      foreigner_info: {
        ...formValues.foreigner_info,
        [name]: value,
      }
    });
  };




  const handleSubmit = () => {

    const formData = new FormData();
    formData.append("files", files);
    
    axios.post(ProjectConfig.api_url, formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          request: 'add_edit_user',
          formdata: formValues,
        }
      })
        .then((response) => {
          console.log(response.data)
          setLoading(true)
          if(props.account_user_id){
            //UPDATE LOCAL STORAGE IMAGE
            localStorage.setItem('user_image', response.data.image);
            window.location.reload();
          }
          else{
            navigate('/users');
          }
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        
  };



  /**/

  useEffect(() => {
    if (user_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "user_details",
            user_id: user_id,
          },
        })
        .then((response) => {
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);


  return (
      <div id="userForm">
        <div className="userForm_right">
          <Box id="project_editor" className="box_editor" title="User" subtitle="Edit"  >
            <div className='form'>

            <div className='input_group'>
                <h2>Personal Info</h2>
                <div className='input_row'>

                  {/* UPLOAD button with preview */}
                  <div className="drawerImagesContainer">
                              
                    <div className="drawerImagesContainerDiv"
                      onDrop={(e) => {
                          e.preventDefault();
                          // UPDATE FILE
                          const files = e.dataTransfer.files;
                          setFiles(files[0]);
                          const fileArray = Array.from(files).map((file:any) => URL.createObjectURL(file));
                          setFormValues({
                            ...formValues,
                            image: fileArray[0],
                          });
                          e.currentTarget.classList.remove("dropzone_active");
                        }
                        } 
                        onDragOver={
                          (e) => {
                            e.preventDefault();
                            e.currentTarget.classList.add("dropzone_active");
                          }
                          
                        }
                        onDragLeave={
                          (e) => {
                            e.preventDefault();
                            e.currentTarget.classList.remove("dropzone_active");
                          }
                        }
                    
                      >
                      {formValues.image && (
                        <img src={formValues.image}  />
                      )}
                      
                      <label id='dropFileDiv' className={formValues.image && ('showOnlyOnHover')}>
                        <IconButton 
                          aria-label="delete"
                          size="small"
                          className="addFile_button"
                          component="span"
                        >
                          <CameraAltIcon/>
                          <input 
                            type="file"
                            multiple 
                            hidden 
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files) {
                                  
                                  // UPDATE FILE
                                  setFiles(files[0]);
                                  const fileArray = Array.from(files).map((file:any) => URL.createObjectURL(file));
                                  setFormValues({
                                    ...formValues,
                                    image: fileArray[0],
                                  });

                                }

                            }}
                          />
                          
                        </IconButton>
                      </label>
                      
                    </div>

                  </div>
                

                          
                  <div className='inputColumn'>
                    <TextField 
                      id="name"
                      name="name"
                      label="Name"
                      type="text"
                      required
                      fullWidth
                      value={formValues.name}
                      onChange={handleInputChange}
                    />

                    <TextField
                      id="nib"
                      name="nib"
                      label="Nib"
                      type="text"
                      required
                      fullWidth
                      value={formValues.nib}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

                <div className='input_group'>
                  <h2>Login Info</h2>
                  <div className="input_row">
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      type="text"
                      required
                      fullWidth
                      value={formValues.email}
                      onChange={handleInputChange}
                    />

                    <TextField
                      id="password"
                      name="password"
                      label="Password"
                      type="password"
                      required
                      fullWidth
                      value={formValues.password}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>


                
                

                

              {!props.account_user_id && (
              <div className='input_group'>
                <h2>Worker info</h2>

                <FormControl fullWidth>
                  <InputLabel id="lbl_product_category">Role</InputLabel>
                  <Select
                    labelId="product_category"
                    id="role_id"
                    value={formValues.role_id}
                    label="Role"
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        ["role_id"]: e.target.value
                      });
                    }}
                  >
                    {roles_list.map((item: any) => (
                      <MenuItem value={item.role_id}>{item.role_name}</MenuItem>
                    ))}

                  </Select>
                </FormControl>
                
                
                <div className="input_row">
                  <TextField
                    id="regular_cost"
                    name="regular_cost"
                    label="Regular Cost"
                    type="text"
                    required
                    fullWidth
                    value={formValues.regular_cost}
                    onChange={handleInputChange}
                  />

                  <TextField
                    id="extra_cost"
                    name="extra_cost"
                    label="Extra Cost"
                    type="text"
                    required
                    fullWidth
                    value={formValues.extra_cost}
                    onChange={handleInputChange}
                  /> 

                  <TextField
                    id="actual_cost"
                    name="actual_cost"
                    label="Actual Cost"
                    type="text"
                    required
                    fullWidth
                    value={formValues.actual_cost}
                    onChange={handleInputChange}
                  />

                  <TextField
                    id="client_cost"
                    name="client_cost"
                    label="Client Cost"
                    type="text"
                    required
                    fullWidth
                    value={formValues.client_cost}
                    onChange={handleInputChange}
                  />


                </div>
                
              </div>
              )}


            

              
              <div className="input_group">     
                <h2>Contact person</h2>        
                <div className="input_row">
                  <TextField

                    id="contact_person_name"
                    name="name"
                    label="Name"
                    type="text"
                    required
                    fullWidth
                    value={formValues.contact_person.name}
                    onChange={handleContactPersonChange}
                  />

                  <TextField
                    id="contact_person_phone"
                    name="phone"
                    label="Phone"
                    type="text"
                    required
                    fullWidth
                    value={formValues.contact_person.phone}
                    onChange={handleContactPersonChange}
                  />

                  <TextField
                    id="contact_person_relationship"
                    name="relationship"
                    label="Relationship"
                    type="text"
                    required
                    fullWidth
                    value={formValues.contact_person.relationship}
                    onChange={handleContactPersonChange}
                  />

                </div>
              </div>
              
              <FormControlLabel
                checked={formValues.is_foreigner}
                
                control={
                  <IOSSwitch 
                    sx={{ m: 1 }}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        ["is_foreigner"]: e.target.checked
                      });
                    }
                    }
                  />
                }
                label="Is Foreigner"
              />
              
              {formValues.is_foreigner && (
                <div className="input_group">
                  <h2>Foreigner info</h2>
                  <div className="input_row">
                    <TextField
                      id="foreigner_passport"
                      name="passport"
                      label="Passport"
                      type="text"
                      required
                      fullWidth
                      value={formValues.foreigner_info.passport}
                      onChange={handleForeignerChange}
                    />

                      <TextField
                        id="foreigner_work_permit"
                        name="work_permit"
                        label="Work Permit"
                        type="text"
                        required
                        fullWidth
                        value={formValues.foreigner_info.work_permit}
                        onChange={handleForeignerChange}
                      />


                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDatePicker
                        label="Work Permit Expire"
                        inputFormat="MMMM DD, yyyy"
                        value={formValues.foreigner_info.work_permit_expire}
                        InputProps={{
                          endAdornment: (
                            <EventIcon />
                          ),
                        }}
                        showToolbar={false}
                        closeOnSelect 
                        onChange={(newValue) => {
                          setFormValues({
                            ...formValues,
                            ["foreigner_info"]: {
                              ...formValues.foreigner_info,
                              ["work_permit_expire"]: (newValue).format('YYYY-MM-DD')
                            }
                          });
                        }}
                        renderInput={(params) => <TextField {...params}  fullWidth/>}
                      />
                    </LocalizationProvider>
                    

                  </div>
                </div>
              )}

              
              {!props.account_user_id && (
              <FormControlLabel
                checked={formValues.is_active}
                
                control={
                  <IOSSwitch 
                    sx={{ m: 1 }}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        ["is_active"]: e.target.checked
                      });
                    }
                    }
                  />
                }
                label="Is Active"
              />
              )}



              

            </div>
            <div className='buttons'>
              <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancel</Button>
              <Button onClick={handleSubmit} variant="contained" className="button_1">Submit</Button>
            </div>
          </Box>
        </div>
        

        
          


      </div>
    
  );
};