import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    MenuItem,
    TextField,
} from '@mui/material';
import moment from 'moment';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import axios from 'axios';

import AutocompleteProjects from 'elements/Autocompletes/AutocompleteProjects';
import { ProjectConfig } from 'Global';
import Box from 'elements/Box';
import ic_empty from 'icons/ic_empty.svg';

import 'assets/css/main.css';

interface IPaymentData {
    balance: number;
    total_paid: number;
}

const today = moment(new Date()).format('YYYY-MM-DD');

const defaultValues = {
    payment_id: 'null',
    date: today,
    project: {
        project_id: null,
        project_name: "",
    },
    value: 0,
    payment_type: 'deposit',
};

export default function PaymentForm( props: any ) {

    const {
        open,
        setOpen,
        reloadList,
        project_id = null,
        callback
    } = props;


    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);
    
    const [formValues, setFormValues] = useState<any>({...defaultValues});
    const [paymentData, setPaymentData] = useState<IPaymentData | any>(null);
    const [percentage, setPercentage] = useState<number>(50);

    useEffect(() => {
        if (project_id) {
            setFormValues({
                ...defaultValues,
                project: {
                    project_id: project_id,
                    project_name: "",
                },
            });
            loadData();
        }
        
        setPercentage(50);
        calculateAmmount(50);
    }, [open]);

    useEffect(() => {
        if (!open) {
            setFormValues({...defaultValues});
            setPaymentData(null);
        }
    }, [open]);

    useEffect(() => {
        if (project_id) {
            setFormValues({
                ...formValues,
                project: {
                    project_id: project_id,
                    project_name: "",
                },
            });
        }
    }, [project_id]);

    useEffect(() => {
        if (formValues.project.project_id) {
            loadData();
        }
    }, [formValues.project]);

    const loadData = () => {
        setLoading(true);
        if (!formValues.project.project_id) return;

        axios.get(ProjectConfig.api_url,{
            params: {
                request: 'get_payments_info',
                params: {
                    project_id: formValues.project.project_id,
                }
            }
        })
        .then((response) => {
            console.log(response.data);

            setPaymentData(response.data);

            if (Number(response.data.balance) > 0) {
                if (percentage && percentage > 0) {
                    calculateAmmount(percentage, response.data);
                } else {
                    setFormValues({
                        ...formValues,
                        type: 'deposit',
                    });
                }
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        let _percentage = 0;
        switch (formValues.payment_type) {
            case 'deposit':
                _percentage = 50;
                setPercentage(_percentage);
                break;
            case 'final_payment':
                _percentage = 100;
                setPercentage(_percentage);
                break;
            default:
                _percentage = 0;
                setPercentage(_percentage);
                break;
        }
        calculateAmmount(_percentage);
    }, [formValues.payment_type]);

    const handleSave = () => {

        setLoading(true);

        axios.post(
            ProjectConfig.api_url,
            {
                ...formValues,
                project_id: formValues.project.project_id,
                quote_id: formValues.project.quote_id || null,
            },
            {
            params: {
                request: 'add_edit_payments',
            }
        })
        .then((response) => {
            console.log(response);
            setOpen(false);
            reloadList();
            setFormValues({...defaultValues});
            if (callback) callback();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }


    const calculatePercentage = (value: number) => {
        if (paymentData) {
            const percentage = Math.round((value / paymentData.balance) * 100);
            setPercentage(percentage);
        }
    }

    const calculateAmmount = (value: number, paymentDataParam: any = paymentData) => {
        if (paymentDataParam) {
            
            //ammount rounded
            const ammount = Math.round(paymentDataParam.balance * (value / 100));

            setFormValues({
                ...formValues,
                value: ammount,
            });
        }
    }
    

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogContent
                className="forced_box_editor"
            >
                {(paymentData === null || (paymentData && paymentData.balance) > 0) ? (
                <>
                    <div className="box_header">
                        <h2>ADD PAYMENT</h2>
                    </div>

                    <div className="input_group">
                        {project_id === null &&
                        <div className="input_row">
                            <AutocompleteProjects
                                fullWidth
                                setLoading={setLoading}
                                value={formValues.project}
                                handleChange={(value: any) => {
                                    setFormValues({
                                        ...formValues,
                                        project: value,
                                    });
                                }}
                            />
                        </div>
                        }

                        <div className="input_row">
                            <TextField
                                select
                                label="Paid type"
                                fullWidth
                                value={formValues.payment_type}
                                onChange={(e: any) => {
                                    setFormValues({
                                        ...formValues,
                                        payment_type: e.target.value,
                                    });
                                }}
                            >
                                <MenuItem value="deposit">Deposit</MenuItem>
                                <MenuItem value="milestone">Milestone</MenuItem>
                                <MenuItem value="final_payment">Final payment</MenuItem>
                            </TextField>
                        </div>

                        <div className="input_row">
                            <TextField
                                type='number'
                                label="Percentage"
                                error={percentage < 0 || percentage > 100}
                                InputProps={{ inputProps: { min: 0, max: 100 }}}
                                sx={{ width: '100px' }}
                                value={percentage}
                                onChange={(e: any) => {
                                    calculateAmmount(e.target.value);
                                    setPercentage(e.target.value);
                                }}
                            />

                            <TextField
                                label="Ammount"
                                type='number'
                                fullWidth
                                error={formValues.value < 0 || formValues.value > paymentData?.balance}
                                
                                InputProps={{ inputProps: 
                                    paymentData !== null ?
                                    { min: 0, max: paymentData.balance } :
                                    {}
                                }}
                                value={formValues.value}
                                onChange={(e: any) => {
                                    calculatePercentage(e.target.value);
                                    setFormValues({
                                        ...formValues,
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className="input_row">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MobileDatePicker
                                    className='filters_datePicker'
                                    label={"Date"}
                                    value={formValues.date}
                                    showToolbar={false}
                                    closeOnSelect
                                    onChange={(newValue) => {
                                        newValue && setFormValues({
                                            ...formValues,
                                            date: newValue.format('YYYY-MM-DD')
                                        });
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </>
                ) : (
                <>
                    <div className="box_header">
                        <h2></h2>
                    </div>

                    <div className="emptyBox">
                        <img src={ic_empty} alt=""/>
                        <p>No pending payments</p>
                    </div>
                </>
                )}

                <div className="buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        Cancel
                    </Button>

                    {(paymentData === null || (paymentData && paymentData.balance) > 0) &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleSave();
                        }}
                    >
                        Confirm
                    </Button>
                    }
                </div>
            </DialogContent>
        </Dialog>
    )
}