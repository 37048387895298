import React, { useEffect, useState } from 'react';
import { Add, AddOutlined, Check, CheckCircleOutline, Save, Task, Timer } from '@mui/icons-material';
import {
    Button, Dialog, DialogContent, MenuItem, TextField, Tooltip,
} from '@mui/material';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import SideDrawer from 'elements/SideDrawer';
import { useParams } from 'react-router-dom';
import AutocompleteProducts from 'elements/Autocompletes/AutocompleteProducts';
import FilesBrowser from 'components/Files/FilesBrowser';
import AlertDialog from 'elements/AlertDialog';
import { LocalizationProvider, MobileDatePicker, MobileTimePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { useSnackbar } from 'notistack';
import InformationDialog from 'elements/InformationDialog';

const cookies = new Cookies();

const defaultValues = {
    task_id: "",
    project_id: "",
    task_name: "",
    task_start_date: "",
    task_deadline: "",
    task_state: "",
    running: false,
}

const fakeAPI2 = {
    folder_id: 1,
    worked_time: [
        {
            date: "2021-10-01",
            times: [
                {
                    start: "2021-10-01",
                    end: "2021-10-01",
                },
                {
                    start: "2021-10-01",
                    end: "2021-10-01",
                },
            ],
        },
        {
            date: "2021-10-02",
            times: [
                {
                    start: "2021-10-02",
                    end: "in progress",
                },
            ],
        },
    ],
    used_inventory: [
        {
            task_product_id: "1",
            product_id: "1",
            product_name: "Product 1",
            quantity: "1",
            date: "2021-10-01",
        },
        {
            task_product_id: "2",
            product_id: "2",
            product_name: "Product 2",
            quantity: "2",
            date: "2021-10-01",
        },
    ],
};

const today = moment(new Date()).format("YYYY-MM-DD");

const defaultValuesProduct = {
    product_name: "",
    quantity: 0,
    product_id: "",
    date: today,
};


export default function WorkerTiming( props: any ) {

    const { project_id } = useParams<any>();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { props.setLoading(loading) }, [loading]);

    const [openTaskEditor, setOpenTaskEditor] = useState<boolean>(false);
    const [openTaskDetails, setOpenTaskDetails] = useState<boolean>(false);
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [openAddTime, setOpenAddTime] = useState<boolean>(false);
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);

    const [list, setList] = useState<any[]>([]);
    const [completedList, setCompletedList] = useState<any[]>([]);
    const [running, setRunning] = useState<boolean>(defaultValues.running);
    const [projectData, setProjectData] = useState<any>({project_id: project_id, project_name: ""});
    const [selectedItem, setSelectedItem] = useState<any>({...defaultValues});

    const [workedtime, setWorkedTime] = useState<any>([]);
    const [inventory, setInventory] = useState<any>([]);
    const [folderId, setFolderId] = useState<any>(null);

    const [formDate, setFormDate] = useState<any>({date: today, start: "07:00", end: new Date().getHours() + ":" + new Date().getMinutes()});
    const [formValues, setFormValues] = useState<any>({...defaultValuesProduct});
    const [formTask, setFormTask] = useState<any>({task_name: "", task_stage_id: "", task_notes: ""});
    const [successMessage, setSuccessMessage] = useState<string>("");

    const {enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            headers: {
                'LTKN': cookies.get('ltkn'),
            },
            params: {
                request: 'get_user_tasks_list',
                params: {
                    project_id: project_id,
                },
            }
        })
        .then((response) => {
            console.log(response.data);
            setProjectData({...{project_id: response.data.project_id, project_name: response.data.project_name}});
            setList([...response.data.tasks.active]);
            setCompletedList([...response.data.tasks.completed]);
            setRunning(response.data.already_running);
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if (folderId !== null) {
            setLoading(false);
        }
    }, [folderId])

    useEffect(() => {
        setFolderId(null);
    }, [selectedItem.task_id])

    useEffect(() => {
        loadDetails();
    }, [selectedItem.task_id])

    const loadDetails = () => {
        if (!selectedItem || !selectedItem.task_id || selectedItem.task_id === "") return;
        setLoading(true);
        console.log(selectedItem);

        axios.get(ProjectConfig.api_url, {
            headers: {
                'LTKN': cookies.get('ltkn'),
            },
            params: {
                request: 'get_user_task_details',
                task_id: selectedItem.task_id,
            }
        })
        .then((response) => {
            console.log("details", response.data);
            setWorkedTime(response.data.worked_time);
            setInventory(response.data.used_inventory);
            setFolderId(response.data.folder_id);
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    const handleRunning = (task: any = null) => {
        if (!task) return;
        if (selectedItem.task_id === task.task_id) task = selectedItem;

        setLoading(true);

        axios.post(ProjectConfig.api_url, null, {
            headers: {
                'LTKN': cookies.get('ltkn'),
            },
            params: {
                request: 'set_task_stopwatch',
                task_id: task.task_id,
            }
        })
        .then((response) => {
            console.log(response.data);
            loadData();
            loadDetails();
        })
        .catch((err) => {
            console.log(err.data);
        });
    }

    function checkFormProducts() {
        let errors = false;

        if (formValues.product_id === "") {
            errors = true;
            enqueueSnackbar('Product name is required', { variant: 'error' });
        }
        if (formValues.quantity === 0) {
            errors = true;
            enqueueSnackbar('Quantity is required', { variant: 'error' });
        }
        if (formValues.date === "") {
            errors = true;
            enqueueSnackbar('Date is required', { variant: 'error' });
        }

        return errors;
    }

    const handleAddProduct = () => {
        setLoading(true);
        console.log(formValues)

        axios.post(ProjectConfig.api_url, formValues, {
            headers: {
                'LTKN': cookies.get('ltkn'),
            },
            params: {
                request: 'add_product_to_task',
                task_id: selectedItem.task_id,
            }
        })
        .then(function (response: any) {
            console.log(response.data);
            loadDetails();
            setFormValues({...defaultValuesProduct});
        })
        .catch(function (error: any) {
            console.log(error);
        })
        .finally(function () {
            setLoading(false);
        });
    }

    const handleFinishTask = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: 'change_task_status',
                task_id: selectedItem.task_id,
                task_status_id: 2,
            }
        })
        .then((response) => {
            console.log(response.data);
            loadData();
            loadDetails();
            if (response.data.status === "success") {
                setSuccessMessage(response.data.message);
                setOpenSuccess(true);
            };
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }


    const handleAddRecord = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, formDate, {
            headers: {
                'LTKN': cookies.get('ltkn'),
            },
            params: {
                request: 'add_time_to_task',
                task_id: selectedItem.task_id,
            }
        })
        .then(function (response: any) {
            console.log(response.data);
            loadDetails();
            setOpenAddTime(false);
        })
        .catch(function (error: any) {
            console.log(error);
        })
        .finally(function () {
            setLoading(false);
        });
    }

    const handleAddTask = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, formTask, {
            params: {
                request: 'add_task',
                project_id: project_id,
                user_id: cookies.get('user_id'),
            }
        })
        .then(function (response: any) {
            console.log(response.data);
            loadData();
            setOpenTaskEditor(false);
        })
        .catch(function (error: any) {
            console.log(error);
        })
        .finally(function () {
            setLoading(false);
        });
    }

    return (
        <>
            <div id="workertimmingEditor" className='moduleContainer ' style={{ maxWidth: '1230px', marginLeft: 0, }}>
                <div className='moduleWrapper'>
                    <div className="box_header buttons_top">
                        <h1><strong style={{letterSpacing: '1px', fontSize: '18px'}}>PROJECT: </strong> {projectData.project_name}</h1>

                        <div>
                            <Button
                                variant="contained"
                                startIcon={<AddOutlined />}
                                onClick={() => {
                                    setOpenTaskEditor(true);
                                }}
                            >
                                Add task
                            </Button>
                        </div>
                    </div>

                    <div id="project_list_container" className="flex_container">
                        {list.map((task: any) => 
                        <div className="card project_element" key={task.task_id}>
                            <div className="card_header">
                                <h2>{task.task_name}</h2>
                            </div>

                            <div className="card_content">
                                <div className="displayer_row">
                                    <div className='displayer_element'>
                                        <div className="card_label">{task.task_start_date}</div>
                                        <div className="card_value">Start date</div>
                                    </div>

                                    <div className='displayer_element'>
                                        <div className="card_label" style={{textAlign: "right"}}>{task.task_deadline}</div>
                                        <div className="card_value" style={{textAlign: "right"}}>Deadline</div>
                                    </div>
                                </div>

                                <Button
                                    variant="outlined"
                                    color='primary'
                                    onClick={() => {
                                        setLoading(true);
                                        setSelectedItem({...task, completed: false});
                                        setOpenTaskDetails(true);
                                    }}
                                >
                                    View task details
                                </Button>
                            </div>

                            <div className="card_footer">
                                <Button
                                    variant="contained"
                                    color={ task.running ? 'error' : 'success' }
                                    disabled={ running && !task.running }
                                    startIcon={<Timer />}
                                    onClick={() => {
                                        setSelectedItem({...task});
                                        handleRunning(task);
                                    }}
                                >
                                    { (running && task.running) ? 'Stop time' : 'Start task' }
                                </Button>
                            </div>
                        </div>
                        )}
                    </div>
                </div>

                <div className='moduleWrapper'>
                    {completedList.length > 0 && (
                    <>
                        <div className="box_header buttons_top">
                            <h1>Completed</h1>
                        </div>
                        <div id="project_completed_list_container" className="flex_container">
                            {completedList.map((task: any) => 
                            <div className="card project_element" key={task.task_id}>
                                <div className="card_header">
                                    <h2>{task.task_name}</h2>
                                </div>

                                <div className="card_content">
                                    <div className="displayer_row">
                                        <div className='displayer_element'>
                                            <div className="card_label">{task.task_start_date}</div>
                                            <div className="card_value">Start date</div>
                                        </div>

                                        <div className='displayer_element'>
                                            <div className="card_label" style={{textAlign: "right"}}>{task.task_deadline}</div>
                                            <div className="card_value" style={{textAlign: "right"}}>Deadline</div>
                                        </div>
                                    </div>

                                    <Button
                                        variant="outlined"
                                        color='primary'
                                        onClick={() => {
                                            setSelectedItem({...task, completed: true});
                                            setOpenTaskDetails(true);
                                        }}
                                    >
                                        View task details
                                    </Button>
                                </div>
                            </div>
                            )}
                        </div>
                    </>
                    )}
                </div>
            </div>

            {openTaskDetails && 
            <SideDrawer
                drawerOpen={openTaskDetails}
                withBackdrop
                label={selectedItem.task_name}
                handleDrawerOpen={() => setOpenTaskDetails(true)}
                handleDrawerClose={() => setOpenTaskDetails(false)}
            >
                <div className="drawer_in">
                    <div className="drawer_content" style={{ opacity: openTaskDetails ? 1 : 0 }}>
                        <div className="box_header">
                            <span className="subtitle" />
                        </div>

                        <div className="box_content" style={{marginBottom: '20px', gap: '50px'}}>
                            <div className="form input_group">
                                <div className="input_row">
                                    Worked time
                                </div>

                                {workedtime.map((item: any, index: number) => (
                                <>
                                    <div className="input_row date-header" key={item.date}>
                                        {item.date}
                                    </div>

                                    <div className="input_group" style={{marginBottom: '15px'}} key={index}>
                                        <div className="input_row displayer_row">
                                            <div className="input_label">Start</div>
                                            <div className="input_value">End</div>
                                        </div>

                                        {item.times.map((time: any, innerIndex: number) => (
                                            <div className="input_row dotted_row displayer_row" key={innerIndex}>
                                                <div className="input_label">{time.start}</div>
                                                <div className="input_value">{time.end}</div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                                ))}

                                {!selectedItem.completed &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    startIcon={<AddOutlined />}
                                    onClick={() => {
                                        setOpenAddTime(true);
                                    }}
                                >
                                    Add record manually
                                </Button>
                                }
                            </div>

                            {(folderId !== null && folderId !== "0") &&
                            <div className="form input_group" id="files_container">
                                <div className="input_row">
                                    Files
                                </div>

                                <FilesBrowser
                                    folder_id={folderId}
                                    view_type="thumbnail"
                                    setLoading={setLoading}
                                    with_drawer={false}
                                />
                            </div>
                            }

                            <div className="form input_group" id="used-inventory-container">
                                <div className="input_row">
                                    Used inventory
                                </div>

                                <div className="form input_group" style={{marginBottom: '20px'}}>
                                    {inventory.map((item: any, index: number) => (
                                    <>
                                        <div className="input_row date-header" key={item.date}>
                                            {item.date}
                                        </div>
    
                                        <div className="input_group" style={{marginBottom: '15px'}} key={index}>
                                            {item.products.map((time: any, innerIndex: number) => (
                                                <div className="input_row dotted_row displayer_row" key={innerIndex}>
                                                    <Tooltip title={time.product_name}>
                                                        <div className="input_label ellipsed-label">{time.product_name}</div>
                                                    </Tooltip>

                                                    <div className="input_value">{time.quantity}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                    ))}
                                </div>

                                {!selectedItem.completed &&
                                <div className="form input_group" style={{marginBottom: '20px'}}>
                                    <div className="input_row">
                                        <AutocompleteProducts
                                            size='small'
                                            fullWidth
                                            setLoading={setLoading}
                                            value={formValues}
                                            handleChange={(value: any) => {
                                                setFormValues({...{
                                                    ...formValues,
                                                    product_id: value.product_id,
                                                    product_name: value.product_name,
                                                }});
                                            }}
                                            />
                                    </div>

                                    <div className="input_row">
                                        <TextField
                                            id="quantity"
                                            label="Quantity"
                                            type="number"
                                            size='small'
                                            fullWidth
                                            variant="outlined"
                                            value={formValues.quantity || ''}
                                            onChange={(event: any) => {
                                                if (!isNaN(event.target.value)) {
                                                    setFormValues({...formValues, quantity: Number(event.target.value)});
                                                }
                                            }}
                                        />

                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <MobileDatePicker className='filters_datePicker'
                                                label={"Date"}
                                                value={formValues.date}
                                                showToolbar={false}
                                                closeOnSelect
                                                onChange={(newValue) => {
                                                    newValue && setFormValues({...formValues, date: newValue.format('YYYY-MM-DD')});
                                                }}
                                                renderInput={(params) => <TextField size='small' fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                    <div className="input_row">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            fullWidth
                                            startIcon={<Add />}
                                            onClick={() => {
                                                if (!checkFormProducts()) {
                                                    handleAddProduct();
                                                }
                                            }}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </div>
                                }
                            </div>

                            {!selectedItem.completed &&
                            <div className="form input_group">
                                <div className="input_row">
                                    Finish
                                </div>

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    startIcon={<Check />}
                                    onClick={() => setOpenAlert(true)}
                                >
                                    Finish task
                                </Button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </SideDrawer>
            }

            <Dialog
                open={openTaskEditor}
                onClose={() => setOpenTaskEditor(false)}
                id="taskEditor"
                sx={{ '& .MuiDialog-paper': { width: '400px', maxHeight: 435 } }}
            >
                <DialogContent className='forced_box_editor'>
                    <div className="box_header">
                        <h1>Create task</h1>
                    </div>

                    <div className="box_content">
                        <div className="form input_group">
                            <div className="input_row">
                                <TextField
                                    id="taskName"
                                    label="Task name"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={formValues.task_name}
                                    onChange={(event: any) => {
                                        setFormValues({...formValues, task_name: event.target.value});
                                    }}
                                />
                            </div>

                            <div className="input_row">
                                <TextField
                                    id="taskStage"
                                    label="Stage"
                                    fullWidth
                                    select
                                    variant="outlined"
                                    value={formValues.task_stage_id}
                                    onChange={(event: any) => {
                                        setFormValues({...formValues, task_stage_id: event.target.value});
                                    }}
                                >
                                    <MenuItem value={"1"}>Design</MenuItem>
                                    <MenuItem value={"2"}>Manufacturing</MenuItem>
                                    <MenuItem value={"3"}>Hardware Assembly</MenuItem>
                                    <MenuItem value={"4"}>Finishing</MenuItem>
                                    <MenuItem value={"5"}>Installation</MenuItem>
                                    <MenuItem value={"6"}>Stone</MenuItem>
                                </TextField>
                            </div>

                            <div className="input_row">
                                <TextField
                                    id="taskNotes"
                                    label="Notes"
                                    type="text"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    value={formValues.task_notes}
                                    onChange={(event: any) => {
                                        setFormValues({...formValues, task_notes: event.target.value});
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="buttons">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setOpenTaskEditor(false)}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Save />}
                            onClick={() => handleAddTask()}
                        >
                            Submit
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openAddTime}
                onClose={() => setOpenAddTime(false)}
                id="addTime"
            >
                <DialogContent className='forced_box_editor'>
                    <div className="box_header">
                        <h1>Add time manually</h1>
                    </div>

                    <div className="box_content">
                        <div className="form input_group">
                            <div className="input_row">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker className='filters_datePicker'
                                        label={"Date"}
                                        value={formDate.date}
                                        showToolbar={false}
                                        closeOnSelect
                                        onChange={(newValue) => {
                                            newValue && setFormDate({...formDate, date: newValue.format('YYYY-MM-DD')});
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className="input_row">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileTimePicker
                                        label="From"
                                        ampm={false}
                                        ampmInClock={false}
                                        value={moment(formDate.date + " " + formDate.start)}
                                        closeOnSelect
                                        onChange={(newValue) => {
                                            newValue && setFormDate({...formDate, start: newValue.format('HH:mm')});
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        />

                                    <MobileTimePicker
                                        label="Untill"
                                        ampm={false}
                                        ampmInClock={false}
                                        value={moment(formDate.date + " " + formDate.end)}
                                        closeOnSelect
                                        onChange={(newValue) => {
                                            newValue && setFormDate({...formDate, end: newValue.format('HH:mm')});
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>

                    <div className="buttons">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setOpenAddTime(false)}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAddRecord()}
                        >
                            Submit
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle="Are you sure you want to finish this task?"
                send={handleFinishTask}
            />

            <InformationDialog
                open={openSuccess}
                setOpen={setOpenSuccess}
                subtitle={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            gap: '10px',

                            minWidth: '200px',
                            padding: '30px 0 0',

                            fontSize: '16px',
                        }}
                    >
                        <CheckCircleOutline />
                        {successMessage}
                    </div>
}
                send={() => {
                    setOpenSuccess(false);
                    setOpenTaskDetails(false);
                }}
            />
        </>
    );
}