import React from 'react';
import { Button, Dialog } from '@mui/material';
import axios from 'axios';
import { Scheduler } from 'devextreme-react';
import 'devextreme/dist/css/dx.light.css';
import { useEffect, useState } from 'react';
import TaskEditor from "components/Tasks/TaskEditor";
import { ViewType } from 'devextreme/ui/scheduler';

import 'assets/css/main.css';

export default function ScheduleView (props: any) {
  /*================================================
    FILTERS
    =================================================*/
    const filters = [
      {type: "dateRange", label: { start: "Start date", end: "Deadline",}, name: { start: "start_date", end: "deadline",},},
    ]
  const [applyFilters, setApplyFilters] = useState(false);
  const [filtersValues, setFiltersValues] = useState<any>({});
  const [openFilters, setOpenFilters] = useState(false);

  const setFilters = (tempFilters: any) => {
      setFiltersValues(tempFilters);
  }
  /*================================================= 
  ==================================================*/

  const {
    setLoading,
    selectedTaskId,
    setSelectedTaskId,
    data,
    project_id = null,
  } = props;

  useEffect(() => {setLoading(false);}, []);


  

  
  const onAppointmentClick = (e: any) => {
    e.cancel = true;
    setSelectedTaskId(e.appointmentData.task_id);
    setOpenTaskEditor(true);
  }

  const currentDate = new Date();

  const views: ViewType[] = [];
  const [currentViewName, setCurrentViewName] = useState<ViewType>('month');
  const [openTaskEditor, setOpenTaskEditor] = useState(false);
    

  return (
    <>
    <Scheduler
        timeZone="America/Nassau"
        dataSource={data}
        views={views}
        currentView={currentViewName}
        currentDate={currentDate}
        height={800}
        startDayHour={9}
        showAllDayPanel={false}
        editing={false}
        onAppointmentClick={onAppointmentClick}
        onAppointmentDblClick={onAppointmentClick}
        adaptivityEnabled={true}
        />

      <Dialog open={openTaskEditor} onClose={() => setOpenTaskEditor(false)}>
        <TaskEditor 
            task_id={selectedTaskId} 
            onSubmitCallback={() => setOpenTaskEditor(false)}
            setLoading={setLoading}
            project_id={project_id}
            asPopup={true}
        />
    </Dialog>
    </>
    );
}