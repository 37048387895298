import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    TextField,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { CheckCircle, Event, Save } from '@mui/icons-material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Box from 'elements/Box';
import AutocompleteMulti from 'elements/AutocompleteMulti';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import 'assets/css/main.css';
import './styles/css/task.css';

// DIALOG COMPONENT FOR RESOLVE TASKS DATE CONFLICTS
export default function TaskDialog( props: any ) {
    const {
        open,
        setOpen,
        setLoading,
    } = props;

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    const navigate = useNavigate();

    const [list, setList] = useState<any[]>(props.list);
    const [totalConflicts, setTotalConflicts] = useState<number>(props.totalConflicts);
    const [needCheck, setNeedCheck] = useState<boolean>(false);

    useEffect(() => {
        setList([...props.list]);

        setTotalConflicts(props.totalConflicts);
    }, [props.list, open])

    useEffect(() => {
        if(needCheck) {
            calcConflicts();
        }
    }, [needCheck])

    const calcConflicts = () => {
        let errors = 0;
        const newList = [...list];

        for (let item of newList) {
            item.conflicted = false;

            // VALIDATE EMPTY FIELDS
            if(item.task_start_date == '' || item.task_deadline == '') {
                errors += 1;
                item.conflicted = true;
                // enqueueSnackbar('Please, fill all the fields at task ' + item.task_name, { variant: 'error' });
                continue;
            }
            
            let startDate = new Date(item.task_start_date).getTime();
            let endDate = new Date(item.task_deadline).getTime();
            
            // VALIDATE ORDER OF DATES
            if(startDate > endDate) {
                errors += 1;
                item.conflicted = true;
                // enqueueSnackbar('The start date must be before the end date at task ' + item.task_name, { variant: 'error' });
                continue;
            }

            //VALIDATE USERS
            if(item.users.length == 0) {
                errors += 1;
                item.conflicted = true;
                // enqueueSnackbar('Please, select at least one user at task ' + item.task_name, { variant: 'error' });
                continue;
            }

            // VALIDATE CONFLICTS BETWEEN TASKS
            for (let compareItem of newList) {
                // SKIP IF THE SAME TASK
                if(item.task_id == compareItem.task_id) {
                    continue;
                }

                // SKIP IF THE COMPARE TASK DOESN'T SHARE USERS WITH THE CURRENT TASK
                if(item.users.filter((user: any) => compareItem.users.some((compareUser: any) => user.user_id == compareUser.user_id)).length == 0) {
                    continue;
                }

                //VALIDATE ORDER OF DATES
                // GET TIMESTAMP OF COMPARE TASK
                let compareStartDate = new Date(compareItem.task_start_date).getTime();
                let compareEndDate = new Date(compareItem.task_deadline).getTime();

                if(endDate <= compareStartDate || compareEndDate <= startDate) {
                    continue;
                } else {
                    errors += 1;
                    item.conflicted = true;
                    compareItem.conflicted = true;
                    // enqueueSnackbar('There is a conflict between task ' + item.task_name + ' and task ' + compareItem.task_name + '. Please, check them.', { variant: 'error' }); 
                }
            }
        }

        setTotalConflicts(errors);
        setList([...newList]);
        setNeedCheck(false);
    }

    const handleSubmit = () => {
        setLoading(true);
        axios.post(ProjectConfig.api_url, list,
            {
              params: {
                request: 'add_edit_task',
              }
            })
            .then((response) => {
              console.log("response",response.data);
              if (response.data.status === "success") {
                setOpen(false);
                if (props.onSubmitCallback) {
                    props.onSubmitCallback();
                } else {
                    navigate(-1);
                }
              } else {
                setList([...response.data.conflicts]);
              }
            })
            .catch((err) => {
              console.log(err.data);
            })
            .finally(() => {
              setLoading(false)
            });
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogContent
                className="forced_box_editor"
            >
                <h2 className="dialog_title">Resolve conflicts</h2>

                <DialogContentText>
                    {totalConflicts == 0 ?
                    'There are no conflicts in your tasks. You can save them.'
                    :
                    (totalConflicts == 1 ?
                    'There is 1 conflict in your tasks. Please resolve it before saving.'
                    :
                    `There are ${totalConflicts} conflicts in your tasks. Please resolve them before saving.`)
                    }
                </DialogContentText>
                        
                {list.map((item: any) => (
                    <>
                    <div className={"taskConflictingSwitchGroup" + (item.conflicted ? ' taskConflicted' : ' conflictResolved')}>
                        <div className='switchHeader'>
                            <h2 key={item.task_id}>{item.task_name}</h2>

                            {item.conflicted ? 
                            <ErrorIcon color='error' sx={{ backgroundColor: 'white' }} />
                            :
                            <CheckCircle color='success' sx={{ backgroundColor: 'white' }} />
                            }
                        </div>

                        <div className='input_group'>
                            <div className="input_row">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        label="Start date"
                                        inputFormat="MMMM DD, yyyy"
                                        value={item.task_start_date}
                                        InputProps={{
                                            endAdornment: (
                                            <Event />
                                            ),
                                        }}
                                        showToolbar={false}
                                        closeOnSelect 
                                        onChange={(newValue) => {
                                            const newList = list.map((listItem: any) => {
                                                if(listItem.task_id == item.task_id) {
                                                    return {
                                                        ...listItem,
                                                        task_start_date: (newValue).format('YYYY-MM-DD')
                                                    }
                                                } else {
                                                    return listItem;
                                                }
                                            });
                                            setList([...newList]);
                                            setNeedCheck(true);
                                        }}
                                        renderInput={(params) => <TextField variant="standard" fullWidth {...params} />}
                                    />

                                    <MobileDatePicker
                                        label="End date"
                                        inputFormat="MMMM DD, yyyy"
                                        value={item.task_deadline}
                                        InputProps={{
                                            endAdornment: (
                                            <Event />
                                            ),
                                        }}
                                        showToolbar={false}
                                        closeOnSelect 
                                        onChange={(newValue) => {
                                            const newList = list.map((listItem: any) => {
                                                if(listItem.task_id == item.task_id) {
                                                    return {
                                                        ...listItem,
                                                        task_deadline: (newValue).format('YYYY-MM-DD')
                                                    }
                                                } else {
                                                    return listItem;
                                                }
                                            });
                                            setList([...newList]);
                                            setNeedCheck(true);
                                        }}
                                        renderInput={(params) => <TextField variant='standard' fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className="input_row">
                                <AutocompleteMulti
                                    setSelectedItems={(value: any) => {
                                        const newList = list.map((listItem: any) => {
                                            if(listItem.task_id == item.task_id) {
                                                return {
                                                    ...listItem,
                                                    ["users"]: value
                                                }
                                            }
                                            return listItem;
                                        })
                                        setList(newList);
                                        setNeedCheck(true);
                                    }}
                                    selectedItems={item.users}
                                />
                            </div>
                        </div>
                    </div>
                    </>
                ))}

                <div className="buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>

                    <Button 
                        variant="contained" 
                        startIcon={<Save />}
                        onClick={() => {
                            if (totalConflicts == 0) {
                                handleSubmit();
                            }
                        }}
                    >
                        Submit
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}