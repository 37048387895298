import { Button, Paper, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from "Global";
import ClientEditor from "components/Clients/ClientEditor";


export default function ProjectClient (props: any) {

  const {setLoading, setMenuTopLeftContent} = props;



  useEffect(() => {props.setLoading(false);}, []);
/*=======================================
    GET ID FROM URL
  =======================================*/
  let { project_id } = useParams();

  /*=======================================
  =======================================*/

  return (
    
    <ClientEditor client_id={localStorage.getItem('client_id')} setLoading={props.setLoading} />
  );
};
