import { Fragment, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import {
    Check,
    Verified,
    CopyAll
} from "@mui/icons-material";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";

import ProjectHeader from "../utils/ProjectHeader";
import SideDrawer from "elements/SideDrawer";
import QuoteStatus from "./QuoteStatus";
import ISwitch from "elements/ISwitch";

import { ProjectConfig } from "Global";
import { QuotesProvider } from "./QuoteContextProvider";
import QuoteView from "./QuoteView/QuoteView";
import ExplodedView from "./ExplodedView/ExplodedView";
import PreviewView from "./PreviewView/PreviewView";
import ExplodedDrawerContent from "./ExplodedView/ExplodedDrawerContent";
import QuoteDrawerContent from "./QuoteView/QuoteDrawerContent";
import QuoteDialogs from "./QuoteDialogs";
import QuoteDrawerButtons from "./QuoteDrawerButtons"



export default function QuotesEditor (props: any) {

    const [loading, setLoading] = useState(false);

    useEffect(() => props.setLoading(loading), [loading]);

    const navigate = useNavigate();

    const [viewType, setViewType] = useState("quote");
    const [explodedItemAnchor, setExplodedItemAnchor] = useState<any>(null);

    useEffect(() => {
        //SCROLL WINDOW TO ServiceItemAnchor POSITION
        if (explodedItemAnchor) {
            setTimeout(() => {
                window.requestAnimationFrame(() => {
                    const element = document.getElementById(explodedItemAnchor);
                    element?.scrollIntoView({ behavior: "smooth", block: "start"})
                })
            }, 100)
            setExplodedItemAnchor(null);
        }
    }, [explodedItemAnchor, viewType]);

    /*=======================================
        GET PROJECT ID AND ESTIMATE ID FROM URL
    =======================================*/
    let {
        project_id,
        quote_id,
        sequence_id: sequence_id_params = null,
    } = useParams();
    /*=======================================
    =======================================*/

    const [autosave, setAutosave] = useState(false);

    /*====================================================
    DRAWER
    ==================================================== */
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerOpen = () => setDrawerOpen(true);
    const handleDrawerClose = () => setDrawerOpen(false);
    /*====================================================
    END DRAWER
    ==================================================== */

    /* --------------------------------------------
    // ------>>>>> End Refactoring <<<<<-----------
    // -------------------------------------------- */

    const [sequenceId, setSequenceId] = useState<string | null>(null);
    const [sequenceList, setSequenceList] = useState<any[]>([]);

    const loadSequenceList = (overrideSequenceId: boolean = true) => {
        setLoading(true);

        const params: any = {
            request: "get_sequence_list",
            quote_id: quote_id,
        };

        axios.get(ProjectConfig.api_url, { params, })
        .then((response: any) => {
            console.log(response);

            const list = [...response.data];

            if (list.length > 0) {
                setSequenceList([...list]);

                if (overrideSequenceId) {
                    if (sequence_id_params) setSequenceId(sequence_id_params);
                    else {
                        const defaultSequence = list.find((sequence: any) => sequence.is_default);

                        if (defaultSequence) setSequenceId(defaultSequence.sequence_id.toString());
                        else setSequenceId(null);
                    }
                }
            }
        })
        .catch((error: any) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        loadSequenceList();
    }, [sequence_id_params]);

    const handleSequenceChange = (sequenceId: string) => {
        navigate(`/projects/${project_id}/quotes/edit/${quote_id}/${sequenceId}`);
    };

    const duplicateQuote = () => {
        setLoading(true);

        const params: any = {
            request: "duplicate_sequence",
            sequence_id: sequenceId,
        };

        axios.post(ProjectConfig.api_url, null, { params, })
        .then((response: any) => {
            console.log(response);

            const sequence = response.data;

            if (sequence) {
                setSequenceId(sequence.sequence_id);
                loadSequenceList(false);
            }
        })
        .catch((error: any) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const markAsDefault = () => {
        setLoading(true);

        const params: any = {
            request: "mark_default_sequence",
            sequence_id: sequenceId,
        };

        axios.post(ProjectConfig.api_url, null, { params, })
        .then((response: any) => {
            console.log(response);

            const sequence = response.data;

            if (sequence) {
                loadSequenceList();
            }
        })
        .catch((error: any) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
            <QuotesProvider
                setLoading={props.setLoading}
                project_id={project_id}
                quote_id={quote_id}
                sequence_id={sequenceId}
                autosave={autosave}
                setAutosave={setAutosave}
                setViewType={setViewType}
                setExplodedItemAnchor={setExplodedItemAnchor}
            >
                <QuoteDialogs />

                <Fragment>
                    <div className="contentWithMiniDrawer">
                        <div className="contentwithMiniDrawer__content">
                            <div className="displayer_row">
                                <ISwitch
                                    label={autosave? "Autosave ON" : "Autosave OFF"}
                                    checked={autosave}
                                    onChange={()=>setAutosave(!autosave)}
                                    name="autosave"
                                />

                                {sequenceList.length > 0 && (
                                <div className="flex_row gap-10">
                                    <FormControl size="small">
                                        <InputLabel id="select-sequence-label">Sequence</InputLabel>
                                        <Select
                                            labelId="select-sequence-label"
                                            label="Sequence"
                                            sx={{
                                                width: 150,
                                                pt: 0,
                                                pb: 0,
                                            }}
                                            value={sequenceId}
                                            onChange={(e) => {
                                                handleSequenceChange(e.target.value as string);
                                            }}
                                        >
                                            {sequenceList.map((sequence: any, indexSequence: number) => (
                                                <MenuItem
                                                    key={indexSequence}
                                                    value={sequence.sequence_id}
                                                >
                                                    {sequence.sequence_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        className="btn"
                                        startIcon={<CopyAll />}
                                        onClick={() => {
                                            duplicateQuote();
                                        }}
                                        >
                                        Duplicate
                                    </Button>
                                    
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className="btn btn--save"
                                        startIcon={<Verified />}
                                        onClick={() => {
                                            markAsDefault();
                                        }}
                                    >
                                        Mark as current
                                    </Button>
                                </div>
                                )}
                            </div>

                            <ProjectHeader>
                                <span
                                    className={"titleQuoteType" + (viewType === 'quote' ? " ActiveViewButton" : "")}
                                    onClick={()=>setViewType("quote")}
                                >
                                    Quote View
                                </span>

                                <span
                                    className={"titleQuoteType" + (viewType === 'exploded' ? " ActiveViewButton" : "")}
                                    onClick={()=>setViewType("exploded")}
                                >
                                    Exploded View
                                </span>

                                <span
                                    className={"titleQuoteType" + (viewType === 'preview' ? " ActiveViewButton" : "")}
                                    onClick={()=>setViewType("preview")}
                                >
                                    PDF Preview
                                </span>

                            </ProjectHeader>

                            {viewType === 'quote' && <QuoteView /> }
                            {viewType === 'exploded' && <ExplodedView /> }
                            {viewType === 'preview' && <PreviewView /> }
                        </div>

                        <SideDrawer
                            drawerOpen={drawerOpen}
                            label = {viewType !== 'exploded' ? "QUOTE SUMMARY" : "EXPLODED VIEW"}
                            handleDrawerOpen = {handleDrawerOpen}
                            handleDrawerClose = {handleDrawerClose}
                        >
                            <div className="drawer_in">
                                <div className="drawer_content" style={{ opacity: drawerOpen ? 1 : 0 }}>
                                    <div className="box_header">
                                        <span className="subtitle"></span>
                                    </div>
                                    {(viewType === 'quote' || viewType == 'preview') && (
                                        <QuoteDrawerContent />
                                    )}
                                    {viewType === 'exploded' && (
                                        <ExplodedDrawerContent />
                                    )}

                                    <QuoteStatus />
                                </div>

                                <QuoteDrawerButtons drawerOpen={drawerOpen} />
                            </div>
                        </SideDrawer>
                    </div>
                </Fragment>
            </QuotesProvider>
        </>
    );
};

