import React, { Fragment, useEffect, useState } from 'react';
import { Autocomplete, Dialog, DialogContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete';
import axios from 'axios';

import { ProjectConfig } from 'Global';
import ic_dots from 'icons/ic_dots.svg';

import 'assets/css/main.css';
import ProductList from 'components/Products/ProductList';
import ProductEditor from 'components/Products/ProductEditor';
import Box from 'elements/Box';

interface IProjectList {
    inputValue?: string,
    project_id?: number,
    project_name: string,
  };

const defaultValues = {
    project_name: "",
    project_id: null,
}

export default function AutocompleteProjects( props: any ) {

    const {
        renderOptions = {type: 'with_client'},
        filterOptions = {
            type: 'none',
            callback: undefined,
        },
        handleChange = (value: any) => {},
        ...autocompliteProps
    } = props;

    delete autocompliteProps.setLoading;

    const [list, setList] = useState<any[]>([]);

    const [openProjectList, setOpenProjectList] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => { props.setLoading(loading) } , [loading]);

    const filter = createFilterOptions<IProjectList>();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'project_list',
                params: {
                    full_list: true,
                }
            }
        })
        .then((response) => {
            console.log(response.data);
            setList(response.data.list);
        })
        .catch((err) => {
            console.log(err.data);
        })
    }

    return (
        <>
            <Autocomplete
                {...autocompliteProps}
                id="autocomplete-projects"
                options={list}
                getOptionLabel={(option: any) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.project_name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option: any) => {
                    let render = <></>;
                    if (renderOptions.type === 'only_name') {
                        render = <span className='autocomplete_project_name'>{option.project_name}</span>;
                    } else if (renderOptions.type === 'with_client') {
                        render = (
                            <div className="flex_select">
                                <span className="flex_option_1">{option.project_name}</span>
                                <span className="flex_option_2">{option.client_name}</span>
                            </div>
                        );
                    }
                    return (
                        <li {...props} key={option.project_id}>
                            {render}
                        </li>
                    );
                }}
                filterOptions={
                    filterOptions.type === 'custom' ?
                    filterOptions.callback :
                    
                    (filterOptions.type === 'none' ?
                        undefined :
                        (options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.project_name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    project_name: `Add "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }
                    )
                }
                freeSolo
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        handleChange({
                            project_id: null,
                            project_name: newValue,
                        });
                    } else if (newValue && newValue.inputValue && filterOptions.type !== 'none') {
                        // Create a new value from the user input
                        // addProduct(newValue.inputValue);
                    } else {
                        handleChange({
                            project_id: newValue.project_id,
                            project_name: newValue.project_name,
                        });
                    }
                }}
                renderInput={(params) => (
                    <TextField 
                        {...params}
                        label="Project"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <Fragment>
                                    <IconButton
                                        className="autocomplete_ic_dots"
                                        onClick={()=>{
                                            setOpenProjectList(true);
                                        }}              
                                    >
                                        <img src={ic_dots}/>
                                    </IconButton>
                                </Fragment>
                            ),
                            style: {
                                fontSize: '12px',
                            }
                        }}
                    />
                )}
            />

            <Dialog
                open={openProjectList}
                onClose={() => setOpenProjectList(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{"& .MuiDialog-paper": {width: "fit-content", maxWidth: "unset",}}}
            >
                <DialogContent sx={{outline:'none', p: 0, maxWidth: 'unset', width: 'fit-content'}}>
                    <Box id='project_list' title="Clients" subtitle="List" >
                        <Table stickyHeader aria-label="sticky table"> 
                            <TableHead>
                                <TableRow>
                                    <TableCell>Project name</TableCell>
                                    <TableCell>Client</TableCell>
                                </TableRow>
                            </TableHead>
                            
                            <TableBody>
                                {list.map((project,i) => (
                                <TableRow 
                                    key={i}
                                    hover
                                    role="checkbox"
                                    onClick={() => {
                                        handleChange({
                                            project_id: project.project_id,
                                            project_name: project.project_name,
                                        })
                                        setOpenProjectList(false);
                                    }} 
                                >
                                    <TableCell>{project.project_name}</TableCell>
                                    <TableCell>{project.client_name}</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}