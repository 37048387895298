import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import ACSupplierList from 'elements/ACSupplierList';
import Box from 'elements/Box';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

const defaultValues = {
    machinery_id: 'null',
    name: '',
    reference: '',
    maintenance_date: moment(new Date()).format('YYYY-MM-DD'),
};

export default function MachineryEditor( props:any ) {
    
    const {} = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const { machinery_id = null } = useParams();

    const [formValues, setFormValues] = useState({...defaultValues});

    useEffect(() => {
        if (machinery_id) {
            loadData();
        }
    }, []);

    const loadData = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: "get_machinery_details",
                machinery_id: machinery_id,
            }
        })
        .then((response) => {
            console.log(response.data);
            setFormValues({...response.data});
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleCheckForm = () => {

        let error = false;

        if (formValues.name === '') {
            error = true;
            enqueueSnackbar('Name is required', { variant: 'error' });
        }
        if (formValues.reference === '') {
            error = true;
            enqueueSnackbar('Reference is required', { variant: 'error' });
        }
        if (formValues.maintenance_date === '') {
            error = true;
            enqueueSnackbar('Maintenance date is required', { variant: 'error' });
        }

        return error;
    };

    const handleSubmit = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, formValues, {
            params: {
                request: 'add_edit_machinery',
            },
        })
        .then((response) => {
            console.log(response.data);

            if (formValues.machinery_id === 'null') {
                enqueueSnackbar('Machinery added', { variant: 'success' });
            } else {
                enqueueSnackbar('Machinery saved', { variant: 'success' });
            }
            navigate('/machinery', {replace: true});
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <form>
            <Box id="machinery_editor" className="box_editor" title="Machinery" subtitle="Edit">
                <div className='form'>
                    <div className='form__row'>
                        <TextField 
                            id="machinerys_name"
                            name="name"
                            label="Name"
                            fullWidth
                            type="text"
                            required
                            value={formValues.name}
                            onChange={handleInputChange}
                        />
                    </div>
                    
                    <div className='form__row'>
                        <TextField 
                            id="machinerys_reference"
                            name="reference"
                            label="Reference"
                            fullWidth
                            type="text"
                            required
                            value={formValues.reference}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className='form__row'>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                className='filters_datePicker'
                                label={"Date"}
                                value={moment(formValues.maintenance_date)}
                                showToolbar={false}
                                closeOnSelect
                                onChange={(newValue) => {
                                    newValue && setFormValues({
                                        ...formValues,
                                        maintenance_date: newValue.format('YYYY-MM-DD')
                                    });
                                }}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

                <div className='buttons'>
                    <Button
                        onClick={() => {
                            navigate('/machinery', {replace: true})
                        }}
                        variant="outlined"
                        className="button_2"
                    >
                        Back
                    </Button>
                    
                    <Button
                        onClick={() => {
                            if(handleCheckForm()) return;
                            handleSubmit();
                        }}
                        variant="contained"
                        className="button_1"
                    >
                        Submit
                    </Button>
                </div>
            </Box>
        </form>
    );
}