import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import {
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import { FormatListNumberedRtl, Send } from '@mui/icons-material';

import { IProductQuoteView, IService, IStoneFields, QuotesContext } from './QuoteContextProvider';
import ProductList from 'components/Products/ProductList';
import ProductEditor from 'components/Products/ProductEditor';
import WorkforceList from 'components/Workforce/WorkforceList';
import WorkforceEditor from 'components/Workforce/WorkforceEditor';
import AlertDialog from 'elements/AlertDialog';
import { ProjectConfig } from 'Global';
import { Link } from 'react-router-dom';

export function DialogNewService ( props: any ) {

    const {
        services_types,
        openNewService,
        setOpenNewService,
        addServiceToQuote,
    } = useContext(QuotesContext);

    const defaultValues: IService = {
        name: '',
        serviceType: 'electrical',
    };

    const [item, setItem] = useState<IService>({...defaultValues});

    useEffect(() => {
        if (!openNewService) setItem({...defaultValues});
    }, [openNewService]);

    return (
        <>
            <Dialog
                open={openNewService}
                onClose={()=>{
                    setOpenNewService(false)
                }}
            >
                <DialogContent
                    id="estimate_configuration"
                    className="forced_box_editor"
                >
                    <div className="DialogHeader">
                        <h2>ADD SERVICE</h2>
                        <span>Enter the service name and type to add this service.</span>
                    </div>

                    <TextField
                        value={item.name}
                        autoFocus
                        label="Service name"
                        fullWidth
                        onChange={(e) => {
                            setItem({
                                ...item,
                                name: e.target.value
                            });
                        }}
                    />

                    <div className="radio_buttons_group">
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={item.serviceType}
                                className="type_radio_group"
                                onChange={(e: any) => {
                                    setItem({
                                        ...item,
                                        serviceType: e.target.value
                                    });
                                }}
                            >
                                {services_types.map((type: any) => {
                                    const type_capitalize = type.serviceType.substring(0,1).toUpperCase() + type.serviceType.substring(1).toLowerCase();
                                    return (
                                        <FormControlLabel
                                            key={type.serviceType}
                                            value={type.serviceType}
                                            control={<Radio />}
                                            label={type_capitalize}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div className="buttons">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={()=>{
                                setOpenNewService(false);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button 
                            variant="contained" 
                            onClick={() => {
                                addServiceToQuote(item.name, item.serviceType);
                                setOpenNewService(false);
                            }}
                            disabled={item.name === '' || ![...services_types].some((type: any) => type.serviceType === item.serviceType)}
                        >
                            Add
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export function DialogNewSection ( props: any ) {

    const {
        openNewSection,
        setOpenNewSection,
        addSection,
    } = useContext(QuotesContext);

    const defaultValues = {
        name: '',
    };

    const [item, setItem] = useState<any>({...defaultValues});

    useEffect(() => {
        if (!openNewSection) setItem({...defaultValues});
    }, [openNewSection]);

    return (
        <>
            <Dialog
                open={openNewSection}
                onClose={() => {
                    setOpenNewSection(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent
                    id="addSectionDialog"
                    className="forced_box_editor"
                >
                    <div className="DialogHeader">
                        <h2>ADD SECTION</h2>
                        <span>Enter the section name to add it.</span>
                    </div>

                    <TextField
                        id="standard-basic"
                        label="Section name"
                        fullWidth
                        value={item.name}
                        autoFocus
                        onChange={(e: any) => {
                            setItem({
                                ...item,
                                name: e.target.value
                            });
                        }}
                        onKeyUp={(e: any) => {
                            if (e.key === 'Enter') {
                                addSection(item.name);
                                setOpenNewSection(false);
                            }
                        }}
                    />

                    <div className="buttons">
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setOpenNewSection(false);
                            }}
                        >
                            Cancel
                        </Button>
                    
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                addSection(item.name);
                                setOpenNewSection(false);
                            }}
                        >
                            Add
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )

}

export function DialogProductList ( props: any ) {

    const {
        setLoading,
        openProductList,
        setOpenProductList,
        setOpenProductEditor,

        selectedItem,
        updateProduct,
    } = useContext(QuotesContext);

    const handleProductChange = (newProduct: any) => {
        const newProductChanges: IProductQuoteView = {
            product_id: newProduct.product_id,
            product_name: newProduct.product_name,
            caso_code: newProduct.caso_code,
            unit_price: newProduct.sale_price,
        };

        if (newProduct.stone_fields && newProduct.stone_fields.price_with_manufacturing) {
            const stone_fields: IStoneFields = {
                price_with_manufacture: newProduct.stone_fields.price_with_manufacturing,
                price_without_manufacture: newProduct.stone_fields.price_without_manufacturing,
            };

            newProductChanges.stone_fields = stone_fields;
        }
        
        updateProduct(
            newProductChanges,
            selectedItem.serviceIndex,
            selectedItem.category,
            selectedItem.itemIndex
        );
    };

    return (
        <>
            <Dialog
                open={openProductList}
                onClose={() => setOpenProductList(false)}
                maxWidth={false}
                >
                    <DialogContent>
                        <ProductList
                            setLoading={setLoading}
                            popupItemParams = {{
                                ...selectedItem,
                            }}
                            setProduct = {handleProductChange}
                            setOpenPopup = {setOpenProductList}
                            setOpenProductEditor = {setOpenProductEditor}
                            productAsObj={true}
                        />
                    </DialogContent>
            </Dialog>
        </>
    )

}

export function DialogProductEditor ( props: any ) {

    const {
        setLoading,
        updateProduct,
        openProductEditor,
        setOpenProductEditor,
        selectedItem,
        inputValueToDialogs,
        setInputValueToDialogs,
        setProductAddedTrigger
    } = useContext(QuotesContext);

    const handleProductChange = (newProduct: any) => {
        const newProductChanges: IProductQuoteView = {
            product_id: newProduct.product_id,
            product_name: newProduct.product_name,
            caso_code: newProduct.caso_code,
            unit_price: newProduct.sale_price,
        };

        if (newProduct.stone_fields && newProduct.stone_fields.price_with_manufacturing) {
            const stone_fields: IStoneFields = {
                price_with_manufacture: newProduct.stone_fields.price_with_manufacturing,
                price_without_manufacture: newProduct.stone_fields.price_without_manufacturing,
            };

            newProductChanges.stone_fields = stone_fields;
        }
        
        updateProduct(
            newProductChanges,
            selectedItem.serviceIndex,
            selectedItem.category,
            selectedItem.itemIndex
        );
    };

    useEffect(() => {
        if (!openProductEditor) setInputValueToDialogs('');
        setProductAddedTrigger(false);
    }, [openProductEditor]);

    return (
            <Dialog
                id='ProductEditorPopup'
                open = {openProductEditor}
                onClose={() => {
                    setOpenProductEditor(false);
                }}
                maxWidth={false}
            >
                    <DialogContent>
                        <ProductEditor
                            setLoading={setLoading}
                            productValues={{
                                product_name: (inputValueToDialogs ? inputValueToDialogs : undefined),
                                category: selectedItem.category,
                            }}
                            forcePopupAppearance={true}
                            onSubmit={(backend_response_data: any) => {
                                setProductAddedTrigger(true);
                                handleProductChange({
                                    ...backend_response_data.details[0],
                                    sale_price: backend_response_data.details[0].unit_price,
                                })
                                setOpenProductEditor(false);
                            }}
                            setOpenProductEditor={setOpenProductEditor}
                            productAsObj={true}
                        />
                    </DialogContent>
            </Dialog>
    )

}

export function DialogWorkforceList ( props: any ) {

    const {
        setLoading,
        updateTask,
        openWorkforceList,
        setOpenWorkforceList,
        selectedItem,
        setOpenWorkforceEditor,
    } = useContext(QuotesContext);

    const handleTaskChange = (newTask: any) => {
        const newTaskChanges = {
            task_id: newTask.workforce_id,
            task_name: newTask.workforce_name,
            hour_cost: newTask.hour_cost,
        };
    
        updateTask(
            newTaskChanges,
            selectedItem.serviceIndex,
            selectedItem.category,
            selectedItem.itemIndex
        );
    }

    return (
        <>
            <Dialog
                open={openWorkforceList}
                onClose={() => setOpenWorkforceList(false)}
                maxWidth={false}
            >
                <WorkforceList
                    setLoading={setLoading}
                    setWorkforce = {handleTaskChange}
                    setOpenPopup = {setOpenWorkforceList}
                    setOpenWorkforceEditor={setOpenWorkforceEditor}
                    popupItemParams = {{}}
                    workforceAsObj={true}
                    withPopupAddButton
                />
            </Dialog>
        </>
    )

}

export function DialogWorkforceEditor ( props: any ) {
    
    const {
        setLoading,
        openWorkforceEditor,
        setOpenWorkforceEditor,
        selectedItem,
        updateTask,
        inputValueToDialogs,
        setTaskAddedTrigger,
        setInputValueToDialogs,
    } = useContext(QuotesContext);

    const handleTaskChange = (newTask: any) => {
        const newTaskChanges = {
            task_id: newTask.workforce_id,
            task_name: newTask.workforce_name,
            hour_cost: newTask.hour_cost,
        };
        
        updateTask(
            newTaskChanges,
            selectedItem.serviceIndex,
            selectedItem.category,
            selectedItem.itemIndex
        );
    }

    useEffect(() => {
        if (!openWorkforceEditor) setInputValueToDialogs('');
        setTaskAddedTrigger(false);
    }, [openWorkforceEditor]);

    return (
        <>
            <Dialog
                open = {openWorkforceEditor}
                onClose={() => {
                    setOpenWorkforceEditor(false);
                }}
                maxWidth={false}
                >
                    <WorkforceEditor
                        setLoading={setLoading}
                        onSubmit = {(backend_response_data: any) => {
                            setTaskAddedTrigger(true);
                            handleTaskChange(backend_response_data);
                            setOpenWorkforceEditor(false);
                        }}
                        setOpenWorkforceEditor = {setOpenWorkforceEditor}
                        name = {inputValueToDialogs}
                    />
            </Dialog>
        </>
    )

}

export function DialogSendQuote ( props: any ) {

    const {
        quoteInfo,
        handleSubmit,
        openSendDialog,
        setOpenSendDialog,
    } = useContext(QuotesContext);

    const { enqueueSnackbar } = useSnackbar();

    const handleSendEmail = async () => {
        await handleSubmit()
            .then((res: any) => {

                if (res.quote_id === undefined) return;

                axios.post(ProjectConfig.api_url, null, {
                    params: {
                        request: "send_email_project_quote",
                        quote_id: res.quote_id,
                    },
                })
                    .then((response) => {
                        enqueueSnackbar("The email was sent successfully", {
                            variant: "success",
                        });
                        
                        setOpenSendDialog(false);
                    })
                    .catch((err) => {
                        console.log(err.data);
                        enqueueSnackbar("There was an error sending the email", {
                            variant: "error",
                        });
                    });
            });
    }

    return (
        <>
            <Dialog
                open={openSendDialog}
                onClose={() => setOpenSendDialog(false)}
            >
                <DialogContent
                    id="sendEmailDialog"
                    className="forced_box_editor"
                >
                    <h2>Send Email</h2>
                    
                    <DialogContentText>
                        This action will send an email with the current quote. Do you want to continue?
                    </DialogContentText>
                    
                    <div className="buttons">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setOpenSendDialog(false);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button 
                            variant="contained" 
                            endIcon={<Send />}
                            onClick={() => {
                                handleSendEmail();
                            }}
                        >
                            Confirm and Send Email
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );

}

export function DialogProjectStarted ( props: any ) {

    const {
        quoteInfo,
        openProductsSeparatedDialog,
        setOpenProductsSeparatedDialog,
        startProjectData,
    } = useContext(QuotesContext);

    return (
        <>
            <Dialog
                open = {openProductsSeparatedDialog}
                onClose={() => {
                    setOpenProductsSeparatedDialog(false);
                }}
            >
                <DialogContent
                    id="products_separated_dialog"
                    className="forced_box_editor"
                >
                    <h2>PROJECT STARTED</h2>
                    <DialogContentText style={{marginBottom:20}}>
                        The current quote has been started as a project. The available products were separated from inventory, and the products out of stock have been requested in a new order.
                    </DialogContentText>

                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Product</TableCell>
                                <TableCell align="right">Quantity</TableCell>
                                <TableCell align="right">Separated</TableCell>
                                <TableCell align="right">Ordered</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {startProjectData.products_separated &&
                            startProjectData.products_separated.map ((product:any, index:any) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{product.product_name}</TableCell>
                                        <TableCell align="right">{product.quantity}</TableCell>
                                        <TableCell align="right">{product.separated}</TableCell>
                                        <TableCell align="right">{product.pending}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>            
                    </Table>

                    {startProjectData.order_id > 0 && (
                        <Chip
                            icon={<FormatListNumberedRtl />}
                            label={"Order # " +startProjectData.order_id + " created with pending products"}
                            color="secondary"
                            sx={{width: "100%", marginTop: "10px", fontWeight: "300"}}
                        />
                    )}
                    
                    <div className="buttons">
                        <Button 
                            variant="contained"
                            component={Link}
                            to={"/projects/"+quoteInfo.project_id}
                        >
                            Go to Project dashboard
                        </Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            component={Link}
                            to={"/orders/"}
                        >
                                Go to Orders
                        </Button>
                    </div>                
                </DialogContent>
            </Dialog>
        </>
    )
}

export default function QuoteDialogs ( props: any ) {

    const {
        openDeleteService,
        setOpenDeleteService,
        deleteService,
    } = useContext(QuotesContext);

    return (
        <>
            <DialogNewService />
            <DialogNewSection />
            <DialogProductList />
            <DialogProductEditor />
            <DialogWorkforceList />
            <DialogWorkforceEditor />
            <DialogSendQuote />
            <DialogProjectStarted />
            <AlertDialog
                open={openDeleteService}
                setOpen={setOpenDeleteService}
                subtitle="Are you sure you want to delete this service?"
                send={deleteService}
            />
        </>
    )

}