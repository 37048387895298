import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { Button, Dialog, DialogContent, FormControl, FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio, RadioGroup,TextField } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactNode, useEffect } from "react";

const drawerWidth = 300;

    const openedMixin = (theme: Theme): CSSObject => ({
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      translateX: 0,
    });
    
    const closedMixin = (theme: Theme): CSSObject => ({
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
    });
    
    const Drawer = styled(MuiDrawer, {
      // shouldForwardProp: (prop) => prop !== "open",
    })(({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      boxSizing: "border-box",
      ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      }),
    }));


export default function SideDrawer(props: any) {

  const {
    withBackdrop = false,
    id = null,
    composeLabel = false,
    customIcon = null,
  } = props;

  const drawerOpen = props.drawerOpen;
  const handleDrawerClose = props.handleDrawerClose;
  const handleDrawerOpen = props.handleDrawerOpen;
  const label = props.label;

  const ListItemIconStyles = {
    minWidth: 0,
    mr: drawerOpen ? 3 : "auto",
    justifyContent: "center",
  };

  const ListItemButtonStyles = {
    minHeight: 48,
    justifyContent: drawerOpen ? "initial" : "center",
    px: 2.5,
  };

  useEffect(() => {
  }, [props.children]);

  const contentFunction = (children: ReactNode) => {
    return (
      <>
        <ListItem key="drawerOpenBtn" disablePadding sx={{ display: "block" }}>
          <ListItemButton
              id="drawer_header"
              onClick={props.drawerOpen ? handleDrawerClose : handleDrawerOpen}
              sx={ListItemButtonStyles}
          >
              <ListItemIcon
                id="drawer_back_icon"
                sx={{
                    minWidth: 0,
                    mr: props.drawerOpen ? 3 : "auto",
                    justifyContent: "center",
                }}
              >
                {customIcon === null ?
                <>
                  {!drawerOpen ? <MenuIcon /> : <ChevronRightIcon />}
                </>
                :
                  <>{customIcon}</>
                }
              </ListItemIcon>
              
              <ListItemText>
                {composeLabel ? 
                <>{label}</> :
                <h2 style={{ opacity: drawerOpen ? 1 : 0 }}>{label}</h2>
                }
              </ListItemText>
          </ListItemButton>
        </ListItem>
        {children}
      </>
    );
  }
    
  return (

    <Drawer
        id={id === null ? 'sideDrawerProducts' : id}
        key="drawer"
        variant={withBackdrop ? "temporary" : "permanent"}
        open={drawerOpen}
        anchor="right"
        className="miniDrawer"
        PaperProps={{
          style: {
            paddingTop: (props.popup || withBackdrop)? 0 : 55,
            boxShadow: "0 0 4px #00000026",
            border: "none",
            position: props.popup? "absolute" : "fixed"
          },
        }}
        ModalProps={ withBackdrop ? { onBackdropClick: props.drawerOpen ? handleDrawerClose : handleDrawerOpen } : {}}
    >
        {contentFunction(props.children)}
    </Drawer>
   
  );
}
