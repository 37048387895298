import { Autocomplete, Button, createFilterOptions, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from 'icons/ic_dots.svg';
import {ProjectConfig} from 'Global';
import ProjectHeader from "components/Projects/utils/ProjectHeader";

import AutocompleteProduct from "components/Projects/utils/AutoCompleteProduct";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export default function OrderIncoming (props: any) {

  const {
    setLoading,
    setMenuTopLeftContent,
    with_set_top_menu = false,
  } = props;
  
  useEffect(() => {setLoading(false);}, []);

  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let { project_id, order_id } = useParams();
  /*=======================================
  =======================================*/

  const navigate = useNavigate();
  const defaultValues: any = {
    order_id: order_id? order_id : "null",
    project_id: project_id? project_id : 0,
    supplier_id: 0,
    create_date: new Date(),
    arrival_date: null,
    cost: 0,
    products: [],
    supplier_quote_id: 0,
    folder_id: 0,
  };
  
  

  const [formValues, setFormValues] = useState(defaultValues)

  const setProduct = (product_name: any, productId: any, sale_price: any, stone_fields: any, serviceIndex: any, category: any, itemIndex: any) => {
       
    const newProducts = formValues.products;
    newProducts[itemIndex].product_name = product_name;
    newProducts[itemIndex].product_id = productId;
    setFormValues({...formValues, products: newProducts});

}



  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
   
    const { name, value } = e.target;
    console.log("CHANGE", name, value);
    setFormValues({
      ...formValues,
      [name]: value,
    });
    console.log("CHANGEx", formValues);
    
  };

  const handleSubmit = () => {
    console.log(formValues)
    setLoading(true);
    
    axios.post(ProjectConfig.api_url, null,
      {
        params: {
          request: 'inventory_incoming',
          products: formValues.products,
          order_id: formValues.order_id,
        }
      })
        .then((response) => {
          console.log("response: ",response.data);
          navigate('/orders');

          setLoading(false)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        
  };



  /**/

  useEffect(() => {
    if (order_id) {
      loadData();
    }
  }, []);

  const loadData = () => {
    setLoading(true);

      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "order_details",
            order_id: order_id,
          },
        })
        .then((response) => {
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          setLoading(false);
        });
  };


  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  

    /*=======================================
    PUSH NEW ITEM 
    =======================================*/
    const handleAddItem = () => {
        
      const newProducts = [...formValues.products];
      newProducts.push({
          product_id: "",
          product_name: "",
          quantity: 0,
      });
      setFormValues({
        ...formValues,
        products: newProducts,
      });


  }; 
  /*=======================================
  =======================================*/

  console.log("formValues", formValues);
  return (
    <div>
      <ProjectHeader />
      
    
    <form>
      <Box id="order_editor" className="box_editor" title="Incoming" subtitle="Edit"  >
        <div className='form'>

          <div className='input_group'>
            {/* <div>Supplier: {formValues.supplier_name}</div>
            <div>Supplier Quote ID: {formValues.supplier_quote_id}</div>
            <div>Cost: ${formValues.cost.toLocaleString()}</div>
            <div>Creation Date: {formValues.create_date}</div>
            <div>Expected Arrival: {formValues.arrival_date}</div>
            <div>Project: {formValues.project_name}</div> */}

            {/* <h2>Files</h2>
            {order_id && formValues.folder_id!=0 && (
            <FilesBrowser 
                folder_id={formValues.folder_id} 
                view_type="thumbnail"
            />
            )} */}

          


          </div>
          


          <div className='input_group'>
            <h2>Products</h2>
            {/* //map products */}
            {formValues.products.map((product: any, index: any) => (
              <div className="order_product_row">
                <AutocompleteProduct 
                    category_filter="'panel','wood','paint','hardware'" 
                    setProduct={setProduct} 
                    product_name={product.product_name}
                    category=""
                    serviceIndex={0}
                    itemIndex={index}
                    disabled
                />
                <TextField 
                  label="Expected Quantity"
                  fullWidth
                  size='small'
                  disabled
                  InputLabelProps={{
                      style: { fontSize: '12px' }
                  }}
                  value={product.quantity}
                  inputProps={{
                      style: {
                          fontSize: '12px',
                      }
                  }}
                  onChange={
                    (e) => {
                        const newProducts = [...formValues.products];
                        newProducts[index].quantity = e.target.value;
                        setFormValues({
                            ...formValues,
                            products: newProducts,
                        });
                    }
                  }
                  
                />
                <TextField 
                  label="Received Quantity"
                  fullWidth
                  size='small'
                  InputLabelProps={{
                      style: { fontSize: '12px' }
                      
                  }}
                  value={product.received_quantity}
                  InputProps={{
                      //IF RECEIVED QUANTITY IS GREATER THAN EXPECTED QUANTITY, SHOW ERROR and adorment
                      error: product.received_quantity < product.quantity,
                      

                      //show endAdornment if error with hover text
                      endAdornment: product.received_quantity < product.quantity && (
                        <Tooltip title="Received quantity is less than expected quantity">
                          <InputAdornment position="end">
                            <ErrorOutlineOutlinedIcon style={{color: 'red'}} />
                          </InputAdornment>
                        </Tooltip>
                      ),
                      
                      style: {
                          fontSize: '12px',
                      }
                  }}
                  onChange={
                    (e) => {
                        const newProducts = [...formValues.products];
                        newProducts[index].received_quantity = e.target.value;
                        setFormValues({
                            ...formValues,
                            products: newProducts,
                        });
                    }
                  }

                />



              </div>
            ))}

              <Button 
                className="item_add_button"
                onClick={()=>{
                    handleAddItem()
                }}>
                Add item
              </Button>
          </div>
          
          

        </div>
        <div className='buttons'>
          <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" className="button_1">Confirm</Button>
        </div>
      </Box>

      
        


    </form>
    </div>
    
  );
};
