import React, { useContext } from 'react';
import { QuotesContext } from '../QuoteContextProvider';
import ExplodedServiceBox from './ExplodedServiceBox';
import { Button } from '@mui/material';

export default function ExplodedView ( props: any ) {

    const {
        serviceDict,
        setOpenNewService,
    } = useContext(QuotesContext);

    /*====================================================
    ====================================================
    RENDER
    ====================================================
    ====================================================*/
    return (
        <>
            <div id="quoteExplodedView">
                {Object.keys(serviceDict).map((service: any, serviceIndex: any) => {
                    return (
                        <ExplodedServiceBox
                            serviceIndex={serviceIndex}
                            key={serviceIndex}
                        />
                    )
                })}
            
                <Button
                id="nn"
                    className="dashedButton"
                    onClick={()=>{
                        setOpenNewService(true);
                    }}
                >
                    Add service
                </Button>
            </div>
        </>
    )

}