import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ITaskQuoteView, QuotesContext } from '../QuoteContextProvider';
import {
    CircularProgress,
    Dialog,
    DialogContent,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    debounce,
} from '@mui/material';
import { Circle, DeleteOutlineOutlined, HourglassEmpty } from '@mui/icons-material';
import AutocompleteWorkforce from 'components/Projects/utils/AutoCompleteWorkforce';

const defaultValues: ITaskQuoteView = {
    task_name: '',
    hours: 0,
    hour_cost: 0,
    total_cost: 0,
};

export default function QuoteTaskRow ( props: any ) {

    const {
        buildUniqueId,
        fixNumberValue,
        taskDict,
        updateTask,
        deleteTask,
        setOpenWorkforceList,
        setSelectedItem,
        setOpenWorkforceEditor,
        setInputValueToDialogs,
        taskAddedTrigger,
        setLoadingFields,
    } = useContext(QuotesContext);

    const {
        serviceIndex,
        category,
        itemIndex,
        key = undefined,
    } = props;

    const [item, setItem] = useState<ITaskQuoteView>(defaultValues);
    const refHoursHandler = useRef<any>();

    const [updatedHours, setUpdatedHours] = useState<boolean>(true);
    
    useEffect(() => {
        if (props) {
            const taskList = taskDict[buildUniqueId(serviceIndex, category)];
            if (taskList) {
                updateItem(taskList[itemIndex]);
            } else {
                initValues();
            }
        }
    }, [props]);

    const initValues = () => {
        setItem({...defaultValues});
        setUpdatedHours(true);
        setLoadingFields(false);
    };

    const updateItem = (updatedItem: ITaskQuoteView) => {
        if (!updatedHours && item.hours !== updatedItem.hours) return;

        setItem((prevState) => ({
            ...prevState,
            ...updatedItem,
        }));
        setUpdatedHours(true);
        setLoadingFields(false);
    };

    const onChangeHours = () => {
        handleValueChange(item.hours, 'hours');
    }

    useEffect(() => {
        refHoursHandler.current = onChangeHours;
    }, [onChangeHours]);
    
    const overrideHours = () => {
        if (updatedHours) return;

        refHoursHandler.current?.();
    }

    const handleTaskChange = (newTask: any) => {
        const newTaskChanges = {
            task_id: newTask.workforce_id,
            task_name: newTask.workforce_name,
            hour_cost: newTask.hour_cost,
        };

        updateTask(newTaskChanges, serviceIndex, category, itemIndex);
    }

    const handleValueChange = (value: any, field: string) => {
        const newTaskChanges = {
            [field]: value,
        };

        updateTask(newTaskChanges, serviceIndex, category, itemIndex);
    }

    return (
        <>
            <tr key={key} className="table_quotes_tr">
                <td className="th_product table_quotes_td">
                    <AutocompleteWorkforce
                        setWorkforce={handleTaskChange}
                        workforce_name={item.task_name}
                        dotsClick={() => {
                            setSelectedItem({
                                serviceIndex: serviceIndex,
                                category: category,
                                itemIndex: itemIndex,
                            });
                            setOpenWorkforceList(true);
                        }}
                        productAsObj={true}
                        setOpenWorkforceEditor={() => {
                            setSelectedItem({
                                serviceIndex: serviceIndex,
                                category: category,
                                itemIndex: itemIndex,
                            });
                            setOpenWorkforceEditor(true);
                        }}
                        setWorkforceCreatorName={setInputValueToDialogs}
                        addedTrigger={taskAddedTrigger}
                    />
                </td>
                <td className="th_quantity table_quotes_td">
                    <TextField
                        label="Hours" 
                        fullWidth
                        size='small'
                        value={item.hours}
                        InputProps={{
                            style: {
                                fontSize: '12px',
                                paddingRight: '7px',
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    {!updatedHours && true ?
                                        <Tooltip title={"Press enter or leave the field to confirm"}>
                                            <Circle
                                                color="secondary"
                                                className='field_badge'
                                            />
                                        </Tooltip>
                                        :
                                        null
                                    }
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => {
                            setItem((prevState: ITaskQuoteView) => ({
                                ...prevState,
                                hours: fixNumberValue(e.target.value, false, false, true),
                            }));

                            setUpdatedHours(false);
                        }}
                        onBlur={overrideHours}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                overrideHours();
                            }
                        }}
                    />
                </td>
                
                <td className="th_quote_unit table_quotes_td">
                    ${item.hour_cost}
                </td>
                <td className="th_quote_cost table_quotes_td">
                    ${((item.hour_cost || 0) * (item.hours || 0)).toLocaleString()}
                </td>
                <td>
                    <IconButton
                        className="btn_delete_row"
                        aria-label="delete"
                        color="primary"
                        onClick={() => deleteTask(serviceIndex, category, itemIndex)}
                        
                    >
                        <DeleteOutlineOutlined />
                    </IconButton>
                </td>
            </tr>
        </>
    )

}