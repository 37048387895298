import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Pagination,
    Dialog,
    DialogContent,
} from '@mui/material';
import {
    DeleteOutlined,
    RemoveRedEye,
} from '@mui/icons-material';
import moment from 'moment';

import { ProjectConfig } from 'Global';
import Box from 'elements/Box';
import AlertDialog from 'elements/AlertDialog';
import { PayrollPaymentList } from './PayrollPaymentList';

const defaultValues: any = {
    payroll_id: "null",
    user_id: "null",
    name: "",
    date: moment().format('YYYY-MM-DD'),
    regular_time_cost: "0",
    extra_time_cost: "0",
    total_time_cost: "0",
    paid: "0",
};

export function PayrollList( props: any ) {

    const {} = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [list, setList] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
    const [formValues, setFormValues] = useState<any>({...defaultValues});

    const [selectedId, setSelectedId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedDeleteId, setSelectedDeleteId] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [page]);

    useEffect(() => {
        loadDetails();
    }, [selectedId]);

    const handleReload = () => {
        loadData();
        loadDetails();
    }

    const loadData = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_payroll_list',
                params: {
                    page: page,
                }
            }
        })
        .then((response) => {
            console.log(response);
            setList(response.data.list);
            setTotalPages(response.data.total_pages);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const loadDetails = () => {
        if (!selectedId) return;
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_payroll_details',
                payroll_id: selectedId,
            }
        })
        .then((response) => {
            console.log(response);

            setFormValues({...response.data});
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleDelete = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: 'delete_payroll',
                payroll_id: selectedDeleteId,
            }  
        })
        .then((response) => {
            console.log(response);

            loadData();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
            setOpenAlert(false);
        });
    }

    return (
        <>
            <div
                id="payrollContainer"
                className="moduleContainer"
            >
                <div className="moduleWrapper">
                    <div className="moduleHeader buttons_top">
                        <h2>PAYROLL</h2>

                        <div className="moduleButtons buttons_top">
                        </div>   
                    </div>

                    <div className="moduleContent">
                        <Box className="box100">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Id</TableCell>
                                        <TableCell>Worker</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Paid</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {list.map((item: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.payroll_id}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>${item.total_time_cost.toLocaleString()}</TableCell>
                                        <TableCell>${item.paid.toLocaleString()}</TableCell>
                                        <TableCell>{item.date}</TableCell>
                                        <TableCell
                                            className='graph_row'
                                            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}
                                        >
                                            <Button
                                                color="primary"
                                                variant='contained'
                                                size="small"
                                                onClick={() => {
                                                    setSelectedId(item.payroll_id);
                                                    setOpenDialog(true);
                                                }}
                                                startIcon={ <RemoveRedEye /> }
                                            >
                                                Details
                                            </Button>

                                            {/* <Button
                                                color="primary"
                                                variant='contained'
                                                size="small"
                                                onClick={() => {
                                                    setSelectedDeleteId(item.payment_id);
                                                    setOpenAlert(true);
                                                }}
                                                startIcon={ <DeleteOutlined /> }
                                            >
                                                Delete
                                            </Button> */}
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            {totalPages > 1 &&
                            <Pagination
                                color='primary'
                                sx={{ "& .MuiPagination-ul ": {justifyContent: 'center'} }}
                                count={totalPages}
                                page={Number(page)}
                                onChange={(event, value) => {
                                    setPage(value);
                                    setSearchParams({ payment_page: value.toString() });
                                }}
                            />
                            }
                        </Box>
                    </div>
                </div>
            </div>

            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle="Are you sure you want to delete this payment?"
                send={handleDelete}
            />
            
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth='sm'
                fullWidth
            >
                <DialogContent
                    className='forced_box_editor'
                >
                    <div className="moduleHeader displayer_row">
                        <h2> { formValues.name } </h2>
                        
                        <div className='flexTd align_right'>
                            <span className="subtitle">Date</span>
                            <span className="subtitle"> { formValues.date } </span>
                        </div>
                    </div>

                    {formValues.payroll_id !== 'null' &&
                    <div id="payroll_details_container" className="graph_row">
                        <Box className='graph_top_boxes'>
                            <div className="graph_top_number">
                                ${(formValues.total_time_cost || 0).toLocaleString()}
                            </div>

                            <div className="graph_top_text">Total</div>
                        </Box>

                        <Box className='graph_top_boxes'>
                            <div className="graph_top_number">
                                ${(formValues.regular_time_cost || 0).toLocaleString()}
                            </div>

                            <div className="graph_top_text">Regular</div>
                        </Box>

                        <Box className='graph_top_boxes'>
                            <div className="graph_top_number">
                                ${(formValues.extra_time_cost || 0).toLocaleString()}
                            </div>

                            <div className="graph_top_text">Extra</div>
                        </Box>

                        <Box className='graph_top_boxes'>
                            <div className="graph_top_number">
                                ${(formValues.paid || 0).toLocaleString()}
                            </div>
                            <div className="graph_top_text">Paid</div>
                        </Box>
                    </div>
                    }

                    <div className="input_group" style={{margin: '10px 0'}}>
                        <div className="input_row">
                            <PayrollPaymentList
                                payroll_id={selectedId}
                                setLoading={props.setLoading}
                                handleReload={handleReload}
                            />
                        </div>
                    </div>

                    <div className="buttons">
                        <Button
                            color="primary"
                            variant='contained'
                            size="small"
                            onClick={() => {
                                setSelectedId(null);
                                setOpenDialog(false);
                            }}
                        >
                            Close
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}