import { Button, Paper, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from "Global";
import { useSnackbar } from "notistack";


 export default function WorkforceEditor(props: any) {

  const {
    setLoading,
    onSubmit,
    name,
  } = props;

  useEffect(() => {setLoading(false);}, []);
  const navigate = useNavigate();


  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  


  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  let {workforce_id} = useParams();
  workforce_id = props.workforce_id ? props.workforce_id : workforce_id;
  /*=======================================
  =======================================*/


  // SELECT `workforce_id`, `name`, `tin`, `address`, `email`, `phone`, `contact_person`, `payment_terms`, `payment_method`, `workforce_deleted` FROM `workforces` WHERE 1


  const defaultValues = {
      workforce_id: workforce_id? workforce_id: "null",
      workforce_name: name? name: "",
      hour_cost: "",
  };
 

  
  const [formValues, setFormValues] = useState(defaultValues)

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };



  // IF CLIENT ID IS PROVIDED, GET CLIENT INFO
    useEffect(() => {
      if (workforce_id) {
        axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "workforce_details",
              workforce_id: workforce_id,
            },
          })
          .then((response) => {
            console.log(response.data);
            setFormValues(response.data);
          })
          .catch((err) => {
            console.log(err.data);
          });
      }
    }, []);

    //===========================================================



 /*====================================
  SUBMIT 
  =====================================*/
  const handleSubmit = () => {

    //CHECK IF REQUIRED FIELDS ARE FILLED
    if(!formValues.workforce_name || !formValues.hour_cost) {
      enqueueSnackbar("Please fill all required fields", { variant: "error" });
      return;
    }

    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "add_edit_workforce",
          formdata: formValues,
        },
      })
      .then((response) => {
        console.log(response.data);
        enqueueSnackbar("Workforce Saved", { variant: "success" });

        if(onSubmit) {
          onSubmit(response.data);
        }
        else{
          navigate(-1)
        }
        
        console.log(response);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  /*====================================
  =====================================*/




  return (
    <form>
      <Box id="project_list" className="box_editor" title="Workforce Information" subtitle="Edit"  >
        <div className='form'>
              <TextField 
                id="workforce_name"
                name="workforce_name"
                label="Workforce Name"
                type="text"
                fullWidth
                required
                value={formValues.workforce_name}
                onChange={handleInputChange}
              />

              <TextField
                  id="hour_cost"
                  name="hour_cost"
                  label="Hour Cost"
                  type="number"
                  fullWidth
                  required
                  value={formValues.hour_cost}
                  onChange={handleInputChange}
                />

          
          </div>
        <div className='buttons'>
          <Button onClick={
            
            () => {
              if(onSubmit) {
                onSubmit(null);
              }
              else{
                navigate(-1)
              }
            }
          } 
          variant="outlined" 
          className="button_2">
            Back
          </Button>
          <Button onClick={handleSubmit} variant="contained" className="button_1">Submit</Button>
        </div>
      </Box>

      



    </form>

    
  );
};


