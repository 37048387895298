import React, {
    createContext,
    FC,
    useEffect,
    useState,
} from "react";
import axios from "axios";

import { ProjectConfig } from "Global";
import { unstable_batchedUpdates } from "react-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";


/* ------->>> INTERFACES <<<------- */

interface ISectionItem {
    section_name: string;
}

interface IItemObject {
    serviceIndex: number;
    category: string;
    itemIndex: number;
};

interface ISectionDict {
    [key: string]: IItemObject[];
}

export interface IStoneFields {
    price_with_manufacture: number;
    price_without_manufacture: number;
}

export interface IProductQuoteView {
    unique_id?: string;
    itemIndex?: number;

    product_id?: number;
    product_name?: string;
    product_description?: string;

    caso_code?: string;
    quantity?: number;
    section?: string;
    unit_price?: number;
    total_price?: number;

    stone_fields?: IStoneFields;
};

export interface IFabricationQuoteView {
    unique_id?: string;
    itemIndex?: number;

    have_workforce?: boolean;
    sq_ft?: number;
    destination?: string;
    edge?: string;
    finish?: string;
    sink?: number;
    workforce_cost?: number;
};

export interface ITaskQuoteView {
    unique_id?: string;
    itemIndex?: number;

    task_id?: number;
    task_name?: string;
    hours?: number;
    hour_cost?: number;
    total_cost?: number;
};

export interface IMeasureExplodedView {
    unique_id?: string;
    itemIndex?: number;
    measureIndex?: number;

    measure?: string;
    width?: number;
    length?: number;
    quantity?: number;
    sq_ft?: number;
    description?: string;
    sheets?: number;
};

export interface IProductExplodedView {
    unique_id?: string;
    itemIndex?: number;

    product_id?: number;
    product_name?: string;
    product_description?: string;

    caso_code?: string;
    quantity?: number;
    unit_price?: number;
    total_price?: number;
    measures?: IMeasureExplodedView[];

    stone_fields?: IStoneFields;
};

export interface ICategory {
    category: string;
    category_name: string;
    category_filter: string
    category_element_id?: string;
};

interface ICategoryDict {
    millwork?: ICategory[];
    stone?: ICategory[];
    remodeling?: ICategory[];
};

export interface IService {
    serviceIndex?: number;
    
    serviceType: string | '';
    name?: string;
    collapseService?: boolean,
    collapseSections?: boolean,
};

export interface IServiceCost {
    serviceIndex?: number;
    serviceType: string;

    total_cost?: number;
    percentage?: number;

    material_cost?: number,
    workforce_cost?: number,

    discounts?: number;
    increases?: number;

    [id: string]: number | string | undefined;
};

export interface ITypeCost {    
    material_cost: number;
    workforce_cost: number;
    company_cost: number;
    profit_percentage?: number;
    profit_value?: number;
    profit_fixed?: number;
};

interface IQuoteCost {
    [id: string]: ITypeCost | number | boolean | undefined;

    subtotal_cost?: number;
    tax_cost?: number;
    tax_percentage?: number;
    have_tax?: boolean;
    total_cost?: number;
};

interface IProductPreviewView {
    unique_id?: string;
    itemIndex?: number;

    product_name?: string;
    quote_text?: string;
    section?: string;
};

interface IQuoteInfo {
    quote_id?: number;

    project_id?: string;
    project_name?: string;
    client_name?: string;
    client_address?: string;

    quote_text?: string;
};

interface IServiceFolder {
    serviceIndex?: number;

    folder_id?: number;
    name?: string;
    serviceType?: string;
};

export interface IServiceType {
    serviceType: string;
    with_profit: boolean;
};

interface IStartProjectData {
    project_id?: string;
    quote_id?: number;

    products_separated?: any[];
    order_id?: number;
};

export interface IQuoteStatus {
    status_id: number;
    status_name: string;

    date: string;
    time: string;
    user: string;
};

/* ------->>> END INTERFACES <<<------- */

export const QuotesContext = createContext<any>({});

/*=======================================
This function provide the context for the Quotes components hierarchy.

This function build the objects that will be used in the Quotes components hierarchy
    and rebuild them to submit a single object to the database.
=======================================*/
export const QuotesProvider: FC<any> = ( props: any ) => {

    /* ------->>> CONSTANTS <<<------- */

    const defaultSelectedItem: IItemObject = {
        serviceIndex: 0,
        category: '',
        itemIndex: 0,
    };

    const defaultQuoteCost: IQuoteCost = {
        subtotal_cost: 0,
        tax_cost: 0,
        tax_percentage: 10,
        have_tax: true,
        total_cost: 0,
        millwork: {
            material_cost: 0,
            workforce_cost: 0,
            company_cost: 0,
            profit_percentage: 50,
        },
        stone: {
            material_cost: 0,
            workforce_cost: 0,
            company_cost: 0,
        },
        remodeling: {
            material_cost: 0,
            workforce_cost: 0,
            company_cost: 0,
            profit_percentage: 50,
        },
    };

    const defaultInfo: IQuoteInfo = {
        quote_text: "- Quotes is valid for 15 days" + '\n' +
        "- Changes must be submitted in writing or emailed" + '\n' +
        "- Changes of any description may delay the completion date of the projec" + '\n' +
        "- Changes may add to the total cost of the projec" + '\n' +
        "- A deposit of 50% is required" + '\n' +
        " - Milestone and balance payments to be paid according to contract" + '\n' +
        "Thank you for the opportunity, we look forward to working with you." + '\n' +
        "Caso Kitchens",
    };

    const categories: ICategoryDict = {
        millwork: [
            { category: "panel", category_name: "Panels and adhesives", category_filter: "'panel','wood'" },
            { category: "paint", category_name: "Paint", category_filter: "'paint'" },
            { category: "hardware", category_name: "Hardware", category_filter: "'hardware'" },
            { category: "workforce", category_name: "Workforce", category_filter: "'workforce'" },
        ],
        stone: [
            { category: "stone", category_name: "Countertop / Backsplash", category_filter: "'stone'" },
        ],
        remodeling: [
            { category: "panel", category_name: "Panels and adhesives", category_filter: "'panel','wood'" },
            { category: "paint", category_name: "Paint", category_filter: "'paint'" },
            { category: "hardware", category_name: "Hardware", category_filter: "'hardware'" },
            { category: "workforce", category_name: "Workforce", category_filter: "'workforce'" },
        ],
    };

    const categoriesFilters: {[category: string]: ICategory} = {
        ...categories.millwork?.reduce((obj: any, item: ICategory) => {
            obj[item.category] = item;
            return obj;
        }, {}),
        ...categories.stone?.reduce((obj: any, item: ICategory) => {
            obj[item.category] = item;
            return obj;
        }, {}),
        ...categories.remodeling?.reduce((obj: any, item: ICategory) => {
            obj[item.category] = item;
            return obj;
        }, {}),
    };

    const services_types: IServiceType[] = [
        {
            serviceType: "millwork",
            with_profit: true,
        }, {
            serviceType: "stone",
            with_profit: false,
        }, {
            serviceType: "remodeling",
            with_profit: true,
        },
    ];

    const defaultStatus: IQuoteStatus = {
        status_id: 1,
        status_name: "Unfinished quote",
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm:ss"),
        user: localStorage.getItem("user_name") || "",
    }

    const {
        quote_id = 'null',
        project_id = 'null',
        sequence_id = null,
        setViewType = () => {},

        autosave = false,

        setExplodedItemAnchor = () => {},
    } = props;

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    /* ------->>> VARIABLES <<<------- */

    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => { props.setLoading(loading); }, [loading]);

    const [productDict, setProductDict] = useState<{[id: string]: IProductQuoteView[]}>({});

    const [sectionList, setSectionList] = useState<ISectionItem[]>([]);
    const [sectionDict, setSectionDict] = useState<ISectionDict>({});

    const [fabricationDict, setFabricationDict] = useState<{[id: string]: IFabricationQuoteView[]}>({});

    const [taskDict, setTaskDict] = useState<{[id: string]: ITaskQuoteView[]}>({});

    const [measureDict, setMeasureDict] = useState<{[id: string]: {[itemIndex: number]: IMeasureExplodedView[]}}>({});

    const [serviceDict, setServiceDict] = useState<{[id: string]: IService}>({});

    const [costDict, setCostDict] = useState<{[id: string]: IServiceCost}>({});

    const [quoteCost, setQuoteCost] = useState<IQuoteCost>({...defaultQuoteCost});

    const [previewDict, setPreviewDict] = useState<{[id: string]: IProductPreviewView[]}>({});

    const [quoteInfo, setQuoteInfo] = useState<IQuoteInfo>({...defaultInfo});

    const [folderDict, setFolderDict] = useState<{[id: string]: IServiceFolder}>({});

    const [startProjectData, setStartProjectData] = useState<IStartProjectData>({});

    const [currentState, setCurrentState] = useState<IQuoteStatus>({...defaultStatus});
    const [history, setHistory] = useState<IQuoteStatus[]>([]);



    const [wasSubmited, setWasSubmited] = useState(false);
    const [wasModified, setWasModified] = useState(false);

    const [openNewService, setOpenNewService] = useState<boolean>(false);
    const [openNewSection, setOpenNewSection] = useState<boolean>(false);
    const [openProductList, setOpenProductList] = useState(false);
    const [openProductEditor, setOpenProductEditor] = useState(false);
    const [openWorkforceList, setOpenWorkforceList] = useState(false);
    const [openWorkforceEditor, setOpenWorkforceEditor] = useState(false);
    const [openDeleteService, setOpenDeleteService] = useState(false);
    const [openSendDialog, setOpenSendDialog] = useState(false);
    const [openProductsSeparatedDialog, setOpenProductsSeparatedDialog] = useState(false);

    const [selectedItem, setSelectedItem] = useState<IItemObject>({...defaultSelectedItem});
    const [inputValueToDialogs, setInputValueToDialogs] = useState<string>('');
    const [selectedDeleteService, setSelectedDeleteService] = useState<any>(null);

    const [productAddedTrigger, setProductAddedTrigger] = useState<boolean>(false);
    const [taskAddedTrigger, setTaskAddedTrigger] = useState<boolean>(false);

    const [loadingFields, setLoadingFields] = useState<boolean>(false);
    const [loadingFieldsDict, setLoadingFieldsDict] = useState<{[id: string]: boolean}>({});
    const [pendingChanges, setPendingChanges]  = useState<boolean>(false);

    const [selectedDuplicateService, setSelectedDuplicateService] = useState<{oldServiceIndex: number, newServiceIndex: number} | null>(null);
    const [duplicateCompletedSteps, setDuplicateCompletedSteps] =
        useState<{products: boolean, task: boolean, fabrication: boolean}>({products: false, task: false, fabrication: false})

    const [pendingUpdateCostDict, setPendingUpdateCostDict] = useState<Boolean>(false);
    const [pendingUpdateQuoteCost, setPendingUpdateQuoteCost] = useState<Boolean>(false);

    /* ------->>> END STATE VARIABLES <<<------- */



    /* ------->>> FRONTEND FUNCTIONS <<<------- */

    useEffect(() => {
        if (quote_id !== 'null') loadData();
    }, []);

    useEffect(() => {
        if (quote_id !== 'null') loadData();
    }, [quote_id]);

    useEffect(() => {
        if (sequence_id !== null) loadData();
    }, [sequence_id]);

    const buildUniqueId = (serviceIndex: number, category: string) => {
        return `${serviceIndex}_${category}`;
    }

    const splitUniqueId = (unique_id: string): string[] => {
        return unique_id.split('_');
    }

    useEffect(() => {
        if(!wasSubmited){
            setWasModified(true);
        }
        else{
            setWasSubmited(false);
        }
    }, [
        productDict,
        sectionList,
        sectionDict,
        fabricationDict,
        taskDict,
        measureDict,
        serviceDict,
        costDict,
        quoteCost,
        previewDict,
        quoteInfo,
        folderDict,
        startProjectData
    ]);

    /*=================================================
    AUTO SAVE QUOTE
    =================================================*/
    
    useEffect(() => {
        if(wasModified && quote_id != "null" && autosave){
            const timer = setTimeout(async () => {
                await handleSubmit(true);
            }, 20000);
            return () => clearTimeout(timer);
        }
    });

    //prevent back button from navigating away SHOW DIALOG
    useEffect(() => {
        if (wasModified) {
            //ON RELOAD OR BACK BUTTON
            window.onbeforeunload = function () {
                return true;
            }
        }
    }, []);

    /*=======================================
    =======================================*/

    const updateProduct = async (
        newProduct: any,
        serviceIndex: number,
        category: string,
        itemIndex: number,
    ) => {
        setLoadingFields(true);
        const unique_id = buildUniqueId(serviceIndex, category);
        setLoadingFieldsDict((prevState: any) => {
            return {
                ...prevState,
                [serviceIndex]: true,
                [unique_id]: true,
            };
        });

        /* ------->>> UPDATE PRODUCT STONE FIELDS <<<------- */
        if (category === 'stone' && newProduct.stone_fields !== undefined) {
            newProduct.unit_price =
                (fabricationDict[unique_id][itemIndex].have_workforce) ?
                    newProduct.stone_fields.price_with_manufacture :
                    newProduct.stone_fields.price_without_manufacture;
        }

        /* ------->>> UPDATE PRODUCT COST <<<------- */
        if (newProduct.quantity !== undefined || newProduct.unit_price !== undefined) {

            let service_cost_delta: number;
            if (newProduct.quantity !== undefined) {
                service_cost_delta = (productDict[unique_id][itemIndex].unit_price || 0) *
                    (newProduct.quantity - (productDict[unique_id][itemIndex].quantity || 0));
            } else {
                service_cost_delta = (productDict[unique_id][itemIndex].quantity || 0) *
                    (newProduct.unit_price - (productDict[unique_id][itemIndex].unit_price || 0));
            };

            // setCostDict((prevState: any) => {
            //     return {
            //         ...prevState,
            //         [serviceIndex]: {
            //             ...prevState[serviceIndex],
            //             total_cost: prevState[serviceIndex].total_cost + service_cost_delta,
            //             [unique_id]: prevState[serviceIndex][unique_id] + service_cost_delta,
            //         },
            //     };
            // });

            // setQuoteCost((prevState: any) => {
            //     const service_type_cost_dict: ITypeCost = prevState[serviceDict[serviceIndex].serviceType];
            //     const old_service_type_total = prevState[serviceDict[serviceIndex].serviceType].company_cost * (1 + (service_type_cost_dict.profit_percentage || 0) / 100);
                
            //     service_type_cost_dict.material_cost = service_type_cost_dict.material_cost + service_cost_delta;
            //     service_type_cost_dict.company_cost = service_type_cost_dict.material_cost + service_type_cost_dict.workforce_cost;
                
                
            //     const new_service_type_total = service_type_cost_dict.company_cost * (1 + (service_type_cost_dict.profit_percentage || 0) / 100);
            //     const quote_subtotal = prevState.subtotal_cost + (new_service_type_total - old_service_type_total);

            //     return {
            //         ...prevState,
            //         [serviceDict[serviceIndex].serviceType]: service_type_cost_dict,
            //         subtotal_cost: quote_subtotal,
            //         tax_cost: quote_subtotal * (prevState.tax_percentage / 100 || 0),
            //         total_cost: quote_subtotal * (1 + (prevState.tax_percentage / 100 || 0)),
            //     }
            // });
        }
        

        /* ------->>> UPDATE PRODUCT SECTION <<<------- */
        if (newProduct.section !== undefined) {
            const old_section = productDict[unique_id][itemIndex].section;
            
            if (old_section && old_section !== '') {
                if (newProduct.section != old_section) {
                    if (newProduct.section !== '') {
                        changeItemSection(
                            old_section,
                            newProduct.section,
                            serviceIndex,
                            category,
                            itemIndex,
                        );
                    } else {
                        removeItemFromSection(
                            old_section,
                            serviceIndex,
                            category,
                            itemIndex,
                        );
                    }
                }
            } else {
                addItemToSection(
                    newProduct.section,
                    serviceIndex,
                    category,
                    itemIndex,
                );
            }
        }

        /* ------->>> UPDATE PRODUCT <<<------- */
        setProductDict((prevState: any) => {
            const updatedProduct = {
                ...prevState[unique_id][itemIndex],
                ...newProduct,
            };

            let stoneSpecs = {};

            if (category === 'stone') {
                stoneSpecs = {
                    ...fabricationDict[unique_id][itemIndex],
                };
            }

            updatePreviewItem(
                {
                    ...updatedProduct,
                    ...stoneSpecs,
                },
                serviceIndex,
                category,
                itemIndex,
            );

            return {
                ...prevState,
                [unique_id]: [
                    ...prevState[unique_id].slice(0, itemIndex),
                    updatedProduct,
                    ...prevState[unique_id].slice(itemIndex + 1),
                ],
            };
        });
    }

    const addProductToCategory = (
        serviceIndex: number,
        category: string,
    ) => {
        const unique_id = buildUniqueId(serviceIndex, category);

        setProductDict((prevState: any) => {
            return {
                ...prevState,
                [unique_id]: [
                    ...prevState[unique_id],
                    {
                        unique_id: unique_id,
                        itemIndex: prevState[unique_id].length || 0,

                        product_name: '',
                        caso_code: '',
                        quantity: 0,
                        section: '',
                        unit_price: 0,
                        total_price: 0,
                    },
                ],
            }
        });
    }

    const deleteProduct = async (
        serviceIndex: number,
        category: string,
        itemIndex: number,
    ) => {
        const unique_id = buildUniqueId(serviceIndex, category);

        const service_workforce_cost_delta = (
            category === 'stone' ?
            (fabricationDict[unique_id][itemIndex].workforce_cost || 0) : 0
        );

        const service_cost_delta = (productDict[unique_id][itemIndex].unit_price || 0) *
            (productDict[unique_id][itemIndex].quantity || 0);

        // setCostDict((prevState: any) => {
        //     return {
        //         ...prevState,
        //         [serviceIndex]: {
        //             ...prevState[serviceIndex],
        //             total_cost: prevState[serviceIndex].total_cost - service_cost_delta - service_workforce_cost_delta,
        //             [unique_id]: prevState[serviceIndex][unique_id] - service_cost_delta - service_workforce_cost_delta,
        //         },
        //     };
        // });

        // setQuoteCost((prevState: any) => {
        //     const service_type_cost_dict: ITypeCost = prevState[serviceDict[serviceIndex].serviceType];
        //     const old_service_type_total = prevState[serviceDict[serviceIndex].serviceType].company_cost * (1 + (service_type_cost_dict.profit_percentage || 0) / 100);

        //     service_type_cost_dict.material_cost = service_type_cost_dict.material_cost - service_cost_delta;
        //     service_type_cost_dict.workforce_cost = service_type_cost_dict.workforce_cost - service_workforce_cost_delta;
        //     service_type_cost_dict.company_cost = service_type_cost_dict.material_cost + service_type_cost_dict.workforce_cost;

        //     const new_service_type_total = service_type_cost_dict.company_cost * (1 + (service_type_cost_dict.profit_percentage || 0) / 100);
        //     const quote_subtotal = prevState.subtotal_cost + (new_service_type_total - old_service_type_total);

        //     return {
        //         ...prevState,
        //         [serviceDict[serviceIndex].serviceType]: service_type_cost_dict,
        //         subtotal_cost: quote_subtotal,
        //         tax_cost: quote_subtotal * (prevState.tax_percentage / 100 || 0),
        //         total_cost: quote_subtotal * (1 + (prevState.tax_percentage / 100 || 0)),
        //     };
        // });

        if (category === 'stone') deleteFabrication(serviceIndex, category, itemIndex, false);

        setPreviewDict((prevState: any) => {
            return {
                ...prevState,
                [unique_id]: [
                    ...prevState[unique_id].slice(0, itemIndex),
                    ...prevState[unique_id].slice(itemIndex + 1),
                ],
            };
        });

        setProductDict((prevState: any) => {
            if (prevState[unique_id][itemIndex].section !== '')
                removeItemFromSection(prevState[unique_id][itemIndex].section, serviceIndex, category, itemIndex);

            removeMeasureFromItem(serviceIndex, category, itemIndex);

            return {
                ...prevState,
                [unique_id]: [
                    ...prevState[unique_id].slice(0, itemIndex),
                    ...prevState[unique_id].slice(itemIndex + 1),
                ],
            };
        });
    }

    const deleteSection = async (
        section: string,
    ) => {
        sectionDict[section].forEach((item: any) => {
            // buildUniqueId
            const unique_id = `${item.serviceIndex}_${item.category}`;

            setProductDict((prevState: any) => {
                return {
                    ...prevState,
                    [unique_id]: [
                        ...prevState[unique_id].slice(0, item.itemIndex),
                        {
                            ...prevState[unique_id][item.itemIndex],
                            section: '',
                        },
                        ...prevState[unique_id].slice(item.itemIndex + 1),
                    ],
                };
            });
        });
        
        setSectionDict((prevState: any) => {
            return {
                ...prevState,
                [section]: [],
            };
        });
    }

    const addSection = (
        section: string,
    ) => {
        if (sectionList.some((itemSection: ISectionItem) => itemSection.section_name == section)) return;

        setSectionList((prevState: any) => {
            return [
                ...prevState,
                {
                    section_name: section,
                },
            ];
        });

        setSectionDict((prevState: any) => {
            return {
                ...prevState,
                [section]: [],
            };
        });
    }

    const addItemToSection = (
        section: string,
        serviceIndex: number,
        category: any,
        itemIndex: number,
    ) => {
        setSectionDict((prevState: any) => {
            return {
                ...prevState,
                [section]: [
                    ...prevState[section] || [],
                    {
                        serviceIndex: serviceIndex,
                        category: category,
                        itemIndex: itemIndex
                    }
                ]
            }
        });
    }

    const createItemInSection = (
        section: string,
        serviceIndex: number,
        category: any,
    ) => {
        const unique_id = buildUniqueId(serviceIndex, category);

        setProductDict((prevState: any) => {
            
            setSectionDict((prevSectionState: any) => {
                return {
                    ...prevSectionState,
                    [section]: [
                        ...prevSectionState[section] || [],
                        {
                            serviceIndex: serviceIndex,
                            category: category,
                            itemIndex: prevState[unique_id].length || 0,
                        }
                    ]
                }
            });

            if (category === 'stone') {
                setFabricationDict((prevFabricationState: any) => {
                    return {
                        ...prevFabricationState,
                        [unique_id]: [
                            ...prevFabricationState[unique_id] || [],
                            {
                                unique_id: unique_id,
                                itemIndex: prevState[unique_id].length || 0,

                                have_workforce: true,
                                sq_ft: 0,
                                destination: 'countertop',
                                edge: '0',
                                finish: '0',
                                sink: 0,
                                workforce_cost: 0,
                            }
                        ]
                    }
                });
            }

            return {
                ...prevState,
                [unique_id]: [
                    ...prevState[unique_id],
                    {
                        unique_id: unique_id,
                        itemIndex: prevState[unique_id].length || 0,

                        product_name: '',
                        caso_code: '',
                        quantity: 0,
                        section: section,
                        unit_price: 0,
                        total_price: 0,
                    },
                ],
            }
        });
    }

    const changeItemSection = (
        oldSection: string,
        newSection: string,
        serviceIndex: number,
        category: any,
        itemIndex: number,
    ) => {
        setSectionDict((prevState: any) => {
            return {
                ...prevState,
                [oldSection]: (prevState[oldSection] || []).filter((item: any) => {
                    return (
                        item.serviceIndex !== serviceIndex &&
                        item.category !== category &&
                        item.itemIndex !== itemIndex
                    );
                }),
                [newSection]: [
                    ...prevState[newSection] || [],
                    {
                        serviceIndex: serviceIndex,
                        category: category,
                        itemIndex: itemIndex,
                    },
                ],
            }
        });
    }

    const removeItemFromSection = async (
        section: string,
        serviceIndex: number,
        category: any,
        itemIndex: number,
    ) => {
        setSectionDict((prevState: any) => {
            return {
                ...prevState,
                [section]: (prevState[section] || []).filter((item: any) => {
                    return (
                        item.serviceIndex !== serviceIndex ||
                        item.category !== category ||
                        item.itemIndex !== itemIndex
                    );
                })
            }
        });
    }

    const updateFabrication = async (
        newFabrication: IFabricationQuoteView,
        serviceIndex: number,
        category: string,
        itemIndex: number,
    ) => {
        setLoadingFields(true);
        const unique_id = buildUniqueId(serviceIndex, category);
        setLoadingFieldsDict((prevState: any) => {
            return {
                ...prevState,
                [serviceIndex]: true,
                [unique_id]: true,
            };
        });

        if (newFabrication.have_workforce !== undefined) {
            const stone_fields = productDict[unique_id][itemIndex].stone_fields;
            if (newFabrication.have_workforce === false) {
                updateProduct(
                    {
                        unit_price: stone_fields?.price_without_manufacture,
                    },
                    serviceIndex,
                    category,
                    itemIndex,
                );
            } else {
                updateProduct(
                    {
                        unit_price: stone_fields?.price_with_manufacture,
                    },
                    serviceIndex,
                    category,
                    itemIndex,
                );
            }
        }

        if (newFabrication.workforce_cost !== undefined) {
            let service_cost_delta = newFabrication.workforce_cost -
                (fabricationDict[unique_id][itemIndex].workforce_cost || 0);

            // setCostDict((prevState: any) => {
            //     return {
            //         ...prevState,
            //         [serviceIndex]: {
            //             ...prevState[serviceIndex],
            //             total_cost: prevState[serviceIndex].total_cost + service_cost_delta,
            //             [unique_id]: prevState[serviceIndex][unique_id] + service_cost_delta,
            //         },
            //     };
            // });

            // setQuoteCost((prevState: any) => {
            //     const service_type_cost_dict: ITypeCost = prevState[serviceDict[serviceIndex].serviceType];

            //     service_type_cost_dict.workforce_cost += service_cost_delta;
            //     service_type_cost_dict.company_cost = service_type_cost_dict.workforce_cost + service_type_cost_dict.material_cost;

            //     const quote_subtotal = prevState.subtotal_cost + service_cost_delta;
                
            //     return {
            //         ...prevState,
            //         [serviceDict[serviceIndex].serviceType]: service_type_cost_dict,
            //         subtotal_cost: quote_subtotal,
            //         tax_cost: quote_subtotal * (prevState.tax_percentage / 100 || 0),
            //         total_cost: quote_subtotal * (1 + (prevState.tax_percentage / 100 || 0)),
            //     };
            // });
        }

        setFabricationDict((prevState: any) => {
            const updatedFabrication = {
                ...prevState[unique_id][itemIndex],
                ...newFabrication,
            };

            updatePreviewItem(
                {
                    ...updatedFabrication,
                    ...productDict[unique_id][itemIndex],
                },
                serviceIndex,
                category,
                itemIndex,
            );

            return {
                ...prevState,
                [unique_id]: [
                    ...prevState[unique_id].slice(0, itemIndex),
                    updatedFabrication,
                    ...prevState[unique_id].slice(itemIndex + 1),
                ],
            };
        });
    }

    const addFabricationToCategory = (
        serviceIndex: number,
        category: string,
    ) => {
        const unique_id = buildUniqueId(serviceIndex, category);

        setFabricationDict((prevState: any) => {
            return {
                ...prevState,
                [unique_id]: [
                    ...prevState[unique_id],
                    {
                        unique_id: unique_id,
                        itemIndex: prevState[unique_id].length || 0,

                        have_workforce: true,
                        sq_ft: 0,
                        destination: 'countertop',
                        edge: '0',
                        finish: '0',
                        sink: 0,
                        workforce_cost: 0,
                    },
                ],
            }
        });
    }

    const deleteFabrication = async (
        serviceIndex: number,
        category: string,
        itemIndex: number,
        with_cost_delete: boolean = true,
    ) => {
        const unique_id = buildUniqueId(serviceIndex, category);

        if (with_cost_delete) {
            const service_workforce_cost_delta = -(fabricationDict[unique_id][itemIndex].workforce_cost || 0);
            
            // setCostDict((prevState: any) => {
            //     return {
            //         ...prevState,
            //         [serviceIndex]: {
            //             ...prevState[serviceIndex],
            //             total_cost: prevState[serviceIndex].total_cost + service_workforce_cost_delta,
            //             [unique_id]: prevState[serviceIndex][unique_id] + service_workforce_cost_delta,
            //         },
            //     };
            // });

            // setQuoteCost((prevState: any) => {
            //     const service_type_cost_dict: ITypeCost = prevState[serviceDict[serviceIndex].serviceType];

            //     service_type_cost_dict.workforce_cost += service_workforce_cost_delta;
            //     service_type_cost_dict.company_cost = service_type_cost_dict.workforce_cost + service_type_cost_dict.material_cost;

            //     const quote_subtotal = prevState.subtotal_cost + service_workforce_cost_delta;
                
            //     return {
            //         ...prevState,
            //         [serviceDict[serviceIndex].serviceType]: service_type_cost_dict,
            //         subtotal_cost: quote_subtotal,
            //         tax_cost: quote_subtotal * (prevState.tax_percentage / 100 || 0),
            //         total_cost: quote_subtotal * (1 + (prevState.tax_percentage / 100 || 0)),
            //     };
            // });
        }

        setFabricationDict((prevState: any) => {
            return {
                ...prevState,
                [unique_id]: prevState[unique_id].filter((item: any) => {
                    return item.itemIndex !== itemIndex;
                }),
            };
        });
    }

    const updateTask = async (
        newTask: ITaskQuoteView,
        serviceIndex: number,
        category: string,
        itemIndex: number,
    ) => {
        setLoadingFields(true);
        const unique_id = buildUniqueId(serviceIndex, category);
        setLoadingFieldsDict((prevState: any) => {
            return {
                ...prevState,
                [serviceIndex]: true,
                [unique_id]: true,
            };
        });

        if (newTask.hours !== undefined || newTask.hour_cost !== undefined) {
            let service_cost_delta: number;
            if (newTask.hours !== undefined) {
                service_cost_delta = (taskDict[unique_id][itemIndex].hour_cost || 0) *
                    ((newTask.hours || 0) - (taskDict[unique_id][itemIndex].hours || 0));
            } else {
                service_cost_delta = (taskDict[unique_id][itemIndex].hours || 0) *
                    ((newTask.hour_cost || 0) - (taskDict[unique_id][itemIndex].hour_cost || 0));
            }

            // setCostDict((prevState: any) => {
            //     return {
            //         ...prevState,
            //         [serviceIndex]: {
            //             ...prevState[serviceIndex],
            //             total_cost: prevState[serviceIndex].total_cost + service_cost_delta,
            //             [unique_id]: prevState[serviceIndex][unique_id] + service_cost_delta,
            //         },
            //     };
            // });

            // setQuoteCost((prevState: any) => {
            //     const service_type_cost_dict: ITypeCost = prevState[serviceDict[serviceIndex].serviceType];
            //     const old_service_type_total = prevState[serviceDict[serviceIndex].serviceType].company_cost * (1 + (service_type_cost_dict.profit_percentage || 0) / 100);

            //     service_type_cost_dict.workforce_cost = service_type_cost_dict.workforce_cost + service_cost_delta;
            //     service_type_cost_dict.company_cost = service_type_cost_dict.material_cost + service_type_cost_dict.workforce_cost;

            //     const new_service_type_total = service_type_cost_dict.company_cost * (1 + (service_type_cost_dict.profit_percentage || 0) / 100);
            //     const quote_subtotal = prevState.subtotal_cost + (new_service_type_total - old_service_type_total);

            //     return {
            //         ...prevState,
            //         [serviceDict[serviceIndex].serviceType]: service_type_cost_dict,
            //         subtotal_cost: quote_subtotal,
            //         tax_cost: quote_subtotal * (prevState.tax_percentage / 100 || 0),
            //         total_cost: quote_subtotal * (1 + (prevState.tax_percentage / 100 || 0)),
            //     };
            // });
        }

        setTaskDict((prevState: any) => {
            return {
                ...prevState,
                [unique_id]: [
                    ...prevState[unique_id].slice(0, itemIndex),
                    {
                        ...prevState[unique_id][itemIndex],
                        ...newTask,
                    },
                    ...prevState[unique_id].slice(itemIndex + 1),
                ],
            };
        });
    }

    const addTaskToCategory = (
        serviceIndex: number,
        category: string,
    ) => {
        const unique_id = buildUniqueId(serviceIndex, category);

        setTaskDict((prevState: any) => {
            return {
                ...prevState,
                [unique_id]: [
                    ...prevState[unique_id] || [],
                    {
                        unique_id: unique_id,
                        itemIndex: prevState[unique_id].length || 0,

                        task_name: '',
                        hours: 0,
                        hour_cost: 0,
                        total_cost: 0,
                    }
                ]
            };
        });
    }

    const deleteTask = async (
        serviceIndex: number,
        category: string,
        itemIndex: number,
    ) => {
        const unique_id = buildUniqueId(serviceIndex, category);

        const service_cost_delta = (taskDict[unique_id][itemIndex].hour_cost || 0) * (taskDict[unique_id][itemIndex].hours || 0);

        // setCostDict((prevState: any) => {
        //     return {
        //         ...prevState,
        //         [serviceIndex]: {
        //             ...prevState[serviceIndex],
        //             total_cost: prevState[serviceIndex].total_cost - service_cost_delta,
        //             [unique_id]: prevState[serviceIndex][unique_id] - service_cost_delta,
        //         },
        //     };
        // });

        // setQuoteCost((prevState: any) => {
        //     const service_type_cost_dict: ITypeCost = prevState[serviceDict[serviceIndex].serviceType];
        //     const old_service_type_total = prevState[serviceDict[serviceIndex].serviceType].company_cost * (1 + (service_type_cost_dict.profit_percentage || 0) / 100);

        //     service_type_cost_dict.workforce_cost = service_type_cost_dict.workforce_cost - service_cost_delta;
        //     service_type_cost_dict.company_cost = service_type_cost_dict.material_cost + service_type_cost_dict.workforce_cost;

        //     const new_service_type_total = service_type_cost_dict.company_cost * (1 + (service_type_cost_dict.profit_percentage || 0) / 100);
        //     const quote_subtotal = prevState.subtotal_cost + (new_service_type_total - old_service_type_total);

        //     return {
        //         ...prevState,
        //         [serviceDict[serviceIndex].serviceType]: service_type_cost_dict,
        //         subtotal_cost: quote_subtotal,
        //         tax_cost: quote_subtotal * (prevState.tax_percentage / 100 || 0),
        //         total_cost: quote_subtotal * (1 + (prevState.tax_percentage / 100 || 0)),
        //     };
        // });

        setTaskDict((prevState: any) => {
            return {
                ...prevState,
                [unique_id]: [
                    ...prevState[unique_id].slice(0, itemIndex),
                    ...prevState[unique_id].slice(itemIndex + 1),
                ],
            };
        });
    }

    const updateMeasure = async (
        newMeasure: any,
        serviceIndex: number,
        category: string,
        itemIndex: number,
        measureIndex: number,
    ) => {
        setLoadingFields(true);
        const unique_id = buildUniqueId(serviceIndex, category);
        setLoadingFieldsDict((prevState: any) => {
            return {
                ...prevState,
                [serviceIndex]: true,
                [unique_id]: true,
                [`${unique_id}_${itemIndex}`]: true,
            };
        });

        setMeasureDict((prevState: any) => {
            if (newMeasure.sheets) {
                const newProductChanges = {
                    quantity: (prevState[unique_id][itemIndex].reduce(
                        (subTotal: number, measure: any) => 
                            subTotal + (parseFloat(measure.sheets || 0))
                        , 0)
                    ) - prevState[unique_id][itemIndex][measureIndex].sheets + parseFloat(newMeasure.sheets),
                };

                updateProduct(newProductChanges, serviceIndex, category, itemIndex);
            }

            if (newMeasure.sq_ft !== undefined || newMeasure.quantity !== undefined || newMeasure.sq_ft !== null || newMeasure.quantity !== null) {
                let newFabricationChanges: any = {
                    sq_ft: (prevState[unique_id][itemIndex].reduce(
                        (subTotal: number, measure: any) =>
                            subTotal + (parseFloat(measure.sq_ft || 0) * (measure.quantity || 0))
                        , 0)
                    )
                    - (prevState[unique_id][itemIndex][measureIndex].sq_ft * prevState[unique_id][itemIndex][measureIndex].quantity)
                    + (
                        (parseFloat(newMeasure.sq_ft) || prevState[unique_id][itemIndex][measureIndex].sq_ft)
                        * (parseFloat(newMeasure.quantity) || prevState[unique_id][itemIndex][measureIndex].quantity)
                    )
                    ,
                };

                if (isFinite(newFabricationChanges.sq_ft)) {
                    newFabricationChanges = calcQuoteViewWorkforceCost({
                        ...fabricationDict[unique_id][itemIndex],
                        ...newFabricationChanges,
                    });
                    
                    updateFabrication(newFabricationChanges, serviceIndex, category, itemIndex);
                }
            }

            return {
                ...prevState,
                [unique_id]: {
                    ...prevState[unique_id],
                    [itemIndex]: [
                        ...prevState[unique_id][itemIndex].slice(0, measureIndex),
                        {
                            ...prevState[unique_id][itemIndex][measureIndex],
                            ...newMeasure,
                        },
                        ...prevState[unique_id][itemIndex].slice(measureIndex + 1),
                    ],
                },
            };
        });
    }

    const deleteMeasure = async (
        serviceIndex: number,
        category: string,
        itemIndex: number,
        measureIndex: number,
    ) => {
        const unique_id = buildUniqueId(serviceIndex, category);

        setMeasureDict((prevState: any) => {
            const item: IProductExplodedView = {
                ...productDict[unique_id][itemIndex],
                measures: measureDict[unique_id][itemIndex],
            };

            
            const newProductChanges = {
                quantity:
                    Math.ceil((item.measures || []).reduce(
                        (subTotal: number, measure: any) =>
                            subTotal + (parseFloat(measure.sheets || 0)),
                        0)) -
                    (prevState[unique_id][itemIndex][measureIndex].sheets || 0),
            };

            updateProduct(newProductChanges, serviceIndex, category, itemIndex);

            const fabricationList = fabricationDict[unique_id];
            if (fabricationList) {

                const newFabricationChanges = {
                    sq_ft:
                        (Math.ceil((item.measures || []).reduce(
                            (subTotal: number, measure: any) =>
                                subTotal + (parseFloat(measure.sq_ft || 0) * (measure.quantity || 0))
                            , 0))
                        ) -
                        (prevState[unique_id][itemIndex][measureIndex].sq_ft || 0) *
                        (prevState[unique_id][itemIndex][measureIndex].quantity || 0),
                };

                updateFabrication(newFabricationChanges, serviceIndex, category, itemIndex);
            }


            return {
                ...prevState,
                [unique_id]: {
                    ...prevState[unique_id],
                    [itemIndex]: [
                        ...prevState[unique_id][itemIndex].slice(0, measureIndex),
                        ...prevState[unique_id][itemIndex].slice(measureIndex + 1),
                    ],
                },
            };
        });
    }

    const addMeasureToItem = (
        serviceIndex: number,
        category: string,
        itemIndex: number,
        serviceType: string,
    ) => {
        const unique_id = buildUniqueId(serviceIndex, category);

        setMeasureDict((prevState: any) => {
            return {
                ...prevState,
                [unique_id]: {
                    ...prevState[unique_id],
                    [itemIndex]: [
                        ...prevState[unique_id][itemIndex] || [],
                        (serviceType === "stone" ? {
                            unique_id: unique_id,
                            itemIndex: itemIndex,
                            measureIndex: prevState[unique_id][itemIndex]?.length || 0,

                            width: 0,
                            length: 0,
                            quantity: 0,
                            sq_ft: 0,
                            description: "",
                            sheets: 0,
                        } : {
                            unique_id: unique_id,
                            itemIndex: itemIndex,
                            measureIndex: prevState[unique_id][itemIndex]?.length || 0,
                            
                            measure: "",
                            quantity: 0,
                            description: "",
                            sheets: 0,
                        }),
                    ],
                },
            };
        });
    }

    const removeMeasureFromItem = async (
        serviceIndex: number,
        category: string,
        itemIndex: number,
    ) => {
        const unique_id = buildUniqueId(serviceIndex, category);

        setMeasureDict((prevState: any) => {
            return {
                ...prevState,
                [unique_id]: {
                    ...prevState[unique_id],
                    [itemIndex]: [],
                },
            };
        });
    }

    const updateService = async (
        newService: IService,
        serviceIndex: number,
    ) => {
        setServiceDict((prevState: any) => {
            return {
                ...prevState,
                [serviceIndex]: {
                    ...prevState[serviceIndex],
                    ...newService,
                },
            };
        });
    }

    const deleteService = async ( ) => {
        if (selectedDeleteService === null) return;
        const serviceIndex = Number(selectedDeleteService);
        setSelectedDeleteService(null);

        const oldProductDict: {[id: string]: IProductQuoteView[]} = {...productDict};
        const oldSectionDict: ISectionDict = {...sectionDict};
        const oldFabricationDict: {[id: string]: IFabricationQuoteView[]} = {...fabricationDict};
        const oldTaskDict: {[id: string]: ITaskQuoteView[]} = {...taskDict};
        const oldMeasureDict: {[id: string]: {[itemIndex: number]: IMeasureExplodedView[]}} = {...measureDict};
        const oldServiceDict: {[serviceIndex: string]: IService} = {...serviceDict};
        const oldCostDict: {[id: string]: IServiceCost} = {...costDict};
        const oldPreviewDict: {[id: string]: IProductPreviewView[]} = {...previewDict};
        const oldFolderDict: {[id: string]: IServiceFolder} = {...folderDict};

        const newProductDict: {[id: string]: IProductQuoteView[]} = {...productDict};
        const newSectionDict: ISectionDict = {};
        const newFabricationDict: {[id: string]: IFabricationQuoteView[]} = {...fabricationDict};
        const newTaskDict: {[id: string]: ITaskQuoteView[]} = {...taskDict};
        const newMeasureDict: {[id: string]: {[itemIndex: number]: IMeasureExplodedView[]}} = {...measureDict};
        const newServiceDict: {[serviceIndex: string]: IService} = {...serviceDict};
        const newCostDict: {[id: string]: IServiceCost} = {...costDict};
        const newQuoteCost: IQuoteCost = {...quoteCost};
        const newPreviewDict: {[id: string]: IProductPreviewView[]} = {...previewDict};
        const newFolderDict: {[id: string]: IServiceFolder} = {...folderDict};

        const serviceType = serviceDict[serviceIndex].serviceType;
        let workforce = 0;
        let material = 0;

        Object.entries(oldProductDict).forEach(
            ([id, item]: [string, IProductQuoteView[]]) => {
                const itemServiceIndex = Number(splitUniqueId(id)[0]);
                const itemCategory = splitUniqueId(id)[1];

                if (itemServiceIndex === serviceIndex) {
                    material += item.reduce(
                        (subTotal: number, product: IProductQuoteView) =>
                        subTotal + ((product.quantity || 0) * (product.unit_price || 0))
                    , 0);

                    delete newProductDict[id];
                } else if (itemServiceIndex > serviceIndex) {
                    const newUniqueId = buildUniqueId(itemServiceIndex - 1, itemCategory);
                    newProductDict[newUniqueId] =
                        item.map((item: IProductQuoteView) => {
                            return {
                                ...item,
                                unique_id: newUniqueId,
                            }
                        });
                    delete newProductDict[id];
                } else {
                    newProductDict[id] = item;
                }
            }
        );
        
        Object.entries(oldSectionDict).forEach(
            ([section, item]: [string, IItemObject[]]) => {
                newSectionDict[section] =
                    item
                        .filter((itemFiltred: IItemObject) =>
                            itemFiltred.serviceIndex !== serviceIndex)
                        .map((itemMapped: IItemObject) => {
                            return {
                                ...itemMapped,
                                serviceIndex: Number(itemMapped.serviceIndex) > Number(serviceIndex) ?
                                    Number(itemMapped.serviceIndex) - 1 :
                                    Number(itemMapped.serviceIndex),
                            };
                        });
            }
        );

        Object.entries(oldFabricationDict).forEach(
            ([id, item]: [string, IFabricationQuoteView[]]) => {
                const itemServiceIndex = Number(splitUniqueId(id)[0]);
                const itemCategory = splitUniqueId(id)[1];

                if (itemServiceIndex === serviceIndex) {
                    workforce += item.reduce(
                        (subTotal: number, fabrication: IFabricationQuoteView) =>
                        subTotal + ((fabrication.workforce_cost || 0))
                    , 0);
                    delete newFabricationDict[id];
                } else if (itemServiceIndex > serviceIndex) {
                    const newUniqueId = buildUniqueId(itemServiceIndex - 1, itemCategory);
                    newFabricationDict[newUniqueId] =
                        item.map((item: IFabricationQuoteView) => {
                            return {
                                ...item,
                                unique_id: newUniqueId,
                            }
                        });
                    delete newFabricationDict[id];
                } else {
                    newFabricationDict[id] = item;
                }
            }
        );

        Object.entries(oldTaskDict).forEach(
            ([id, item]: [string, ITaskQuoteView[]]) => {
                const itemServiceIndex = Number(splitUniqueId(id)[0]);
                const itemCategory = splitUniqueId(id)[1];

                if (itemServiceIndex === serviceIndex) {
                    workforce += item.reduce(
                        (subTotal: number, task: ITaskQuoteView) =>
                        subTotal + ((task.hours || 0) * (task.hour_cost || 0))
                    , 0);

                    delete newTaskDict[id];
                } else if (itemServiceIndex > serviceIndex) {
                    const newUniqueId = buildUniqueId(itemServiceIndex - 1, itemCategory);
                    newTaskDict[newUniqueId] =
                        item.map((item: ITaskQuoteView) => {
                            return {
                                ...item,
                                unique_id: newUniqueId,
                            }
                        });
                    delete newTaskDict[id];
                } else {
                    newTaskDict[id] = item;
                }
            }
        );

        Object.entries(oldMeasureDict).forEach(
            ([id, item]: [string, {[itemIndex: number]: IMeasureExplodedView[]}]) => {
                const itemServiceIndex = Number(splitUniqueId(id)[0]);
                const itemCategory = splitUniqueId(id)[1];

                if (itemServiceIndex === serviceIndex) {
                    delete newMeasureDict[id];
                } else if (itemServiceIndex > serviceIndex) {
                    const newUniqueId = buildUniqueId(itemServiceIndex - 1, itemCategory);
                    newMeasureDict[newUniqueId] = {};
                    Object.entries(item).forEach(
                        ([itemIndex, measures]: [any, IMeasureExplodedView[]]) => {
                            newMeasureDict[newUniqueId][Number(itemIndex)] =
                                measures.map((measure: IMeasureExplodedView) => {
                                    return {
                                        ...measure,
                                        unique_id: newUniqueId,
                                    }
                                });
                        }
                    );
                    delete newMeasureDict[id];
                } else {
                    newMeasureDict[id] = item;
                }
            }
        );

        Object.entries(oldServiceDict).forEach(
            ([id, item]: [string, IService]) => {
                const itemServiceIndex = Number(id);

                if (itemServiceIndex === serviceIndex) {
                    delete newServiceDict[id];
                } else if (itemServiceIndex > serviceIndex) {
                    const newServiceIndex = itemServiceIndex - 1;
                    newServiceDict[newServiceIndex] = {
                        ...item,
                        serviceIndex: newServiceIndex,
                    };
                    delete newServiceDict[id];
                } else {
                    newServiceDict[id] = item;
                }
            }
        );

        Object.entries(oldCostDict).forEach(
            ([id, item]: [string, IServiceCost]) => {
                if (Number(id) === serviceIndex) {
                    delete newCostDict[id];
                } else if (Number(id) > serviceIndex) {
                    const newServiceIndex = Number(id) - 1;
                    let category_ids = {};
                    
                    if (item.serviceType === 'stone') {
                        category_ids = {
                            [buildUniqueId(newServiceIndex, 'stone')]: item[buildUniqueId(Number(id), 'stone')],
                        }
                    } else if (item.serviceType === 'millwork' || item.serviceType === 'remodeling') {
                        category_ids = {
                            [buildUniqueId(newServiceIndex, 'hardware')]: item[buildUniqueId(Number(id), 'hardware')],
                            [buildUniqueId(newServiceIndex, 'paint')]: item[buildUniqueId(Number(id), 'paint')],
                            [buildUniqueId(newServiceIndex, 'panel')]: item[buildUniqueId(Number(id), 'panel')],
                            [buildUniqueId(newServiceIndex, 'workforce')]: item[buildUniqueId(Number(id), 'workforce')],
                        };
                    }

                    newCostDict[newServiceIndex] = {
                        ...category_ids,
                        serviceIndex: newServiceIndex,
                        percentage: item.percentage,
                        serviceType: item.serviceType,
                        total_cost: item.total_cost,
                    };
                    delete newCostDict[id];
                } else {
                    newCostDict[id] = item;
                }
            }
        );

        const newQuoteTypeCost: any = newQuoteCost[serviceType];
        const oldQuoteTypeCost: any = {...newQuoteTypeCost};
        newQuoteTypeCost.material_cost -= material;
        newQuoteTypeCost.workforce_cost -= workforce;
        newQuoteTypeCost.company_cost = newQuoteTypeCost.material_cost + newQuoteTypeCost.workforce_cost;
        newQuoteCost[serviceType] = newQuoteTypeCost;

        newQuoteCost.subtotal_cost = (newQuoteCost.subtotal_cost || 0) -
            oldQuoteTypeCost.company_cost * (1 + (oldQuoteTypeCost.profit_percentage || 0) / 100 ) +
            newQuoteTypeCost.company_cost * (1 + (newQuoteTypeCost.profit_percentage || 0) / 100 );

        newQuoteCost.tax_cost = (newQuoteCost.tax_percentage || 0) / 100 *
            newQuoteCost.subtotal_cost;
        
        newQuoteCost.total_cost = newQuoteCost.subtotal_cost + newQuoteCost.tax_cost;

        Object.entries(oldPreviewDict).forEach(
            ([id, item]: [string, IProductPreviewView[]]) => {
                const itemServiceIndex = Number(splitUniqueId(id)[0]);
                const itemCategory = splitUniqueId(id)[1];

                if (itemServiceIndex === serviceIndex) {
                    delete newPreviewDict[id];
                } else if (itemServiceIndex > serviceIndex) {
                    const newUniqueId = buildUniqueId(itemServiceIndex - 1, itemCategory);
                    newPreviewDict[newUniqueId] =
                        item.map((item: IProductPreviewView) => {
                            return {
                                ...item,
                                unique_id: newUniqueId,
                            }
                        });
                    delete newPreviewDict[id];
                } else {
                    newPreviewDict[id] = item;
                }
            }
        );

        Object.entries(oldFolderDict).forEach(
            ([id, item]: [string, IServiceFolder]) => {
                const itemServiceIndex = Number(id);

                if (itemServiceIndex === serviceIndex) {
                    delete newFolderDict[id];
                } else if (itemServiceIndex > serviceIndex) {
                    const newServiceIndex = itemServiceIndex - 1;
                    newFolderDict[newServiceIndex] = {
                        ...item,
                        serviceIndex: newServiceIndex,
                    };
                    delete newFolderDict[id];
                } else {
                    newFolderDict[id] = item;
                }
            }
        );

        unstable_batchedUpdates(() => {
            setServiceDict(newServiceDict);
            setMeasureDict(newMeasureDict);
            setProductDict(newProductDict);
            setFolderDict(newFolderDict);
            setPreviewDict(newPreviewDict);
            setSectionDict(newSectionDict);
            setFabricationDict(newFabricationDict);
            setTaskDict(newTaskDict);
            setCostDict(newCostDict);
            setQuoteCost(newQuoteCost);
        })
    }

    const addServiceToQuote = (
        serviceName: string,
        serviceType: string,
    ) => {
        const serviceIndex = Object.keys(serviceDict).length;

        let category_ids_numbers = {};
        let category_ids_material = {};
        let category_ids_workforce = {};

        if (serviceType === 'stone') {
            category_ids_numbers = {
                [buildUniqueId(serviceIndex, 'stone')]: 0,
            };

            category_ids_material = {
                [buildUniqueId(serviceIndex, 'stone')]: [],
            };

            setFabricationDict((prevState: any) => {
                return {
                    ...prevState,
                    ...category_ids_material,
                };
            });

            setMeasureDict((prevState: any) => {
                return {
                    ...prevState,
                    [buildUniqueId(serviceIndex, 'stone')]: {},
                };
            });

            setPreviewDict((prevState: any) => {
                return {
                    ...prevState,
                    [buildUniqueId(serviceIndex, 'stone')]: [],
                };
            });

        } else if (serviceType === 'millwork' || serviceType === 'remodeling') {
            category_ids_numbers = {
                [buildUniqueId(serviceIndex, 'hardware')]: 0,
                [buildUniqueId(serviceIndex, 'paint')]: 0,
                [buildUniqueId(serviceIndex, 'panel')]: 0,
                [buildUniqueId(serviceIndex, 'workforce')]: 0,
            };

            category_ids_material = {
                [buildUniqueId(serviceIndex, 'hardware')]: [],
                [buildUniqueId(serviceIndex, 'paint')]: [],
                [buildUniqueId(serviceIndex, 'panel')]: [],
            };

            category_ids_workforce = {
                [buildUniqueId(serviceIndex, 'workforce')]: [],
            };

            setTaskDict((prevState: any) => {
                return {
                    ...prevState,
                    ...category_ids_workforce,
                };
            });

            setMeasureDict((prevState: any) => {
                return {
                    ...prevState,
                    [buildUniqueId(serviceIndex, 'panel')]: {},
                };
            });

            setPreviewDict((prevState: any) => {
                return {
                    ...prevState,
                    ...category_ids_material,
                };
            });
        };

        setCostDict((prevState: {[id: string]: IServiceCost}) => {
            return {
                ...prevState,
                [serviceIndex]: {
                    ...category_ids_numbers,
                    serviceIndex,
                    serviceType: serviceType.toLowerCase(),
                    percentage: 0,
                    total_cost: 0,
                }
            };
        });

        setProductDict((prevState: any) => {
            return {
                ...prevState,
                ...category_ids_material,
            };
        });

        // setPreviewDict((prevState: any) => {
        //     return {
        //         ...prevState,
        //         [serviceIndex]: [],
        //     };
        // });

        setFolderDict((prevState: any) => {
            return {
                ...prevState,
                [serviceIndex]: {
                    serviceIndex,
                    name: serviceName,
                    serviceType: serviceType.toLowerCase(),
                    folder_id: 0,
                },
            };
        });

        setServiceDict((prevState: {[id: string]: IService}) => {
            return {
                ...prevState,
                [serviceIndex]: {
                    serviceIndex,
                    name: serviceName,
                    serviceType: serviceType.toLowerCase(),
                    collapseService: false,
                    collapseSections: true,
                }
            };
        });
    }

    const updateQuoteCostType = (
        newValues: ITypeCost,
        serviceType: string,
    ) => {
        if (newValues.profit_percentage !== undefined) {
            setCostDict((prevState: {[id: string]: IServiceCost}) => {
                const newCostDict: {[id: string]: IServiceCost} = {}
                
                Object.keys(prevState).forEach((serviceIndex: string) => {
                    if (prevState[serviceIndex].serviceType === serviceType) {
                        newCostDict[serviceIndex] = {
                            ...prevState[serviceIndex],
                            percentage: newValues.profit_percentage
                        };
                    } else {
                        newCostDict[serviceIndex] = prevState[serviceIndex];
                    }
                });
                
                return newCostDict;
            });
            
            setPendingUpdateCostDict(true);

            return;
        }

        setQuoteCost((prevState: any) => {
            const oldQuoteTypeCost: ITypeCost = prevState[serviceType];
            const newQuoteTypeCost: ITypeCost = {
                ...prevState[serviceType],
                ...newValues,
            };

            const subtotal_cost = prevState.subtotal_cost -
                oldQuoteTypeCost.company_cost * (1 + (oldQuoteTypeCost.profit_percentage || 0) / 100) +
                newQuoteTypeCost.company_cost * (1 + (newQuoteTypeCost.profit_percentage || 0) / 100);
            
            return {
                ...prevState,
                [serviceType]: newQuoteTypeCost,
                subtotal_cost: subtotal_cost,
                tax_cost: subtotal_cost * (prevState.tax_percentage || 0) / 100,
                total_cost: subtotal_cost * (1 + (prevState.tax_percentage || 0) / 100),
            };
        });
    }

    const updateQuoteCostValue = (
        newValues: any,
    ) => {
        setQuoteCost((prevState: any) => {
            const newQuoteCost = {
                ...prevState,
                ...newValues,
            };

            if (newValues.have_tax !== undefined) {
                if (newValues.have_tax === false)
                    newQuoteCost.total_cost = newQuoteCost.subtotal_cost;
                else
                    newQuoteCost.total_cost = newQuoteCost.subtotal_cost * (1 + (newQuoteCost.tax_percentage || 0) / 100);
            }

            return newQuoteCost;
        });
    }

    const updatePreviewItem = (
        itemChanges: any,
        serviceIndex: number,
        category: string,
        itemIndex: number,
    ) => {
        const unique_id = buildUniqueId(serviceIndex, category);

        let newPreview: IProductPreviewView = {};

        if (itemChanges.quote_text) {
            newPreview = {
                ...itemChanges,
            };
        } else {
            if (itemChanges.product_name && itemChanges.section) {
                newPreview.product_name = itemChanges.product_name;
                newPreview.section = itemChanges.section;

                if (category === 'stone') {
                    newPreview.quote_text = "To manufacture, finish and install " + itemChanges.section + " with the following specifications: \n" +
                        "Stone: " + itemChanges.product_name + "\n" +
                        "Area: " + itemChanges.sq_ft + "\n" +
                        "Edge: " + itemChanges.edge + "\n" +
                        "Finish: " + itemChanges.finish + "\n" +
                        "Sink cut out: " + itemChanges.sink;
                } else if (category === 'hardware') {
                    newPreview.quote_text = itemChanges.section +": " + (itemChanges.quantity || 0) + ' ' + itemChanges.product_name;
                } else if (category === 'paint') {
                    newPreview.quote_text = "Spray " + itemChanges.section + " in " + itemChanges.product_name;
                } else if (category === 'panel') {
                    newPreview.quote_text = "Manufacture " + itemChanges.section + " in " + itemChanges.product_name;
                }
            } else {
                newPreview = {
                    section: '',
                    product_name: '',
                    quote_text: '',
                };
            }
        }

        setPreviewDict((prevState: any) => {
            return {
                ...prevState,
                [unique_id]: [
                    ...prevState[unique_id].slice(0, itemIndex),
                    {
                        ...prevState[unique_id][itemIndex],
                        ...newPreview,
                    },
                    ...prevState[unique_id].slice(itemIndex + 1),
                ]
            }
        });
    }

    const updateQuoteInfo = (
        quoteInfo: IQuoteInfo,
    ) => {
        setQuoteInfo(quoteInfo);
    }

    const fixNumberValue = (
        value: any,
        canDecimal = true,
        canNegative = true,
        canZero = true,
    ): Number | string => {

        //if value start with 0 and after has a number, remove 0
        if (value[0] === "0" && value[1] !== ".") value = value.slice(1);
        else if (canDecimal && value[0] === ".") value = "0" + value;

        if (!canNegative) {
            if (value[0] === "-") value = value.slice(1);
        }

        let formatRegex = /^\d*$/;
        if (canDecimal) formatRegex = /^\d*\.?\d*$/;

        if (!formatRegex.test(value)) return 0;

        //if value is empty set 0
        if (value === "") {
            if (canZero) value = 0;
            else value = 1;
        }

        if (Number(value).toString() === "NaN" || Number(value).toString() === "Infinity") return canZero ? 0 : 1;

        if (Number(value).toString() === value.toString()) return Number(value);

        return value;
    };

    const calcQuoteViewWorkforceCost = (item: IFabricationQuoteView): IFabricationQuoteView => {
        const sink = Number(item.sink) * 500;
        const workforce_per_sq_ft = item.destination === "countertop" ? 75 : 45;
        const workforce_cost = workforce_per_sq_ft * (item.sq_ft || 0) + sink;

        return {
            ...item,
            workforce_cost,
        };
    }

    const getAlternateView = (currentView: string) => {
        if (currentView == "Exploded") {
            return 'Quote';
        } else {
            return 'Exploded';
        }
    }

    useEffect(() => {
        if (!loadingFields) {
            setLoadingFieldsDict({});
        }
    }, [loadingFields]);

    /*=================================================
    DUPLICATE QUOTE
    =================================================*/

    useEffect(() => {
        if (selectedDuplicateService !== null) {
            const { serviceType } = serviceDict[selectedDuplicateService.oldServiceIndex];
            const { newServiceIndex, oldServiceIndex } = selectedDuplicateService;
            
            if (serviceType === 'stone') {
                if (
                    productDict[buildUniqueId(newServiceIndex, 'stone')] &&
                    productDict[buildUniqueId(newServiceIndex, 'stone')].length !== undefined
                ) {
                    addProductsToDuplicate();
                }

                if (
                    fabricationDict[buildUniqueId(newServiceIndex, 'stone')] &&
                    fabricationDict[buildUniqueId(newServiceIndex, 'stone')].length !== undefined
                ) {
                    addFabricationsToDuplicate();
                }
            } else if (serviceType === 'millwork' || serviceType === 'remodeling') {
                if (
                    productDict[buildUniqueId(newServiceIndex, 'hardware')] &&
                    productDict[buildUniqueId(newServiceIndex, 'hardware')].length !== undefined &&
                    productDict[buildUniqueId(newServiceIndex, 'paint')] &&
                    productDict[buildUniqueId(newServiceIndex, 'paint')].length !== undefined &&
                    productDict[buildUniqueId(newServiceIndex, 'panel')] &&
                    productDict[buildUniqueId(newServiceIndex, 'panel')].length !== undefined
                ) {
                    addProductsToDuplicate();
                }

                if (
                    taskDict[buildUniqueId(newServiceIndex, 'workforce')] &&
                    taskDict[buildUniqueId(newServiceIndex, 'workforce')].length !== undefined
                ) {
                    addTasksToDuplicate();
                }
            }
        }
    }, [productDict, fabricationDict, taskDict]);

    useEffect(() => {
        if (selectedDuplicateService === null) {
            setDuplicateCompletedSteps({products: false, task: false, fabrication: false});
        }
    }, [selectedDuplicateService]);

    useEffect(() => {
        if (selectedDuplicateService !== null) {
            const { serviceType } = serviceDict[selectedDuplicateService.oldServiceIndex];

            if (serviceType === 'stone') {
                if (duplicateCompletedSteps.fabrication && duplicateCompletedSteps.products) {
                    setSelectedDuplicateService(null);
                }
            } else if (serviceType === 'millwork' || serviceType === 'remodeling') {
                if (duplicateCompletedSteps.task && duplicateCompletedSteps.products) {
                    setSelectedDuplicateService(null);
                }
            }
        }
    }, [duplicateCompletedSteps]);

    const duplicateService = async (serviceIndex: number) => {
        const serviceToCopy: IService = serviceDict[serviceIndex];

        const newServiceIndex: number = Object.keys(serviceDict).length;
        const serviceType: string = serviceToCopy.serviceType;

        setSelectedDuplicateService({
            oldServiceIndex: serviceIndex,
            newServiceIndex: newServiceIndex,
        });

        addServiceToQuote(serviceToCopy.name + '_copy', serviceType);
    };

    const addProductsToDuplicate = () => {
        if (selectedDuplicateService === null) return;

        const { newServiceIndex, oldServiceIndex } = selectedDuplicateService;
        const { serviceType } = serviceDict[oldServiceIndex];

        let category_ids_products: {category: string, category_id: string, products: IProductQuoteView[]}[] = [];

        if (serviceType === 'stone') {
            category_ids_products.push({
                category: 'stone',
                category_id: buildUniqueId(newServiceIndex, 'stone'),
                products: productDict[buildUniqueId(oldServiceIndex, 'stone')],
            });
        } else if (serviceType === 'millwork' || serviceType === 'remodeling') {
            category_ids_products.push({
                category: 'hardware',
                category_id: buildUniqueId(newServiceIndex, 'hardware'),
                products: productDict[buildUniqueId(oldServiceIndex, 'hardware')],
            });
            category_ids_products.push({
                category: 'paint',
                category_id: buildUniqueId(newServiceIndex, 'paint'),
                products: productDict[buildUniqueId(oldServiceIndex, 'paint')],
            });
            category_ids_products.push({
                category: 'panel',
                category_id: buildUniqueId(newServiceIndex, 'panel'),
                products: productDict[buildUniqueId(oldServiceIndex, 'panel')],
            });
        }

        category_ids_products.forEach(({category, category_id, products, ...others}) => {
            for (let itemIndex: number = 0; itemIndex < products.length; itemIndex++) {
                const product = productDict[category_id].find((product: IProductQuoteView) => product.itemIndex === itemIndex);
                
                if (!product) {
                    addProductToCategory(
                        newServiceIndex,
                        category
                    );
                }
            }
        });

        category_ids_products.forEach(({category, category_id, products}) => {
            for (let itemIndex: number = 0; itemIndex < products.length; itemIndex++) {
                const productToCopy = products.find((product: IProductQuoteView) => product.itemIndex === itemIndex);
                const product = productDict[category_id].find((product: IProductQuoteView) => product.itemIndex === itemIndex);

                if (productToCopy && product && product.product_name === '') {
                    updateProduct(
                        {
                            ...productToCopy,
                            itemIndex: product.itemIndex,
                            unique_id: product.unique_id
                        },
                        newServiceIndex,
                        category,
                        itemIndex
                    );

                    if (products.length - 1 === itemIndex) {
                        setDuplicateCompletedSteps((prevState: any) => {
                            return {
                                ...prevState,
                                products: true
                            };
                        });
                    }
                }
            }
            
            if (products.length === 0) {
                setDuplicateCompletedSteps((prevState: any) => {
                    return {
                        ...prevState,
                        products: true
                    };
                });
            }
        });
    };

    const addFabricationsToDuplicate = () => {
        if (selectedDuplicateService === null) return;

        const { newServiceIndex, oldServiceIndex } = selectedDuplicateService;
        const { serviceType } = serviceDict[oldServiceIndex];

        let category_ids_fabrications: {category: string, category_id: string, fabrications: IFabricationQuoteView[]}[] = [];

        if (serviceType === 'stone') {
            category_ids_fabrications.push({
                category: 'stone',
                category_id: buildUniqueId(newServiceIndex, 'stone'),
                fabrications: fabricationDict[buildUniqueId(oldServiceIndex, 'stone')],
            });
        } else if (serviceType === 'millwork' || serviceType === 'remodeling') {
            return;
        }

        category_ids_fabrications.forEach(({category, category_id, fabrications}) => {
            for (let itemIndex: number = 0; itemIndex < fabrications.length; itemIndex++) {
                const fabrication = fabricationDict[category_id].find((fabrication: IFabricationQuoteView) => fabrication.itemIndex === itemIndex);

                if (!fabrication) {
                    addFabricationToCategory(
                        newServiceIndex,
                        category
                    );
                }
            }
        });

        category_ids_fabrications.forEach(({category, category_id, fabrications}) => {
            for (let itemIndex: number = 0; itemIndex < fabrications.length; itemIndex++) {
                const fabricationToCopy = fabrications.find((fabrication: IFabricationQuoteView) => fabrication.itemIndex === itemIndex);
                const fabrication = fabricationDict[category_id].find((fabrication: IFabricationQuoteView) => fabrication.itemIndex === itemIndex);

                if (
                    fabricationToCopy &&
                    fabrication &&
                    (
                        fabricationToCopy.destination !== fabrication.destination ||
                        fabricationToCopy.edge === fabrication.edge ||
                        fabricationToCopy.finish === fabrication.finish ||
                        fabricationToCopy.have_workforce === fabrication.have_workforce ||
                        fabricationToCopy.sink === fabrication.sink ||
                        fabricationToCopy.sq_ft === fabrication.sq_ft ||
                        fabricationToCopy.workforce_cost === fabrication.workforce_cost
                    )
                ) {
                    updateFabrication(
                        {
                            ...fabricationToCopy,
                            itemIndex: fabrication.itemIndex,
                            unique_id: fabrication.unique_id
                        },
                        newServiceIndex,
                        category,
                        itemIndex
                    );

                    if (fabrications.length - 1 === itemIndex) {
                        setDuplicateCompletedSteps((prevState: any) => {
                            return {
                                ...prevState,
                                fabrication: true
                            };
                        });
                    }
                }
            }

            if (fabrications.length === 0) {
                setDuplicateCompletedSteps((prevState: any) => {
                    return {
                        ...prevState,
                        fabrication: true
                    };
                });
            }
        });
    };

    const addTasksToDuplicate = () => {
        if (selectedDuplicateService === null) return;

        const { newServiceIndex, oldServiceIndex } = selectedDuplicateService;
        const { serviceType } = serviceDict[oldServiceIndex];

        let category_ids_tasks: {category: string, category_id: string, tasks: ITaskQuoteView[]}[] = [];

        if (serviceType === 'stone') {
            return;
        } else if (serviceType === 'millwork' || serviceType === 'remodeling') {
            category_ids_tasks.push({
                category: 'workforce',
                category_id: buildUniqueId(newServiceIndex, 'workforce'),
                tasks: taskDict[buildUniqueId(oldServiceIndex, 'workforce')],
            });
        }

        category_ids_tasks.forEach(({category, category_id, tasks, ...others}) => {
            for (let itemIndex: number = 0; itemIndex < tasks.length; itemIndex++) {
                const task = taskDict[category_id].find((task: ITaskQuoteView) => task.itemIndex === itemIndex);
                
                if (!task) {
                    addTaskToCategory(
                        newServiceIndex,
                        category
                    );
                }
            }
        });

        category_ids_tasks.forEach(({category, category_id, tasks}) => {
            for (let itemIndex: number = 0; itemIndex < tasks.length; itemIndex++) {
                const taskToCopy = tasks.find((task: ITaskQuoteView) => task.itemIndex === itemIndex);
                const task = taskDict[category_id].find((task: ITaskQuoteView) => task.itemIndex === itemIndex);

                if (taskToCopy && task && task.task_name === '') {
                    updateTask(
                        {
                            ...taskToCopy,
                            itemIndex: task.itemIndex,
                            unique_id: task.unique_id
                        },
                        newServiceIndex,
                        category,
                        itemIndex
                    );

                    if (tasks.length - 1 === itemIndex) {
                        setDuplicateCompletedSteps((prevState: any) => {
                            return {
                                ...prevState,
                                task: true
                            };
                        });
                    }
                }
            }

            if (tasks.length === 0) {
                setDuplicateCompletedSteps((prevState: any) => {
                    return {
                        ...prevState,
                        task: true
                    };
                });
            }
        });
    };

    /*=================================================
    CALC COST
    =================================================*/
    useEffect(() => {
        calcNewCostDict();
    }, [productDict, fabricationDict, serviceDict, taskDict]);

    useEffect(() => {
        if (pendingUpdateCostDict) calcNewCostDict();
    }, [pendingUpdateCostDict]);

    const calcNewCostDict = () => {
        setLoadingFields(true);
        // console.log('calcNewCostDict', costDict);
        const newCostDict: {[id: string]: IServiceCost} = {};
        Object.keys(serviceDict).forEach((serviceIndexString: string) => {
            const serviceIndex = Number(serviceIndexString);
            const { serviceType } = serviceDict[serviceIndex];
            
            let category_ids_numbers: any = {};
            let otherCost = {};

            if (serviceType === 'stone') {
                const stoneId: string = buildUniqueId(serviceIndex, 'stone');

                const productStoneCosts: number = productDict[stoneId]
                    .reduce((acum: number, item: IProductQuoteView) => {
                        acum += (item.unit_price || 0) * (item.quantity || 0);
                        return acum;
                    }, 0);
                const fabricationStoneCost: number = fabricationDict[stoneId]
                    .reduce((acum: number, item: IFabricationQuoteView) => {
                        if (item.have_workforce) acum += (item.workforce_cost || 0);
                        return acum;
                    }, 0);
                category_ids_numbers = {
                    [stoneId]: productStoneCosts + fabricationStoneCost,
                };

                otherCost = {
                    total_cost: category_ids_numbers[stoneId],
                    material_cost: productStoneCosts,
                    workforce_cost: fabricationStoneCost,
                    discounts: costDict[serviceIndex].discounts,
                    increases: costDict[serviceIndex].increases,
                };
                
            } else if (serviceType === 'millwork' || serviceType === 'remodeling') {
                const hardwareId: string = buildUniqueId(serviceIndex, 'hardware');
                const paintId = buildUniqueId(serviceIndex, 'paint');
                const panelId = buildUniqueId(serviceIndex, 'panel');
                const workforceId = buildUniqueId(serviceIndex, 'workforce');

                const productHardwareCosts: number = productDict[hardwareId]
                    .reduce((acum: number, item: IProductQuoteView) => {
                        acum += (item.unit_price || 0) * (item.quantity || 0);
                        return acum;
                    }, 0);

                const productPaintCosts: number = productDict[paintId]
                    .reduce((acum: number, item: IProductQuoteView) => {
                        acum += (item.unit_price || 0) * (item.quantity || 0);
                        return acum;
                    }, 0);

                const productPanelCosts: number = productDict[panelId]
                    .reduce((acum: number, item: IProductQuoteView) => {
                        acum += (item.unit_price || 0) * (item.quantity || 0);
                        return acum;
                    }, 0);

                const taskWorkforceCost: number = taskDict[workforceId]
                    .reduce((acum: number, item: ITaskQuoteView) => {
                        acum += (item.hour_cost || 0) * (item.hours || 0);
                        return acum;
                    },0);

                category_ids_numbers = {
                    [hardwareId]: productHardwareCosts,
                    [paintId]: productPaintCosts,
                    [panelId]: productPanelCosts,
                    [workforceId]: taskWorkforceCost,
                };

                otherCost = {
                    total_cost: (
                        category_ids_numbers[hardwareId] +
                        category_ids_numbers[paintId] +
                        category_ids_numbers[panelId] +
                        category_ids_numbers[workforceId]
                    ),
                    material_cost: (
                        category_ids_numbers[hardwareId] +
                        category_ids_numbers[paintId] +
                        category_ids_numbers[panelId]
                    ),
                    workforce_cost: (
                        category_ids_numbers[workforceId]
                    ),
                    percentage: costDict[serviceIndex].percentage,
                };

            }

            newCostDict[serviceIndex] = {
                ...category_ids_numbers,
                ...otherCost,
                serviceIndex: serviceIndex,
                serviceType: serviceType,
            };
        })
        setCostDict(newCostDict);
        setPendingUpdateQuoteCost(true);
        setPendingUpdateCostDict(false);
    };

    useEffect(() => {
        if (pendingUpdateQuoteCost) calcNewQuoteCost();
    }, [pendingUpdateQuoteCost]);

    const calcNewQuoteCost = () => {
        setLoadingFields(true);
        // console.log('quoteCost', quoteCost);
        const newQuoteCost: any = {
            millwork: {
                material_cost: 0,
                workforce_cost: 0,
                company_cost: 0,
                profit_value: 0,
                profit_percentage: 0,
            },
            stone: {
                material_cost: 0,
                workforce_cost: 0,
                company_cost: 0,
                profit_value: 0,
            },
            remodeling: {
                material_cost: 0,
                workforce_cost: 0,
                company_cost: 0,
                profit_value: 0,
                profit_percentage: 0,
            },
            subtotal_cost: 0,
            tax_cost: 0,
            tax_percentage: quoteCost.tax_percentage,
            have_tax: quoteCost.have_tax,
            total_cost: 0,
        };

        let millwork_total = 0;
        let remodeling_total = 0;

        Object.keys(costDict).forEach((serviceIndexString: string) => {
            const serviceIndex = Number(serviceIndexString);
            const { serviceType } = costDict[serviceIndex];

            if (serviceType === 'stone') {
                const {
                    workforce_cost,
                    material_cost,
                    discounts,
                    increases,
                    total_cost
                } = costDict[serviceIndex];
                
                newQuoteCost.stone.material_cost += material_cost;
                newQuoteCost.stone.workforce_cost += workforce_cost;
                newQuoteCost.stone.company_cost += total_cost;
                newQuoteCost.stone.profit_value += (increases || 0);
                newQuoteCost.stone.profit_value -= (discounts || 0);

                newQuoteCost.subtotal_cost += newQuoteCost.stone.company_cost + newQuoteCost.stone.profit_value;

                if ((quoteCost.stone as ITypeCost).profit_fixed) {
                    newQuoteCost.stone.profit_value = (quoteCost.stone as ITypeCost).profit_fixed
                }
            } else if (serviceType === 'millwork' || serviceType === 'remodeling') {
                const {
                    workforce_cost,
                    material_cost,
                    percentage,
                    total_cost,
                } = costDict[serviceIndex];

                newQuoteCost[serviceType].material_cost += material_cost;
                newQuoteCost[serviceType].workforce_cost += workforce_cost;
                newQuoteCost[serviceType].company_cost += total_cost;
                newQuoteCost[serviceType].profit_value += (percentage || 0) / 100 * (total_cost || 0);
                newQuoteCost.subtotal_cost += newQuoteCost[serviceType].company_cost + newQuoteCost[serviceType].profit_value;

                if (serviceType === 'millwork') {
                    millwork_total += ((100 + (percentage || 0)) / 100 * (total_cost || 0));
                } else if (serviceType === 'remodeling') {
                    remodeling_total += ((100 + (percentage || 0)) / 100 * (total_cost || 0));
                }
            }
        });

        newQuoteCost.tax_cost = newQuoteCost.subtotal_cost * newQuoteCost.tax_percentage / 100;
        newQuoteCost.total_cost = newQuoteCost.have_tax ? newQuoteCost.tax_cost + newQuoteCost.subtotal_cost : newQuoteCost.subtotal_cost;
        
        newQuoteCost.millwork.profit_percentage = (millwork_total / newQuoteCost.millwork.company_cost - 1) * 100;
        newQuoteCost.remodeling.profit_percentage = (remodeling_total / newQuoteCost.remodeling.company_cost - 1) * 100;


        setQuoteCost(newQuoteCost as IQuoteCost);
        setPendingUpdateQuoteCost(false);
    };

    const updateServiceCost = (
        newServiceCost: IServiceCost,
        serviceIndex: number,
    ) => {
        setCostDict((prevState: {[id: string]: IServiceCost}) => {
            const newServiceState = {
                ...prevState[serviceIndex],
                ...newServiceCost,
            };

            if (newServiceCost.increases && newServiceCost.increases > 0) {
                delete newServiceState.discounts;
            } else if (newServiceCost.discounts && newServiceCost.discounts > 0) {
                delete newServiceState.increases;
            } else {
                delete newServiceState.increases;
                delete newServiceState.discounts;
            }
            
            return {
                ...prevState,
                [serviceIndex]: newServiceState,
            };
        });

        setPendingUpdateCostDict(true);
    }


    /* ------->>> END FRONTEND FUNCTIONS <<<------- */


    
    /* ------->>> BACKEND FUNCTIONS <<<------- */

    useEffect(() => {
        if (!loadingFields && pendingChanges) {
            handleSubmit();
        }
    }, [loadingFields, pendingChanges]);

    const buildDicts = (
        service: any,
        serviceIndex: number,
        newSectionDict: ISectionDict,
        quoteCost: any,
    ): any => {
        const newProductDict: {[id: string]: IProductQuoteView[]} = {};
        const newFabricationDict: {[id: string]: IFabricationQuoteView[]} = {};
        const newTaskDict: {[id: string]: ITaskQuoteView[]} = {};
        const newMeasureDict: {[id: string]: {[itemIndex: number]: IMeasureExplodedView[]}} = {};
        const newServiceDict: {[id: string]: IService} = {};
        const newCostDict: {[id: string]: IServiceCost} = {};
        const newPreviewDict: {[id: string]: IProductPreviewView[]} = {}; 
        const newFolderDict: {[id: string]: IServiceFolder} = {};
        
        let unique_id: string = '';
        const index: string = serviceIndex.toString();

        newServiceDict[index] = {
            serviceIndex: serviceIndex,
            serviceType: service.type,
            name: service.name,
            collapseService: !service.serviceOpen,
            collapseSections: true,
        };

        newCostDict[index] =  {
            serviceIndex: serviceIndex,
            serviceType: service.type,
            total_cost: service.service_cost,
            percentage: (service.percentage || 0),
            discounts: (service.discounts || 0),
            increases: (service.increases || 0),
        };

        newFolderDict[index] = {
            serviceIndex: serviceIndex,
            folder_id: service.service_folder_id,
            name: service.name,
            serviceType: service.type,
        };


        switch (service.type) {
            case 'millwork':
            case 'remodeling':
                unique_id = buildUniqueId(serviceIndex, 'hardware');
                newCostDict[index][unique_id] = service['hardware']['total'];
                newProductDict[unique_id] = [];
                newPreviewDict[unique_id] = [];

                service.hardware.products.forEach((product: any, index: number) => {
                    const productToAdd: IProductQuoteView = {
                        unique_id: unique_id,
                        itemIndex: index,

                        product_id: product.product_id,
                        product_name: product.product_name,
                        product_description: product.product_description || '',
                        caso_code: product.caso_code,
                        quantity: product.quantity,
                        section: product.section,
                        unit_price: product.sale_price,
                    };

                    newProductDict[unique_id].push(productToAdd);

                    if (product.section !== '') {
                        newSectionDict[product.section] = [
                            ...(newSectionDict[product.section] || []),
                            {
                                serviceIndex: serviceIndex,
                                category: 'hardware',
                                itemIndex: index,
                            },
                        ];
                    }

                    const previewToAdd: IProductPreviewView = {
                        unique_id: unique_id,
                        itemIndex: index,

                        product_name: product.product_name,
                        quote_text: product.quoteText,
                        section: product.section,
                    };

                    newPreviewDict[unique_id].push(previewToAdd);
                });

                unique_id = buildUniqueId(serviceIndex, 'paint');
                newCostDict[index][unique_id] = service['paint']['total'];
                newProductDict[unique_id] = [];
                newPreviewDict[unique_id] = [];
                
                service.paint.products.forEach((product: any, index: number) => {
                    const productToAdd: IProductQuoteView = {
                        unique_id: unique_id,
                        itemIndex: index,

                        product_id: product.product_id,
                        product_name: product.product_name,
                        product_description: product.product_description || '',
                        caso_code: product.caso_code,
                        quantity: product.quantity,
                        section: product.section,
                        unit_price: product.sale_price,
                    };

                    newProductDict[unique_id].push(productToAdd);

                    if (product.section !== '') {
                        newSectionDict[product.section] = [
                            ...(newSectionDict[product.section] || []),
                            {
                                serviceIndex: serviceIndex,
                                category: 'paint',
                                itemIndex: index,
                            },
                        ];
                    }

                    const previewToAdd: IProductPreviewView = {
                        unique_id: unique_id,
                        itemIndex: index,

                        product_name: product.product_name,
                        quote_text: product.quoteText,
                        section: product.section,
                    };

                    newPreviewDict[unique_id].push(previewToAdd);
                });
                
                unique_id = buildUniqueId(serviceIndex, 'panel');
                newCostDict[index][unique_id] = service['panel']['total'];
                newProductDict[unique_id] = [];
                newPreviewDict[unique_id] = [];
                newMeasureDict[unique_id] = {};

                service.panel.products.forEach((product: any, index: number) => {
                    const productToAdd: IProductQuoteView = {
                        unique_id: unique_id,
                        itemIndex: index,

                        product_id: product.product_id,
                        product_name: product.product_name,
                        product_description: product.product_description || '',
                        caso_code: product.caso_code,
                        quantity: product.quantity,
                        section: product.section,
                        unit_price: product.sale_price,
                    };

                    newProductDict[unique_id].push(productToAdd);

                    if (product.section !== '') {
                        newSectionDict[product.section] = [
                            ...(newSectionDict[product.section] || []),
                            {
                                serviceIndex: serviceIndex,
                                category: 'panel',
                                itemIndex: index,
                            },
                        ];
                    }

                    newMeasureDict[unique_id][index] = [];

                    product.measures.forEach((measure: any, measureIndex: number) => {
                        newMeasureDict[unique_id][index].push({
                            unique_id: unique_id,
                            itemIndex: index,
                            measureIndex: measureIndex,

                            measure: measure.measure,
                            quantity: measure.quantity,
                            description: measure.description,
                            sheets: measure.sheets,
                        });
                    });

                    const previewToAdd: IProductPreviewView = {
                        unique_id: unique_id,
                        itemIndex: index,

                        product_name: product.product_name,
                        quote_text: product.quoteText,
                        section: product.section,
                    };

                    newPreviewDict[unique_id].push(previewToAdd);
                });

                unique_id = buildUniqueId(serviceIndex, 'workforce');
                newCostDict[index][unique_id] = service['workforce']['total'];
                newTaskDict[unique_id] = [];

                service.workforce.tasks.forEach((task: any, index: number) => {
                    const taskToAdd: ITaskQuoteView = {
                        unique_id: unique_id,
                        itemIndex: index,

                        task_id: task.workforce_id,
                        task_name: task.workforce_name,
                        hours: task.hours,
                        hour_cost: task.hour_cost,
                    };

                    newTaskDict[unique_id].push(taskToAdd);
                });
                break;

            case 'stone':
                unique_id = buildUniqueId(serviceIndex, 'stone');
                newCostDict[index][unique_id] = service['stone']['total'];
                newProductDict[unique_id] = [];
                newFabricationDict[unique_id] = [];
                newPreviewDict[unique_id] = [];
                newMeasureDict[unique_id] = {};

                service.stone.products.forEach((product: any, index: number) => {
                    const productToAdd: IProductQuoteView = {
                        unique_id: unique_id,
                        itemIndex: index,

                        stone_fields: {
                            price_with_manufacture: product.price_with_manufacturing,
                            price_without_manufacture: product.price_without_manufacturing,
                        },

                        product_id: product.product_id,
                        product_name: product.product_name,
                        product_description: product.product_description || '',
                        caso_code: product.caso_code,
                        quantity: product.quantity,
                        section: product.section,
                        unit_price: product.have_workforce ? product.price_with_manufacturing : product.price_without_manufacturing,
                    };

                    newProductDict[unique_id].push(productToAdd);

                    if (product.section !== '') {
                        newSectionDict[product.section] = [
                            ...(newSectionDict[product.section] || []),
                            {
                                serviceIndex: serviceIndex,
                                category: 'stone',
                                itemIndex: index,
                            },
                        ];
                    }

                    const fabricationToAdd: IFabricationQuoteView = {
                        unique_id: unique_id,
                        itemIndex: index,

                        have_workforce: product.have_workforce,
                        sq_ft: product.sq_ft,
                        destination: product.destination,
                        edge: product.edge,
                        finish: product.finish,
                        sink: product.sink,
                        workforce_cost: product.workforce_cost,
                    };

                    newFabricationDict[unique_id].push(fabricationToAdd);

                    newMeasureDict[unique_id][index] = [];

                    product.measures.forEach((measure: any, measureIndex: number) => {
                        newMeasureDict[unique_id][index].push({
                            unique_id: unique_id,
                            itemIndex: index,
                            measureIndex: measureIndex,

                            width: measure.width,
                            length: measure.length,
                            quantity: measure.quantity,
                            sq_ft: measure.sq_ft,
                            description: measure.description,
                            sheets: measure.sheets,
                        });
                    });

                    const previewToAdd: IProductPreviewView = {
                        unique_id: unique_id,
                        itemIndex: index,

                        product_name: product.product_name,
                        quote_text: product.quoteText,
                        section: product.section,
                    };

                    newPreviewDict[unique_id].push(previewToAdd);
                });
                break;
        };

        return {
            productDict: newProductDict,
            sectionDict: newSectionDict,
            fabricationDict: newFabricationDict,
            taskDict: newTaskDict,
            measureDict: newMeasureDict,
            serviceDict: newServiceDict,
            costDict: newCostDict,
            previewDict: newPreviewDict,
            folderDict: newFolderDict,
        };
    }

    const loadData = () => {
        setLoading(true);

        const params: any = {
            request: "quote_details",
            quote_id: quote_id,
        };

        if (sequence_id !== null) {
            params.sequence_id = sequence_id;
        }

        axios.get(ProjectConfig.api_url, { params, })
        .then((response) => {
            console.log(response.data);

            let newProductDict: any = {};
            let newSectionDict: ISectionDict = {};
            let newFabricationDict: any = {};
            let newTaskDict: any = {};
            let newMeasureDict: any = {};
            let newServiceDict: any = {};
            let newCostDict: any = {};
            const newQuoteCost: any = {};
            let newPreviewDict: any = {};
            const newQuoteInfo: IQuoteInfo = {};
            let newFolderDict: any = {};

            let services_need_type_profit: any = {};

            response.data.quote_data.services.forEach((service: any, serviceIndex: number) => {
                const serviceType = service.type;
                const serviceTypeFound = services_types.find((type: IServiceType) => type.serviceType === serviceType);
                
                if (serviceTypeFound?.with_profit) {
                    if (services_need_type_profit[serviceType] === undefined) {
                        const no_individual_profit = response.data.quote_data.services
                            .filter((service_filtered: any) => service_filtered.type == serviceType)
                            .every((service_mapped: any) => !service_mapped.percentage || service_mapped.percentage <= 0)
                        services_need_type_profit[serviceType] = no_individual_profit;
                    }

                    if (services_need_type_profit[serviceType]) {
                        service.percentage = response.data.quote_data[serviceType].profit_percentage;
                    }
                }

                const {
                    productDict,
                    sectionDict,
                    fabricationDict,
                    taskDict,
                    measureDict,
                    serviceDict,
                    costDict,
                    previewDict,
                    folderDict,
                } = buildDicts(
                    service,
                    serviceIndex,
                    newSectionDict,
                    response.data.quote_data,
                );

                newProductDict = {...newProductDict, ...productDict};
                newSectionDict = sectionDict;
                newFabricationDict = {...newFabricationDict, ...fabricationDict};
                newTaskDict = {...newTaskDict, ...taskDict};
                newMeasureDict = {...newMeasureDict, ...measureDict};
                newServiceDict = {...newServiceDict, ...serviceDict};
                newCostDict = {...newCostDict, ...costDict}
                newPreviewDict = {...newPreviewDict, ...previewDict};
                newFolderDict = {...newFolderDict, ...folderDict};
            });

            newQuoteCost.subtotal_cost = response.data.quote_data.subtotal;
            newQuoteCost.tax_cost = response.data.quote_data.tax;
            newQuoteCost.tax_percentage = 10;
            newQuoteCost.have_tax = response.data.quote_data.have_tax;
            newQuoteCost.total_cost = response.data.quote_data.total;

            services_types.forEach((type: IServiceType) => {
                newQuoteCost[type.serviceType] = {
                    material_cost: response.data.quote_data[type.serviceType].material_cost,
                    workforce_cost: response.data.quote_data[type.serviceType].workforce_cost,
                    company_cost: response.data.quote_data[type.serviceType].company_cost,
                };

                if (type.with_profit) {
                    newQuoteCost[type.serviceType].profit_percentage =
                        response.data.quote_data[type.serviceType].profit_percentage;
                } else {
                    newQuoteCost[type.serviceType].profit_fixed =
                        response.data.quote_data[type.serviceType].profit_value;
                }
            });

            newQuoteInfo.quote_id = response.data.quote_id;
            newQuoteInfo.project_id = project_id;
            newQuoteInfo.client_name = response.data.project_info.client_name;
            newQuoteInfo.client_address = response.data.project_info.client_address;
            newQuoteInfo.project_name = response.data.project_info.project_name;
            newQuoteInfo.quote_text = response.data.quote_data.quotePDFinformation;

            
            setProductDict(newProductDict);
            setSectionList(response.data.section_list);
            setSectionDict(newSectionDict);
            setFabricationDict(newFabricationDict);
            setTaskDict(newTaskDict);
            setMeasureDict(newMeasureDict);
            setServiceDict(newServiceDict);
            setCostDict(newCostDict);
            setQuoteCost(newQuoteCost);
            setPreviewDict(newPreviewDict);
            setQuoteInfo(newQuoteInfo);
            setFolderDict(newFolderDict);
            setHistory(response.data.quote_data.history);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleSubmit = async (is_autosave: boolean = false): Promise<IQuoteInfo> => {
        console.log('handleSubmit', loadingFields);
        if (loadingFields) {
            if (is_autosave) {
                setWasSubmited(false);
            } else {
                setPendingChanges(true);
            }

            return new Promise((resolve, reject) => {
                resolve(quoteInfo);
            });
        }

        setWasSubmited(true);

        const formValues = {
            project_id: project_id,
            quote_id: quote_id === 'null' ? "" : quote_id,
            sequence_id: sequence_id === 'null' ? "" : Number(sequence_id),
            services: Object.entries(serviceDict).map(([serviceIndex, service]: [string, IService]) => {

                const dataFromCategories: any = {};

                if (service.serviceType === "millwork" || service.serviceType === "remodeling") {
                    dataFromCategories.workforce_cost = costDict[serviceIndex][buildUniqueId(Number(serviceIndex), "workforce")];
                    dataFromCategories.percentage = costDict[serviceIndex].percentage;
                    dataFromCategories.material_cost =
                        Number(costDict[serviceIndex][buildUniqueId(Number(serviceIndex), "hardware")]) +
                        Number(costDict[serviceIndex][buildUniqueId(Number(serviceIndex), "paint")]) +
                        Number(costDict[serviceIndex][buildUniqueId(Number(serviceIndex), "panel")]);

                    dataFromCategories.workforce = {
                        total: costDict[serviceIndex][buildUniqueId(Number(serviceIndex), "workforce")],
                        tasks: taskDict[buildUniqueId(Number(serviceIndex), "workforce")].map((item) => {
                            return {
                                hours: item.hours,
                                hour_cost: item.hour_cost,
                                workforce_id: item.task_id,
                                workforce_name: item.task_name,
                            }
                        }),
                    };

                    dataFromCategories.hardware = {
                        total: Number(costDict[serviceIndex][buildUniqueId(Number(serviceIndex), "hardware")]),
                        products: productDict[buildUniqueId(Number(serviceIndex), "hardware")].map((item, itemIndex) => {
                            return {
                                product_id: item.product_id,
                                product_name: item.product_name,
                                product_description: item.product_description,
                                sale_price: item.unit_price,
                                quantity: item.quantity,
                                caso_code: '', // TODO: Update
                                quoteText: previewDict[buildUniqueId(Number(serviceIndex), "hardware")][itemIndex].quote_text,
                                product_type: 'product', // TODO: Update
                                section: item.section,
                                measures: [],
                            }
                        }),
                    };

                    dataFromCategories.paint = {
                        total: Number(costDict[serviceIndex][buildUniqueId(Number(serviceIndex), "paint")]),
                        products: productDict[buildUniqueId(Number(serviceIndex), "paint")].map((item, itemIndex) => {
                            return {
                                product_id: item.product_id,
                                product_name: item.product_name,
                                product_description: item.product_description,
                                sale_price: item.unit_price,
                                quantity: item.quantity,
                                caso_code: '', // TODO: Update
                                quoteText: previewDict[buildUniqueId(Number(serviceIndex), "paint")][itemIndex].quote_text,
                                product_type: 'product', // TODO: Update
                                section: item.section,
                                measures: [],
                            }
                        }),
                    };

                    dataFromCategories.panel = {
                        total: Number(costDict[serviceIndex][buildUniqueId(Number(serviceIndex), "panel")]),
                        products: productDict[buildUniqueId(Number(serviceIndex), "panel")].map((item, itemIndex) => {
                            return {
                                product_id: item.product_id,
                                product_name: item.product_name,
                                product_description: item.product_description,
                                sale_price: item.unit_price,
                                quantity: item.quantity,
                                caso_code: '', // TODO: Update
                                quoteText: previewDict[buildUniqueId(Number(serviceIndex), "panel")][itemIndex].quote_text,
                                product_type: 'product', // TODO: Update
                                section: item.section,
                                measures: (measureDict[buildUniqueId(Number(serviceIndex), "panel")][itemIndex] || []).map((measure) => {
                                    return {
                                        measure: measure.measure,
                                        quantity: measure.quantity,
                                        description: measure.description,
                                        sheets: measure.sheets,
                                        sq_ft: measure.sq_ft,
                                    }
                                }),
                            }
                        }),
                    };
                } else {
                    let material = 0;
                    let workforce = 0;

                    dataFromCategories.stone = {
                        total: costDict[serviceIndex][buildUniqueId(Number(serviceIndex), "stone")],
                        products: productDict[buildUniqueId(Number(serviceIndex), "stone")].map((item, itemIndex) => {
                            const fabrication = fabricationDict[buildUniqueId(Number(serviceIndex), "stone")][itemIndex];
                            const fabrication_with_workforce = calcQuoteViewWorkforceCost(fabrication);

                            const product_cost = (item.unit_price || 0) * (item.quantity || 0);
                            const manufacturing_cost = (fabrication_with_workforce.workforce_cost || 0);

                            material += product_cost;
                            workforce += manufacturing_cost;
                            return {
                                product_id: item.product_id,
                                product_name: item.product_name,
                                product_description: item.product_description,
                                sale_price: item.unit_price,
                                quantity: item.quantity,
                                caso_code: '', // TODO: Update
                                quoteText: previewDict[buildUniqueId(Number(serviceIndex), "stone")][itemIndex].quote_text,
                                product_type: 'product', // TODO: Update
                                section: item.section,
                                edge: fabrication.edge,
                                sink: fabrication.sink,
                                sq_ft: fabrication.sq_ft,
                                finish: fabrication.finish,
                                destination: fabrication.destination,
                                have_workforce: fabrication.have_workforce,
                                price_without_manufacturing: item.stone_fields?.price_without_manufacture,
                                workforce_cost: manufacturing_cost,
                                price_with_manufacturing: item.stone_fields?.price_with_manufacture,
                                measures: (measureDict[buildUniqueId(Number(serviceIndex), "stone")][itemIndex] || []).map((measure) => {
                                    return {
                                        sq_ft: measure.sq_ft,
                                        width: measure.width,
                                        length: measure.length,
                                        sheets: measure.sheets,
                                        measure: measure.measure,
                                        quantity: measure.quantity,
                                        description: measure.description,
                                    }
                                }),
                            };
                        }),
                        material_total: material,
                        workforce_total: workforce,
                    };

                    dataFromCategories.workforce_cost = workforce;
                    dataFromCategories.material_cost = material;
                    dataFromCategories.discounts = costDict[serviceIndex].discounts;
                    dataFromCategories.increases = costDict[serviceIndex].increases;
                }

                return {
                    ...dataFromCategories,
                    name: service.name,
                    type: service.serviceType,
                    images: [],
                    serviceOpen: !service.collapseService,
                    service_cost: costDict[serviceIndex].total_cost,
                    service_folder_id: folderDict[serviceIndex].folder_id,
                }
            }),
            subtotal: quoteCost.subtotal_cost,
            tax: quoteCost.tax_cost,
            have_tax: quoteCost.have_tax,
            total: quoteCost.total_cost,
            history: history,
            current_status: {
                date: (history[history.length - 1] || currentState).date,
                time: (history[history.length - 1] || currentState).time,
                status_id: (history[history.length - 1] || currentState).status_id,
                status_name: (history[history.length - 1] || currentState).status_name,
            },
            quotePDFinformation: quoteInfo.quote_text,
            millwork: {
                company_cost: ((quoteCost.millwork || {} as any).company_cost || 0),
                material_cost: ((quoteCost.millwork || {} as any).material_cost || 0),
                workforce_cost: ((quoteCost.millwork || {} as any).workforce_cost || 0),
                profit_percentage: ((quoteCost.millwork || {} as any).profit_percentage || 0),
                profit: ((quoteCost.millwork || {} as any).profit_percentage || 0) *
                    ((quoteCost.millwork || {} as any).company_cost || 0),
                total: (((quoteCost.millwork || {} as any).profit_percentage || 0) + 1) *
                    ((quoteCost.millwork || {} as any).company_cost || 0),
            },
            stone: {
                company_cost: ((quoteCost.stone || {} as any).company_cost || 0),
                material_cost: ((quoteCost.stone || {} as any).material_cost || 0),
                workforce_cost: ((quoteCost.stone || {} as any).workforce_cost || 0),
                total: (((quoteCost.stone || {} as any).profit_percentage || 0) + 1) *
                    ((quoteCost.stone || {} as any).company_cost || 0),
                profit_value: ((quoteCost.stone || {} as any).profit_value || 0),
            },
            remodeling: {
                company_cost: ((quoteCost.remodeling || {} as any).company_cost || 0),
                material_cost: ((quoteCost.remodeling || {} as any).material_cost || 0),
                workforce_cost: ((quoteCost.remodeling || {} as any).workforce_cost || 0),
                profit_percentage: ((quoteCost.remodeling || {} as any).profit_percentage || 0),
                profit: ((quoteCost.remodeling || {} as any).profit_percentage || 0) *
                    ((quoteCost.remodeling || {} as any).company_cost || 0),
                total: (((quoteCost.remodeling || {} as any).profit_percentage || 0) + 1) *
                    ((quoteCost.remodeling || {} as any).company_cost || 0),
            },
        };

        console.log("submit", formValues);

        //formdata 
        const formdata = new FormData()
        formdata.append("params" , JSON.stringify({
            quote_id: quote_id,
            formdata: formValues,
            sectionList: JSON.stringify(sectionList),
        }));

        return axios.post(ProjectConfig.api_url, formdata, {
            params: {
                request: "add_edit_project_quote",
            },
        })
        .then((response) => {
            console.log("submit", response.data);
            
            let newProductDict: any = {};
            let newSectionDict: ISectionDict = {};
            let newFabricationDict: any = {};
            let newTaskDict: any = {};
            let newMeasureDict: any = {};
            let newServiceDict: any = {};
            let newCostDict: any = {};
            const newQuoteCost: any = {};
            let newPreviewDict: any = {};
            const newQuoteInfo: IQuoteInfo = {};
            let newFolderDict: any = {};

            let services_need_type_profit: any = {};

            response.data.quote_data.services.forEach((service: any, serviceIndex: number) => {
                const serviceType = service.type;
                const serviceTypeFound = services_types.find((type: IServiceType) => type.serviceType === serviceType);

                if (serviceTypeFound?.with_profit) {
                    if (services_need_type_profit[serviceType] === undefined) {
                        const no_individual_profit = response.data.quote_data.services
                            .filter((service_filtered: any) => service_filtered.type == serviceType)
                            .every((service_mapped: any) => !service_mapped.percentage || service_mapped.percentage <= 0)
                        services_need_type_profit[serviceType] = no_individual_profit;
                    }

                    if (services_need_type_profit[serviceType]) {
                        service.percentage = response.data.quote_data[serviceType].profit_percentage;
                    }
                }
                
                const {
                    productDict,
                    sectionDict,
                    fabricationDict,
                    taskDict,
                    measureDict,
                    serviceDict,
                    costDict,
                    previewDict,
                    folderDict,
                } = buildDicts(
                    service,
                    serviceIndex,
                    newSectionDict,
                    response.data.quote_data,
                );

                newProductDict = {...newProductDict, ...productDict};
                newSectionDict = sectionDict;
                newFabricationDict = {...newFabricationDict, ...fabricationDict};
                newTaskDict = {...newTaskDict, ...taskDict};
                newMeasureDict = {...newMeasureDict, ...measureDict};
                newServiceDict = {...newServiceDict, ...serviceDict};
                newCostDict = {...newCostDict, ...costDict}
                newPreviewDict = {...newPreviewDict, ...previewDict};
                newFolderDict = {...newFolderDict, ...folderDict};
            });

            newQuoteCost.subtotal_cost = response.data.quote_data.subtotal;
            newQuoteCost.tax_cost = response.data.quote_data.tax;
            newQuoteCost.tax_percentage = 10;
            newQuoteCost.have_tax = response.data.quote_data.have_tax;
            newQuoteCost.total_cost = response.data.quote_data.total;

            services_types.forEach((type: IServiceType) => {
                newQuoteCost[type.serviceType] = {
                    material_cost: response.data.quote_data[type.serviceType].material_cost,
                    workforce_cost: response.data.quote_data[type.serviceType].workforce_cost,
                    company_cost: response.data.quote_data[type.serviceType].company_cost,
                };

                if (type.with_profit) {
                    newQuoteCost[type.serviceType].profit_percentage =
                        response.data.quote_data[type.serviceType].profit_percentage;
                } else {
                    newQuoteCost[type.serviceType].profit_fixed =
                        response.data.quote_data[type.serviceType].profit_value;
                }
            });

            newQuoteInfo.quote_id = response.data.quote_id;
            newQuoteInfo.project_id = project_id;
            // newQuoteInfo.client_name = response.data.project_info.client_name;
            // newQuoteInfo.client_address = response.data.project_info.client_address;
            // newQuoteInfo.project_name = response.data.project_info.project_name;
            newQuoteInfo.quote_text = response.data.quote_data.quotePDFinformation;
            
            setProductDict(newProductDict);
            setSectionDict(newSectionDict);
            setFabricationDict(newFabricationDict);
            setTaskDict(newTaskDict);
            setMeasureDict(newMeasureDict);
            setServiceDict(newServiceDict);
            setCostDict(newCostDict);
            setQuoteCost(newQuoteCost);
            setPreviewDict(newPreviewDict);
            setQuoteInfo(newQuoteInfo);
            setFolderDict(newFolderDict);

            if (quote_id !== 'null') {
                enqueueSnackbar("Quote saved", { variant: "success" });
            } else {
                enqueueSnackbar("Quote created", { variant: "success" });
                //remove navigate history
                navigate(`/projects/${project_id}/quotes/edit/${response.data.quote_id}`, { replace: true });
                
            }
            setWasModified(false);

            console.log("submited", response.data.quote_id);
            return newQuoteInfo;
        })
        .catch((err) => {
            console.log("submit", err);
            enqueueSnackbar("Error saving quote "+err.code, { variant: "error" });
            return {} as IQuoteInfo;
        });
    }

    const handleChangeHistory = ( status: any ) => {
        setHistory((prevState: any) => [...prevState, status]);
        setCurrentState(status);
        setWasModified(true);
    }

    const handleDeleteHistory = (index: any) => {
        setHistory((prevState: any) => [
            ...prevState.slice(0, index),
            ...prevState.slice(index + 1),
        ]);
        setWasModified(true);
    }

    /* ------->>> END BACKEND FUNCTIONS <<<------- */



    /* ------->>> RENDERIZATION <<<------- */
    return (
        <QuotesContext.Provider value={{
            // Utils
            setLoading,
            fixNumberValue,
            buildUniqueId,
            calcQuoteViewWorkforceCost,
            categories,
            services_types,
            categoriesFilters,
            getAlternateView,
            setViewType,
            handleSubmit,
            setExplodedItemAnchor,

            // Products
            productDict,
            updateProduct,
            deleteProduct,
            addProductToCategory,
            
            // Sections
            sectionList,
            sectionDict,
            setSectionList,
            setSectionDict,
            deleteSection,
            addSection,
            addItemToSection,
            createItemInSection,

            // Fabrications
            fabricationDict,
            updateFabrication,
            addFabricationToCategory,

            // Tasks
            taskDict,
            updateTask,
            deleteTask,
            addTaskToCategory,

            // Measures
            measureDict,
            updateMeasure,
            deleteMeasure,
            addMeasureToItem,

            serviceDict,
            updateService,
            deleteService,
            addServiceToQuote,

            costDict,
            updateQuoteCostType,
            updateQuoteCostValue,
            quoteCost,
            quoteInfo,

            previewDict,
            updatePreviewItem,
            updateQuoteInfo,
            folderDict,

            startProjectData,
            setStartProjectData,
            wasModified,
            setWasModified,
            wasSubmited,
            setWasSubmited,

            currentState,
            history,
            handleChangeHistory,
            handleDeleteHistory,



            openNewService,
            setOpenNewService,
            openNewSection,
            setOpenNewSection,
            openProductList,
            setOpenProductList,
            openProductEditor,
            setOpenProductEditor,
            openWorkforceList,
            setOpenWorkforceList,
            openWorkforceEditor,
            setOpenWorkforceEditor,
            openDeleteService,
            setOpenDeleteService,
            openSendDialog,
            setOpenSendDialog,
            openProductsSeparatedDialog,
            setOpenProductsSeparatedDialog,

            selectedItem,
            setSelectedItem,
            inputValueToDialogs,
            setInputValueToDialogs,
            setSelectedDeleteService,

            productAddedTrigger,
            setProductAddedTrigger,
            taskAddedTrigger,
            setTaskAddedTrigger,

            loadingFields,
            setLoadingFields,
            loadingFieldsDict,

            duplicateService,

            updateServiceCost,
        }}>
            {props.children}
        </QuotesContext.Provider>
    );

};