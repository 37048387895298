import React, { useEffect, useState } from 'react';
import { Add, DeleteOutlined, ModeEditOutline } from '@mui/icons-material';
import {
    Button,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Box from 'elements/Box';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import AlertDialog from 'elements/AlertDialog';

export default function ApplianceList( props:any ) {

    const {} = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [list, setList] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [page, setPage] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 1);

    const [openDelete, setOpenDelete] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [page]);

    const loadData = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_appliance_list',
                params: {
                    page: page,
                },
            },
        })
        .then((response) => {
            console.log('response/loadData/ApplianceList', response.data);
            setList(response.data.list);
            setTotalPages(response.data.total_pages);
        })
        .catch((error) => {
            console.log('error/loadData/ApplianceList', error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleDelete = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: 'delete_appliance',
                appliance_id: selectedId,
            },
        })
        .then((response) => {
            console.log('response/deleteItem/ApplianceList', response.data);
            loadData();
            setSelectedId(0);
        })
        .catch((error) => {
            console.log('error/deleteItem/ApplianceList', error);
        })
        .finally(() => {
            setLoading(false);
            setOpenDelete(false);
        });
    }

    return (
        <>
            <div
                id="ApplianceContainer"
                className="moduleContainer"
            >
                <div className="moduleWrapper">
                    <div className="moduleHeader buttons_top">
                        <h2>APPLIANCE</h2>

                        <div className="moduleButtons">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                onClick={() => {
                                    navigate('add');
                                }}
                            >
                                Add
                            </Button>
                        </div>
                    </div>

                    <div className="moduleContent">
                        <Box className="box100">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Reference</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {list.map((item: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.reference}</TableCell>
                                        <TableCell>${item.price.toLocaleString()}</TableCell>
                                        <TableCell sx={{minWidth: '100px'}} align='right'>
                                            <IconButton
                                                color="secondary"
                                                size="small"
                                                onClick={() => {
                                                    navigate('edit/' + item.appliance_id);
                                                }}
                                            >
                                                <ModeEditOutline />
                                            </IconButton>

                                            <IconButton
                                                color="secondary"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedId(item.appliance_id);
                                                    setOpenDelete(true);
                                                }}
                                            >
                                                <DeleteOutlined />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            {totalPages > 1 &&
                            <Pagination
                                color='primary'
                                sx={{ "& .MuiPagination-ul ": {justifyContent: 'center'} }}
                                count={totalPages}
                                page={Number(page)}
                                onChange={(event, value) => {
                                    setPage(value);
                                    setSearchParams({ payment_page: value.toString() });
                                }}
                            />
                            }
                        </Box>
                    </div>
                </div>
            </div>

            <AlertDialog
                open={openDelete}
                setOpen={setOpenDelete}
                subtitle="Are you sure you want to delete this appliance?"
                send={handleDelete}
            />
        </>
    );

}