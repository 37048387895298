import React, { useState, useEffect, useContext } from 'react';
import { ICategory, QuotesContext } from '../QuoteContextProvider';
import Box from 'elements/Box';
import QuoteProductRow from './QuoteProductRow';
import QuoteFabricationRow from './QuoteFabricationRow';
import QuoteTaskRow from './QuoteTaskRow';
import { Button, IconButton } from '@mui/material';
import { ViewInAr } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

const defaultValues: ICategory = {
    category_name: "",
    category: "",
    category_filter: "",
};

export default function QuoteCategoryBox ( props: any ) {

    const {
        getAlternateView,
        buildUniqueId,
        setViewType,
        productDict,
        addProductToCategory,
        addFabricationToCategory,
        taskDict,
        addTaskToCategory,
        costDict,
        setExplodedItemAnchor,
        loadingFields,
        loadingFieldsDict,
    } = useContext(QuotesContext);

    const {
        viewType = "Quote", // This component is for the quote view by default
        serviceIndex,
        categoryItem,
    } = props;

    const [item, setItem] = useState<ICategory>(defaultValues);
    const [rowList, setRowList] = useState<any[]>([]);

    const [alternateView, setAlternateView] = useState<string>('');

    useEffect(() => {
        if (props) {
            setAlternateView(getAlternateView(viewType));
            setItem({
                category_name: categoryItem.category_name,
                category: categoryItem.category,
                category_filter: categoryItem.category_filter,
                category_element_id: "catIndex" + getAlternateView(viewType) + serviceIndex + "-" + categoryItem.category,
            })

            if (categoryItem.category !== "workforce") {
                const productList = productDict[buildUniqueId(serviceIndex, categoryItem.category)];
                if (productList) {
                    setRowList(productList);
                } else {
                    setRowList([]);
                }
            } else {
                const taskList = taskDict[buildUniqueId(serviceIndex, categoryItem.category)];
                if (taskList) {
                    setRowList(taskList);
                } else {
                    setRowList([]);
                }
            }
        };
    }, [props]);

    return (
        <>
            <Box className="categoryRow">
                <div id={item.category_element_id} className="categoryRowHeader"> 
                    <h2>
                        {item.category_name}
                        
                        {(item.category == "panel" || item.category == "stone") && (
                            <IconButton
                                aria-label={viewType}
                                size="small"
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    setViewType("exploded")
                                    setExplodedItemAnchor(`catIndex${viewType}${serviceIndex}-${categoryItem.category}`);
                                }}
                            >
                                <ViewInAr />
                            </IconButton>
                        )}
                        
                    </h2>
                    {loadingFields && loadingFieldsDict[buildUniqueId(serviceIndex, item.category)] ?
                        <CircularProgress color="secondary" size="1rem" /> :
                        <span>${(costDict[serviceIndex][buildUniqueId(serviceIndex, item.category)] || 0).toLocaleString()}</span>
                    }
                </div>
                <table className="categoryRowTable">
                    <thead>
                        {item.category === "workforce" &&
                        <tr>
                            <th>Task</th>
                            <th>Hours</th>
                            <th className="align_right">Hour Cost</th>
                            <th className="align_right">Total</th>
                            <th></th>
                        </tr>
                        }

                        {item.category !== "workforce" &&
                        <tr>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Section</th>
                            <th className="align_right">Unit</th>
                            <th className="align_right">Total</th>
                            <th></th>
                        </tr>
                        }
                    </thead>
                    <tbody>
                        {item.category !== "workforce" &&
                        rowList.map((_, itemIndex:any) => (
                            <>
                                <QuoteProductRow
                                    serviceIndex={serviceIndex}
                                    category={item.category}
                                    itemIndex={itemIndex}
                                    key={itemIndex}
                                />

                                {item.category =="stone" &&
                                <QuoteFabricationRow
                                    serviceIndex={serviceIndex}
                                    category={item.category}
                                    itemIndex={itemIndex}
                                    key={itemIndex + "fabrication"}
                                />
                                }
                            </>
                        ))
                        }

                        {item.category === "workforce" &&
                        rowList.map((_, itemIndex:any) => (
                            <QuoteTaskRow
                                serviceIndex={serviceIndex}
                                category={item.category}
                                itemIndex={itemIndex}
                                key={itemIndex}
                            />
                        ))
                        }
                    </tbody>
                </table>

                <Button
                    className="item_add_button"
                    onClick={()=>{
                        if(item.category == "workforce") addTaskToCategory(serviceIndex, item.category);
                        else {
                            if(item.category == "stone") addFabricationToCategory(serviceIndex, item.category);
                            addProductToCategory(serviceIndex, item.category);
                        }
                    }}
                >
                    Add item
                </Button>
            </Box>
        </>
    )

}