import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import AlertDialog from 'elements/AlertDialog';
import { useSnackbar } from 'notistack';


export default function SupplierList (props: any) {
  useEffect(() => {props.setLoading(false);}, []);

  const [loading, setLoading] = useState(false); 
  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'supplier_list',
          params: {
            page: page,
          }
        }
      })
        .then((response) => {
          console.log(response.data);
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/

  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (supplier_id:number) => {
    setOpenAlert(true);
    setActiveId(supplier_id);
  }

  const deleteItem = () => {

    axios.post(ProjectConfig.api_url, null, 
      {
        params: {
          request: 'delete_supplier',
          supplier_id: activeId,
        }
      })
  
      .then((response) => {
        enqueueSnackbar('Supplier deleted', {variant: 'error'});
        setRefreshList(refreshList + 1)
        
      })
      .catch((err) => {
        console.log(err.data);
      })
  }

  /*=========================================================*/

// call global snackbar



const [openAlert, setOpenAlert] = useState(false);
const [activeId, setActiveId] = useState<any>(null);
  

  
  return (
    <div id='supplierList' className='moduleContent'>
      
      <div className='buttons_top'>
        <div></div>
        <Button component={Link} to="add" variant="contained">
          New Supplier
        </Button>

      </div>
        <div id="supplier_list_container" className="flex_container">
          
            <Box id='supplierListBox' title="Suppliers" subtitle="List"  >
            {!loading && <div id='loader'><CircularProgress /></div>}
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {list.map((item,i) => (
                    <TableRow 
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell onClick={()=> {window.location.href = `suppliers/edit/${item.supplier_id}`}} >
                        <AppAvatar name={item.name} image="" />
                      </TableCell>
                      <TableCell >
                        <Link to={`/suppliers/edit/${item.supplier_id}`}>
                          {item.name}
                        </Link>
                      </TableCell>

                     
                      <TableCell>
                        {item.email}
                      </TableCell>

                      <TableCell>
                        {item.address}
                      </TableCell>

                      <TableCell>
                        {item.phone}
                      </TableCell>


                      <TableCell> 
                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(item.supplier_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
 
        </div>

        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          subtitle="Are you sure you want to delete this supplier?"
          send={deleteItem}
        />
    </div>
  );
};


