import { HomeRepairService } from '@mui/icons-material';
import { Button } from '@mui/material';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function WorkerTiming( props: any ) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { props.setLoading(loading) }, [loading]);

    const [list, setList] = useState<any[]>([]);

    useEffect(() => {
        loadList();
    }, []);

    const loadList = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            headers: {
                'LTKN': cookies.get('ltkn'),
            },
            params: {
                request: 'get_user_projects_list',
            }
        })
        .then((response) => {
            console.log(response.data);
            setList(response.data.list);
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <div id="workertimmingUser" className='moduleContainer' style={{ maxWidth: 'unset' }}>
                <div className='moduleWrapper'>
                    <div className="box_header buttons_top">
                        <h1>Projects</h1>
                    </div>

                    <div id="project_list_container" className="flex_container">
                        {list.map((project: any) => (
                        <div className="card project_element" key={project.project_id}>
                            <Button
                                component={Link}
                                to={'/worker-timing/projects/' + project.project_id}
                                color='secondary'
                                className="card_content"
                            >
                                <HomeRepairService color='disabled' />

                                <div className='project_list_name'>
                                    <h2 className='card_title'>{project.project_name}</h2>
                                    <span className='card_subtitle'>{project.client_name}</span>
                                </div>
                            </Button>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}