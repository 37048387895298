import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TextField,
  TextFieldProps,
  createFilterOptions,
  TableCell
} from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import TuneIcon from '@mui/icons-material/Tune';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { LocalizationProvider, MobileDatePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EventIcon from '@mui/icons-material/Event';
import { Tune } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import AutocompleteMulti from "elements/AutocompleteMulti";

import ic_dots from 'icons/ic_dots.svg';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import ClientEditor from 'components/Clients/ClientEditor';
import DateRange from 'elements/DateRange';
import moment from 'moment';

interface Props {
  open: boolean;
  setOpen: any;
  filters: Array<any>;
  setFilters: any;
  setApplyFilters?: any;
}

export default function Filters( props: Props ) {

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const [tempFilters, setTempFilters] = useState<any>({});
  const [tempAppliedFilters, setTempAppliedFilters] = useState<any>({});
  
  const handleTempFilters = (filter: any, value: any) => {
    if (value === null || value === undefined || value === "") {
      delete tempFilters[filter.name];
      setTempFilters({...tempFilters});
    } else {
      setTempFilters({...tempFilters, [filter.name]: value});
    }
  }

  const handleAppliedFilters = (filterName: any, value: any) => {
    if (value === null || value === undefined || value === "") {
      delete tempAppliedFilters[filterName];
      setTempAppliedFilters({...tempAppliedFilters});
    } else {
      setTempAppliedFilters({...tempAppliedFilters, [filterName]: value});
    }
  }

  const handleCompositeTempFilters = (filter: any, value: any, compositeAttributes: any) => {
    if (value === null || value === undefined || value === "") {
      delete tempFilters[filter.name[compositeAttributes]];
      setTempFilters({...tempFilters});
    } else {
      setTempFilters((prevState: any) => {
        return {
          ...prevState,
          [filter.name[compositeAttributes]]: value
        }
      });
    }
  }
  
  useEffect(() => {
  }, [tempFilters]);

  //TODAY 
  const today: any = new Date();
  const [selectedDate, handleDateChange] = useState(today);
  const [validationError, setValidationError] = useState(false);

  /** FOR PROJECTS */
  const [projectInput, setProjectInput] = useState<any | null>(null);
  const [projectList, setProjectList] = useState<any | null>([]);
  const [openClients, setOpenClients] = useState(false);

  const loadProjectList = () => {
    axios.get(ProjectConfig.api_url,{
        params: {
          request: 'project_list',
          params: {
            full_list: true
          }
        }
      })
      .then((response) => {
        setProjectList(response.data.list)
      })
      .catch((err) => {
        console.log(err.data);
      })
  }

  const handleOpenClientList = () => {
    loadProjectList();
    setOpenClients(true);
  }

  const filterProjects = createFilterOptions<any>();

  /** FOR CLIENTS */
  const [clientInput, setClientInput] = useState<any | null>(null);
  const [clientList, setClientList] = useState<any | null>([]);
  const [openProjects, setOpenProjects] = useState(false);

  const loadClientList = () => {
    axios.get(ProjectConfig.api_url,{
        params: {
          request: 'client_list',
          full_list: true,
        }
      })
      .then((response) => {
        setClientList(response.data.list)
      })
      .catch((err) => {
        console.log(err.data);
      })
  }

  const handleOpenProjectList = () => {
    loadClientList();
    setOpenProjects(true);
  }

  const filterClients = createFilterOptions<any>();

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="menu"
        onClick={() => props.setOpen(true)}
      >
        <Tune />
      </IconButton>

      <Dialog
        className='filtersDialog'
        open={props.open}
        onClose={() => props.setOpen(false)}
        fullWidth
        maxWidth='sm'
      >
        <DialogContent className='forced_box_editor'>
          <h2>Filters</h2>
          <div className='filtersForm'>
            {props.filters && props.filters.map((filter, index) => (
              <div className="filter" key={index}>
                
                {filter.type === "text" && (
                  <TextField 
                  variant="outlined"
                    label={filter.label}
                    fullWidth
                    defaultValue={tempFilters[filter.name]}
                    onChange={(e) => {
                      handleTempFilters(filter, e.target.value)
                      handleAppliedFilters(filter.name, e.target.value)
                    }}
                    />
                )}

                {filter.type === "date" && (
                  
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StaticDatePicker className='filters_datePicker'
                      label={filter.label}
                      displayStaticWrapperAs="mobile"
                      value={tempFilters[filter.name] || new Date()}
                      showToolbar={false}
                      onChange={(newValue) => {
                        if (newValue) {
                          handleTempFilters(filter, newValue.format('YYYY-MM-DD'));
                          handleAppliedFilters(filter.name, newValue.format('YYYY-MM-DD'));
                        }
                        handleDateChange(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                )}
                
                {filter.type === "select" && (
                  <TextField 
                  variant="outlined"
                  label={filter.label}
                  fullWidth
                  select
                  value={tempFilters[filter.name] || ""}
                  onChange={(e) => {
                    handleTempFilters(filter, e.target.value)
                    handleAppliedFilters(filter.name, filter.options.filter((option: any) => option.value === e.target.value)[0].label)
                  }}
                  >
                      {filter.options && filter.options.map((option: any, index: number) => (
                        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                        ))}
                  </TextField>
                )}

                {filter.type === "dateRange" && (
                  <div className='input_row'>
                    <DateRange
                      className='filters_datePicker'
                      label='Date range'
                      variant="outlined"
                      fullWidth
                      value={{
                        startDate: tempFilters[filter.name.start] || new Date(),
                        endDate: tempFilters[filter.name.end] || new Date(),
                      }}
                      format="MMMM DD, yyyy"
                      onChange={(e: any) => {
                        if (e.startDate && e.endDate) {
                          const startDate = moment(e.startDate).format('YYYY-MM-DD');
                          const endDate = moment(e.endDate).format('YYYY-MM-DD');

                          handleCompositeTempFilters(filter, startDate, "start");
                          handleCompositeTempFilters(filter, endDate, "end");
                          handleAppliedFilters(tempFilters[filter.name.composite], {value: `${startDate} - ${endDate}`, names: [filter.name.start, filter.name.end]})
                        }
                      }}
                      variantDatePicker="side-by-side"
                    />
                  </div>
                )}

                {filter.type === "project" && (
                  <>
                  <Autocomplete
                    onFocus={() => {
                      if(projectList?.length === 0) {
                        loadProjectList();
                      }
                    }}
                    value={(projectList.filter((project: any) => tempFilters[filter.name] == project.project_id)[0]?.project_name) || ""}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        setProjectInput({ project_name: newValue, });
                      } else if (newValue && newValue.inputValue) {
                        setProjectInput({ project_name: newValue.inputValue, });
                      } else {
                        setProjectInput({ project_name: newValue, });
                        if (newValue?.project_id != null) {
                          handleTempFilters(filter, newValue.project_id.toString());
                          handleAppliedFilters(filter.name, newValue.project_name);
                        }
                        else {
                          handleTempFilters(filter, "");
                          handleAppliedFilters(filter.name, "");
                        }
                      }
                    }}
                    // filterOptions={(options, params) => {
                    //   const filtered = filterProjects(options, params);
                    //   const { inputValue } = params;
                    //   // Suggest the creation of a new value
                    //   const isExisting = options.some((option) => inputValue === option.project_name);
                    //   if (inputValue !== '' && !isExisting) {
                    //     filtered.push({
                    //       inputValue,
                    //       project_name: `Add "${inputValue}"`,
                    //     });
                    //   }
                    //   return filtered;
                    // }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    fullWidth
                    id="free-solo-with-text-demo"
                    options={projectList}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.project_name;
                    }}
                    renderOption={(props, option) =>
                      <li className="flex_select" {...props}>
                        <div className="flex_option_1">{option.project_name}</div>
                        <div className="flex_option_2">{option.client_name}</div>
                      </li>
                    }
                    sx={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        label="Project" 
                        InputProps={{
                          ...params.InputProps,
                          // startAdornment: (
                          //   <IconButton 
                          //   onClick={handleOpenClientList}
                            
                          //   ><img src={ic_dots}></img></IconButton>
                          //   )
                        }}
                      />
                      )}

                      /> 
                </>
                )}

                {filter.type === "workers" && (
                  <AutocompleteMulti
                    setSelectedItems={(values: any) => {
                      handleTempFilters(filter, values)
                      handleAppliedFilters(filter.name, {value: values, names: [filter.name]})
                    }}
                    selectedItems={tempFilters[filter.name] || []}
                  />
                )}

                {filter.type === "client" && (
                  <>
                  <Autocomplete
                    onFocus={() => {
                      if(clientList?.length === 0) {
                        loadClientList();
                      }
                    }}
                    value={(clientList.filter((client: any) => tempFilters[filter.name] == client.client_id)[0]?.client_name) || ""}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        setClientInput({ client_name: newValue, });
                      } else if (newValue && newValue.inputValue) {
                        setClientInput({ client_name: newValue.inputValue, });
                      } else {
                        setClientInput({ client_name: newValue, });
                        if (newValue?.client_id != null) {
                          handleTempFilters(filter, newValue.client_id.toString());
                          handleAppliedFilters(filter.name, newValue.client_name);
                        }
                        else {
                          handleTempFilters(filter, "");
                          handleAppliedFilters(filter.name, "");
                        }
                      }
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    fullWidth
                    id="free-solo-with-text-demo"
                    options={clientList}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.client_name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.client_id}>
                        {option.client_name}
                      </li>
                    )}
                    sx={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField 
                      {...params} 
                      label="Client Name" 
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <IconButton 
                            onClick={handleOpenClientList}
                            
                          ><img src={ic_dots}></img></IconButton>
                        )
                      }}/>
                    )}
                    
                  />


                  <Dialog 
                    open={openClients}
                    onClose={() => setOpenClients(false)}
                    fullWidth
                    maxWidth="md"
                  >
                    <DialogContent>
                      <div className="flex">
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Client Name</TableCell>
                              <TableCell>Client Email</TableCell>
                              <TableCell>Client Address</TableCell>
                              <TableCell>Client Phone</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {clientList.map((client: any) => (
                              <TableRow
                                hover
                                key={client.client_id}
                                onClick={() => {
                                  handleTempFilters(filter, client.client_id.toString());
                                  handleAppliedFilters(filter.name, client.client_name);
                                  setOpenClients(false);
                                }}
                              >
                                <TableCell>{client.client_name}</TableCell>
                                <TableCell>{client.client_email}</TableCell>
                                <TableCell>{client.client_address}</TableCell>
                                <TableCell>{client.client_phone}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </DialogContent>
                  </Dialog>
                  </>
                )}
              </div>
            ))}
          </div>

          <div className='buttons'>
            <Button
              variant="contained"
              onClick={() => {
                props.setFilters(tempFilters)
                props.setApplyFilters(tempAppliedFilters)
                props.setOpen(false)
              }}
            >
              Apply
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
