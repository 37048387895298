import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Box from './Box';
import ic_dots from "icons/ic_dots.svg";

interface IAutocompleteList {
  name: string;
  id: number;
}



export default function ACProjectList(props: any) {


    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const handleOpenList = () => {
      setOpen(true);
    };


/* =================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface IProjectList {
      inputValue?: string,
      project_id?: number,
      project_name: string,
    };
    const filter = createFilterOptions<IProjectList>();

    //setClientList
    const [project_list, setProjectList] = useState<any []>([]);
    const [project, setProject] = useState<IProjectList | null>(null);
    
    useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'project_list',
          params: {
            full_list: true,
          },
        }
      })
        .then((response) => {
          console.log(response.data);
          setProjectList(response.data.list)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
    }, [])
    /* ================================================= 
    ===================================================*/

    let item_name = "";
    if (project_list && (props.value && props.value!=0)) 
    {
      
      item_name = project_list.find(obj => {
        return obj.project_id === props.value; 
      }).project_name;
    } 
    
    
  return (
    <>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <div style={{outline:'none'}}>
          <Box id='project_list' title="Clients" subtitle="List"  >
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Project name</TableCell>
                  <TableCell>Client</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {project_list.map((project,i) => (
                    <TableRow 
                      onClick={() => (
                      setProject({
                        project_name: project.project_name,
                      }),
                      props.onChange(
                        {
                            target: {
                                value: project.project_id.toString(),
                                name: "project_id",
                            }
                        }
                    ),

                      handleClose()
                      )} 
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell>{project.project_name}</TableCell>
                      <TableCell>{project.client_name}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Box>
        </div>
      </Modal>

      <Autocomplete
                value={item_name}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setProject({
                      project_name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setProject({
                      project_name: newValue.inputValue,
                    });
                  } else {
                    setProject(newValue);
                    if (newValue?.project_id!=null)
                    {
                      props.onChange({
                          target: {
                              value: newValue.project_id.toString(),
                              name: "project_id",
                          }
                      })
                    }
                    else {
                      props.onChange({
                        target: {
                            value: "",
                            name: "project_id",
                        }
                      })
                    }
                    
                  }
                  
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.project_name);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      project_name: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                fullWidth
                id="free-solo-with-text-demo"
                options={project_list}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.project_name;
                }}
                renderOption={(props, option) => <li className="flex_select" {...props}>
                  <div className="flex_option_1">{option.project_name}</div>
                  <div className="flex_option_2">{option.client_name}</div>
                </li>}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                  <TextField 
                  {...params} 
                  label="Project" 
                  placeholder='Project'
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <IconButton 
                        onClick={handleOpenList}
                        
                      ><img src={ic_dots}></img></IconButton>
                    )
                  }}/>
                )}
                
              /> 
    </>
  );
}
