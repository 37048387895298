import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import { InventoryOutlined } from '@mui/icons-material';


export default function UserList (props: any) {

  const navigate = useNavigate();

  useEffect(() => {props.setLoading(false);}, []);
  
  const [loading, setLoading] = useState(false); 
  const [user_list, setUserList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'user_list',
          params: {
            page: page,
          }
        }
      })
        .then((response) => {
          console.log(response.data);
          
          setUserList(response.data.user_list)
          setTotalPages(response.data.total_pages)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/

  console.log("ul ",user_list); 

  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (user_id:number) => {
    axios.post(ProjectConfig.api_url, null, 
    {
      params: {
        request: 'delete_user',
        user_id: user_id,
      }
    })

    .then((response) => {
      console.log(response.data);
      setRefreshList(refreshList + 1)
      
    })
    .catch((err) => {
      console.log(err.data);
    })
  }
  /*=========================================================*/

// call global snackbar
  

  
  return (
    <div id='ProductList'>
      
      <div className='buttons_top'>
        <Button component={Link} to="add" variant="contained">
          New User
        </Button>

      </div>
        <div id="task_list_container" className="flex_container">
          
            <Box id='user_list' title="Users" subtitle="List"  >
            {!loading && <div id='loader'><CircularProgress /></div>}
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {user_list.map((user,i) => (
                    <TableRow 
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell onClick={()=> {window.location.href = `users/edit/${user.user_id}`}} >
                        <AppAvatar name={user.name} image={user.image} />
                      </TableCell>
                      <TableCell onClick={()=> {window.location.href = `users/edit/${user.user_id}`}} >{user.name}</TableCell>

                     
                      <TableCell>
                        {user.role_name}
                      </TableCell>

                      <TableCell>
                        <IconButton onClick={() => navigate(`/users/${user.user_id}/performance`)} >
                          <InventoryOutlined />
                        </IconButton>

                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(user.user_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
 
        </div>
    </div>
  );
};

