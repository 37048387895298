import React, { useContext, useState, } from "react";
import Box from "elements/Box";
import logo from 'assets/img/logo.png';
import { Button, Dialog, DialogContent, DialogContentText, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { ProjectConfig } from "Global";
import { useSnackbar } from 'notistack';
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import PreviewServiceBox from "../PreviewView/PreviewServiceBox";
import { QuotesContext } from "../QuoteContextProvider";
import { CloudDownloadOutlined, Send } from "@mui/icons-material";

export default function QuotePreview(props:any){

  const {
    quoteCost,
    quoteInfo,
  } = useContext(QuotesContext);

  const {
    profitPercentages,
  } = props;

      /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    const formValues = props.formValues;
    const [sendEmailDialogOpen, setSendEmailDialogOpen] = useState(false);

    const handleSendEmail = () => {
        props.handleSubmit();
        axios
          .post(ProjectConfig.api_url, null, {
            params: {
              request: "send_email_project_quote",
              quote_id: props.quote_id,
            },
          })
          .then((response) => {
            enqueueSnackbar("The email was sent successfully", {
              variant: "success",
              });
              setSendEmailDialogOpen(false);
          })
          .catch((err) => {
            enqueueSnackbar("There was an error sending the email", {
                variant: "error",
                });
            console.log(err.data);
          });
      };






      const handleDownload = () => {
        props.handleSubmit();
        axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "download_project_quote",
              quote_id: props.quote_id,
            },
          })
          .then((response) => {
            window.open(response.data, "_blank", "noopener,noreferrer");
          })
          .catch((err) => {
            console.log(err.data);
          });
      };

     
  /*====================================================
  ====================================================
    RENDER 
  ====================================================
  ====================================================*/
  return (
    <div id="QuotePreviewContainer" style={{display: props.showQuotePreview ? "flex" : "none"}}>
        <Box id='quotePreviewBox'>
            

            {/* HEADER  */}
            <div className="quotePreview__row">
                <div className="quotePreview_flex_left">
                    <img src={logo} alt="logo" width={150} />
                    <span>Airport Industrial Park</span>
                    <span>Nassau, Bahamas 242.424.7113</span>

                </div>
                <div className="quotePreview_flex_right">
                    <span className="quotePreview__header__right__title">Price Quote</span>
                    <span className="quotePreview__header__right__date">November 29 / 2022</span>
                    <span className="quotePreview__header__right__quoteNumber">Quote # {props.quote_id}</span>

                </div>
            </div>


            {/* CLIENT INFO */}
            <div className="quotePreview__row">
                <div className="quotePreview_flex_left">
                    <span className="quotePreview__clientInfo__title">Bill to</span>
                    <span className="quotePreview__clientInfo__left__name">{props.projectInfo.client_name}</span>
                    <span className="quotePreview__clientInfo__left__address">{props.projectInfo.client_address}</span>
                </div>
                <div className="quotePreview_flex_right">
                    <span className="quotePreview__clientInfo__title">Project Information</span>
                    <span className="quotePreview__clientInfo__right__name">{props.projectInfo.project_name}</span>
                </div>
            </div>

            {/* MAP SERVICES */}
            {props.services.map((service:any, serviceIndex:any) => {

                return(
                    <div className="QuoteService" key={serviceIndex}>
                        <div className="QuoteServiceItems">
                            <div className="QuoteServiceHeader">
                                <div className="QuoteServiceHeaderLeft">
                                    <span className="QuoteServiceName">{service.name}</span>


                                    {/* MAP CATEGORIES (PANELES, PAINT, HARDWARE) */}
                                    {props.categories[service.type].map((category:any, categoryIndex:any) => {
                                   
                                        const category_var = category.category_var;

                                        return(
                                        
                                        <div className="quoteTextsContainer" key={categoryIndex}>

                                            {

                                                /*=================================
                                                * MILLWORK OR REMODELING
                                                =================================*/
                                                service.type === "millwork" || service.type === "remodeling" ?(
                                                  category_var != "workforce" &&
                                                  service[category_var].products.map((item:any, itemIndex:any) => {
                                                    if(item.section === "") { return null}
                                                    else {
                                                      return(
                                                          <TextField 
                                                              key={itemIndex}
                                                              label={item.product_name} 
                                                              fullWidth
                                                              // maxRows={5}
                                                              // multiline
                                                              InputLabelProps={{
                                                                  style: { fontSize: '12px' }
                                                              }}
                                                              value={item.quoteText}
                                                              inputProps={{
                                                                  style: {
                                                                      fontSize: '12px',
                                                                  }
                                                              }}

                                                              onChange={(e:any) => {
                                                                  const newServices = [...props.services];
                                                                  newServices[serviceIndex][category_var].products[itemIndex].quoteText = e.target.value;
                                                                  props.setServices(newServices);
                                                              }}
                                                          />
                                                      )
                                                    }
                                                })) :
                                                /*=================================
                                                * STONE
                                                =================================*/
                                                (service[category_var].products.map((item:any, itemIndex:any) => {
                                                    if(item.section === "") { return null}
                                                    else {
                                                      return(
                                                          <TextField 
                                                              key={itemIndex}
                                                              label={item.product_name}
                                                              fullWidth
                                                              maxRows={5}
                                                              multiline
                                                              InputLabelProps={{
                                                                  style: { fontSize: '12px' }
                                                              }}
                                                              value={item.quoteText}
                                                              inputProps={{
                                                                  style: {
                                                                      fontSize: '12px',
                                                                  }
                                                              }}

                                                              onChange={(e:any) => {
                                                                  const newServices = [...props.services];
                                                                  newServices[serviceIndex][category_var].products[itemIndex].quoteText = e.target.value;
                                                                  props.setServices(newServices);
                                                              }}
                                                          />
                                                      )
                                                    }
                                                }))

                                                
                                                
                                            }

                                            </div>
                                        )
                                    })}


                                </div>
                                <div className="QuoteServiceHeaderRight">
                                    {profitPercentages[service.type] ?
                                    <div className="QuoteServiceTotal">
                                      ${(service.service_cost + service.service_cost * profitPercentages[service.type] / 100).toLocaleString()}
                                    </div>
                                    :
                                    <div className="QuoteServiceTotal">${(service.service_cost || 0).toLocaleString()}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}


            <div id='quotePreview_footer' className="quotePreview__row">
                <div className="QuoteServiceHeaderLeft">
                    <TextField 
                        label="Notes"
                        fullWidth
                        multiline
                        InputLabelProps={{
                            style: { fontSize: '12px' }
                        }}
                        value={formValues.quotePDFinformation}
                        inputProps={{
                            style: {
                                fontSize: '12px',
                            }
                        }}
                        onChange={(e:any) => {
                            props.setFormValues({
                                ...formValues,
                                quotePDFinformation: e.target.value
                            })
                        }}
                    />

                </div>
                    <div id="QuotePreviewSummary">

                        <div className="summary_row">
                            <div className="summary_row_left">Subtotal</div>
                            <div className="summary_row_right">
                                ${(formValues.subtotal || 0).toLocaleString()}
                            </div>
                        </div>

                        {formValues.have_tax && (
                        <div className="summary_row">
                            <div className="summary_row_left">Tax (10%)</div>
                            <div className="summary_row_right">
                                ${(formValues.tax || 0).toLocaleString()}
                            </div>
                        </div>
                        )}

                        <div className="summary_total">
                            <div className="summary_total_label">Total Quote</div>
                            <div className="summary_total_value">
                                ${(formValues.total || 0).toLocaleString()}
                            </div>
                        </div>
                        
                    </div>
            </div>
            
            

            <div className="buttons">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDownload}
              startIcon={<CloudDownloadOutlinedIcon />}
            >
              Download PDF
            </Button>

            <Button 
              variant="contained" 
              endIcon={<SendIcon />}
              onClick={() => {
                setSendEmailDialogOpen(true);
              }}>
              Send Email
            </Button>
          </div>
        </Box>


        <Dialog open={sendEmailDialogOpen} onClose={() => setSendEmailDialogOpen(false)}>
        <DialogContent id="sendEmailDialog" className="forced_box_editor">
          <h2>Send Email</h2>
          <DialogContentText>
            This action will send an email with the current quote. Do you want to continue?
          </DialogContentText>
          <div className="buttons">
          <Button
              variant="outlined"
              color="primary"
              onClick={() => { setSendEmailDialogOpen(false); }}
            >
              Cancel
            </Button>

            <Button 
              variant="contained" 
              endIcon={<SendIcon />}
              onClick={() => {
                handleSendEmail();
              }}>
              Confirm and Send Email
            </Button>
          </div>
        </DialogContent>
        
      </Dialog>
        


    </div>
  );
};



 ;