import React, { useContext } from 'react';
import axios from 'axios';
import {
    Button,
    TextField,
} from '@mui/material';
import {
    CloudDownloadOutlined,
    Send,
} from '@mui/icons-material';

import { QuotesContext } from '../QuoteContextProvider';
import logo from 'assets/img/logo.png';
import Box from 'elements/Box';
import PreviewServiceBox from './PreviewServiceBox';
import { ProjectConfig } from 'Global';

export default function PreviewView ( props: any ) {

    const {
        quoteCost,
        quoteInfo,
        serviceDict,
        updateQuoteInfo,
        handleSubmit,
        setOpenSendDialog,
    } = useContext(QuotesContext);

    /*====================================================
    ====================================================
    RENDER
    ====================================================
    ====================================================*/

    const handleDownload = async () => {
        await handleSubmit()
            .then((res: any) => {

                if (res.quote_id === undefined) return;

                console.log('start download', res.quote_id)
                
                axios.get(ProjectConfig.api_url, {
                    params: {
                        request: "download_project_quote",
                        quote_id: res.quote_id,
                    },
                })
                .then((response) => {
                    window.open(response.data, "_blank", "noopener,noreferrer");
                })
                .catch((err) => {
                    console.log(err.data);
                });
            });
    }

    return (
        <>
            <div id="QuotePreviewContainer">
                <Box id='quotePreviewBox'>
                    {/* HEADER  */}
                    <div className="quotePreview__row">
                        <div className="quotePreview_flex_left">
                            <img src={logo} alt="logo" width={150} />
                            <span>Airport Industrial Park</span>
                            <span>Nassau, Bahamas 242.424.7113</span>
                        </div>
                        
                        <div className="quotePreview_flex_right">
                            <span className="quotePreview__header__right__title">Price Quote</span>
                            <span className="quotePreview__header__right__date">November 29 / 2022</span>
                            <span className="quotePreview__header__right__quoteNumber">Quote # {quoteInfo.quote_id}</span>
                        </div>
                    </div>

                    {/* CLIENT INFO */}
                    <div className="quotePreview__row">
                        <div className="quotePreview_flex_left">
                            <span className="quotePreview__clientInfo__title">Bill to</span>
                            <span className="quotePreview__clientInfo__left__name">{quoteInfo.client_name}</span>
                            <span className="quotePreview__clientInfo__left__address">{quoteInfo.client_address}</span>
                        </div>

                        <div className="quotePreview_flex_right">
                            <span className="quotePreview__clientInfo__title">Project Information</span>
                            <span className="quotePreview__clientInfo__right__name">{quoteInfo.project_name}</span>
                        </div>
                    </div>

                    {/* MAP SERVICES */}
                    {Object.keys(serviceDict).map((service:any, serviceIndex:any) => (
                        <PreviewServiceBox
                            serviceIndex={serviceIndex}
                            key={serviceIndex}
                        />
                    ))}

                    <div id='quotePreview_footer' className="quotePreview__row">
                        <div className="QuoteServiceHeaderLeft">
                            <TextField 
                                label="Notes"
                                fullWidth
                                multiline
                                InputLabelProps={{
                                    style: {
                                        fontSize: '12px',
                                    }
                                }}
                                value={quoteInfo.quote_text}
                                inputProps={{
                                    style: {
                                        fontSize: '12px',
                                    }
                                }}
                                onChange={(e:any) => {
                                    updateQuoteInfo({
                                        ...quoteInfo,
                                        quote_text: e.target.value,
                                    })
                                }}
                            />
                        </div>

                        <div id="QuotePreviewSummary">
                            <div className="summary_row">
                                <div className="summary_row_left">Subtotal</div>
                                <div className="summary_row_right">
                                    ${(quoteCost.subtotal_cost || 0).toLocaleString()}
                                </div>
                            </div>

                            {quoteCost.have_tax && (
                                <div className="summary_row">
                                    <div className="summary_row_left">Tax ({quoteCost.tax_percentage}%)</div>
                                    <div className="summary_row_right">
                                        ${(quoteCost.tax_cost || 0).toLocaleString()}
                                    </div>
                                </div>
                            )}

                            <div className="summary_total">
                                <div className="summary_total_label">Total Quote</div>
                                <div className="summary_total_value">
                                    ${(quoteCost.total_cost || 0).toLocaleString()}
                                </div>
                            </div>
                                
                        </div>
                    </div>
                    
                    

                    <div className="buttons">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleDownload}
                            startIcon={<CloudDownloadOutlined />}
                        >
                            Download PDF
                        </Button>

                        <Button 
                            variant="contained" 
                            endIcon={<Send />}
                            onClick={() => {
                                setOpenSendDialog(true);
                            }}
                        >
                            Send Email
                        </Button>
                    </div>
                </Box>
            </div>
        </>
    );
}