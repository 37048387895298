import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import ProjectHeader from 'components/Projects/utils/ProjectHeader';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import EditIcon from '@mui/icons-material/Edit';


export default function OrderList (props: any) {

  const {
    setLoading,
    setMenuTopLeftContent,
    with_set_top_menu = false,
  } = props;

  useEffect(() => {setLoading(false);}, []);
  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");


  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);


    /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
    =======================================*/
    let { project_id } = useParams();

    /*=======================================
    =======================================*/


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    loadData();
    localStorage.setItem('page', page.toString());
  }, [page, refreshList])
  /*=========================================================*/

  const loadData = () => {
    setLoading(true)
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'order_list',
          page: page,
          project_id: project_id? project_id : 0,
        }
      })
        .then((response) => {
          setList(response.data)
          setTotalPages(response.data.total_pages)
          
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          setLoading(false);
        });
  }


  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (order_id:number) => {
    axios.post(ProjectConfig.api_url, null, 
    {
      params: {
        request: 'delete_order',
        order_id: order_id,
      }
    })

    .then((response) => {
      console.log(response.data);
      setRefreshList(refreshList + 1)
      
    })
    .catch((err) => {
      console.log(err.data);
    })
  }
  /*=========================================================*/

// call global snackbar
  

  
  return (
    <div id='ProductList'>
      <ProjectHeader>
        <Button component={Link} to="add" variant="contained">
          New Order
        </Button>
      </ProjectHeader>
      

        <div id="orders_list_container" className="flex_container">
          
            <Box id='orders' title="Orders" subtitle="List"  >
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Creation date</TableCell>
                  <TableCell>Arrival date</TableCell>
                  <TableCell>Supplier</TableCell>
                  <TableCell>Project</TableCell>
                  <TableCell>Cost</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {list.map((item,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell>{item.order_id}</TableCell>

                      <TableCell>
                        {item.create_date && moment(item.create_date).format('MMMM DD, YYYY')}
                      </TableCell>
                      <TableCell>
                        {item.arrival_date && moment(item.arrival_date).format('MMMM DD, YYYY')}
                      </TableCell>
                      <TableCell>
                        {item.supplier_name}
                      </TableCell>
                      <TableCell>
                        {item.project_name}
                      </TableCell>
                      <TableCell>
                        ${item.cost.toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {item.order_status}
                      </TableCell>

                      <TableCell className="actionsColumn align_right">
                          <Button
                            component={Link}
                            to={`/orders/edit/${item.order_id}`}
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                          >
                            Edit
                          </Button>

                        <IconButton onClick={()=> {window.location.href = `orders/incoming/${item.order_id}`}} >
                          <InventoryOutlinedIcon />
                        </IconButton>


                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(item.order_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
 
        </div>
    </div>
  );
};
