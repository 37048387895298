import { Autocomplete, Button, createFilterOptions, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from 'icons/ic_dots.svg';
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import AutocompleteMulti from "elements/AutocompleteMulti";
import ProjectHeader from "components/Projects/utils/ProjectHeader";
import ACSupplierList from "elements/ACSupplierList";
import ACProjectList from "elements/ACProjectList";
import FilesBrowser from "components/Files/FilesBrowser";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import AutocompleteProduct from "components/Projects/utils/AutoCompleteProduct";

export default function OrderEditor (props: any) {

  const {
    setLoading,
    setMenuTopLeftContent,
    with_set_top_menu = false,
  } = props;
  
  useEffect(() => {setLoading(false);}, []);

  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let { project_id, order_id } = useParams();
  /*=======================================
  =======================================*/

  const navigate = useNavigate();
  const defaultValues: any = {
    order_id: order_id? order_id : "null",
    project_id: project_id? project_id : 0,
    supplier_id: 0,
    create_date: new Date(),
    arrival_date: null,
    cost: 0,
    products: [],
    supplier_quote_id: 0,
    folder_id: 0,
  };
  
  

  const [formValues, setFormValues] = useState(defaultValues)

  const setProduct = (product_name: any, productId: any, sale_price: any, stone_fields: any, serviceIndex: any, category: any, itemIndex: any) => {
       
    const newProducts = formValues.products;
    newProducts[itemIndex].product_name = product_name;
    newProducts[itemIndex].product_id = productId;
    setFormValues({...formValues, products: newProducts});

}



  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
   
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    
  };

  const handleSubmit = () => {
    setLoading(true);
    
    axios.post(ProjectConfig.api_url, null,
      {
        params: {
          request: 'add_edit_order',
          formdata: formValues,
        }
      })
        .then((response) => {
          console.log("response: ",response.data);
          
          setFormValues(response.data);
          setLoading(false)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        
  };



  /**/

  useEffect(() => {
    if (order_id) {
      loadData();
    }
  }, []);

  const loadData = () => {
    setLoading(true);

      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "order_details",
            order_id: order_id,
          },
        })
        .then((response) => {
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          setLoading(false);
        });
  };



  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  

    /*=======================================
    PUSH NEW ITEM 
    =======================================*/
    const handleAddItem = () => {
      if (!formValues.products) setFormValues({ ...formValues, products: []});

      const newProducts = [...formValues.products];
      newProducts.push({
          product_id: "",
          product_name: "",
          quantity: 0,
      });
      setFormValues({
        ...formValues,
        products: newProducts,
      });


  }; 
  /*=======================================
  =======================================*/

  return (
    <div>
      <ProjectHeader />
      
    
    <form>
      <Box id="order_editor" className="box_editor" title="Order" subtitle="Edit"  >
        <div className='form'>


          
          <div className='input_group'>
          <h2>Supplier information</h2>
            <ACSupplierList onChange={handleInputChange} value={formValues.supplier_id}/>
          </div>

          <div className='input_group'>
            <h2>Quote information</h2>
            <div className='input_row'>
              <TextField 
                id="supplier_quote_id"
                name="supplier_quote_id"
                label="Supplier Quote ID"
                type="text"
                fullWidth
                value={formValues.supplier_quote_id}
                onChange={handleInputChange}
              />

            <TextField 
              id="cost"
              name="cost"
              label="Quote Cost"
              type="number"
              fullWidth
              value={formValues.cost}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            </div>

            

            <div className="input_row">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                    label="Create date"
                    inputFormat="MMMM DD, yyyy"
                    value={formValues.create_date}
                    InputProps={{
                      endAdornment: (
                        <EventIcon />
                      ),
                    }}
                    showToolbar={false}
                    closeOnSelect 
                    onChange={(newValue) => {
                      setFormValues({
                        ...formValues,
                        ["create_date"]: (newValue).format('YYYY-MM-DD')
                      });
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>


                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                    label="Arrrival date"
                    inputFormat="MMMM DD, yyyy"
                    value={formValues.arrival_date}
                    InputProps={{
                      endAdornment: (
                        <EventIcon />
                      ),
                    }}
                    showToolbar={false}
                    closeOnSelect 
                    onChange={(newValue) => {
                      setFormValues({
                        ...formValues,
                        ["arrival_date"]: (newValue).format('YYYY-MM-DD')
                      });
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
            </div>
          </div>
          

          

          <div className='input_group'>
            <h2>Project</h2>
            <ACProjectList onChange={handleInputChange} value={formValues.project_id}/>
          </div>

          <div className='input_group'>
            <h2>Files</h2>
            {order_id && formValues.folder_id!=0 && (
            <FilesBrowser 
                folder_id={formValues.folder_id} 
                view_type="thumbnail"
                setLoading={setLoading}
            />
            )}

            {(!order_id || formValues.folder_id==0) && (<div className='msg_save_quote'>
                <SaveOutlinedIcon />
                <p>Save the order to upload files</p>
            </div>)}
          </div>


          <div className='input_group'>
            <h2>Products</h2>
            {/* //map products */}
            {formValues.products && (
            <>
              {formValues.products.map((product: any, index: any) => (
                <div className="order_product_row" key={index}>
                  <AutocompleteProduct 
                      category_filter="'panel','wood','paint','hardware'" 
                      setProduct={setProduct} 
                      product_name={product.product_name}
                      category=""
                      serviceIndex={0}
                      itemIndex={index}
                      dotsClick={()=>{
                        console.log("No hay nada");
                      }}
                      />
                  <TextField 
                    label="Quantity" 
                    fullWidth
                    size='small'
                    InputLabelProps={{
                      style: { fontSize: '12px' }
                    }}
                    value={product.quantity}
                    inputProps={{
                      style: {
                        fontSize: '12px',
                      }
                    }}
                    onChange={
                      (e) => {
                        const newProducts = [...formValues.products];
                        newProducts[index].quantity = e.target.value;
                        setFormValues({
                          ...formValues,
                          products: newProducts,
                        });
                      }
                    }
                    
                    />
                </div>
              ))}
            </>
            )}

              <Button 
                className="item_add_button"
                onClick={()=>{
                  handleAddItem()
                }}>
                Add item
              </Button>
          </div>
          
          
          
          </div>
        <div className='buttons'>
          <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" className="button_1">Submit</Button>
        </div>
      </Box>

      
        


    </form>
    </div>
    
  );
};
