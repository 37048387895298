import { Button, Chip, CSSObject, Dialog, DialogContent, DialogContentText, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, styled, Switch, SwitchProps, TextField, Theme, Typography } from "@mui/material"
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from "Global";

//ICONS
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { Delete, DeleteOutline, Download, FolderCopyOutlined, NavigateBefore, NavigateNext, OpenInNew } from "@mui/icons-material";
import PreviewIcon from '@mui/icons-material/Preview';
import LinearProgress from '@mui/material/LinearProgress';

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import MuiDrawer from "@mui/material/Drawer";
import LoadingCircle from "elements/LoadingCircle";

import ic_empty from 'icons/ic_empty.svg';
import SideDrawer from "elements/SideDrawer";
import { Timeline, TimelineConnector, TimelineContent, TimelineItem, timelineItemClasses, TimelineSeparator } from "@mui/lab";
import AlertDialog from "elements/AlertDialog";
import ic_dots from 'icons/ic_dots.svg';

const drawerWidth = 300;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const defaultValues = {
  file_id: '',
  file_name: "",
  file_extension: "",
  folder_route: "",
  file_src: "",
  file_base64: "",
  created_at: "",
  file_description: "",
  current_version: 1,
  version_history: [],
  comments: [],
};

interface Props {
  project_id?: number,
  client_id?: number,
  folder_id?: number,
  view_type?: string,
};

const fakeAPI = {
  file_id: '1',
  file_name: 'file_name',
  file_extension: 'pdf',
  folder_route: 'Projects',
  file_src: 'https://casoapi.greenars.com.co//files/Projects/Project Test/Quotes/Quote 2/2.png',
  created_at: '2023-01-10',
  file_description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
  current_version: 3,
  version_history: [
    {
      file_name: 'file_name',
      file_extension: 'pdf',
      created_at: '2023-01-10',
    },
    {
      file_name: 'file_name1',
      file_extension: 'pdf',
      created_at: '2023-01-10',
    },
    {
      file_name: 'file_name2',
      file_extension: 'pdf',
      created_at: '2023-01-10',
    },
  ],
  comments: [
    {
      comment_id: 1,
      comment: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
      created_at: '2023-01-10',
      user: {
        user_id: 1,
        user_name: 'user_name',
      },
    },
    {
      comment_id: 2,
      comment: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
      created_at: '2023-01-10',
      user: {
        user_id: 1,
        user_name: 'user_name',
      },
    },
  ],
};

const defaultVersion = {
  file_version_id: null,
  anchorElement: null,
};

export default function FilesBrowser (props: any) {

  const {
    with_drawer = true,
    onSubmitEnds = () => {},
    view_type = "list",
    withoutParamsSearch = false,
  } = props;

  const {setLoading, setMenuTopLeftContent} = props;
  
  useEffect(() => {setLoading(false);}, []);
  
  const navigate = useNavigate();
  
  /*=======================================
    GET ID FROM URL
    =======================================*/
  let { project_id } = useParams();
  /*=======================================
  =======================================*/
  
  /*====================================================
    DRAWER
    ==================================================== */
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>({...defaultValues});
    
    
    const ListItemIconStyles = {
        minWidth: 0,
        mr: drawerOpen ? 3 : "auto",
        justifyContent: "center",
    };

    const ListItemButtonStyles = {
    minHeight: 48,
    justifyContent: drawerOpen ? "initial" : "center",
    px: 2.5,
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
      };
    
      const handleDrawerClose = () => {
        setDrawerOpen(false);
      };

      useEffect(() => {
        if (selectedItem.file_id) {
          setSelectedItem({...defaultValues, file_id: selectedItem.file_id});
          loadDetails();
        }
      }, [selectedItem.file_id]);

      const loadDetails = () => {
        axios.get(ProjectConfig.api_url, {
          params: {
            request: "get_file_details",
            file_id: selectedItem.file_id,
          },
        })
        .then((response) => {
          console.log(response.data);

          let created_at: any = undefined;
          setSelectedItem({
            ...fakeAPI,
            file_id: selectedItem.file_id,
            file_name: response.data.file_data.file_name,
            file_extension: response.data.file_data.file_extension,
            folder_route: response.data.file_data.folder_route,
            file_src: response.data.file_data.file_src,
            file_version_id: response.data.file_data.file_version_id,
            current_version: response.data.file_data.version_number,
            version_history:
              response.data.file_versions.reverse().map((version: any) => {
                
                created_at =
                  (version.version_number === response.data.file_data.version_number ?
                    version.creation_date :
                    created_at
                  );

                return {
                  file_name: version.file_name,
                  file_extension: version.file_extension,
                  created_at: version.creation_date,
                  file_version_id: version.file_version_id,
                  version_number: version.version_number,
                };
              }),
            created_at: created_at,
          });
        })
        .catch((error) => {
          console.log(error);
        });
      }
    /*====================================================
    END DRAWER
    ==================================================== */



    /*====================================================
    SLIDE GALLERY
    ==================================================== */

    const [slideGalleryIndex, setSlideGalleryIndex] = useState<number>(0);

    /*====================================================
    END SLIDE GALLERY
    ==================================================== */



  const [fileList, setFileList] = useState<any>([]);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [imageLightbox, setImageLightBox] = useState("");
  const [openNewVersionDialog, setOpenNewVersionDialog] = useState(false);
  const [openDeleteFile, setOpenDeleteFile] = useState(false);
  const [pipe, setPipe] = useState<any>([]);

  const [selectedVersion, setSelectedVersion] = useState<any>(defaultVersion);
  const openContextVersionMenu = Boolean(selectedVersion.anchorElement);

  const [loadingImg, setLoadingImg] = useState(true);

  // FORCE RELOAD
  useEffect(() => {
    loadData();
  }, [props.triggerRefresh]);
  //===========================================================

  // GET FILE LIST
    useEffect(() => {
      loadData();
    }, []);
    //===========================================================

    const loadData = () => {
      setLoading(true);

      // console.log("project_id", project_id, props.folder_id);

      axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "file_list",
              project_id: (!withoutParamsSearch && project_id) ? project_id : 0,
              parent_folder_id: props.folder_id? props.folder_id : 1,
            },
          })
          .then((response) => {
            // console.log(response.data);

            const newFileList = [...fileList];
            newFileList[0] = response.data;
            setFileList(newFileList);
          })
          .catch((err) => {
            console.log(err.data);
          })
          .finally(() => {
            setLoading(false);
          });
    }

    const handleFolderClick = (folder_id: number, folderLevel: number) => {
      setDrawerOpen(false);
      setSelectedItem({...defaultValues});
      
      axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "file_list",
          project_id: (withoutParamsSearch ? project_id : undefined) || 0,
          parent_folder_id: folder_id,
        },
      })
      .then((response) => {
        console.log(response.data)
        
        //ADD DATA LIKE A CHILD OF THE PARENT FOLDER
        const newFileList = [...fileList];
        newFileList[folderLevel+1] = response.data;

        setFileList(newFileList);

        //DELETE index and next indexes
        const newFileList2 = [...newFileList];
        
        newFileList2.splice(folderLevel+2, newFileList2.length);
        setFileList(newFileList2);

      })
      .catch((err) => {
        console.log(err.data);
      });
    }



    const handleFileClick = (file_id: number, folderLevel: number) => {
      console.log("file_id",file_id);
      setSelectedItem({
        ...selectedItem,
        file_id: file_id,
      });

      setDrawerOpen(true);
    }

    const handleDeleteFile = () => {
      setLoading(true);

      axios.post(ProjectConfig.api_url, null, {
        params: {
          request: "delete_file",
          file_id: selectedItem.file_id,
          },
      })
        .then((response) => {
          console.log(response.data);
          handleFolderClick(fileList[fileList.length - 1].level_folder_id,  fileList.length - 2);
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          setLoading(false);
          setSelectedItem({...defaultValues})
          setOpenDeleteFile(false);
        });
    }

    
 /*====================================
  SUBMIT 
  =====================================*/
  const handleSubmit = () => {


    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "add_edit_client",
          //formdata: formValues,
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  /*====================================
  =====================================*/

  const [openDialogCreateFolder, setOpenDialogCreateFolder] = useState(false);

  const handleCloseDialogCreateFolder = () => {
    setOpenDialogCreateFolder(false);
  };
  


  /*====================================
  CREATE FOLDER FUNCTION
  =====================================*/
  const handleCreateFolder = () => {
    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "create_folder",
          formdata: newFolderFormValues,
          project_id: project_id,
        },
      })
      .then((response) => {
        setOpenDialogCreateFolder(false);
        console.log(response.data);
        //REFRESH FILE LIST
        //ADD DATA LIKE A CHILD OF THE PARENT FOLDER
        const newFileList = [...fileList];
        newFileList[newFolderFormValues.folderLevel] = response.data.folder_content;
        setFileList(newFileList);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }
  /*====================================
  =====================================*/



  /*====================================
  UPLOAD FILE
  =====================================*/
  const handleUploadFiles = (selected_files: any, folder_id: any, folderLevel: number) => {
    console.log("selected_files",selected_files, "folder_id", folder_id, "folderLevel", folderLevel);
    
    //MULTIPLE FILES UPLOAD
    const formData = new FormData();
    for (let i = 0; i < selected_files.length; i++) {
      formData.append("files["+i+"]", selected_files[i]);
    }
    formData.append("request", "upload_files");
    formData.append("folder_id", folder_id);

    //add className to the progress bar
    const progressBar = document.getElementById("progressBar_"+folderLevel);
    //add className to the progress bar
    progressBar?.classList.add("show_progress_bar");
    



    //UPLOAD FILES AND SHOW PROGRESS
    axios
      .post(ProjectConfig.api_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setProgress(
              Math.round((progressEvent.loaded / (progressEvent.total || progressEvent.loaded)) * 100)
          );

        },
      })
      .then((response) => {
        console.log(response);
        //REFRESH FILE LIST
        //ADD DATA LIKE A CHILD OF THE PARENT FOLDER
        const newFileList = [...fileList];
        newFileList[folderLevel] = response.data;
        setFileList(newFileList);
        loadDetails();
        progressBar?.classList.remove("show_progress_bar");
        setProgress(0);
        onSubmitEnds();
      })
      .catch((err) => {
        console.log(err.data);
      });


  }
    

  /*====================================
  =====================================*/

  /*====================================
  PIPE FUNCTIONS
  =====================================*/
  const handleRunPipe = () => {
    setPipe((prevState: any) => {
      const pipeMethod = prevState.shift();
      pipeMethod();
      console.log("pipe", prevState);
      return [];
    });
  }

  /*====================================
  =====================================*/


  /*====================================
  VERSION FUNCTIONS (CONTEXT MENU)
  =====================================*/
  const restartAnchorVersionElement = () => {
    setSelectedVersion({...defaultVersion});
  };
  /*====================================
  =====================================*/


  const showDialogCreateFolder = (parent_folder_id: any, folderLevel: any) => {
    setNewFolderFormValues({
      folder_name: "",
      parent_folder_id: parent_folder_id,
      folder_locked: 0,
      folderLevel: folderLevel,
    });
    setOpenDialogCreateFolder(true);
  }

  

  const newFolderValues = {
    folder_name: "",
    parent_folder_id: 1,
    folder_locked: 0,
    folderLevel: 0,
  };

  const [newFolderFormValues, setNewFolderFormValues] = useState(newFolderValues);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (props.uploadProgress > 0) {
      setProgress(props.uploadProgress);
    }
  }, [props.uploadProgress]);

  const handleOpenPopupImage = (imageSource: string) => {
    window.open('/viewer?img=' + imageSource, '_blank', 'scrollbars=yes')
  }

  const handleDownloadFile = (file: any) => {
    const arr = file.file_base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const uint8Array = new Uint8Array(n);
    while (n--) uint8Array[n] = bstr.charCodeAt(n);
    const fileGenered = new File([uint8Array], `${file.file_name}.${file.file_extension}`, { type: mime });
    
    const url = window.URL.createObjectURL(fileGenered);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${file.file_name}.${file.file_extension}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }

  const handleDownloadVersion = (file_version_id: any) => {
    setLoading(true);
    
    axios.get(ProjectConfig.api_url, {
      params: {
        request: "download_version",
        file_version_id: file_version_id,
      },
    })
    .then((response) => {
      console.log(response.data);

      if (response.data.file_base64 && response.data.file_name && response.data.file_extension)
        handleDownloadFile({...response.data});
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      restartAnchorVersionElement();
      setLoading(false);
    });
  }

  const handleOpenPopupVersion = (file_version_id: any) => {
    setLoading(true);

    axios.get(ProjectConfig.api_url, {
      params: {
        request: "download_version",
        file_version_id: file_version_id,
      },
    })
    .then((response) => {
      console.log(response.data);

      if (response.data.file_base64 && response.data.file_name && response.data.file_extension)
        handleOpenPopupImage(response.data.file_src);
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      restartAnchorVersionElement();
      setLoading(false);
    });
  }
  
  const handleOpenFile = (file: any) => {
    window.open(file.file_src, '_blank', 'scrollbars=yes');
  }

  return (
    <>
      <div
        className={
          "contentWithMiniDrawer "
          + (view_type === "thumbnail_list" ? 'viewThumbnailList' : '')
          + (view_type === "thumbnail_gallery" ? 'viewThumbnailGallery' : '')
          + (view_type === "slide_gallery" ? 'viewSlideGallery' : '')
        }
      >
        <div id="filesBrowser" className="contentwithMiniDrawer__content">

          {fileList.map((element: any, folderLevel: any) => {
            return(
              <Box className="box_file_browser" subtitle="Edit" id={"level_"+folderLevel} key={folderLevel}>
                <LinearProgress variant="determinate" value={progress} id={"progressBar_"+folderLevel} className="progressBar"/>
                {/* ON DRAG ADD CLASS */}
                <div
                  className="dropzone"
                  onDrop={(e) => {
                    e.preventDefault();
                    const filesUploaded = e.dataTransfer.files;
                    const filesUploadedArray = Array.from(filesUploaded);
                    
                    // Check if some fileUploaded has the same name as some file in the folder (element.files)
                    if (filesUploaded.length > 0 && element.files.length > 0 && filesUploadedArray.some((fileUploaded: any) => element.files.some((file: any) => fileUploaded.name.includes(file.file_extension) && fileUploaded.name.includes(file.file_name.replace('...', ''))))) {
                    // if (filesUploaded.length > 0 && element.files.length > 0 && filesUploadedArray.some((fileUploaded: any) => element.files.some((file: any) => fileUploaded.name == `${file.file_name}.${file.file_extension}`))) {
                      setPipe((prevState: any) => [
                        ...prevState,
                        () => handleUploadFiles(filesUploaded, element.level_folder_id, folderLevel),
                      ])
                      setOpenNewVersionDialog(true);
                    } else {
                      handleUploadFiles(filesUploaded, element.level_folder_id, folderLevel);
                    }
                    e.currentTarget.classList.remove("dropzone_active");
                  }}
                  onDragOver={
                    (e) => {
                      e.preventDefault();
                      e.currentTarget.classList.add("dropzone_active");
                    }
                    
                  }
                  onDragLeave={
                    (e) => {
                      e.preventDefault();
                      e.currentTarget.classList.remove("dropzone_active");
                    }
                  }
                >
                  {(view_type !== "thumbnail_list" && view_type !== "slide_gallery") &&
                    <div className="level_folder_name">
                      {element.level_folder_name}
                      
                      <div className="addButton_container">
                        <label>
                          <IconButton 
                            aria-label="delete"
                            size="small"
                            className="addFile_button"
                            component="span"
                          >
                            <NoteAddIcon fontSize="small" />
                            <input 
                              type="file"
                              multiple 
                              hidden 
                              onChange={(e) => {
                                handleUploadFiles(
                                    e.target.files,
                                    element.level_folder_id,
                                    folderLevel
                                    );
                              }}
                            />
                            
                          </IconButton>
                        </label>

      

                        <IconButton 
                          aria-label="delete"
                          size="small"
                          className="addFolder_button"
                          onClick={() => showDialogCreateFolder(element.level_folder_id, folderLevel)}

                        >
                          <CreateNewFolderIcon fontSize="small" />
                          
                        </IconButton>
                      </div>
                    </div>
                    }

                  {(element.folders.length === 0 && element.files.length === 0) && (
                    <>
                      <div className="emptyBox">
                          <img src={ic_empty} alt=""/>
                          <p style={{marginTop: 0}}>No files found</p>
                      </div>
                  </>
                  )}
                  
                  <div
                    className={
                      "file_list "
                      + (view_type === "thumbnail_list" ? 'file_list_with_header' : '')
                      + (view_type === "slide_gallery" ? 'file_list_with_header' : '')
                    }
                  >
                    {!props.folder_id && (
                    
                      (element.folders.map((folder: any) => {
                        return(
                          <Button 
                            key={folder.folder_id}
                            className={
                                "fs_row folder " +
                                (fileList.some((item: any) =>
                                    folder.folder_id == item.level_folder_id) ? 'fs_row_selected ' : ' ' )}
                            onClick={() => {
                              console.log(fileList)
                              handleFolderClick(folder.folder_id,folderLevel);
                            }}
                          >
                            <FolderIcon />
                            <span>{folder.folder_name}</span>
                          </Button>
                        )
                      }))
                    
                    )}

                    {(
                      element.files.filter((file: any) => (file.file_extension === "jpg" || file.file_extension === "png" || file.file_extension === "jpeg" || file.file_extension === "gif")).length === 0
                    ) ?
                    <>
                      {view_type !== "slide_gallery" ?
                      null
                      :
                      <div className="hide_parent_container">
                      </div>
                      }
                    </>
                    :
                    <>
                      {(element.files
                        // .sort((a: any, b: any) => (view_type !== "thumbnail_gallery" ? 1 : (a.file_extension === "jpg" || a.file_extension === "png" || a.file_extension === "jpeg" || a.file_extension === "gif") ? -1 : 1))
                        .filter((filterItem: any) => ((view_type !== "slide_gallery" && view_type !== "thumbnail_gallery") ? true : (filterItem.file_extension === "jpg" || filterItem.file_extension === "png" || filterItem.file_extension === "jpeg" || filterItem.file_extension === "gif")))
                        .filter((filterItem: any, filterIndex: number) => (view_type !== "slide_gallery" ? true : filterIndex === slideGalleryIndex))
                        .map((file: any) => {
                        const file_type = (file.file_extension === "jpg" || file.file_extension === "png" || file.file_extension === "jpeg" || file.file_extension === "gif") ? "image" : file.file_extension;

                        const galleryNavigationButtons = {
                          prev: (<></>),
                          next: (<></>)
                        };

                        if (view_type === "slide_gallery") {
                          const length = element.files
                            .filter((filterItem: any) =>
                              (
                                filterItem.file_extension === "jpg" ||
                                filterItem.file_extension === "png" ||
                                filterItem.file_extension === "jpeg" ||
                                filterItem.file_extension === "gif"
                              ))
                            .length;
                          
                          if (length > 1) {
                            galleryNavigationButtons.prev = (
                              <IconButton
                                disableFocusRipple
                                disableRipple
                                onClick={() => {
                                  setSlideGalleryIndex((prevState: number) => {
                                    if (prevState === 0)
                                      return length - 1;
                                    else return prevState - 1;
                                  })
                                }}
                              >
                                <NavigateBefore />
                              </IconButton>
                            );

                            galleryNavigationButtons.next = (
                              <IconButton
                                disableFocusRipple
                                disableRipple
                                onClick={() => {
                                  setSlideGalleryIndex((prevState: number) => {
                                    if (prevState === length - 1)
                                      return 0;
                                    else return prevState + 1;
                                  })
                                }}
                              >
                                <NavigateNext />
                              </IconButton>
                            );
                          }
                        }
                        
                        return(
                          <div
                            key={file.file_id}
                            className={"" + (view_type === "slide_gallery" ? 'navigation_row' : '')}
                            style={{display: 'contents',}}
                          >
                            {galleryNavigationButtons.prev}
                            <Button 
                              disableRipple
                              className={
                                "file " +
                                (view_type === "thumbnail_list" ? 'fs_forced_row' : 'fs_row ') +
                                (selectedItem.file_id == file.file_id ? 'fs_row_selected ' : ' ' ) +
                                (view_type === "thumbnail_gallery" ? 'only-on-hover-container ' : ' ') +
                                ((view_type === "thumbnail_gallery" || view_type === "slide_gallery") ? 'thumbnail_button ' : ' ') +
                                ((view_type === "thumbnail_gallery" && file_type === "image") ? 'image_file ' : ' ')
                              }
                              onClick={() => {
                                if(file_type === "image" && props.folder_id){
                                  setOpenLightBox(true)
                                  setImageLightBox(file.file_src)
                                }
                                else{
                                  if (view_type === "slide_gallery") {
                                    const url = file.file_src;
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.target = '_blank';
                                    link.setAttribute('download', `${file.file_name}.${file.file_extension}`);
                                    document.body.appendChild(link);
                                    link.click();
                                    link.parentNode?.removeChild(link);
                                  } else {
                                    handleFileClick(file.file_id,folderLevel)
                                  }
                                }
                              }}
                            >
                              {view_type === "list" &&  
                                <Fragment>
                                  <InsertDriveFileOutlinedIcon />
                                  <span>{file.file_name}.{file.file_extension}</span> 
                                </Fragment>
                              }
                              {view_type === "thumbnail" && (
                                file_type === "image" ? (
                                  <div className="file_thumnbnail_image">
                                    <img src={file.file_src} />
                                    <div className="displayer_row">
                                      <span className='thumbnail_name'>{file.file_name}.{file.file_extension}</span>
                                      
                                      <IconButton
                                        size='small'
                                        disableRipple
                                        onClick={(e) => {
                                          e.stopPropagation(); 
                                          setSelectedItem({...defaultValues, file_id: file.file_id})
                                          setOpenDeleteFile(true);
                                        }}
                                      >
                                        <DeleteOutline
                                          sx={{
                                            width: '15px',
                                            height: '15px',
                                          }}
                                        />
                                      </IconButton>
                                    </div>
                                  </div>
                                ) : (
                                <div className="file_thumnbnail displayer_row">
                                  {/* download button */}
                                  <Link
                                    href={file.file_src}
                                    target="_blank"
                                    download
                                    className="download_button"
                                  >
                                    <InsertDriveFileOutlinedIcon />
                                    <span>{file.file_name}.{file.file_extension}</span>
                                  </Link>

                                  <IconButton
                                    size='small'
                                    disableRipple
                                    onClick={(e) => {
                                      e.stopPropagation(); 
                                      setSelectedItem({...defaultValues, file_id: file.file_id})
                                      setOpenDeleteFile(true);
                                    }}
                                  >
                                    <DeleteOutline />
                                  </IconButton>
                                </div>
                                )
                              )}
                              {view_type === "thumbnail_gallery" && (
                                file_type === "image" ? (
                                  <div className="file_thumnbnail_image">
                                    <img src={file.file_src} />
                                    <IconButton
                                      disableRipple
                                      className="delete_button only-on-hover-content"
                                      onClick={(e) => {
                                        e.stopPropagation(); 
                                        setSelectedItem({...defaultValues, file_id: file.file_id})
                                        setOpenDeleteFile(true);
                                      }}
                                    >
                                      <DeleteOutline
                                        sx={{
                                          width: '25px',
                                          height: '25px',
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                ) : (
                                <div className="file_thumnbnail displayer_row">
                                  {/* download button */}
                                  <Link
                                    href={file.file_src}
                                    target="_blank"
                                    download
                                    className="download_button"
                                  >
                                    <InsertDriveFileOutlinedIcon />
                                    <span>{file.file_name}.{file.file_extension}</span>
                                  </Link>

                                  <IconButton
                                    size='small'
                                    disableRipple
                                    onClick={(e) => {
                                      e.stopPropagation(); 
                                      setSelectedItem({...defaultValues, file_id: file.file_id})
                                      setOpenDeleteFile(true);
                                    }}
                                  >
                                    <DeleteOutline />
                                  </IconButton>
                                </div>
                                )
                              )}
                              {view_type === "slide_gallery" && (
                                file_type === "image" && (
                                  <div className="file_thumnbnail_image">
                                    <img src={file.file_src}/>
                                  </div>
                                )
                              )}
                              {view_type === "thumbnail_list" && (
                                file_type === "image" ? (
                                  <div className="file_thumnbnail_list_image">
                                    <div className="file_thumbnail"> 
                                      <img src={file.file_src} />
                                    </div>

                                    <div className="file_thumbnail_info">
                                      <span className='thumbnail_name'>{file.file_name}.{file.file_extension}</span>
                                      <span>Added: {file.created_at}</span>
                                    </div>
                                  </div>
                                ) : (
                                <div className="file_thumnbnail_list">
                                  <div className="file_thumbnail">
                                    <InsertDriveFileOutlinedIcon />
                                    <span>{file.file_extension}</span>
                                  </div>

                                  <div className="file_thumbnail_info">
                                    {/* download button */}
                                    <Link
                                      href={file.file_src}
                                      target="_blank"
                                      download
                                      className="download_button"
                                    >
                                      <span className="thumbnail_name">{file.file_name}.{file.file_extension}</span>
                                    </Link>

                                    <span>Added: {file.created_at}</span>
                                  </div>
                                </div>
                                )
                              )}
                            </Button>
                            {galleryNavigationButtons.next}
                          </div>
                        )
                      }))}
                    </>
                    }
                  </div>
                </div>
              </Box>
              
            )
          })}
          
          <Dialog 
            
            id="imageLightbox" 
            open={openLightBox} 
            onClose={() => setOpenLightBox(false)}
            maxWidth="lg"
          >
            <DialogContent
              className="forced_box_editor"
            >
              <LoadingCircle
                loading={loadingImg}
              />
              <img
                src={imageLightbox}
                onLoad={() => setLoadingImg(false)}
              />

              <div className="buttons">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setOpenLightBox(false)
                  }}
                >
                    Close
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleOpenPopupImage(imageLightbox)
                  }}
                  startIcon={<OpenInNew />}
                >
                  Open in new tab
                </Button>
              </div>
            </DialogContent>
          </Dialog>


          <Dialog open={openDialogCreateFolder} onClose={handleCloseDialogCreateFolder}>
            <DialogContent id="estimate_configuration" className="forced_box_editor">
              <div className="DialogHeader">
                <h2>CREATE FOLDER</h2>
                <span>Complete the form to create a new folder</span>
              </div>
              <TextField
                id="folder_name"
                name="folder_name"
                label="Folder Name"
                type="text"
                required
                fullWidth
                value={newFolderFormValues.folder_name}
                onChange={
                  (e) => setNewFolderFormValues({ ...newFolderFormValues, folder_name: e.target.value })
                }
              />
              <div className="buttons">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleCloseDialogCreateFolder}
                >
                  Cancel
                </Button>

                <Button 
                  variant="contained" 
                  startIcon={<FolderCopyOutlined />}
                  onClick={() => {
                    handleCreateFolder();
                  }}>
                  Create Folder
                </Button>
              </div>
            </DialogContent>
          </Dialog>

        </div>

        {(with_drawer && !props.folder_id) &&(
            <SideDrawer
                composeLabel
                withBackdrop
                customIcon={selectedItem.file_id !== '' ? <InsertDriveFileOutlinedIcon /> : null}
                drawerOpen={drawerOpen}
                id="files_drawer"
                label={
                  <>
                    {selectedItem.file_id === '' ? <></> :
                    <div className="displayer_row">
                      <div className="file-info-header">
                          <span className="file-title">{selectedItem.file_name}</span>
                          <span className="file-subtitle">Added: {selectedItem.created_at}</span>
                      </div>

                      <Chip
                          label={selectedItem.current_version}
                          color="primary"
                      />
                    </div>
                    }
                  </>
                }
                handleDrawerOpen={() => setDrawerOpen(true)}
                handleDrawerClose={() => {
                  setDrawerOpen(false)
                  setSelectedItem({
                    ...defaultValues
                  })
                }}
            >
                <div className="drawer_in">
                    <div className="drawer_content">
                        <div className="box_content" style={{marginBottom: '20px', gap: '50px'}}>
                          {drawerOpen && selectedItem.file_id !== '' &&
                          <>
                            {selectedItem.file_id === '' ?
                              <div className="emptyBox">
                                  <img src={ic_empty} alt=""/>
                                  <p style={{marginTop: 0}}>No file selected</p>
                              </div>
                              :
                              <div className="input_column">
                                <div>
                                  <span className='card_title'>ACTIONS</span>
                                  <div className="actions">
                                  {(selectedItem.file_extension === "jpg" ||
                                      selectedItem.file_extension === "png" ||
                                      selectedItem.file_extension === "jpeg" ||
                                      selectedItem.file_extension === "gif"
                                    ) ?
                                      <Button
                                        className="button-icon-column"
                                        variant="contained"
                                        startIcon={<PreviewIcon className="icon" />}
                                        sx={{
                                          backgroundColor: '#65A1B7',
                                          color: '#fff',
                                          "&:hover": {
                                            backgroundColor: '#65A1B7',
                                          }
                                        }}
                                        onClick={() => {
                                          handleOpenPopupVersion(selectedItem.file_version_id);
                                        }}
                                      >
                                        PREVIEW
                                      </Button>
                                      :
                                      // <Button
                                      //   className="button-icon-column"
                                      //   variant="contained"
                                      //   startIcon={<OpenInNew className="icon" />}
                                      //   sx={{
                                      //     backgroundColor: '#65A1B7',
                                      //     color: '#fff',
                                      //     "&:hover": {
                                      //       backgroundColor: '#65A1B7',
                                      //     }
                                      //   }}
                                      //   onClick={() => {
                                      //     handleOpenFile(selectedItem)
                                      //   }}
                                      <>
                                      </>
                                    }
                                    
                                    <Button
                                      className="button-icon-column"
                                      variant="contained"
                                      startIcon={<Download className="icon" />}
                                      sx={{
                                        backgroundColor: '#65A1B7',
                                        color: '#fff',
                                        "&:hover": {
                                          backgroundColor: '#65A1B7',
                                        }
                                      }}
                                      onClick={() => {
                                        handleDownloadVersion(selectedItem.file_version_id)
                                      }}
                                    >
                                      DOWNLOAD
                                    </Button>

                                    <Button
                                      className="button-icon-column"
                                      variant="contained"
                                      startIcon={<Delete className="icon" />}
                                      sx={{
                                        backgroundColor: '#D9D9D9',
                                        color: '#000',
                                        "&:hover": {
                                          backgroundColor: '#D9D9D9',
                                        }
                                      }}
                                      disableRipple
                                      onClick={() => {
                                        setOpenDeleteFile(true);
                                      }}
                                    >
                                      DELETE
                                    </Button>
                                  </div>
                                </div>

                                <div>
                                  <span className='card_title'>DESCRIPTION</span>
                                  <p style={{margin: 0,}}>{selectedItem.file_description}</p>
                                </div>

                                <div>
                                  <span className='card_title'>VERSION HISTORY</span>

                                  <Timeline
                                    id="version-history-timeline"
                                    sx={{
                                      p: 0,
                                      [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                      },
                                    }}
                                  >
                                    {selectedItem.version_history.map((version: any, versionIndex: number) => {
                                      const versionLength: number = selectedItem.version_history.length;

                                      return (
                                        <TimelineItem sx={{minHeight: 'unset',}}>
                                          <TimelineSeparator>
                                            <Chip
                                                label={version.version_number}
                                                color={version.version_number === selectedItem.current_version ? "primary" : "secondary"}
                                            />
                                            {versionIndex === versionLength - 1 ? null : <TimelineConnector />}
                                          </TimelineSeparator>
                                          
                                          <TimelineContent sx={{height: 'fit-content'}}>
                                              <div className="flex_row displayer_row file-info-header-container">
                                                <div className="file-info-header">
                                                  <span className="file-title">{version.file_name}</span>
                                                  <span className="file-subtitle">Added: {version.created_at}</span>
                                                </div>

                                                <IconButton
                                                  id={`button-options-version-${version.version_number}`}
                                                  onClick={(e: any) => {
                                                    setSelectedVersion({
                                                      file_version_id: version.file_version_id,
                                                      anchorElement: e.currentTarget
                                                    });
                                                  }}
                                                >
                                                  <img src={ic_dots} />
                                                </IconButton>
                                              </div>
                                          </TimelineContent>
                                        </TimelineItem>
                                      )
                                    })}

                                    <Menu
                                      anchorEl={selectedVersion.anchorElement}
                                      open={openContextVersionMenu}
                                      onClose={restartAnchorVersionElement}
                                    >
                                      <MenuItem onClick={() => handleDownloadVersion(selectedVersion.file_version_id)}>
                                        <Download />
                                        Download
                                      </MenuItem>

                                      {(selectedItem.file_extension === "jpg" ||
                                        selectedItem.file_extension === "png" ||
                                        selectedItem.file_extension === "jpeg" ||
                                        selectedItem.file_extension === "gif"
                                      ) &&
                                        <MenuItem onClick={() => handleOpenPopupVersion(selectedVersion.file_version_id)}>
                                          <PreviewIcon />
                                          Preview
                                        </MenuItem>
                                      }
                                    </Menu>
                                  </Timeline>
                                </div>

                                {false &&
                                <div>
                                  <span className='card_title'>COMMENTS</span>
                                  <div className="basic_comments_container">
                                  {selectedItem.comments.map((comment: any, commentIndex: number) =>
                                    <Box className="basic_comments_box">
                                      <div className='input_group padding-off gap-off'>
                                          <p>{comment.comment}</p>

                                          <div className='input_row displayer_row padding-10 note_info'>
                                              <span className="input_label flexTd_bold">{comment.user.user_name}</span>
                                              <span className="input_label">{comment.created_at}</span>
                                          </div>
                                      </div>
                                    </Box>
                                  )}
                                  </div>
                                </div>
                                }
                              </div>
                            }
                          </>
                          }
                        </div>
                    </div>
                </div>
            </SideDrawer>
        )}
        
        {(with_drawer && !props.folder_id && false) &&(
        <Drawer
          variant="permanent"
          open={drawerOpen}
          anchor="right"
          className="miniDrawer"
          PaperProps={{
          style: {
              paddingTop: "55px",
              boxShadow: "0 0 4px #00000026",
              border: "none",
          },
          }}
        >
          <ListItem key="drawerOpenBtn" disablePadding sx={{ display: "block" }}>
          <ListItemButton
              onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
              sx={ListItemButtonStyles}
          >
              <ListItemIcon
              sx={{
                  minWidth: 0,
                  mr: drawerOpen ? 3 : "auto",
                  justifyContent: "center",
              }}
              >
              {!drawerOpen ? <MenuIcon /> : <ChevronRightIcon />}
              </ListItemIcon>
              <ListItemText>
              <h2 style={{ opacity: drawerOpen ? 1 : 0 }}>QUOTE SUMMARY</h2>
              </ListItemText>
          </ListItemButton>
          </ListItem>

          <div className="drawer_in">
              <div className="drawer_content" style={{ opacity: drawerOpen ? 1 : 0 }}>
                  <div className="box_header">
                      <span className="subtitle">
                          
                      </span>
                  </div>
              </div>

              <List>

            
                  
                  <ListItem key="save" disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                        sx={ListItemButtonStyles}
                        onClick={() => {
                        handleSubmit();
                        }}
                    >
                        <ListItemIcon sx={ListItemIconStyles}>
                        <SaveOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                        primary="SAVE"
                        sx={{ opacity: drawerOpen ? 1 : 0 }}
                        />
                    </ListItemButton>
                  </ListItem>

    
              </List>
          </div>
      
        </Drawer>
        )}
      </div>

      <AlertDialog
        open={openNewVersionDialog}
        setOpen={setOpenNewVersionDialog}
        send={handleRunPipe}
        title="Confirm update new version"
        subtitle="This action will update the current version of the file. Are you sure you want to continue?" 
      />

      <AlertDialog
        open={openDeleteFile}
        setOpen={setOpenDeleteFile}
        send={handleDeleteFile}
        subtitle="Are you sure you want to delete this file?"
      />
    </>
  );
};