import React, { useEffect, useState } from 'react';
import {
    Button,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { Add, CloudDownload, DeleteOutlined, RemoveRedEye } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';

import Box from 'elements/Box';
import { ProjectConfig } from 'Global';

import 'assets/css/main.css';
import AlertDialog from 'elements/AlertDialog';
import PaymentForm from './PaymentForm';
import { useSearchParams } from 'react-router-dom';
import PaymentDialog from './PaymentDialog';
import ic_empty from 'icons/ic_empty.svg';

export const PaymentList = ( props: any ) => {

    const {
        project_id = null,
        callback
    } = props;

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const [searchParams, setSearchParams] = useSearchParams();

    const [page, setPage] = useState(searchParams.get('page') ? searchParams.get('payment_page') : 1 );
    const [totalPages, setTotalPages] = useState<number>(1);

    const [list, setList] = useState<any[]>([]);
    const [paymentId, setPaymentId] = useState<number | null>(null);

    const [openAddTime, setOpenAddTime] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openAlert, setOpenAlert] = useState<boolean>(false);

    useEffect(() => {
        loadData()
    }, []);

    useEffect(() => {
        loadData()
    }, [page]);

    const loadData = () => {
        setLoading(true);

        const params =
            project_id ?
            {
                project_id: project_id,
                items_per_page: 3,
            } : {};

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_payments_list',
                params: {
                    ...params,
                    page: page,
                },
            }
        })
        .then((response) => {
            setTotalPages(response.data.total_pages);
            setList(response.data.list);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleDelete = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: 'delete_payment',
                payment_id: paymentId,
            }
        })
        .then((response) => {
            console.log(response);
            loadData();
            if (callback) callback();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
            setOpenAlert(false);
        });
    };

    const handleDownload = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'download_project_payments',
                project_id: project_id,
            },
        })
        .then((response) => {
            console.log(response.data);
            window.open(response.data, '_blank', 'noopener,noreferrer');
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const payments_header = (
        <div className="moduleHeader buttons_top">
            <h2>PAYMENTS</h2>

            <div className="moduleButtons buttons_top">
                {/* {project_id === null ? null :
                <IconButton
                    color="default"
                    onClick={() => {
                        handleDownload();
                    }}
                >
                    <CloudDownload />
                </IconButton>
                } */}
                
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => {
                        setOpenAddTime(true);
                    }}
                >
                    Add
                </Button>
            </div>   
        </div>
    )

    return (
        <>
            <div
                id="superviseWorkersContainer"
                className="moduleContainer"
                style={project_id === null ? {} : {height: '100%', padding: '0'}}
            >
                <div className="moduleWrapper">
                    {project_id === null && payments_header}

                    <div className="moduleContent">
                        <Box className="box100">
                            {project_id !== null && payments_header}
                            <>
                                {(list.length !== 0) ? (
                                <>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                {project_id === null && <TableCell>Project</TableCell> }
                                                <TableCell>Ammount</TableCell>
                                                {project_id === null && <TableCell>Type</TableCell> }
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {list.map((item: any, index: number) => (
                                            <TableRow key={item.payment_id}>
                                                <TableCell sx={{minWidth: '100px'}}>{item.date}</TableCell>
                                                {project_id === null && <TableCell sx={{minWidth: '250px'}}>{item.project_name}</TableCell> }
                                                <TableCell>${item.value.toLocaleString()}</TableCell>
                                                {project_id === null && <TableCell>{item.payment_type}</TableCell> }
                                                <TableCell sx={{minWidth: '100px'}}>
                                                    <IconButton
                                                        color="secondary"
                                                        size="small"
                                                        onClick={() => {
                                                            setPaymentId(item.payment_id);
                                                            setOpenDialog(true);
                                                        }}
                                                    >
                                                        <RemoveRedEye />
                                                    </IconButton>

                                                    <IconButton
                                                        color="secondary"
                                                        size="small"
                                                        onClick={() => {
                                                            setPaymentId(item.payment_id);
                                                            setOpenAlert(true);
                                                        }}
                                                    >
                                                        <DeleteOutlined />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </>
                            ) : (
                                <div className="emptyBox">
                                    <img src={ic_empty} alt=""/>
                                    <p>No payments found</p>
                                </div>
                            )}
                            
                            {totalPages > 1 &&
                            <Pagination
                                color='primary'
                                sx={{ "& .MuiPagination-ul ": {justifyContent: 'center'} }}
                                count={totalPages}
                                page={Number(page)}
                                onChange={(event, value) => {
                                    setPage(value);
                                    setSearchParams({ payment_page: value.toString() });
                                }}
                            />
                            }
                            </>
                        </Box>
                    </div>
                </div>
            </div>

            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle="Are you sure you want to delete this payment?"
                send={handleDelete}
            />
            
            <PaymentForm
                open={openAddTime}
                setOpen={setOpenAddTime}
                reloadList={loadData}
                setLoading={props.setLoading}
                project_id={project_id}
                callback={callback}
            />

            <PaymentDialog
                open={openDialog}
                setOpen={setOpenDialog}
                setLoading={props.setLoading}
                paymentId={paymentId}
            />
        </>
    );
}