import Box from "elements/Box";
import ic_empty from 'icons/ic_empty.svg';


export default function StockPending (props: any) {

  const {data} = props;

  return (
   
    <Box title="STOCK PENDING" subtitle="Materials out of stock">
      <div className="stockPendingContent">
        {data.length >0 ?
          data.map((item: any, index: number) => (
          <div className="box_row_1" key={index}>
            <div className="box_column_1">
              <div className="box_column_flex_column">
                <h3>{item.product_name}</h3>
                {item.order_id ? <span className="order_label">ORDER # {item.order_id} - <b>{item.order_status}</b></span> : 
                <span className="no_order_label">NO ORDER CREATED</span>
                }
              </div>
            </div>
            <div className="box_column_2">
              <div className="box_column_flex_column flex_column_align_right">
                <span className="box_column_label">PENDING</span>
                <span className="box_column_value">{item.stock_pending}</span>
              </div>
            </div>
          </div>
          ))
          :
          <div className="noProducts">
            <img src={ic_empty} alt=""/>
            <p>No products found</p>
          </div>
        }
      </div>

    </Box>
  );
};

