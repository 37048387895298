import React, { useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    TextField,
    Link,
} from '@mui/material';

import Box from 'elements/Box';
import { ProjectConfig } from 'Global';

export default function FormChangePass( props: any ) {
    
    const {
        loading,
        logo,
        setLoading,
        setState,
        email,
        code,
    } = props;

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    /*================================================
    SNACKBAR FUNCTION FOR NOTIFICATIONS
    ================================================*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */


    /*================================================
    CHANGE PASSWORD (SUBMIT)
    ================================================*/
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (newPassword !== confirmPassword) {
            enqueueSnackbar("Passwords do not match", { variant: 'error' });
            return;
        }
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);
        axios.get(ProjectConfig.api_url, {
            params: {
                request: "change_password",
                email: email,
                code: code,
                newPassword: data.get('newPassword'),
                confirmPassword: data.get('confirmPassword'),
            },
        })
        .then((response) => {
            if(response.data.status === "success"){
                enqueueSnackbar(response.data.message, { variant: 'success' });
                setState('login');
            } else {
                enqueueSnackbar(response.data.message, { variant: 'error' });
            }
        })
        .catch((error) => {
            enqueueSnackbar(error.toString(), { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    };
    /*================================================
    ================================================*/

    return(
        <Box id='loginBox'>

          <img className='loginLogo' src={logo}></img>

          <p>Enter a new password for your account.</p>
          <form onSubmit={handleSubmit} className='form'>
            <TextField
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              required
              fullWidth
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={confirmPassword !== newPassword}
              helperText={confirmPassword !== newPassword ? "Passwords do not match" : " "}
            />
            <LoadingButton
              size="small"
              type="submit"
              loading={loading}
              fullWidth
              variant="contained"
              sx={{ fontSize: '13px', padding: '10px 0px'}}
              disabled={((confirmPassword !== newPassword) || confirmPassword === '' || newPassword === '')}
            >
              Update Password
            </LoadingButton>

          </form>
        </Box>
    );
}