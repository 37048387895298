import {Autocomplete, Button, Dialog, DialogContent, FormControl, FormControlLabel, Icon, IconButton, InputLabel, MenuItem, Select, Switch, SwitchProps, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import AutocompleteProduct from "../../utils/AutoCompleteProduct";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Fragment, useContext, useEffect, useState } from "react";
import Box from "elements/Box";
import AutocompleteSections from "../../utils/AutoCompleteSections";
import AutocompleteWorkforce from "../../utils/AutoCompleteWorkforce";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Delete } from '@mui/icons-material';
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AlertDialog from "elements/AlertDialog";


const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  
export default function QuoteView(props:any){

    const {
        setOpenProductList,
        services,
        setServices,
        sectionList,
        setSectionList,
        setCategoryTotal,
        setServiceTotal,
        setSection,
        updateQuoteText,
        setActiveItemParams,
        setOpenWorkforceList,
        setWorkforce,
        setOpenWorkforceEditor,
        setWorkforceCreatorName,
        showView,
        explodedItemAnchor,
        setExplodedItemAnchor,
        quoteItemAnchor,
        setQuoteItemAnchor,
        setSectionsDict,
        sectionsDict,
        setQuoteTotal,
        profitPercentages,
        setStoneWorkforceCost,
        fixNumberValue,
    } = props;

    const [openDeleteService, setOpenDeleteService] = useState(false);
    const [selectedServiceIndex, setSelectedServiceIndex] = useState<any>(null);


    /*=======================================
    DELETE  PRODUCT FROM PRODUCTS GROUP
    =======================================*/
    const handleDelete = (serviceIndex: any, category: any, itemIndex: any) => {


        const categoryIndex = (category != "workforce") ? "products" : "tasks";

        const newServices = [...services];
        newServices[serviceIndex][category][categoryIndex].splice(itemIndex, 1);
        setServices(newServices);
        setCategoryTotal(category,serviceIndex);
    };
    /*=======================================*/



    /*=======================================
    PUSH NEW TASK IN WORKFORCE 
    =======================================*/
    const handleAddTask = (serviceIndex:any, category_var: any) => {
        
        const newServices = [...services];
        newServices[serviceIndex][category_var].tasks.push({
            workforce_id: "",
            workforce_name: "",
            hours: 0,
            hour_cost: 0,
        });
        setServices(newServices);


    }; 
    /*=======================================
    =======================================*/


    /*=======================================
    PUSH NEW ITEM 
    =======================================*/
    const handleAddStone = (serviceIndex:any, category_var: any) => {
        
        const newServices = [...services];
        newServices[serviceIndex][category_var].products.push({
            product_id: "",
            product_name: "",
            quantity: 0,
            section: "",
            sale_price: 0,
            price_with_manufacturing: 0,
            price_without_manufacturing: 0,
            workforce_cost: 0,
            destination: "countertop",
            edge: "",
            finish: "",
            sink: "",
            sq_ft: 0,
            have_workforce: true,
            measures: [],
            quoteText: "",
        });
        setServices(newServices);


    }; 
    /*=======================================
    =======================================*/

    /*=======================================
    PUSH NEW ITEM 
    =======================================*/
    const handleAddItem = (serviceIndex:any, category_var: any) => {

        const newServices = [...services];
        newServices[serviceIndex][category_var].products.push({
            product_id: "",
            product_name: "",
            quantity: 0,
            section: "",
            sale_price: 0,
            measures: [],
            quoteText: "",
        });
        setServices(newServices);
    }; 
    /*=======================================
    =======================================*/

    const setHaveWorkforce = (checked:any, serviceIndex: any, category_var: any, itemIndex: any) => {

        const newServices = [...services];
        newServices[serviceIndex][category_var].products[itemIndex].have_workforce = checked;

        //IF !CHECKED, SET WORKFORCE_COST TO 0 AND SQ_FT TO 0 
        if(!checked){
            newServices[serviceIndex][category_var].products[itemIndex].workforce_cost = 0;

            newServices[serviceIndex][category_var].products[itemIndex].sq_ft = 0;
        }

        //UPDATE unit price
        if(checked){
            newServices[serviceIndex][category_var].products[itemIndex].sale_price = newServices[serviceIndex][category_var].products[itemIndex].price_with_manufacturing;
        }else{
            newServices[serviceIndex][category_var].products[itemIndex].sale_price = newServices[serviceIndex][category_var].products[itemIndex].price_without_manufacturing;
        }

        setServices(newServices);
        setCategoryTotal(category_var,serviceIndex);
    }

    useEffect(() => {
        //SCROLL WINDOW TO ServiceItemAnchor POSITION
        if (quoteItemAnchor) {
          console.log(quoteItemAnchor);
          const element = document.getElementById(quoteItemAnchor);
          element?.scrollIntoView({ behavior: "smooth" }); 
        }
      }, [quoteItemAnchor]);


    // funtion for delete services
    const handleDeleteService = () => {
        const newServices = [...services];
        const serviceCategories: {category_var: string}[] = [...props.categories[newServices[selectedServiceIndex].type]];
        for (let category in serviceCategories) {
            const category_var = serviceCategories[category].category_var;
            setCategoryTotal(category_var, selectedServiceIndex, true);
        }
        newServices.splice(selectedServiceIndex, 1);
        setServices([...newServices]);
    };

     
  /*====================================================
  ====================================================
    RENDER 
  ====================================================
  ====================================================*/
  return (
    <>
        <div id="QuoteContainter" style={{display: props.showQuoteView ? "flex" : "none"}}>
            {props.services.map((service:any, serviceIndex:any) => {
                
                return(
                <div className={"QuoteService "+(service.serviceOpen ? "serviceOpen" : "serviceClose")} key={serviceIndex}>
                    <div className={"QuoteServiceCategory type_"+service.type}>
                        
                        
                    
                        <IconButton
                            aria-label="Expand"
                            size="small"
                            //colo white
                            style={{color: "#fff"}}
                            onClick={() => {
                                const newServices = [...services];
                                newServices[serviceIndex].serviceOpen = !newServices[serviceIndex].serviceOpen;
                                setServices(newServices);
                            }}
                        >
                            {service.serviceOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        

                        <span className="QuoteServiceText">
                            {service.type}
                        </span>
                        
                    </div>

                    
                    <div className="QuoteServiceItems">
                        <div className="QuoteServiceHeader">
                            <div className="QuoteServiceHeaderLeft">
                                <span className="QuoteServiceName">



                                    <TextField
                                        id="service_name"
                                        label="Service Name"
                                        value={service.name}
                                        fullWidth
                                        variant="standard"
                                        onChange={(e:any) => {
                                            const newServices = [...services];
                                            newServices[serviceIndex].name = e.target.value;
                                            setServices(newServices);
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="QuoteServiceHeaderRight">
                                <div className="QuoteServiceTotal">
                                    <Typography variant="body1" component="h5" sx={{fontWeight: 500}}>
                                        ${(service.service_cost || 0).toLocaleString()}
                                    </Typography>

                                    {profitPercentages[service.type] && (
                                        <Tooltip title="With profit" placement="bottom" arrow followCursor>
                                            <Typography variant="overline" component="p">
                                                ${(service.service_cost + service.service_cost * profitPercentages[service.type] / 100).toLocaleString()}
                                            </Typography>
                                        </Tooltip>
                                    )}
                                </div>
                                <div className="QuoteServiceDelete">
                                    <IconButton
                                        aria-label="Expand"
                                        size="small"
                                        onClick={() => {
                                            setSelectedServiceIndex(serviceIndex);
                                            setOpenDeleteService(true);
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        {props.categories[service.type].map((category:any, categoryIndex:any) => {
                            const category_name = category.category_name;
                            const category_var = category.category_var;
                            const category_filter = category.category_filter;
                            const categoryElement = `catIndexExploded${serviceIndex}-${categoryIndex}`;
                            return(
                                <Box className="categoryRow" key={categoryIndex}>
                                    <div id={"catIndexQuote"+serviceIndex+"-"+categoryIndex} className="categoryRowHeader"> 
                                        <h2>
                                            {category_name}
                                            {(category_var == "panel" || category_var == "stone") && (
                                                <IconButton
                                                    aria-label="Expand"
                                                    size="small"
                                                    onClick={() => {
                                                        showView("exploded")
                                                        setExplodedItemAnchor(categoryElement);

                                                    }}
                                                >
                                                <ViewInArIcon />
                                                </IconButton>
                                                
                                            )
                                            }
                                            
                                        </h2>
                                        <span>${(service[category_var].total || 0).toLocaleString()}</span>
                                    </div>
                                    <table className="categoryRowTable">
                                        <thead>
                                            
                                            {category_var == "workforce" && (
                                                <tr>
                                                    <th>Task</th>
                                                    <th>Hours</th>
                                                    <th className="align_right">Hour Cost</th>
                                                    <th className="align_right">Total</th>
                                                    <th></th>
                                                </tr>
                                            )}

                                            {category_var == "stone" && (
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Section</th>
                                                    <th className="align_right">Unit</th>
                                                    <th className="align_right">Total</th>
                                                    <th></th>
                                                </tr>
                                            )}

                                            {category_var != "workforce" && category_var != "stone" && (
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Section</th>
                                                    <th className="align_right">Unit</th>
                                                    <th className="align_right">Total</th>
                                                    <th></th>
                                                </tr>
                                            )}
                                        </thead>
                                        <tbody>
                                            {category_var != "workforce" &&
                                            service[category_var].products.map((item:any, itemIndex:any) => {
                                                return(
                                                <Fragment key={itemIndex}>
                                                <tr>
                                                    <td className="th_product table_quotes_td">
                                                        <AutocompleteProduct 
                                                            category_filter={category_filter}
                                                            setProduct={props.setProduct} 
                                                            product_name={item.product_name}
                                                            caso_code={item.caso_code}
                                                            category={category_var}
                                                            serviceIndex={serviceIndex}
                                                            itemIndex={itemIndex}
                                                            dotsClick={setOpenProductList}
                                                            setActiveItemParams={setActiveItemParams}
                                                        />
                                                    </td> 
                                                    <td className="th_quantity table_quotes_td">
                                                        <TextField 
                                                            label="Quantity" 
                                                            fullWidth
                                                            size='small'
                                                            InputLabelProps={{
                                                                style: { fontSize: '12px' }
                                                            }}
                                                            value={item.quantity}
                                                            inputProps={{
                                                                style: {
                                                                    fontSize: '12px',
                                                                }
                                                            }}
                                                            onChange={
                                                                (e) => {
                                                                    const newServices = [...services];
                                                                    newServices[serviceIndex][category_var].products[itemIndex].quantity = fixNumberValue(e.target.value, true, false, true);
                                                                    props.setServices(newServices);
                                                                    setCategoryTotal(category_var,serviceIndex);
                                                                }
                                                            }
                                                            />
                                                    </td>
                                                    <td className="th_quote_section table_quotes_td">
                                                        <AutocompleteSections
                                                            setSectionList={setSectionList}
                                                            setSection={setSection}
                                                            section={item.section}
                                                            category={category_var}
                                                            serviceIndex={serviceIndex}
                                                            itemIndex={itemIndex}
                                                            sectionList={sectionList}
                                                            setSectionsDict={setSectionsDict}
                                                            sectionsDict={sectionsDict}
                                                            services={services}
                                                            setServices={props.setServices}
                                                        />
                                                    </td>
                                                    
                                                    
                                                    
                                                    <td className="align_right">
                                                        ${item.sale_price}
                                                    </td>
                                                    <td className="align_right">
                                                        ${(item.sale_price * item.quantity).toLocaleString()}
                                                    </td>

                                                    
                                                    
                                                    <td className="align_right">
                                                        <IconButton
                                                            className="btn_delete_row"
                                                            aria-label="delete"
                                                            color="primary"
                                                            onClick={() => handleDelete(serviceIndex, category_var, itemIndex)}
                                                        >
                                                            <DeleteOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </td>
                                                    
                                                </tr>

                                                {category_var =="stone" && 
                                                    (<tr className="stoneAuxFields">
                                                        <td colSpan={3}>
                                                            <div className="stone_aditional_fields">



                                                                <FormControlLabel
                                                                    checked={item.have_workforce}
                                                                    control={
                                                                    <IOSSwitch
                                                                        sx={{ m: 1 }}
                                                                        
                                                                        onChange={(e: any) => {
                                                                            setHaveWorkforce(e.target.checked, serviceIndex, category_var, itemIndex);
                                                                        }}
                                                                        
                                                                    />
                                                                    }
                                                                    label={<span className="switchLabel">Fabrication</span>}
                                                                    
                                                                />

                                                                {item.have_workforce && (
                                                                    <TextField
                                                                        label="Sq Ft"
                                                                        size='small'
                                                                        sx={{ maxWidth: 70 }}
                                                                        value={item.sq_ft}
                                                                        InputLabelProps={{
                                                                            style: { fontSize: '12px' }
                                                                        }}
                                                                        inputProps={{
                                                                            style: {
                                                                                fontSize: '12px',
                                                                            }
                                                                        }}
                                                                        onChange={
                                                                            (e) => {
                                                                                const newServices = [...services];
                                                                                newServices[serviceIndex][category_var].products[itemIndex].sq_ft = fixNumberValue(e.target.value, false, false, true);
                                                                                setServices(newServices);
                                                                                setStoneWorkforceCost(serviceIndex, itemIndex);
                                                                                updateQuoteText(serviceIndex, category_var, itemIndex);
                                                                                
                                                                            }
                                                                        }
                                                                    />
                                                                )}

                                                                <FormControl>
                                                                    <InputLabel id="lbl_product_category">
                                                                        Destination
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId="product_category"
                                                                        id="product_category"
                                                                        size='small'
                                                                        sx={{ fontSize: 12 }}
                                                                        value={item.destination}
                                                                        label="Measurement"
                                                                        onChange={
                                                                            (e) => {
                                                                                const newServices = [...services];
                                                                                newServices[serviceIndex][category_var].products[itemIndex].destination = e.target.value;
                                                                                setServices(newServices);
                                                                                setStoneWorkforceCost(serviceIndex, itemIndex);
                                                                                setCategoryTotal(category_var,serviceIndex);
                                                                            }
                                                                        }
                                                                    >
                                                                    <MenuItem value="countertop">Countertop</MenuItem>
                                                                    <MenuItem value="backsplash">Backsplash</MenuItem>
                                                                    </Select>
                                                                </FormControl>

                                                                <TextField
                                                                    label="Edge"
                                                                    size='small'
                                                                    sx={{ maxWidth: 70 }}
                                                                    value={item.edge}
                                                                    InputLabelProps={{
                                                                        style: { fontSize: '12px' }
                                                                    }}
                                                                    inputProps={{
                                                                        style: {
                                                                            fontSize: '12px',
                                                                        }
                                                                    }}
                                                                    onChange={
                                                                        (e) => {
                                                                            const newServices = [...services];
                                                                            newServices[serviceIndex][category_var].products[itemIndex].edge = e.target.value;
                                                                            setServices(newServices);


                                                                            //updateQuoteText(product_name, section, serviceIndex, category, itemIndex);
                                                                            updateQuoteText(serviceIndex, category_var, itemIndex);

                                                                        }
                                                                    }
                                                                />

                                                                <TextField
                                                                    label="Finish"
                                                                    size='small'
                                                                    sx={{ maxWidth: 70 }}
                                                                    value={item.finish}
                                                                    InputLabelProps={{
                                                                        style: { fontSize: '12px' }
                                                                    }}
                                                                    inputProps={{
                                                                        style: {
                                                                            fontSize: '12px',
                                                                        }
                                                                    }}
                                                                    onChange={
                                                                        (e) => {
                                                                            const newServices = [...services];
                                                                            newServices[serviceIndex][category_var].products[itemIndex].finish = e.target.value;
                                                                            setServices(newServices);
                                                                            updateQuoteText(serviceIndex, category_var, itemIndex);
                                                                        }
                                                                    }
                                                                />


                                                                <TextField
                                                                    label="Sink"
                                                                    size='small'
                                                                    sx={{ maxWidth: 70 }}
                                                                    value={item.sink}
                                                                    InputLabelProps={{
                                                                        style: { fontSize: '12px' }
                                                                    }}
                                                                    inputProps={{
                                                                        style: {
                                                                            fontSize: '12px',
                                                                        }
                                                                    }}
                                                                    onChange={
                                                                        (e) => {
                                                                            const newServices = [...services];
                                                                            newServices[serviceIndex][category_var].products[itemIndex].sink = e.target.value;
                                                                            setServices(newServices);
                                                                            setStoneWorkforceCost(serviceIndex, itemIndex);
                                                                            updateQuoteText(serviceIndex, category_var, itemIndex);
                                                                        }
                                                                    }
                                                                />

                                                            </div>
                                                        </td>
                                                        {item.have_workforce && (
                                                        <td colSpan={2} className="align_right">
                                                            <div className="label_workforce"><span className="thSpan" >Workforce</span></div>
                                                            ${(item.workforce_cost).toLocaleString()}
                                                        </td>
                                                        
                                                        )}
                                                        
                                                    </tr>
                                                    )}
                                                </Fragment>
                                                
                                                
                                            )
                                            })}


                                            {category_var == "workforce" &&
                                            service[category_var].tasks.map((item:any, itemIndex:any) => {
                                            
                                                return(
                                                <tr className="table_quotes_tr" key={itemIndex}>
                                                    <td className="th_product table_quotes_td">
                                                        <AutocompleteWorkforce 
                                                            category_filter={category_filter}
                                                            setWorkforce={setWorkforce} 
                                                            workforce_name={item.workforce_name}
                                                            serviceIndex={serviceIndex}
                                                            itemIndex={itemIndex}
                                                            dotsClick={setOpenWorkforceList}
                                                            setActiveItemParams={setActiveItemParams}
                                                            setOpenWorkforceEditor={setOpenWorkforceEditor}
                                                            setWorkforceCreatorName={setWorkforceCreatorName}
                                                        />
                                                    </td>
                                                    <td className="th_quantity table_quotes_td">
                                                        <TextField 
                                                            label="Hours" 
                                                            fullWidth
                                                            size='small'
                                                            value={item.hours}
                                                            inputProps={{
                                                                style: {
                                                                    fontSize: '12px',
                                                                }
                                                            }}
                                                            onChange={
                                                                (e) => {
                                                                    const newServices = [...services];
                                                                    newServices[serviceIndex].workforce.tasks[itemIndex].hours = e.target.value;
                                                                    setServices(newServices);
                                                                    setCategoryTotal(category_var,serviceIndex);
                                                                }
                                                            }
                                                            />
                                                    </td>
                                                    
                                                    <td className="th_quote_unit table_quotes_td">
                                                        ${item.hour_cost}
                                                    </td>
                                                    <td className="th_quote_cost table_quotes_td">
                                                        ${(item.hour_cost * item.hours).toLocaleString()}
                                                    </td>
                                                    <td>
                                                        <IconButton
                                                            className="btn_delete_row"
                                                            aria-label="delete"
                                                            color="primary"
                                                            onClick={() => handleDelete(serviceIndex, category_var, itemIndex)}
                                                            
                                                        >
                                                            <DeleteOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </td>
                                                </tr>
                                            )})}
                                        </tbody>
                                    </table>

                                        

                                    
                                    
                                    <Button 
                                        className="item_add_button"
                                        onClick={()=>{
                                            if(category_var == "workforce"){
                                                handleAddTask(serviceIndex,category_var)
                                            }
                                            else if(category_var == "stone"){
                                                handleAddStone(serviceIndex,category_var)
                                            }
                                            else{
                                                handleAddItem(serviceIndex,category_var)
                                            }
                                        }}>
                                        Add item
                                    </Button>
                                </Box>
                            )
                        })}
                    </div>
                    


                </div>
            )})}


            <Button className="dashedButton" onClick={()=>{props.setOpenDialogServiceCreator(true)}}>Add service</Button>

        </div>

        <AlertDialog
            open={openDeleteService}
            setOpen={setOpenDeleteService}
            subtitle="Are you sure you want to delete this service?"
            send={handleDeleteService}
        />
    </>
  );
};