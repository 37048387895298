import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import axios from 'axios';

import AutocompleteProjects from 'elements/Autocompletes/AutocompleteProjects';
import { ProjectConfig } from 'Global';
import Box from 'elements/Box';

import 'assets/css/main.css';
import { CloudDownload } from '@mui/icons-material';

const defaultValues = {
    payment_name: '',
    date: moment(new Date()).format('YYYY-MM-DD'),
    project_id: null,
    value: 0,
    payment_type: 'deposit',
}

export default function PaymentDialog( props: any ) {

    const {
        open,
        setOpen,
        paymentId,
    } = props;

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const [formValues, setFormValues] = useState<any>({...defaultValues});

    useEffect(() => {
        if (open) {
            loadData();
        } else {
            setFormValues({...defaultValues});
        }
    }, [open]);

    useEffect(() => {
        if (paymentId) {
            loadData();
        }
    }, [paymentId]);

    const loadData = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url,{
            params: {
                request: 'get_payments_details',
                payment_id: paymentId,
            }
        })
        .then((response) => {
            console.log(response.data);
            setFormValues({...response.data})
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleDownload = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'download_payment',
                payment_id: paymentId,
            },
        })
        .then((response) => {
            console.log(response.data);
            window.open(response.data, '_blank', 'noopener,noreferrer');
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent
                className="forced_box_editor"
            >
                <div className="box_header">
                    <h2>ADD PAYMENT</h2>
                </div>

                <div className="input_group">
                    <div className="input_row">
                        <Typography variant="subtitle1" gutterBottom>
                            Project
                        </Typography>
                        <TextField
                            size='small'
                            variant="standard"
                            disabled
                            fullWidth
                            sx={{
                                "& .MuiInputBase-input": { textAlign: 'right', maxWidth: '250px' },
                                "& .Mui-disabled": { color: "black", WebkitTextFillColor: 'black !important' }
                            }}
                            value={formValues.project_name}
                        />
                    </div>

                    <div className="input_row">
                        <Typography variant="subtitle1" gutterBottom>
                            Ammount
                        </Typography>
                        <TextField
                            size='small'
                            variant="standard"
                            disabled
                            fullWidth
                            sx={{
                                "& .MuiInputBase-input": { textAlign: 'right', maxWidth: '250px' },
                                "& .Mui-disabled": { color: "black", WebkitTextFillColor: 'black !important' }
                            }}
                            value={formValues.value}
                        />
                    </div>

                    <div className="input_row displayer_row">
                        <Typography variant="subtitle1" gutterBottom>
                            Date
                        </Typography>
                        <TextField
                            size='small'
                            variant="standard"
                            disabled
                            fullWidth
                            sx={{
                                "& .MuiInputBase-input": { textAlign: 'right', maxWidth: '250px' },
                                "& .Mui-disabled": { color: "black", WebkitTextFillColor: 'black !important' }
                            }}
                            value={formValues.date}
                        />
                    </div>

                    <div className="input_row displayer_row">
                    <Typography variant="subtitle1" gutterBottom>
                            Type
                        </Typography>
                        <TextField
                            size='small'
                            variant="standard"
                            disabled
                            fullWidth
                            sx={{
                                "& .MuiInputBase-input": { textAlign: 'right', maxWidth: '250px' },
                                "& .Mui-disabled": { color: "black", WebkitTextFillColor: 'black !important' }
                            }}
                            value={formValues.payment_type}
                        />
                    </div>

                    <div className="input_row displayer_row">
                    <Typography variant="subtitle1" gutterBottom>
                            Notes
                        </Typography>
                        <TextField
                            size='small'
                            variant="standard"
                            disabled
                            fullWidth
                            sx={{
                                "& .MuiInputBase-input": { textAlign: 'right', maxWidth: '250px' },
                                "& .Mui-disabled": { color: "black", WebkitTextFillColor: 'black !important' }
                            }}
                            value={formValues.notes}
                        />
                    </div>
                </div>

                <div className="buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(false)}
                    >
                        Cerrar
                    </Button>

                    {/* <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloudDownload />}
                        onClick={() => handleDownload()}
                    >
                        Download
                    </Button> */}
                </div>
            </DialogContent>
        </Dialog>
    )
}