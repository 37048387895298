import { Button } from '@mui/material';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import GanttView from 'components/Tasks/GanttView';
import Filters from 'elements/Filters/Filters';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PerformancePointsList from './PerformancePointsList';

export default function PerformanceList( props: any ) {
    /*================================================
    FILTERS
    =================================================*/
    const filters = [
        {type: "dateRange", label: { start: "Start date", end: "End date",}, name: { start: "start_date", end: "end_date", composite: 'Date renge'},},
    ]
    const [applyFilters, setApplyFilters] = useState({});
    const [filtersValues, setFiltersValues] = useState<any>({});
    const [openFilters, setOpenFilters] = useState(false);
  
    const setFilters = (tempFilters: any) => {
        setFiltersValues(tempFilters);
    }
    /*================================================= 
    ==================================================*/

    const {} = props;

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { user_id } = useParams();


    const [loading, setLoading] = useState(false);
    
    const [workedHours, setWorkedHours] = useState(0);
    const [earns, setEarns] = useState(0);
    const [points, setPoints] = useState(0);

    const [data, setData] = useState<any[]>([]);
    const [rangeDate, setRangeDate] = useState<any>({start: new Date(), end: new Date((new Date().setMonth(new Date().getMonth() + 1))), months_quantity: 1});

    const [page, setPage] = useState(searchParams.get('page') || 1);
    const [totalPages, setTotalPages] = useState(0);
    const [newsList, setNewsList] = useState<any[]>([]);
    
    

    useEffect(() => {
        props.setLoading(loading);
    }, [loading]);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadPointsList();
    }, [page]);



    const loadIndicators = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_user_performance_indicators',
                user_id: user_id,
            }
        })
        .then((response) => {
            setWorkedHours(response.data.worked_hours);
            setEarns(response.data.earns);
            setPoints(response.data.total_points);
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const loadPointsList = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_user_points',
                user_id: user_id,
                params: {
                    page: page,
                }
            }
        })
        .then((response) => {
            setNewsList(response.data.list);
            setTotalPages(response.data.total_pages);
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });

        loadIndicators();
    }

    const loadSchedule = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_user_worked_schedule',
                params: {
                    user_id: user_id,
                    filters: filtersValues,
                }
            }
        })
        .then((response) => {
            console.log("usr schedule ",response.data);
            setData(response.data.tasks);
            setRangeDate({
                start: new Date(response.data.schedule_start_date),
                end: new Date(response.data.schedule_end_date),
                months_quantity: response.data.months_quantity,
            });
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const loadData = () => {
        loadIndicators();
        loadPointsList();
        loadSchedule();
    }

    useEffect(() => {
        setLoading(true);
        loadData();
    }, [filtersValues]);

    return (
        <div className='moduleContainer' id="performanceContainer">
            <div className='moduleWrapper'>
                <div className="box_header buttons_top">

                    <h1>User performance</h1>

                    <div className="buttons_right" style={{display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', }}>
                        <Filters
                            open={openFilters}
                            setOpen={setOpenFilters}
                            filters={filters}
                            setFilters={setFilters}
                            setApplyFilters={setApplyFilters}
                        />
                    </div>

                </div>
                
                <div style={{margin: '10px 0'}}>
                    <FiltersApplied
                        applyFilters={applyFilters}
                        setApplyFilters={setApplyFilters}
                        filtersValues={filtersValues}
                        setFiltersValues={setFiltersValues}
                    />
                </div>

                <div className='moduleContent'>
                    <div className='top_box_list_header'>
                        <div className="performanceIndicator box">
                            <h3>{workedHours}</h3>
                            <span>Worked hours</span>
                        </div>

                        <div className="performanceIndicator box">
                            <h3>$ {earns.toLocaleString()}</h3>
                            <span>Earns</span>
                        </div>

                        <div className="performanceIndicator box">
                            <h3>{points}</h3>
                            <span>Points</span>
                        </div>
                    </div>

                    <div id="user_task_view">
                        <GanttView
                            setLoading={setLoading}
                            setOpenTaskEditor={() => {}}
                            setSelectedTaskId={() => {}}
                            data={data}
                            rangeDate={rangeDate}
                        />
                    </div>

                    <div id="user_news_list">
                        <PerformancePointsList
                            setLoading={setLoading}
                            list={newsList}
                            page={page}
                            totalPages={totalPages}
                            loadPointsList={loadPointsList}
                            setPage={(value: any) => {
                                setPage(value);
                                navigate(
                                    {
                                        pathname: `/users/${user_id}/performance#user_news_list`,
                                        search: `?page=${value}`,
                                    },
                                );
                            }}
                            user_id={user_id}
                        />
                    </div>
               </div>
            </div>
        </div>
    );
}