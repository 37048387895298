import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Box from './Box';
import ic_dots from "icons/ic_dots.svg";

interface IAutocompleteList {
  name: string;
  id: number;
}



export default function ACSupplierList(props: any) {


    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const handleOpenSupplierList = () => {
        setOpen(true);
      };


/* =================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface ISupplierList {
        inputValue?: string;
        supplier_id?: number;
        name: string;
      }
      const filter = createFilterOptions<ISupplierList>();
    
      //setSupplierList
      const [supplier_list, setSupplierList] = useState<any[]>([]);
      const [supplier, setSupplier] = useState<ISupplierList | null>(null);
      const [valueSelected , setValueSelected] = useState("");
    
      useEffect(() => {
        axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "supplier_list",
              params: {
                fullList: true,
              }
            },
          })
          .then((response) => {
            console.log("supplier_list", response.data)
            setSupplierList(response.data.list);
            setLoading(true);
          })
          .catch((err) => {
            console.log(err.data);
          });
      }, []);
      /* ================================================= 
        ===================================================*/

  let item_name = "";
  if (supplier_list && (props.value && props.value!=0)) 
  {
    
    item_name = supplier_list.find(obj => {
      return obj.supplier_id === props.value; 
    }).name;
  } 

  return (
    <>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div style={{ outline: "none" }}>
            <Box
                id="supplier_list"
                title="Suppliers"
                subtitle="List"
            >
                <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Supplier Name</TableCell>
                        <TableCell>TIN</TableCell>
                        <TableCell>Adresss</TableCell>
                        <TableCell>Payment Terms</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {supplier_list.map((supplier, i) => (
                        <TableRow
                        onClick={() => (
                            setSupplier({
                            name: supplier.name,
                            }),
                            //const props.onChange = (e: { target: { name: any; value: any; }; })
                            props.onChange(
                                {
                                    target: {
                                        value: supplier.supplier_id.toString(),
                                        name: "supplier_id",
                                    }
                                }
                            ),

                            handleClose()
                        )}
                        key={i}
                        hover
                        role="checkbox"
                        >
                        <TableCell>{supplier.name}</TableCell>
                        <TableCell>{supplier.tin}</TableCell>
                        <TableCell>{supplier.address}</TableCell>
                        <TableCell>{supplier.payment_terms}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </Box>
            </div>
        </Modal>

        <Autocomplete
          value={item_name}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              setSupplier({
                name: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setSupplier({
                name: newValue.inputValue,
              });
            } else {
              setSupplier(newValue);
              if (newValue?.supplier_id != null) {
                props.onChange(
                    {
                        target: {
                            value: newValue.supplier_id.toString(),
                            name: "supplier_id",
                        }
                    }
                )
              } else {
                props.onChange("");
              }
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.client_name
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                name: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          fullWidth
          id="category"
          options={supplier_list}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(props, option) => (
            <li {...props}>{option.name}</li>
          )}
          sx={{  }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label="Supplier"
              placeholder='Supplier'
              sx={{minWidth: 350}}
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <IconButton onClick={handleOpenSupplierList}>
                    <img src={ic_dots}></img>
                  </IconButton>
                ),
              }}
            />
          )}
        />
    </>
  );
}
