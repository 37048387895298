import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Autocomplete, Button, Dialog, DialogContent, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { ProjectConfig } from 'Global';

import 'assets/css/main.css';
import ACPointsList from 'elements/ACPointsList';



export default function PerformanceDialog( props: any ) {
    const {
        open,
        setOpen,
        user_id,
        user_point_id,
        loadPointsList
    } = props;

    const defaultFormValues = {
        user_id: user_id,
        user_point_id: user_point_id || "null",
        point_id: "null",
        points: '0',
        description: '',
        date: new Date(),
    };

    
    
    
    
    const [formValues, setFormValues] = useState({...defaultFormValues});
    const [list, setList] = useState<any[]>([]);
    const [pointSelected, setPointSelected] = useState<any>({});
    
    useEffect(() => {
        loadData();
    }, []);
    
    useEffect(() => {
        setFormValues({...defaultFormValues});
    }, [open]);


    const loadData = () => {
        axios.get(ProjectConfig.api_url, {
            params: {
                request: "get_points_list",
                params: {
                    full_list: true,
                }
            },
        })
        .then((response) => {
            setList(response.data.list);
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
        });
    };

    const handleSubmit = () => {
        
        axios.post(ProjectConfig.api_url, {...formValues, points: Number(formValues.points)}, {
            params: {
                request: 'add_edit_user_points',
            }
        })
        .then((response) => {
            console.log(response.data);
            loadPointsList();
            setFormValues({...defaultFormValues})
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setOpen(false);
        });

        
    }


    const handleInputChange = (data: any) => {
        console.log("data", data);

        setFormValues({
            ...formValues,
            point_id: data.point_id,
            points: data.points,
            description: data.description,
        });

    };   




    /* =================================================
    CLEAN POINT ID ON CLOSE
    =================================================*/
    useEffect(() => {
        setFormValues({
            ...formValues,
            point_id: "null"
        });
    }, [open]);


    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogContent className='forced_box_editor'>
                <h2>Points admin</h2>

                <div className='form input_group'>
                    <div className="input_row">
                        <ACPointsList onChange={handleInputChange} value={formValues.point_id} />

                        <TextField
                            id="points"
                            label="Points"
                            type="number"
                            value={formValues.points}
                            onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    points: e.target.value,
                                });
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </div>

                <div className='buttons'>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleSubmit();
                        }}
                        startIcon={<SaveIcon />}
                    >
                        Add
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}