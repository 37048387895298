import Card from '../../elements/Card';
import axios from 'axios';
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ProjectConfig} from 'Global';
import {
  Button,
  Chip,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AlertDialog from 'elements/AlertDialog';
import Box from 'elements/Box';
import Filters from 'elements/Filters/Filters';
import FiltersApplied from 'elements/Filters/FiltersApplied';


export default function Projects (props: any) {
  /*================================================
    FILTERS
  =================================================*/
  const filters = [
    {type: "client", label: "Clients", name: "client_id",},
    {type: "project", label: "Project", name: "project_id",},
    {type: "select", label: "Status", name: "project_status", options: [{label: 'Active', value: '0'}, {label: 'Paused', value: '1'}, {label: 'Closed', value: '2'}, {label: 'Discarded', value: '3'},]},
  ]
    
  const [applyFilters, setApplyFilters] = useState({});
  const [filtersValues, setFiltersValues] = useState<any>({});
  const [openFilters, setOpenFilters] = useState(false);

  const setFilters = (tempFilters: any) => {
      setFiltersValues(tempFilters);
  }
  /*================================================= 
  ==================================================*/

  const {} = props;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => { props.setLoading(loading); }, [loading]);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [activeId, setActiveId] = useState<any>(null);

  let { project_id } = useParams();
  const [project_list, setProjectList] = useState<any []>([]);
  const [totalPages, setTotalPages] = useState<any>(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get('page') ? parseInt(searchParams.get('page')!) : 1);

  useEffect(() => {
    updateList();
  }, [page]);

  useEffect(() => {
    if (page > 1) {
      setPage(1);
      setSearchParams({...searchParams, page: '1'});
      updateList();
    } else {
      updateList();
    }
  }, [filtersValues]);

  const updateList = () => {
    setLoading(true);

    console.log("filtersValues",filtersValues)
    axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'project_list',
          params: {
            page: page,
            filters: filtersValues,
          }
        }
      })
        .then((response) => {
          console.log("project_list",response.data); 
          setProjectList(response.data.list);
          setTotalPages(response.data.total_pages);
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          setLoading(false);
        });
  }


  const handleClick = (project: any) => {

    //set local storage
    localStorage.setItem('project_id', project.project_id)
    localStorage.setItem('project_name', project.project_name)
    localStorage.setItem('client_name', project.client_name)
    localStorage.setItem('client_id', project.client_id)

    //USE LINK INSTEAD window.location.href = '/projects/' + project.project_id;

  }

  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = () => {
    axios.post(ProjectConfig.api_url, null, 
    {
      params: {
        request: 'delete_project',
        project_id: activeId
      }
    })

    .then((response) => {
      console.log(response.data); 
      updateList()
      
    })
    .catch((err) => {
      console.log(err.data);
    })
  }
  /*=========================================================*/
  
  return (
    <>
      <div id='ProjectList'>
        <div className='moduleContainer'>
          <div className='moduleContainer_header moduleHeader'>
            <div>
              <h2 className='module_title'>PROJECTS</h2>
            </div>

            <div className='buttons_top'>
              <Filters
                open={openFilters}
                setOpen={setOpenFilters}
                filters={filters}
                setFilters={setFilters}
                setApplyFilters={setApplyFilters}
              />

              <Button
                component={Link}
                to="create"
                variant="contained"
              >
                New Project
              </Button>
            </div>
          </div>

          <div style={{margin: '10px 0'}}>
            <FiltersApplied
              applyFilters={applyFilters}
              filtersValues={filtersValues}
              setApplyFilters={setApplyFilters}
              setFiltersValues={setFiltersValues}
            />
          </div>

          <Box id="project_list_container" className="flex_container box100">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Project</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {project_list.map((project,index) => (
                <TableRow
                  key={index}
                >
                  <TableCell>{project.project_id}</TableCell>

                  <TableCell>
                    <div className='project_list_name'>
                      <h2 className='card_title'>{project.project_name}</h2>
                      <span className='card_subtitle'>{project.client_name}</span>
                    </div>
                  </TableCell>

                  <TableCell sx={{minWidth: '200px'}}>
                    <Chip
                      label={project.project_status}
                      color={project.project_status === 'Active' ? 'success' : 'error'}
                      size='small'
                      variant='outlined'
                    />
                  </TableCell>

                  <TableCell align='right'>
                    <div className="card_content">
                      <Button 
                        id={project.project_id} 

                        onClick={() => {
                          handleClick({
                            project_id: project.project_id,
                            project_name: project.project_name,
                            client_name: project.client_name,
                            client_id: project.client_id
                          });
                          navigate('/projects/' + project.project_id)
                        }}
                        variant="contained"
                        size='small'
                      >
                          View
                      </Button>

                      <IconButton aria-label="delete"  color="primary" onClick={() => {
                          setActiveId(project.project_id);
                          setOpen(true);
                      }}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </div>
                  </TableCell>

                </TableRow>
                ))}
              </TableBody>
            </Table>

            {totalPages > 1 &&
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, value) => {
                setPage(value);
                setSearchParams({
                  ...searchParams,
                  page: value.toString()
                });
              }}
              sx={{"& .MuiPagination-ul": {
                margin: 'auto',
                width: 'fit-content'
              }}}
              color="primary"
              size="small"
              className='pagination'
            />
            }
          </Box>
        </div>
      </div>

      <AlertDialog
        open={open}
        setOpen={setOpen}
        subtitle="Are you sure you want to delete this project?"
        send={handleDelete}
      />
    </>
  );
};