import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';

import 'assets/css/main.css';
import { Autorenew, Clear, ZoomIn, ZoomOut } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import LoadingCircle from './LoadingCircle';

export default function PopupViewer( props:any ) {

    const {} = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);
    
    const [searchParams, setSearchParams] = useSearchParams();

    const img = searchParams.get('img') || '';

    const [loadingImg, setLoadingImg] = useState(true);

    const adjustWindow = () => {
        function getImgSize(): { width: number, height: number } {
            let myImg = document.getElementById("popup-img");
            let realWidth;
            let realHeight;
            if (myImg) {
                realWidth = myImg.clientWidth
                realHeight = myImg.clientHeight;
            }
            console.log('getImgSize', realWidth, realHeight);
            return { width: Number(realWidth), height: Number(realHeight) };
        }

        if (img) {
            const { width, height } = getImgSize();

            if (width && height) {
                console.log('AhoraAhoraAhora');
                if (width < 1200) {
                    console.log('AhoraAhora');
                    window.resizeTo(width, height + 100);
                } else {
                    console.log('Ahora');
                    window.resizeTo(1200, 1200 / width * height);
                }
            }
        }
    }

    return (
        <>
            <LoadingCircle
                loading={loadingImg}
            />

            <TransformWrapper
                initialScale={1}
            >
                 {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                        <div className='fixed_button buttons_top' style={{zIndex: 19,}}>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                startIcon={<ZoomIn />}
                                onClick={() => zoomIn()}
                            >
                                Zoom In
                            </Button>

                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                startIcon={<ZoomOut /> }
                                onClick={() => zoomOut()}
                            >
                                Zoom Out
                            </Button>

                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                startIcon={ <Autorenew /> }
                                onClick={() => resetTransform()}
                            >
                                Reset
                            </Button>
                        </div>

                        <TransformComponent wrapperClass='fullWidth' contentClass='fullWidth'>
                            <img
                                id="popup-img"
                                src={img}
                                style={{width: '100%'}}
                                onLoad={() => {
                                    setLoadingImg(false)
                                    adjustWindow();
                                }}
                            />
                        </TransformComponent>
                    </React.Fragment>
                )}
            </TransformWrapper>
        </>
    );
}