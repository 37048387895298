import { Autocomplete, Button, createFilterOptions, Dialog, DialogContent, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from 'icons/ic_dots.svg';
import {ProjectConfig} from 'Global';
import ClientEditor from "components/Clients/ClientEditor";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Event } from "@mui/icons-material";
import { useSnackbar } from "notistack";


export default function ProjectEditor (props: any) {
  const {setLoading, onSubmit} = props;

  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  useEffect(() => {setLoading(false);}, []);
  const navigate = useNavigate();
  const defaultValues = {
    project_name: "",
    client_id: "",
    client_name: "",
    project_start_date: new Date(),
    project_deadline: new Date(),
  };


  
    /* =================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface IClientList {
      inputValue?: string,
      client_id?: string,
      client_name: string,
    };
    const filter = createFilterOptions<IClientList>();

    //setClientList
    const [client_list, setClientList] = useState<any []>([]);
    const [client, setClient] = useState<IClientList | null>(null);
    
    useEffect(() => {
      
      loadList();
      
    }, [])


    const loadList = () => {
      setLoading(true)
      axios.get(ProjectConfig.api_url,
        {
          params: {
            request: 'client_list',
            full_list: true,
          }
        })
          .then((response) => {
            console.log(response.data);
            setClientList(response.data.list)
            setLoading(false)
             
          })
          .catch((err) => {
            console.log(err.data);
          })
    }
    /* ================================================= 
    ===================================================*/


  const [formValues, setFormValues] = useState(defaultValues)



  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


  const handleSubmit = () => {
    

    if (!formValues.project_name || !formValues.client_id) {
      enqueueSnackbar("Please enter a project name and client", { variant: 'error' });
      return;
    }

    if (new Date(formValues.project_start_date).getTime() > new Date(formValues.project_deadline).getTime()) {
      enqueueSnackbar("Project start date cannot be after project deadline", { variant: 'error' });
      return;
    }
    console.log(formValues);

    axios.post(ProjectConfig.api_url, null,
      {
        params: {
          request: 'add_project',
          formdata: formValues,
        }
      })
        .then((response) => {
          console.log(response); 
          setLoading(true)
          navigate(-1)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        
  };

  const handleOpenClientList = () => {
    setOpen(true);
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);


  const [openNewClient, setOpenNewClient] = useState(false);


  const createNewClient = (client: any) => {
    setOpenNewClient(false);
    loadList();

    setClient({
      client_name: client.client_name,
    })

    setFormValues({
      ...formValues,
      ["client_id"]: client.client_id.toString()
    })

    
  }




  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{"& .MuiDialog-paper": { maxWidth: 'unset', width: 'fit-content'}}}
      >
        <DialogContent sx={{outline:'none', p: 0, maxWidth: 'unset', width: 'fit-content'}}>
          <Box id='project_list' title="Clients" subtitle="List" >
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Client Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {client_list.map((client,i) => (
                    <TableRow 
                      onClick={() => (
                      setClient({
                        client_name: client.client_name,
                      }),
                      setFormValues({
                        ...formValues,
                        ["client_id"]: client.client_id.toString()
                      }),
                      handleClose()
                      )} 
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell>{client.client_name}</TableCell>
                      <TableCell>{client.client_address}</TableCell>
                      <TableCell>{client.client_phone}</TableCell>
                      <TableCell>{client.client_email}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Box>
        </DialogContent>
      </Dialog>
    
    <form>
      <Box id="project_editor" className="box_editor" title="New project" subtitle="Edit" >
        <div className='form' style={{minWidth: '400px'}}>
          <div className='input_group'>
            <TextField 
              id="project_name"
              name="project_name"
              label="Project Name"
              type="text"
              required
              fullWidth
              value={formValues.project_name}
              onChange={handleInputChange}
            />
            </div>
            <div className='input_group'>
            
          <Autocomplete
            value={client}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setClient({
                  client_name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setClient({
                  client_name: newValue.inputValue,
                  client_id: "null",
                });
                setOpenNewClient(true);
              } else {
                setClient(newValue);
                if (newValue?.client_id!=null)
                {
                  setFormValues({
                    ...formValues,
                    ["client_id"]: newValue.client_id.toString()
                  });
                }
                else {
                  setFormValues({
                    ...formValues,
                    ["client_id"]: "",
                  });
                }
                
              }
              
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.client_name);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  client_name: `Add "${inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            fullWidth
            id="free-solo-with-text-demo"
            options={client_list}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.client_name;
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.client_id}>
                {option.client_name}
                </li>
              )}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
              <TextField 
              {...params} 
              label="Client Name" 
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <IconButton 
                    onClick={handleOpenClientList}
                    
                  ><img src={ic_dots}></img></IconButton>
                )
              }}
              />
            )}
            
          />

          <div className="input_row">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                label="Start date"
                inputFormat="MMMM DD, yyyy"
                value={formValues.project_start_date}
                InputProps={{
                  endAdornment: (
                    <Event />
                  ),
                }}
                showToolbar={false}
                closeOnSelect 
                onChange={(newValue: any) => {
                  if (newValue) {
                    setFormValues({
                      ...formValues,
                      ["project_start_date"]: (newValue).format('YYYY-MM-DD')
                    });
                  }
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterMoment}>

              <MobileDatePicker
                label="Deadline"
                inputFormat="MMMM DD, yyyy"
                disabled={!formValues?.project_start_date}
                value={formValues.project_deadline}
                InputProps={{
                  endAdornment: (
                    <Event />
                  ),
                }}
                showToolbar={false}
                closeOnSelect 
                onChange={(newValue: any) => {
                  if (newValue) {
                    setFormValues({
                      ...formValues,
                      ["project_deadline"]: (newValue).format('YYYY-MM-DD')
                    });
                  }
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />

            </LocalizationProvider>
          </div>
        </div>
      </div>
      
      <div className='buttons'>
          <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" className="button_1">Submit</Button>
      </div>
    </Box>


      <Dialog 
        open={openNewClient}
        onClose={() => setOpenNewClient(false)}
      >
        <ClientEditor 
          client={client}
          setLoading={setLoading} 
          setOpenNewClient={setOpenNewClient} 
          onSubmit={createNewClient} />
      </Dialog>
    </form>
    </div>
    
  );
};

