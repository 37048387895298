import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    Button, Card, Chip, Dialog, TextField, Typography,
} from '@mui/material';
import { Add, EditOutlined, Save, TextFields } from '@mui/icons-material';
import { useParams } from 'react-router';
import {
    DropResult,
    DragDropContext,
    Draggable,
    // Droppable,
} from 'react-beautiful-dnd';

import Droppable from 'elements/StrictModeDroppable';

import { ProjectConfig } from 'Global';
import { NotesForm } from './NotesForm';
import Box from 'elements/Box';
import 'assets/css/main.css';
import { resetServerContext } from "react-beautiful-dnd"
import AppAvatar from 'elements/AppAvatar';
import moment from 'moment';
import NoteDetailedForm from './NoteDetailedForm';
import { useSnackbar } from 'notistack';

resetServerContext();

const defaultValues = {
    board_id: 'null',
    board_name: '',
    status_id: '0',
};

const defaultColumn = {
    column_id: 'null',
    board_id: 'null',
    column_name: '',
    status_id: '0',
};

const defaultNote = {
    note_id: 'null',
    column_id: "null",
    title : "",
    description : "",
    created_at : moment(new Date()).format("YYYY-MM-DD"),
    last_update : moment(new Date()).format("YYYY-MM-DD"),
    user_id : "null",
    status_id : "0",
}

export function NotesEditor( props: any ) {
    const  {} = props;

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const {
        note_id,
        project_id = 'null',
    } = useParams();

    const {enqueueSnackbar} = useSnackbar();
    
    const [list, setList] = useState<any[]>([]);
    const [formValues, setFormValues] = useState<any>({...defaultValues});
    const [formColumn, setFormColumn] = useState<any>({...defaultColumn});
    const [formNote, setFormNote] = useState<any>({...defaultNote});

    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openAddColumn, setOpenAddColumn] = useState<boolean>(false);
    const [openEditNote, setOpenEditNote] = useState<boolean>(false);
    const [openAddNote, setOpenAddNote] = useState<boolean>(false);

    const [isWinReady, setIsWinReady] = useState<boolean>(false);

    useEffect(() => {
        loadData();
    }, [project_id, note_id]);

    useEffect(() => {
        setIsWinReady(true);
    }, [])

    const loadData = () => {
        setLoading(true);

        const params = project_id !== 'null' ? {project_id: project_id,} : {board_id: note_id,};

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_notes_columns_list',
                params: {
                    ...params,
                    full_list: true,
                },
            }
        })
        .then((response) => {
            console.log(response.data);
            setList(response.data.list);

            setFormValues({
                ...defaultValues,
                board_id: response.data.board_id,
                board_name: response.data.board_name,
            });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
            resetServerContext();
        })
    }

    const loadNotes = (column: any, index: number) => {
        // setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_notes_list',
                column_id: column.column_id,
                params: {
                    full_list: true
                },
            }
        })
        .then((response) => {
            console.log(response.data);
            
            if (response.data.list) {
                column.notes = response.data.list;
            } else {
                column.notes = [];
            }

            const newList = [...list];
            newList[index] = column;
            setList(newList);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
            resetServerContext();
        });
    }

    const handleAddColumn = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, {
            ...formColumn,
            board_id: project_id !== 'null' ? formValues.board_id : note_id,
        }, {
            params: {
                request: 'add_edit_notes_columns',
            },
        })
        .then((response) => {
            console.log(response.data);

            setFormColumn({...defaultColumn});
            loadData();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
            setOpenAddColumn(false);
            resetServerContext();
        });
    }

    const handleAddNote = (note: any = null, withClosure: boolean = true) => {
        setLoading(true);

        const formData = note ? {
            ...note,
            board_id: project_id !== 'null' ? formValues.board_id : note_id,
            user_id: localStorage.getItem('user_id'),
        } : {
            ...formNote,
            board_id: project_id !== 'null' ? formValues.board_id : note_id,
            user_id: localStorage.getItem('user_id'),
        };

        if (project_id !== 'null') {
            formData.project_id = project_id;
        }

        console.log(formData);

        axios.post(ProjectConfig.api_url, formData, {
            params: {
                request: 'add_edit_notes',
            },
        })
        .then((response) => {
            console.log(response.data);

            if (withClosure) {
                setFormNote({...defaultNote});
            }
            loadData();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
            if (withClosure) {
                setOpenEditNote(false);
            }
            setOpenAddNote(false);
            resetServerContext();
        });
    }

    const handleMoveNote = (note: any) => {
        // console.log(note);
        // setLoading(true);

        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: 'move_note',
                note_id: note.note_id,
                column_id: note.column_id,
            },
        })
        .then((response) => {
            // console.log(response.data);
            // loadData();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            // setLoading(false);
            resetServerContext();
        });
    }


    const reorder =(
        list: any[],
        sourceIndex: number,
        destinationIndex: number,
    ) => {
        const arrCopy = [...list];
        const [removed] = arrCopy.splice(sourceIndex, 1);
        arrCopy.splice(destinationIndex, 0, removed);

        return arrCopy;
      };

    const handleDragEnd = ( result: DropResult ) => {
        const { source, destination } = result;
        let noteToMove: any;

        if (!destination) return;

        if (destination.droppableId === 'Notes') {
            setList(reorder(list, source.index, destination.index));
        } else if (destination.droppableId !== source.droppableId) {
            let itemToMove: any;
            const newList = [...list];

            newList.forEach((item, index) => {
                const newNotes = [...item.notes];
                newNotes.forEach((note, noteIndex) => {
                    if (note.note_id === result.draggableId) {
                        itemToMove = newNotes.splice(noteIndex, 1);
                    }
                });
                
                newList[index].notes = newNotes;
            });

            newList.forEach((item, index) => {
                if (item.column_id === destination.droppableId) {
                    newList[index].notes.splice(destination.index, 0, itemToMove[0]);

                    noteToMove = {
                        ...itemToMove[0],
                        column_id: item.column_id,
                    };
                }
            });

            setList(newList);
          } else {
            const newList = [...list];
            
            newList.forEach((item, index) => {
                if (item.column_id === source.droppableId) {
                    newList[index].notes = reorder(item.notes, source.index, destination.index);
                }
            });

            setList(newList);
        }

        if (noteToMove) handleMoveNote({...noteToMove});
        
        resetServerContext();
    };

    const handleAddNewNote = () => {
        if (formNote.title !== '') {
            handleAddNote({
                ...formNote,
            });
        } else {
            enqueueSnackbar('Please enter a title', { variant: 'error' });
        }
    }

    return (
        <>
            <div
                id="notesContainer"
                className="moduleContainer"
                style={{maxWidth: 'calc(100vw - 220px)',}}
                onClick={(e) => {
                    const target: any = e.target;
                    if (openAddNote && (!formNote.title || formNote.title === '') && !target.classList.contains('dont_blur_note_title')) {
                        setOpenAddNote(false);
                        setFormNote({
                            ...defaultNote,
                        });
                    }
                }}
            >
                <div className="moduleWrapper">
                    <div className="moduleHeader buttons_top" style={{maxWidth: 'calc(100vw - 240px)',}}>
                        {formValues ? 
                        <h2>{formValues.board_name}</h2>
                        :
                        <h2>Board</h2>
                        }

                        <div className="moduleButtons">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                onClick={() => {
                                    setFormColumn({...defaultColumn});
                                    setOpenAddColumn(true);
                                }}
                            >
                                Add Column
                            </Button>

                            {/* <Button
                                variant="contained"
                                color="primary"
                                startIcon={<EditOutlined />}
                                onClick={() => {
                                    setOpenEdit(true);
                                }}
                            >
                                Edit
                            </Button> */}
                        </div>   
                    </div>

                    <div className="moduleContent">
                        <div className="box100 boardContainer">
                            {isWinReady &&
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable
                                    droppableId="Notes"
                                    type="droppableItem"
                                >
                                    {provided => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className='board'
                                    >
                                        {list.map((column: any, index: number) => {
                                            if (column.notes === undefined) loadNotes(column, index);

                                            return (
                                                <Draggable
                                                    draggableId={`column-${index}`}
                                                    key={`column-${index}`}
                                                    index={index}
                                                >
                                                    {(parentProvider) => {

                                                        const dragHandleProps = {
                                                            ...parentProvider.dragHandleProps,
                                                        };

                                                        dragHandleProps['aria-describedby'] = '';

                                                        const draggableProps = {
                                                            // ...parentProvider.draggableProps,
                                                        }

                                                        return (
                                                            <div
                                                                ref={parentProvider.innerRef}
                                                                {...draggableProps}
                                                                {...dragHandleProps}
                                                            >
                                                                <Droppable droppableId={column.column_id.toString()}>
                                                                    {(provided) => (
                                                                    <div ref={provided.innerRef}>
                                                                        <Box
                                                                            title={
                                                                                <>
                                                                                    {column.column_name}

                                                                                    {column.new_notes && 
                                                                                    <Chip
                                                                                        label={column.new_notes}
                                                                                        size="small"
                                                                                        color="error"
                                                                                        className="small-text"
                                                                                        sx={{
                                                                                            backgroundColor: '#E56E6E',
                                                                                        }}
                                                                                    />
                                                                                    }
                                                                                </>
                                                                            }
                                                                            {...parentProvider.dragHandleProps}
                                                                            className='column_board'
                                                                        >
                                                                            {column.notes !== undefined && column.notes.map((note: any, index: number) => {
                                                                                return(
                                                                                    <Draggable
                                                                                        draggableId={note.note_id.toString()}
                                                                                        key={note.note_id}
                                                                                        index={index}
                                                                                    >
                                                                                        {(provided, snapshot) => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                className={snapshot.isDragging ? 'note_container note_dragging' : 'note_container'}
                                                                                            >
                                                                                                <Card
                                                                                                    className='note_box'
                                                                                                    onClick={() => {
                                                                                                        setFormNote({
                                                                                                            ...note,
                                                                                                            column_id: column.column_id
                                                                                                        });
                                                                                                        setOpenEditNote(true);
                                                                                                    }}
                                                                                                >
                                                                                                    <div className='input_group padding-off gap-off'>
                                                                                                        {note.cover_image &&
                                                                                                        <div className='input_row note_thumbnail'>
                                                                                                            <img src={note.cover_image} />
                                                                                                        </div>
                                                                                                        }

                                                                                                        <div className='input_row note_title'>
                                                                                                            <span className="input_label">{note.title}</span>
                                                                                                        </div>

                                                                                                        <div className='input_row displayer_row padding-10 note_info'>
                                                                                                            <span className="input_label">{note.user.name}</span>
                                                                                                            <span className="input_label">{note.created_at}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Card>
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                )
                                                                            })}

                                                                            {provided.placeholder}

                                                                            {(openAddNote && formNote.column_id === column.column_id) &&
                                                                            <div className='note_container'>
                                                                                <Card className='note_box'>
                                                                                    <div className='input_group padding-off gap-off'>
                                                                                        <TextField
                                                                                            className="input_label dont_blur_note_title"
                                                                                            fullWidth
                                                                                            size='medium'
                                                                                            label="Title"
                                                                                            variant="filled"
                                                                                            value={formNote.title}
                                                                                            autoFocus
                                                                                            inputProps={{
                                                                                                className: 'dont_blur_note_title',
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                setFormNote({
                                                                                                    ...formNote,
                                                                                                    title: e.target.value,
                                                                                                });
                                                                                            }}
                                                                                            onKeyUp={(e) => {
                                                                                                if (e.key === 'Enter') {
                                                                                                    handleAddNewNote();
                                                                                                }
                                                                                            }}
                                                                                            onBlur={(e) => {
                                                                                                if (!e.relatedTarget?.classList.contains('dont_blur_note_title')) {
                                                                                                    setOpenAddNote(false);
                                                                                                    setFormNote({
                                                                                                        ...defaultNote,
                                                                                                    });
                                                                                                }
                                                                                            }}
                                                                                        />

                                                                                        <Button
                                                                                            className='dont_blur_note_title'
                                                                                            variant='contained'
                                                                                            color='primary'
                                                                                            fullWidth
                                                                                            onClick={handleAddNewNote}
                                                                                            sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                                                                                        >
                                                                                            Add
                                                                                        </Button>
                                                                                    </div>
                                                                                </Card>
                                                                            </div>
                                                                            }

                                                                            <Button
                                                                                variant='outlined'
                                                                                color='secondary'
                                                                                className='dont_blur_note_title'
                                                                                fullWidth
                                                                                onClick={() => {
                                                                                    if (!openAddNote || column.column_id !== formNote.column_id) {
                                                                                        setFormNote({
                                                                                            ...defaultNote,
                                                                                            column_id: column.column_id
                                                                                        });

                                                                                       setOpenAddNote(true);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                New note
                                                                            </Button>
                                                                        </Box>
                                                                    </div>
                                                                    )}
                                                                </Droppable>
                                                            </div>
                                                        )
                                                    }}
                                                </Draggable>
                                            )
                                        })}

                                        {provided.placeholder}
                                    </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                open={openEdit}
                onClose={() => {
                    setOpenEdit(false);
                }}
                fullWidth
                maxWidth="sm"
            >
                <NotesForm
                    formValues={formValues}
                    setLoading={props.setLoading}
                    setOpen={setOpenEdit}
                    onSubmit={loadData}
                />
            </Dialog>

            <Dialog
                open={openAddColumn}
                onClose={() => {
                    setOpenAddColumn(false);
                }}
            >
                <Box
                    title="Add Column"
                    className='forced_box_editor'
                >
                    <div className='input_group'>
                        <div className='input_row'>
                            <TextField
                                label="Column name"
                                variant="outlined"
                                fullWidth
                                value={formColumn.column_name}
                                onChange={(e) => {
                                    setFormColumn({
                                        ...formColumn,
                                        column_name: e.target.value
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <div className='buttons'>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setOpenAddColumn(false);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Save />}
                            onClick={() => {
                                handleAddColumn();
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </Box>
            </Dialog>

            <Dialog
                open={openEditNote}
                onClose={() => {
                    setOpenEditNote(false);
                }}
                fullWidth
                maxWidth="md"
            >
                <NoteDetailedForm
                    formValues={formNote}
                    setLoading={props.setLoading}
                    setOpen={setOpenEditNote}
                    open={openEditNote}
                    onUpdated={loadData}
                    onSubmit={handleAddNote}
                />
            </Dialog>
        </>
    );
}