import { Button } from '@mui/material';
import ProjectsMenu from './Menus/ProjectsMenu';
import { Link, NavLink, useLocation } from 'react-router-dom';
import InventorySubMenu from './Menus/InventorySubMenu';

const exceptionCases = [
  'worker-timing',
];

export default function TopMenu() {
  const location = useLocation();
  const showProjectsMenu = exceptionCases.some((exception: string) => location.pathname.includes(exception)) ? false : /projects\/\d+/.test(location.pathname);
  const showInventoryMenu = location.pathname.startsWith('/inventory');

  let project_id = "";

  if (showProjectsMenu) {
    project_id = extractProjectIdFromURL(location.pathname);
  }

  
  return (
    <>
      {showProjectsMenu && <ProjectsMenu project_id={project_id} />}
      {showInventoryMenu && <InventorySubMenu />}
    </>
  );
}



function extractProjectIdFromURL(pathname: any) {
  const match = pathname.match(/projects\/(\d+)/);
  return match ? match[1] : null;
}


