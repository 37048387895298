import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
    Button,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@mui/material";
import {
    Chart
    as
    ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    ChartOptions,
    Chart,
    ChartData,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Cookies from "universal-cookie";

import { ProjectConfig } from "Global";
import Box from "elements/Box";
import ic_empty from "icons/ic_empty.svg";
import ic_dashboard_1 from "icons/ic_dashboard_1.svg";
import ic_dashboard_2 from "icons/ic_dashboard_2.svg";
import ic_dashboard_3 from "icons/ic_dashboard_3.svg";
import { Circle, OpenInNew } from "@mui/icons-material";

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';



ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    ChartDataLabels,
);

const cookies = new Cookies();

const icon_folder = require.context('icons', true);

const chartDataBackgroundColor = [
    '#E2961E',
    '#E56E6E',
    '#65A1B7',
];

const defaultValuesPayment = {
    total_pending: 0,
    pending_payroll: 0,
    inventory: 0,
};

const defaultClientCharData: ChartData = {
    labels: [],
    datasets: [
        {
            label: 'Top Clients',
            data: [],
            backgroundColor: chartDataBackgroundColor,
            hoverOffset: 4,
        }
    ],
};

const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1.2,
    plugins: {
        legend: {
            display: false,
        },
        datalabels: {
            font: {
                weight: 'bold',
                size: 13,
            },
            color: '#fff',
        },
    },
};

const fakeAPI = {
    quick_access: [
        {
            url: '/projects',
            title: 'Projects',
            icon: <AccountTreeIcon />,
        },
        {
            url: '/inventory',
            title: 'Inventory',
            icon: <Inventory2Icon />,
        },
        {
            url: '/clients',
            title: 'Clients',
            icon: <PeopleIcon />,
        },
        {
            url: '/task',
            title: 'Tasks',
            icon: <AssignmentIcon />,
        },
        {
            url: '/payroll',
            title: 'Payroll',
            icon: <AttachMoneyIcon />,
        },
    ],
    payment_data: {
        total_pending: 123456,
        pending_payroll: 123456,
        inventory: 123456,
    },
    top_products: [
        {
            product_id: 1,
            product_name: 'Product 1',
            total_quantity: 16,
        },
        {
            product_id: 2,
            product_name: 'Product 2',
            total_quantity: 16,
        },
        {
            product_id: 3,
            product_name: 'Product 3',
            total_quantity: 16,
        },
        {
            product_id: 4,
            product_name: 'Product 4',
            total_quantity: 16,
        },
        {
            product_id: 5,
            product_name: 'Product 5',
            total_quantity: 16,
        },
        {
            product_id: 6,
            product_name: 'Product 6',
            total_quantity: 16,
        },
        {
            product_id: 7,
            product_name: 'Product 7',
            total_quantity: 16,
        },
        {
            product_id: 8,
            product_name: 'Product 8',
            total_quantity: 16,
        },
        {
            product_id: 9,
            product_name: 'Product 9',
            total_quantity: 16,
        },
        {
            product_id: 10,
            product_name: 'Product 10',
            total_quantity: 16,
        },
    ],
    top_clients: [
        {
            client_id: 1,
            client_name: "Client 1",
            percentage: 50,
            value: 1234,
        },
        {
            client_id: 2,
            client_name: "Client 2",
            percentage: 25,
            value: 1234,
        },
        {
            client_id: 3,
            client_name: "Client 3",
            percentage: 25,
            value: 1234,
        },
    ],
    top_projects: [
        {
            project_id: 1,
            project_name: "Project 1",
            client_id: 1,
            project_start_date: "2023-07-28",
            project_deadline: "2023-07-28",
            project_status: 2,
        },
        {
            project_id: 2,
            project_name: "Project 2",
            client_id: 1,
            project_start_date: "2023-07-28",
            project_deadline: "2023-07-28",
            project_status: 2,
        },
        {
            project_id: 3,
            project_name: "Project 3",
            client_id: 1,
            project_start_date: "2023-07-28",
            project_deadline: "2023-07-28",
            project_status: 2,
        },
        {
            project_id: 4,
            project_name: "Project 4",
            client_id: 1,
            project_start_date: "2023-07-28",
            project_deadline: "2023-07-28",
            project_status: 2,
        },
        {
            project_id: 5,
            project_name: "Project 5",
            client_id: 1,
            project_start_date: "2023-07-28",
            project_deadline: "2023-07-28",
            project_status: 2,
        },
        {
            project_id: 6,
            project_name: "Project 6",
            client_id: 1,
            project_start_date: "2023-07-28",
            project_deadline: "2023-07-28",
            project_status: 2,
        },
        {
            project_id: 7,
            project_name: "Project 7",
            client_id: 1,
            project_start_date: "2023-07-28",
            project_deadline: "2023-07-28",
            project_status: 2,
        },
        {
            project_id: 8,
            project_name: "Project 8",
            client_id: 1,
            project_start_date: "2023-07-28",
            project_deadline: "2023-07-28",
            project_status: 2,
        },
        {
            project_id: 9,
            project_name: "Project 9",
            client_id: 1,
            project_start_date: "2023-07-28",
            project_deadline: "2023-07-28",
            project_status: 2,
        },
        {
            project_id: 10,
            project_name: "Project 10",
            client_id: 1,
            project_start_date: "2023-07-28",
            project_deadline: "2023-07-28",
            project_status: 2,
        },
    ],
    total_projects: 10,
};

export default function Dashboard (props: any) {
    /**
     * INIT VALUES
     */
    const {
    } = props;

    /* ------------>>> LOADING <<<------------ */
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {props.setLoading(loading);}, [loading]);

    const navigate = useNavigate();

    /* ------------>>> STATES <<<------------ */
    const [quick_access, setQuickAccess] = useState<any[]>([]);
    const [paymentData, setPaymentData] = useState<any>({...defaultValuesPayment});
    const [top_products, setTopProducts] = useState<any[]>([]);
    const [top_clients, setTopClients] = useState<any[]>([]);
    const [client_char_data, setClientCharData] = useState<any>({...defaultClientCharData});
    const [top_projects, setTopProjects] = useState<any[]>([]);
    const [projects_count, setProjectsCount] = useState<number>(0);

    useEffect(() => {
        loadData();
    }, []);

    /**
     * LOAD DATA
     */
    const loadData = () => {
        setLoading(true);
        
        axios.get(ProjectConfig.api_url, {
            headers: {
                'LTKN': cookies.get('ltk'),
            },
            params: {
                request: 'get_main_dashboard',
            }
        })
        .then((response) => {
            console.log(response);

            setPaymentData({
                total_pending: response.data.total_pending_payments,
                pending_payroll: response.data.pending_payroll,
                inventory: response.data.inventory_value,
            });
            setTopProducts(response.data.top_products);
            setTopClients(response.data.top_clients);
            setTopProjects(response.data.last_projects);
            setProjectsCount(response.data.total_projects);
            setQuickAccess([...fakeAPI.quick_access]); return; // TODO: REMOVE THIS LINE
            setQuickAccess(response.data.quick_access);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    /* ------------>>> CHARTS <<<------------ */
    useEffect(() => {
        buildClientCharData();
    }, [top_clients]);

    const buildClientCharData = () => {
        const labels: string[] = [];
        const data: number[] = [];

        top_clients.map((item: any) => {
            labels.push(item.client_name);
            data.push(item.percentage);
        });

        setClientCharData({
            labels,
            datasets: defaultClientCharData.datasets.map((item: any) => {
                return {
                    ...item,
                    data,
                };
            })
        });
    };

    /* ------------>>> DYNAMIC IMAGES <<<------------ */
    const loadImage = (icon: string) => {
        try {
            return icon_folder(`./${icon}.svg`);
        } catch (error) {
            return ic_empty;
        }
    };
    
    return (
        <div id='mainDashboardContainer'>
            <div className='dashboardWrapper'>
                <div className='dashboard_flex_container'>
                    <div className='dashboard_left_column graph_column gap-off'>
                        <Box
                            id="dashboard_quick_access_box"
                            title="QUICK ACCESS MENU"
                            subtitle="MOST USED"
                            className="transparent_box without-subtitle-bottom"
                        >
                            <div id="dashboard_quick_access_container" className="graph_row">
                                {quick_access.map((item, index) => (
                                    <div
                                        className='button-icon-column gap-5 cursor_pointer'
                                        key={index}
                                        onClick={() => {
                                            navigate(item.url);
                                        }}
                                    >
                                        <Box className='graph_top_boxes fc-primary'>
                                            {item.icon}
                                        </Box>
                                        
                                        <span>{item.title}</span>
                                    </div>
                                ))}
                            </div>   
                        </Box>

                        <Divider />

                        <Box
                            id="dashboard_main_indicators_box"
                            title="DASHBOARD"
                            subtitle="MAIN INDICATORS"
                            className="transparent_box without-subtitle-bottom"
                        >
                            <div id="dashboard_payment_container" className="graph_row">
                                <Box className='graph_top_boxes'>
                                    <div className="flex_row gap-10">
                                        <img src={ic_dashboard_1} alt="Total pending payment icon" />
                                        
                                        <div className="payment_data">
                                            <div className="graph_top_text">TOTAL PENDING PAYMENT</div>
                                            <div className="graph_top_number">${paymentData.total_pending.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </Box>
                                <Box className='graph_top_boxes'>
                                    <div className="flex_row gap-10">
                                        <img src={ic_dashboard_2} alt="Pending payroll icon" />
                                        
                                        <div className="payment_data">
                                            <div className="graph_top_text fc-primary">PENDING PAYROLL</div>
                                            <div className="graph_top_number fc-primary">${paymentData.pending_payroll.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </Box>
                                <Box className='graph_top_boxes'>
                                    <div className="flex_row gap-10">
                                        <img src={ic_dashboard_3} alt="Inventory value icon" />
                                        
                                        <div className="payment_data">
                                            <div className="graph_top_text">INVENTORY VALUE</div>
                                            <div className="graph_top_number">${paymentData.inventory.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </Box>
                            </div>

                            <div id="dashboard_top_indicators_box" className="graph_row">
                                <Box
                                    title="TOP PRODUCTS"
                                    subtitle="MOST USED IN PRODUCTS"
                                >
                                    <Table
                                        size="small"
                                        sx={{
                                            "& .MuiTableCell-root": {
                                                borderBottom: "none",
                                            }
                                        }}
                                    >
                                        <TableBody>
                                            {top_products.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{index}</TableCell>
                                                    <TableCell>{item.product_name}</TableCell>
                                                    <TableCell align="right">{item.total_quantity}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>

                                <Box
                                    title="TOP CLIENTS"
                                    subtitle="IN PAYMENTS"
                                >
                                    <div className="graph_column gap-10">
                                        <Pie data={client_char_data} options={options} className="padding-10"/>

                                        <div className="graph_legends_container">
                                            {top_clients.map((item, index) => (
                                                <div className="graph_legends_item" key={index}>
                                                    <Circle sx={{color: chartDataBackgroundColor[index]}}/>

                                                    <div className="graph_legends_data">
                                                        <span className="graph_legends_title">{item.client_name}</span>
                                                        <span>${item.value.toLocaleString()}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Box>

                                <Box
                                    id="dashboard_top_projects_indicators_box"
                                    title="LAST PROJECTS"
                                >
                                    <Table
                                        size="small"
                                        sx={{
                                            "& .MuiTableCell-root": {
                                                borderBottom: "none",
                                            }
                                        }}
                                    >
                                        <TableBody>
                                            {top_projects.map((item, index) => (
                                                <TableRow className="table_row" key={index}>
                                                    <TableCell className="table_data">{item.project_name}</TableCell>
                                                    <TableCell
                                                        align="right"
                                                        sx={{pr: 0}}
                                                    >
                                                        <IconButton
                                                            className="fc-primary"
                                                            size="small"
                                                            sx={{p: 0}}
                                                            onClick={() => {
                                                                navigate(`/projects/${item.project_id}`);
                                                                // window.open(`/projects/${item.project_id}`, '_blank')
                                                            }}
                                                        >
                                                            <OpenInNew />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                    <Box id="dashboard_projects_count_box" className="padding-10">
                                        <div className="flex_row gap-5">
                                            <span className="graph_top_number">{projects_count}</span>
                                            <span className="graph_top_text">Projects</span>
                                        </div>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                navigate('/projects');
                                            }}
                                        >
                                            GO TO PROJECTS
                                        </Button>
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
}