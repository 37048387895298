import React, { Fragment, useEffect, useState } from 'react';
import { Autocomplete, Dialog, DialogContent, IconButton, TextField } from '@mui/material';
import { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete';
import axios from 'axios';

import { ProjectConfig } from 'Global';
import ic_dots from 'icons/ic_dots.svg';

import 'assets/css/main.css';
import ProductList from 'components/Products/ProductList';
import ProductEditor from 'components/Products/ProductEditor';

interface IProductList {
    inputValue?: string,
    product?: {
        product_id?: number,
        sale_price?: number,
        stone_fields?: any,
        product_name: string,
        disabled?: boolean,
        caso_code?: string,
    },
};

const defaultValues = {
    product_name: "",
    product_id: null,
    sale_price: 0,
    stone_fields: [],
    caso_code: "",
    serviceIndex: '',
    category: '',
    itemIndex: '',
    product_type: "",
}

export default function AutocompleteProducts( props: any ) {

    const {
        renderOptions = {type: 'only_name'},
        filterOptions = {
            type: 'none',
            callback: undefined,
        },
        handleChange = (value: any) => {},
        ...autocompliteProps
    } = props;

    delete autocompliteProps.setLoading;

    const [list, setList] = useState<any[]>([]);

    const [openProductList, setOpenProductList] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => { props.setLoading(loading) } , [loading]);

    const filter = createFilterOptions<IProductList>();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'product_list',
                params: {
                    category: props.category_filter,
                    full_list: true,
                    hide_groups: props.hide_groups,
                }
            }
        })
        .then((response) => {
            console.log(response.data);
            setList(response.data.products);
        })
        .catch((err) => {
            console.log(err.data);
        })
    }

    return (
        <>
            <Autocomplete
                {...autocompliteProps}
                id="autocomplete-products"
                options={list}
                getOptionLabel={(option: any) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                    return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                    return option.inputValue;
                    }
                    // Regular option
                    return option.product_name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option: any) => {
                    let render = <></>;
                    if (renderOptions.type === 'only_name') {
                        render = <span className='autocomplete_product_name'>{option.product_name}</span>;
                    } else if (renderOptions.type === 'name_code') {
                        render = (
                            <div className='liAutocomplete_product'>
                                <span className='autocomplete_product_name'>{option.product_name}</span>
                                <span className='autocomplete_product_code'>{option.caso_code}</span>
                            </div>
                        );
                    }
                    return (
                        <li {...props} key={option.product_id}>
                            {render}
                        </li>
                    );
                }}
                filterOptions={
                    filterOptions.type === 'custom' ?
                    filterOptions.callback :
                    
                    (filterOptions.type === 'none' ?
                        undefined :
                        (options, params) => {
                            const filtered = filter(options, params);
                    
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.product.product_name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    product: {
                                        product_name: `Add "${inputValue}"`,
                                        sale_price: 0,
                                        stone_fields: [],
                                    },
                                });
                            }
                            
                            return filtered;
                        }
                    )
                }
                freeSolo
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        handleChange({
                            product_id: null,
                            product_name: newValue,
                        });
                    } else if (newValue && newValue.inputValue && filterOptions.type !== 'none') {
                        // Create a new value from the user input
                        // addProduct(newValue.inputValue);
                    } else {
                        handleChange({
                            product_id: newValue.product_id,
                            product_name: newValue.product_name,
                        });
                    }
                }}
                renderInput={(params) => (
                    <TextField 
                        {...params}
                        fullWidth
                        label="Product" 
                        size='small'
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <Fragment>
                                    <IconButton
                                        className="autocomplete_ic_dots"
                                        onClick={()=>{
                                            setOpenProductList(true);
                                        }}              
                                    >
                                        <img src={ic_dots}/>
                                    </IconButton>

                                    {renderOptions.type === 'name_code' &&
                                        <span className='chip_code'>{props.caso_code}</span>
                                    }
                                </Fragment>
                            ),
                            style: {
                                fontSize: '12px',
                            }
                        }}
                    />
                )}
            />

            <Dialog
                open={openProductList}
                onClose={() => setOpenProductList(false)}
                maxWidth={false}
                >
                    <DialogContent>
                        <ProductList 
                            setLoading={setLoading}
                            popupItemParams = {defaultValues}
                            setProduct = {(name: any, id: any) => handleChange({product_name: name, product_id: id})}
                            setOpenPopup = {setOpenProductList}
                            addOption={false}
                            withoutCategory = {true}
                        />
                    </DialogContent>
            </Dialog>
        </>
    );
}