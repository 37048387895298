import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import AlertDialog from 'elements/AlertDialog';
import { useSnackbar } from 'notistack';


export default function WorkforceList (props: any) {
  
  const {
    setWorkforce,
    popupItemParams,
    setOpenPopup,
    workforceAsObj = false,
    withPopupAddButton = false,
    setOpenWorkforceEditor = () => {},
  } = props;

  useEffect(() => {props.setLoading(false);}, []);

  const [loading, setLoading] = useState(false); 
  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'workforce_list',
          params: {
            page: page,
          }
        }
      })
        .then((response) => {
          console.log(response.data);
          setList(response.data.workforce_list)
          setTotalPages(response.data.total_pages)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/

  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (workforce_id:number) => {
    setOpenAlert(true);
    setActiveId(workforce_id);
  }

  const deleteItem = () => {

    axios.post(ProjectConfig.api_url, null, 
      {
        params: {
          request: 'delete_workforce',
          workforce_id: activeId,
        }
      })
  
      .then((response) => {
        enqueueSnackbar('Workforce deleted', {variant: 'error'});
        setRefreshList(refreshList + 1)
        
      })
      .catch((err) => {
        console.log(err.data);
      })
  }

  /*=========================================================*/

// call global snackbar



const [openAlert, setOpenAlert] = useState(false);
const [activeId, setActiveId] = useState<any>(null);
  
// props.setWorkforce(
//   newValue.inputValue,
//   newValue.workforce_id,
//   newValue.hour_cost,
//   props.serviceIndex,
//   props.itemIndex
// );



  
  return (
    <div id='workforceList' className='moduleContent'>
      {!popupItemParams && 
      <div className='buttons_top'>
        <Button component={Link} to="add" variant="contained">
          New Workforce
        </Button>
      </div>
       }
        <div id="workforce_list_container" className="flex_container">
            <Box id='workforceListBox' title="Workforces" subtitle="List">
            {!loading && <div id='loader'><CircularProgress /></div>}
            {(popupItemParams && withPopupAddButton) &&
            <Button variant="contained" onClick={() => {setOpenWorkforceEditor(true)}}>
              New Workforce
            </Button>
            }
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Hour cost</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {list.map((item,i) => {
                    
                    //workforce_name: any, workforce_id: any, hour_cost: any, serviceIndex: any, itemIndex: any)
                    const popupSelectButton = popupItemParams ? (
                      <Button
                        variant="text"
                        color="primary"
                        size='small'
                        onClick={() => {
                          if (workforceAsObj) {
                            setWorkforce({
                              workforce_name: item.workforce_name,
                              workforce_id: item.workforce_id,
                              hour_cost: item.hour_cost,
                            });
                          } else {
                            setWorkforce(
                              item.workforce_name,
                              item.workforce_id,
                              item.hour_cost,
                              popupItemParams.serviceIndex,
                              popupItemParams.itemIndex
                            );
                          }

                          setOpenPopup(false);
                    
                    
                        }}
                      >
                        {item.workforce_name}
                      </Button>
                    ) : null;

                    return(
                    <TableRow 
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell >

                        {!popupItemParams? (
                        <Link to={`/workforce/edit/${item.workforce_id}`}>
                           {item.workforce_name}
                        </Link>
                        ) : (
                          popupSelectButton
                        )}
                      </TableCell>

                     
                      <TableCell>
                        $ {item.hour_cost.toLocaleString()}
                      </TableCell>

                      <TableCell> 
                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(item.workforce_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  )})}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
 
        </div>

        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          subtitle="Are you sure you want to delete this workforce?"
          send={deleteItem}
        />
    </div>
  );
};


