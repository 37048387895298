import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Box from './Box';
import ic_dots from "icons/ic_dots.svg";



export default function ACPointsList(props: any) {


    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const handleOpenPointsList = () => {
        setOpen(true);
      };


/* =================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface IPointsList {
        inputValue?: string;
        point_id?: any;
        description: any;
        points: any;
      }
      const filter = createFilterOptions<IPointsList>();
    
      //setList
      const [list, setList] = useState<any[]>([]);
      const [point, setPoint] = useState<IPointsList | null>(null);
      const [valueSelected , setValueSelected] = useState("");
    
      useEffect(() => {
        axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "get_points_list",
              params: {
                fullList: true,
              }
            },
          })
          .then((response) => {
            setList(response.data.list);
            setLoading(true);
          })
          .catch((err) => {
            console.log(err.data);
          });
      }, []);
      /* ================================================= 
        ===================================================*/

  let item_description = "";

  if (list && (props.value) && (props.value !== "null"))
  {
    
    item_description = list.find(obj => {
      return obj.point_id === props.value; 
    }).description;
  }


  return (
    <>
        <Autocomplete
          value={item_description}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              setPoint({
                description: newValue,
                points: 0,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              props.onChange(
                {
                  value: newValue.inputValue,
                  description: newValue.inputValue,
                  points: 0,
                  point_id: "-1",
                }
              )

              //PUSH to list
              const newList = [...list];

              //ADD new value to list IF not exist UPDATE if exist with point id = 0
              const index = newList.findIndex((obj) => obj.point_id === "-1");
              if (index === -1) {
                newList.push({
                  point_id: '-1',
                  description: newValue.inputValue,
                  points: 0,
                });
              }
              else {
                newList[index].description = newValue.inputValue;
              }
              setList(newList);
              

            } else {
              setPoint(newValue);
              if (newValue?.point_id != null) {
                props.onChange(
                    {
                      value: newValue.point_id.toString(),
                      point_id: newValue.point_id,
                      description: newValue.description,
                      points: newValue.points,

                    }
                )
              } else {
                console.log("newValue", newValue) 
                props.onChange("");
              }
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.client_description
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                description: `Add "${inputValue}"`,
                points: 0,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          fullWidth
          id="category"
          options={list}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.description;
          }}
          renderOption={(props, option) => (
            <li {...props}>{option.description}</li>
          )}
          sx={{  }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label="Description"
              sx={{minWidth: 350}}
              fullWidth
            />
          )}
        />
    </>
  );
}
