import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, Chip, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import ProjectHeader from 'components/Projects/utils/ProjectHeader';
import AlertDialog from 'elements/AlertDialog';


export default function TaskList (props: any) {

  const {
    setLoading,
    setMenuTopLeftContent,
    with_set_top_menu = false,
  } = props;

  
  useEffect(() => {setLoading(false);}, []);

  const [task_list, setTaskList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");


  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const [openAlert, setOpenAlert] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>(null);


  
  /*=======================================
  GET PROJECT ID FROM URL
  =======================================*/
  let { project_id } = useParams();
  /*=======================================
  =======================================*/


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    loadData();
  }, [page, refreshList])
  /*=========================================================*/

  const loadData = () => {
    setLoading(true)
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'task_list',
          params: {
            project_id: project_id? project_id : null,
            page: page,
          },
        }
      })
        .then((response) => {
          console.log(response.data); 
          setTaskList(response.data.list)
          setTotalPages(response.data.total_pages)
          setLoading(false)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
  }

  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = () => {
    axios.post(ProjectConfig.api_url, null, 
    {
      params: {
        request: 'delete_task',
        task_id: selectedTask.task_id,
      }
    })
    .then((response) => {
      console.log(response.data);
      setRefreshList(refreshList + 1)
    })
    .catch((err) => {
      console.log(err.data);
    })
  }
  /*=========================================================*/

// call global snackbar
  

  
  return (
    <>
      <div id='ProductList'>
        <ProjectHeader>
          <Button component={Link} to="add" variant="contained">
            New Task
          </Button>
        </ProjectHeader>
        

          <div id="task_list_container" className="flex_container">
            
              <Box id='task_list' title="Tasks" subtitle="List"  >
              <TableContainer>
              <Table stickyHeader aria-label="sticky table"> 
                <TableHead>
                  <TableRow>
                    <TableCell>Task / Project</TableCell>
                    <TableCell>Limit Date</TableCell>
                    <TableCell>Stage</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {task_list.map((task,i) => (
                      <TableRow 
                        
                        key={i}
                        hover
                        role="checkbox"
                      >
                        <TableCell>{task.task_name}</TableCell>

                        <TableCell>
                          {moment(task.task_deadline).format('MMMM DD, YYYY')}
                        </TableCell>
                        
                        <TableCell>
                          {task.stage_name}
                        </TableCell>

                        <TableCell>
                          <Chip
                            label={task.task_status}
                            color="primary"
                            size="small"
                            variant="outlined"
                          />
                        </TableCell>

                        <TableCell> 
                          <Button component={Link} to={`edit/${task.task_id}`} variant="contained" size="small" color="primary" style={{"marginRight": "10px"}}>
                            Edit
                          </Button>
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => {
                              setSelectedTask(task);
                              setOpenAlert(true);
                            }}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                      </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
            </Box>
  
          </div>
      </div>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="Are you sure you want to delete this task?"
        send={handleDelete}
      />
    </>
  );
};


