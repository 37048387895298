import { Button, Dialog, ToggleButton, ToggleButtonGroup } from '@mui/material';
import axios from 'axios';
import { AddTask, Event, FormatAlignLeft } from '@mui/icons-material';
import { Calendar, Gantt, Scheduler } from 'devextreme-react';
import 'devextreme/dist/css/dx.light.css';
import { ProjectConfig } from 'Global';
import { useEffect, useState } from 'react';
import TaskEditor from "components/Tasks/TaskEditor";
import { ViewType } from 'devextreme/ui/scheduler';

import 'assets/css/main.css';
import Filters from 'elements/Filters/Filters';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import CalendarView from './CalendarView';
import GanttView from './GanttView';

export default function ScheduleView (props: any) {
  
  const {
    setLoading,
    project_id = null,
  } = props;

  useEffect(() => {setLoading(false);}, []);
  
  /*================================================
    FILTERS
    =================================================*/
    const filters = [
      // FILTER OF PROJECT TYPE IS ADDED IN NEXT PART OF CODE
      // FILTER OF CLIENT TYPE IS ADDED IN NEXT PART OF CODE
      {type: "workers", label: "Workers", name: "workers",},
      {type: "dateRange", label: { start: "Start date", end: "End date",}, name: { start: "start_date", end: "end_date", composite: "Date range",},},
      {type: "select", label: "Stage", name: "task_stage_id", options: [{label: 'Ninguna', value: '0'}, {label: 'Design', value: '1'}, {label: 'Manufacturing', value: '2'}, {label: 'Hardware Assembly', value: '3'}, {label: 'Finishing', value: '4'}, {label: 'Installation', value: '5'}, {label: 'Stone', value: '6'},]},
    ]
    
  const [applyFilters, setApplyFilters] = useState({});
  const [filtersValues, setFiltersValues] = useState<any>({});
  const [openFilters, setOpenFilters] = useState(false);

  const setFilters = (tempFilters: any) => {
      setFiltersValues(tempFilters);
  }
  /*================================================= 
  ==================================================*/

  if ( !project_id ) {
    filters.splice(0, 0, {type: "client", label: "Clients", name: "client_id",});
    filters.splice(0, 0, {type: "project", label: "Project", name: "project_id",});
  }
  
  const [currentView, setCurrentView] = useState('calendar');
  const [data, setData] = useState<any>([]);
  const [selectedTaskId, setSelectedTaskId] = useState<any>(null);
  const [openTaskEditor, setOpenTaskEditor] = useState(false);

  const [rangeDate, setRangeDate] = useState({start: new Date(), end:new Date((new Date().setMonth(new Date().getMonth() + 1))), months_quantity: 1});

  useEffect (() => {
      loadData();
  }, []);
  
  const loadData = () => {
    setLoading(true);

    const filterParams = project_id ?
      {
        ...filtersValues,
        project_id: project_id
      } : {
        ...filtersValues,
      };
    
    axios.get(ProjectConfig.api_url,{
      params: {
        request: 'schedule_list',
        params: {
          schedule_view: currentView,
          filters: filterParams,
        }
      }
    })
    .then((response) => {
        setData([...response.data.tasks]);
        setRangeDate({
          start: new Date(response.data.schedule_start_date),
          end: new Date(response.data.schedule_end_date),
          months_quantity: response.data.months_quantity,
        });
    })
    .catch((err) => {
        console.log(err.data);
    })
    .finally(() => {
        setLoading(false);
    });
  }

  useEffect(() => {
      console.log('filtersValues', filtersValues);
      loadData();
  }, [filtersValues, currentView]);
    

  return (

    <div className='moduleContainer'>
      <div className='moduleWrapper'>
        <div className="box_header buttons_top">

            <h1>Schedule</h1>

            <div className="buttons_right" style={{display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', }}>
              <Filters
                open={openFilters}
                setOpen={setOpenFilters}
                filters={filters}
                setFilters={setFilters}
                setApplyFilters={setApplyFilters}
              />

              <ToggleButtonGroup
                size="small"
                aria-label="Schedule view"
                value={currentView}
                exclusive
                onChange={(e, value) => {
                  if(value) setCurrentView(value);
                }}
              >
                <ToggleButton value="gantt" key="gantt">
                  <div className='toogleOption'>
                    <FormatAlignLeft />
                    Gantt
                  </div>
                </ToggleButton>

                <ToggleButton value="calendar" key="calendar">
                  <div className='toogleOption'>
                    <Event />
                    Calendar
                  </div>
                </ToggleButton>,
              </ToggleButtonGroup>

              <Button
                variant="contained"
                onClick={() => {
                  setSelectedTaskId(null);
                  setOpenTaskEditor(true);
                }}
              >
                Add Task
              </Button>
              </div>

        </div>
        
        <div style={{margin: '10px 0'}}>
          <FiltersApplied
            applyFilters={applyFilters}
            filtersValues={filtersValues}
            setApplyFilters={setApplyFilters}
            setFiltersValues={setFiltersValues}
          />
        </div>

        {currentView === 'calendar' && 
          <CalendarView
            setLoading={setLoading}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={setSelectedTaskId}
            data={data}
            project_id={project_id}
          />
        }

        {currentView === 'gantt' &&
          <GanttView
              setLoading={setLoading}
              setOpenTaskEditor={setOpenTaskEditor}
              setSelectedTaskId={setSelectedTaskId}
              data={data}
              rangeDate={rangeDate}
          />
        }

      </div>

      <Dialog open={openTaskEditor} onClose={() => setOpenTaskEditor(false)}>
          <TaskEditor
              task_id={selectedTaskId}
              onSubmitCallback={() => {
                  setOpenTaskEditor(false)
                  loadData();
              }}
              project_id={project_id}
              setLoading={setLoading}
              asPopup={true}
          />
      </Dialog>
    </div>
  );
}