import React, { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';

import FormForgotPass from './FormForgotPass';
import FormLogin from './FormLogin';
import logo from '../../assets/img/logo.png';
import FormChangePass from './FormChangePass';
import { useSearchParams } from 'react-router-dom';
import FormCredentialsSent from './FormCredentialsSent';

export default withSnackbar(
  function SignIn(props: any) {
  
  const {
    loading,
    setLoading
  } = props;
  
  const [state, setState] = useState('login');
  const [userEmail,setUserEmail] = useState('');
  const [email,setEmail] = useState('');
  const [code,setCode] = useState('');

  const [searchParams,setSearchParams] = useSearchParams();

  useEffect (() => {
    setLoading(false);
    setEmail(searchParams.get('email') || '');
    setCode(searchParams.get('code') || '');
  }, []);

  useEffect (() => {
    if (email && code) {
      setState('changePass');
    }
  }, [code]);

  return (
    <>
      <div className='loginContainer'>
        { state === 'login' && (
        <FormLogin
          loading={loading}
          logo={logo}
          setLoading={setLoading}
          setState={setState}
          email={userEmail}
          setEmail={setUserEmail}
        />
        )}
        { state === 'forgotPass' && (
          <FormForgotPass
            loading={loading}
            logo={logo}
            setLoading={setLoading}
            setState={setState}
            email={userEmail}
            setEmail={setUserEmail}
          />
        )}
        { state === 'credentialsSent' && (
          <FormCredentialsSent
            logo={logo}
            setState={setState}
            setEmail={setUserEmail}
          />
        )}
        { state === 'changePass' && (
          <FormChangePass
            loading={loading}
            logo={logo}
            setLoading={setLoading}
            setState={setState}
            email={email}
            code={code}
          />
        )}
      </div>
    </>
  );
}
);