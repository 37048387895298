import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.css';
import MainMenu from './Menu'
import logo from './assets/img/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider, useSnackbar } from 'notistack';
import AppRoutes from 'AppRoutes';
import {BrowserRouter as Router , Route, Routes, useLocation} from 'react-router-dom'
import Login from 'components/Login/Login';
import Cookies from 'universal-cookie';
import AccountMenu from 'components/Account/AccountMenu';
import CircularProgress from '@mui/material/CircularProgress';
import PopupViewer from 'elements/PopupViewer';
import TopMenu from 'elements/TopMenu';


const cookies = new Cookies();

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 300,
    fontWeightMedium: 300,
    fontWeightBold: 700,
  },
  palette: { 
    primary: {
      light: '#e2951e',
      main: '#e2951e',
      dark: '#ff9c01',
      contrastText: '#fff',
    },
    secondary: {
      light: '#303030',
      main: '#303030',
      dark: '#000000',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      contrastText: '#fff',
    },
    success: {
      main: '#469b49',
      contrastText: '#fff',
    },
    background: {
      default: '#fafafa',
    },
  },
  

});




const Site = () => {
  const childRef = useRef();
  const [subMenu, setSubmenu] = useState("asd"); 
  const userIsLogged = cookies.get('ltkn') !== undefined;
  const [loading, setLoading] = useState(true);

  const [MenuTopLeftContent, setMenuTopLeftContent] = useState<any>({});

    useEffect(() => {
        setSubmenu("")
    }, []);

    const location = useLocation();
    useEffect(() => {
      setMenuTopLeftContent({
        child: null,
      });
    }, [location.pathname, location.search, location.state]);

    useEffect(() => {
      if (cookies.get('ltkn') !== undefined && (!cookies.get('update_version') || cookies.get('update_version') === undefined || cookies.get('update_version') === null)) {
        cookies.set('ltkn', undefined, { path: '/', maxAge: (86400 * 365), });
        cookies.set('ltkn', undefined, { path: '/', maxAge: (86400 * 365), domain: window.location.hostname });
        
        cookies.remove('ltkn', { path: '/', domain: window.location.hostname});
        cookies.remove('ltkn', { path: '/'});
      }
    }, [cookies.get('ltkn')]);

    const handleLogout = () => {
      localStorage.clear();
      // cookies.remove('user_id', { path: '/', domain: window.location.hostname});
      // cookies.remove('user_name', { path: '/', domain: window.location.hostname});
      // cookies.remove('role_id', { path: '/', domain: window.location.hostname});
      // cookies.remove('rol', { path: '/', domain: window.location.hostname});
      // cookies.remove('nombres_usuario', { path: '/', domain: window.location.hostname});
      
      console.log(window.location.hostname)
      cookies.remove('ltkn', { path: '/', domain: window.location.hostname});
      window.location.href = '/';
    }

    return (
    <ThemeProvider theme={theme} >
      <SnackbarProvider maxSnack={3}>
        <div id='loader' className={!loading? 'loaderHide' : ''}>
          <CircularProgress />
        </div>

        {//  userIsLogged ? REDIRECT TO LOGIN
        !userIsLogged? (
          <Login setLoading={setLoading} />
        ) : (
          <>
          {window.location.pathname === '/viewer' ?
          
          <PopupViewer setLoading={setLoading} /> :
          
          <div id='structure'>
            <aside id="menu">
              <div id='logo'>
                <a href='/'>
                  <img src={logo}></img>
                </a>
                
              </div>
              <MainMenu />
            </aside>
            <div id="main-container"> 
              <div id='top_bar'>
                  <div id='top_bar_left'>
                    <TopMenu />
                  </div>
                  <div id='top_bar_right'>
                    <AccountMenu
                      handleLogout={handleLogout}
                    />
                  </div>
              </div>
              <div id="main-content">
                  <AppRoutes
                    setLoading={setLoading}
                    setMenuTopLeftContent={setMenuTopLeftContent}
                    handleLogout={handleLogout}/>
              </div>
            </div>
          </div>
          }
          </>
        )}
      </SnackbarProvider>
    </ThemeProvider>
    );
  
}

// ========================================
 

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Router>
      <Site />
    </Router>
);







