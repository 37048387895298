import axios from "axios";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ProjectConfig } from "Global";
import { useSnackbar, withSnackbar } from "notistack";
import {
    Badge,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import FilesBrowser from "components/Files/FilesBrowser";
import ProjectHeader from "../../utils/ProjectHeader";
import ExplodedView from "./ExplodedView";
import QuoteView from "./QuoteView";
import AppleSwitch from "elements/AppleSwitch";
import SideDrawer from "elements/SideDrawer";
import QuoteStatus from "./QuoteStatus";
import moment from "moment";
import QuotePreview from "./QuotePreview";
import ProductList from "components/Products/ProductList";
import ProductEditor from "components/Products/ProductEditor";
import WorkforceList from "components/Workforce/WorkforceList";
import WorkforceEditor from "components/Workforce/WorkforceEditor";
import ISwitch from "elements/ISwitch";
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';

const categories: any = 
{
    millwork:[
        {
            category_var: "panel",
            category_name: "Panels and adhesives",
            category_filter: "'panel','wood'" 
        },
        {
            category_var: "paint",
            category_name: "Paint",
            category_filter: "'paint'" 
        },
        {
            category_var: "hardware",
            category_name: "Hardware",
            category_filter: "'hardware'" 
        },
        {
            category_var: "workforce",
            category_name: "Workforce",
            category_filter: "'workforce'" 
        }
    ],
    stone:[
        {
            category_var: "stone",
            category_name: "Countertop / Backsplash",
            category_filter: "'stone'"

        }
    ],
    remodeling:[
        {
            category_var: "panel",
            category_name: "Panels and adhesives",
            category_filter: "'panel','wood'" 
        },
        {
            category_var: "paint",
            category_name: "Paint",
            category_filter: "'paint'" 
        },
        {
            category_var: "hardware",
            category_name: "Hardware",
            category_filter: "'hardware'" 
        },
        {
            category_var: "workforce",
            category_name: "Workforce",
            category_filter: "'workforce'" 
        }
    ]
}

interface IDicctionary {
    [key: string]: {
        serviceIndex: number;
        category: string;
        itemIndex: number;
    }[];
}

export default function QuotesEditorV1 (props: any) {

    const {setLoading} = props;

    useEffect(() => {setLoading(false);}, []);
    
    const [services, setServices] = useState<any>([]);
    const [sectionList, setSectionList] = useState<any>([]);
    const [millworkProfitPercentage, setMillworkProfitPercentage] = useState(50);
    const [remodelingProfitPercentage, setRemodelingProfitPercentage] = useState(50);
    const [haveTax, setHaveTax] = useState(true);


    


    const [showQuoteView, setShowQuoteView] = useState(true);
    const [showExplodedView, setShowExplodedView] = useState(false);
    const [showQuotePreview, setShowQuotePreview] = useState(false);

    const [projectInfo, setProjectInfo] = useState<any>({});

    const showView = (view: string) => {
        switch (view) {
            case "quote":
                setShowQuoteView(true);
                setShowExplodedView(false);
                setShowQuotePreview(false);
                break;
            case "exploded":
                setShowQuoteView(false);
                setShowExplodedView(true);
                setShowQuotePreview(false);
                break;
            case "preview":
                setShowQuoteView(false);
                setShowExplodedView(false);
                setShowQuotePreview(true);
                break;
        }
    };
    
    
    const navigate = useNavigate();

    const [explodedItemAnchor, setExplodedItemAnchor] = useState<any>(null);
    const [quoteItemAnchor, setQuoteItemAnchor] = useState<any>(null);
    /*====================================================
    DRAWER
    ==================================================== */
    const [drawerOpen, setDrawerOpen] = useState(false);

    
    const ListItemIconStyles = {
        minWidth: 0,
        mr: drawerOpen ? 3 : "auto",
        justifyContent: "center",
    };

    const ListItemButtonStyles = {
    minHeight: 48,
    justifyContent: drawerOpen ? "initial" : "center",
    px: 2.5,
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
      };
    
      const handleDrawerClose = () => {
        setDrawerOpen(false);
      };
    /*====================================================
    END DRAWER
    ==================================================== */


    



    const setProduct = (
        product_name: any, 
        productId: any, 
        sale_price: any, 
        stone_fields: any, 
        caso_code: any,
        serviceIndex: any, 
        category: any, 
        itemIndex: any,
        product_type: any
        ) => {

       console.log("setProduct", product_name, productId, sale_price, stone_fields, caso_code, serviceIndex, category, itemIndex);
        const newServices = [...services];
        newServices[serviceIndex][category].products[itemIndex].product_id = productId;
        newServices[serviceIndex][category].products[itemIndex].product_name = product_name;
        newServices[serviceIndex][category].products[itemIndex].quoteText = "Manufacture";
        newServices[serviceIndex][category].products[itemIndex].caso_code = caso_code;
        newServices[serviceIndex][category].products[itemIndex].product_type = product_type;

        
        if(category === "stone"){
            if(newServices[serviceIndex][category].products[itemIndex].have_workforce){
                newServices[serviceIndex][category].products[itemIndex].sale_price = stone_fields.price_with_manufacturing;
            }
            else{
                newServices[serviceIndex][category].products[itemIndex].sale_price = stone_fields.price_without_manufacturing;
            }

            //PRICE WITH MANUFACTURING
            newServices[serviceIndex][category].products[itemIndex].price_with_manufacturing = stone_fields.price_with_manufacturing;

            //PRICE WITHOUT MANUFACTURING
            newServices[serviceIndex][category].products[itemIndex].price_without_manufacturing = stone_fields.price_without_manufacturing;
        }
        else{
            newServices[serviceIndex][category].products[itemIndex].sale_price = sale_price;
        }
        

        

        
        setServices(newServices);
        setCategoryTotal(category,serviceIndex);

        //get section
        const section = newServices[serviceIndex][category].products[itemIndex].section;

        updateQuoteText(serviceIndex, category, itemIndex);
    }

    const [sectionsDict, setSectionsDict] = useState<IDicctionary>({});


    const setSection = (section: any, serviceIndex: any, category: any, itemIndex: any) => {

        const oldSection = services[serviceIndex][category].products[itemIndex].section;
        
        // Remove item from previous section
        if (oldSection !== '') {
            removeItemFromSections(oldSection, serviceIndex, category, itemIndex);
        };
        // Add section to product
        const newServices = [...services];
        newServices[serviceIndex][category].products[itemIndex].section = section;

        // Index section in sectionDict
        addSection(section, serviceIndex, category, itemIndex);

        setServices(newServices);

        //get product_name
        const product_name = newServices[serviceIndex][category].products[itemIndex].product_name;

        updateQuoteText(serviceIndex, category, itemIndex);
    }

    const fixNumberValue = (
        value: any,
        canDecimal = true,
        canNegative = true,
        canZero = true,
    ): Number | string => {

        //if value start with 0 and after has a number, remove 0
        if (value[0] === "0" && value[1] !== ".") {
            value = value.slice(1);
        } else if (canDecimal && value[0] === ".") {
            value = "0" + value;
        }

        if (!canNegative) {
            if (value[0] === "-") {
            value = value.slice(1);
            }
        }

        let formatRegex = /^\d*$/;
        if (canDecimal) {
            formatRegex = /^\d*\.?\d*$/;
        }

        if (!formatRegex.test(value)) {
            return 0;
        }

        //if value is empty set 0
        if (value === "") {
            if (canZero) {
                value = 0;
            } else {
                value = 1;
            }
        }

        if (Number(value).toString() === "NaN" || Number(value).toString() === "Infinity") {
            return canZero ? 0 : 1;
        }

        if (Number(value).toString() === value.toString()) {
            return Number(value);
        }

        return value;
    };


    const updateQuoteText = (serviceIndex: any, category: any, itemIndex: any) => {
        const newServices = [...services];
        const product_name = newServices[serviceIndex][category].products[itemIndex].product_name;
        const section = newServices[serviceIndex][category].products[itemIndex].section;
        const quantity = newServices[serviceIndex][category].products[itemIndex].quantity;
        
        if(category=="panel")
        {
            const quoteText = "Manufacture " + section +" in "+product_name;
            newServices[serviceIndex][category].products[itemIndex].quoteText = quoteText;
            setServices(newServices);
        }
        else if(category=="paint"){
            const quoteText = "Spray " + section +" in "+product_name;
            newServices[serviceIndex][category].products[itemIndex].quoteText = quoteText;
            setServices(newServices);
        }
        else if(category=="hardware"){
            const quoteText = section +": " + quantity + ' ' + product_name;
            newServices[serviceIndex][category].products[itemIndex].quoteText = quoteText;
            setServices(newServices);
        }
        else if(category=="stone"){
            
            const sq_ft = newServices[serviceIndex][category].products[itemIndex].sq_ft;
            const edge = newServices[serviceIndex][category].products[itemIndex].edge;
            const finish = newServices[serviceIndex][category].products[itemIndex].finish;
            const sink = newServices[serviceIndex][category].products[itemIndex].sink;
            const quoteText = "To manufacture, finish and install " + section +" with the following specifications: \n" +
            "Stone: " + product_name + "\n" +
            "Area: " + sq_ft + "\n" +
            "Edge: " + edge + "\n" +
            "Finish: " + finish + "\n" +
            "Sink cut out: " + sink;
            newServices[serviceIndex][category].products[itemIndex].quoteText = quoteText;
            setServices(newServices);
        }


       
        
    }


    const setWorkforce = (workforce_name: any, workforce_id: any, hour_cost: any, serviceIndex: any, itemIndex: any) => {
        console.log("setWorkforce", workforce_name, workforce_id, hour_cost, serviceIndex, itemIndex);
        const newServices = [...services];
        newServices[serviceIndex].workforce.tasks[itemIndex].workforce_id = workforce_id;
        newServices[serviceIndex].workforce.tasks[itemIndex].workforce_name = workforce_name;
        newServices[serviceIndex].workforce.tasks[itemIndex].hour_cost = hour_cost;
        setServices(newServices);
        setCategoryTotal("workforce",serviceIndex);
    }

    

    

    //UPDATE TOTAL PRICE OF CATEGORY
    const setCategoryTotal = (category: any, serviceIndex: any, deleting = false) => {
        const newServices = [...services];
        let total = 0;
        let workforce_cost = 0;
        let material_total = 0;
        
        if(!deleting){
            if(category != "workforce"){
                newServices[serviceIndex][category].products.forEach((item: any) => {

                    total += item.sale_price * item.quantity;

                    if(category == "stone"){
                        if(item.have_workforce){
                            total += item.workforce_cost;
                            workforce_cost += item.workforce_cost;
                        }
                        material_total += item.sale_price * item.quantity;
                    }
                });
                
            }
            else{
                newServices[serviceIndex][category].tasks.forEach((item: any) => {
                    total += item.hour_cost * item.hours;
                });
            }
        }

      

        
        newServices[serviceIndex][category].total = total;
        if(category == "stone"){
            newServices[serviceIndex].stone.material_total = material_total;
            newServices[serviceIndex].stone.workforce_total = workforce_cost;
        }

        newServices[serviceIndex].workforce_cost = workforce_cost;
        newServices[serviceIndex].material_cost = (category == "stone")? material_total : total;
        
        
        
        setServices(newServices);
        setServiceTotal(serviceIndex);
    }

    //UPDATE SERVICE PRICE
    const setServiceTotal = (serviceIndex: any) => {
        const newServices = [...services];
        let total = 0;
        let material_cost = 0;
        let workforce_cost = 0;

        if(newServices[serviceIndex].type != "stone"){
                
            total += newServices[serviceIndex].panel.total;
            total += newServices[serviceIndex].paint.total;
            total += newServices[serviceIndex].hardware.total;
            total += newServices[serviceIndex].workforce.total;

            material_cost += newServices[serviceIndex].panel.total;
            material_cost += newServices[serviceIndex].paint.total;
            material_cost += newServices[serviceIndex].hardware.total;

            workforce_cost += newServices[serviceIndex].workforce.total;
        }
        else{
            total += newServices[serviceIndex].stone.total;
            material_cost += newServices[serviceIndex].stone.material_total;
            workforce_cost += newServices[serviceIndex].stone.workforce_total;
        }
        

        newServices[serviceIndex].service_cost = total;
        newServices[serviceIndex].material_cost = material_cost;
        newServices[serviceIndex].workforce_cost = workforce_cost;

        

        setServices(newServices);
        setQuoteTotal();
    }
    
    useEffect(() => {
        setQuoteTotal();
    }, [millworkProfitPercentage, remodelingProfitPercentage, haveTax]);




    //UPDATE QUOTE TOTALS
    const setQuoteTotal = () => {

        //MILLWORK
        let millwork_material_cost = 0;
        let millwork_workforce_cost = 0;
        let millwork_company_cost = 0;
        
        let millwork_profit = 0;
        let millwork_total = 0;



        //REMODELING
        let remodeling_material_cost = 0;
        let remodeling_workforce_cost = 0;
        let remodeling_company_cost = 0;
        let remodeling_profit = 0;
        let remodeling_total = 0;
        
        //STONE
        let stone_material_cost = 0;
        let stone_workforce_cost = 0;
        let stone_company_cost = 0;
        let stone_total = 0;
        


        let total = 0;
        let tax = 0;

        services.forEach((service: any) => {

            if(service.type=="millwork"){
                millwork_material_cost += service.material_cost;
                millwork_workforce_cost += service.workforce_cost;            
            }
            else if(service.type=="stone"){
                stone_material_cost += service.material_cost;
                stone_workforce_cost += service.workforce_cost;
            }
            else{
                remodeling_material_cost += service.material_cost;
                remodeling_workforce_cost += service.workforce_cost;
            }
        });

        
        
        millwork_company_cost = millwork_material_cost + millwork_workforce_cost;
        millwork_profit = millwork_company_cost * (millworkProfitPercentage/100);
        millwork_total = millwork_company_cost + millwork_profit;

        stone_company_cost = stone_material_cost + stone_workforce_cost;
        stone_total = stone_company_cost;

        remodeling_company_cost = remodeling_material_cost + remodeling_workforce_cost;
        remodeling_profit = remodeling_company_cost *  (remodelingProfitPercentage/100);
        remodeling_total = remodeling_company_cost + remodeling_profit;

        const subtotal = millwork_total + remodeling_total + stone_total;
        
        tax = (haveTax)? subtotal * 0.10: 0;
        

        total = subtotal + tax;


        
        const newQuote = {...formValues};
        newQuote.millwork.material_cost = millwork_material_cost;
        newQuote.millwork.workforce_cost = millwork_workforce_cost;
        newQuote.millwork.company_cost = millwork_company_cost;
        newQuote.millwork.profit_percentage = millworkProfitPercentage;
        newQuote.millwork.profit = millwork_profit;
        newQuote.millwork.total = millwork_total;

        newQuote.remodeling.material_cost = remodeling_material_cost;
        newQuote.remodeling.workforce_cost = remodeling_workforce_cost;
        newQuote.remodeling.company_cost = remodeling_company_cost;
        newQuote.remodeling.profit_percentage = remodelingProfitPercentage;
        newQuote.remodeling.profit = remodeling_profit;
        newQuote.remodeling.total = remodeling_total;

        newQuote.stone.material_cost = stone_material_cost;
        newQuote.stone.workforce_cost = stone_workforce_cost;
        newQuote.stone.company_cost = stone_company_cost;
        newQuote.stone.total = stone_total;
        
        newQuote.subtotal = subtotal;
        newQuote.tax = tax;
        newQuote.have_tax = haveTax;
        newQuote.total = total;

        setFormValues(newQuote);

    }


    


    
    /*=======================================
    PUSH NEW SERVICE TO SERVICES
    =======================================*/
    const handleAddService = () => {

        const newServices = [...services];
        if(serviceCategory == "millwork"){
            newServices.push({
                name: serviceName,
                type: serviceCategory,
                service_cost: 0,
                workforce_cost: 0,
                material_cost: 0,
                images: [],
                service_folder_id: 0,
                serviceOpen: true,
                panel: {
                    total: 0,
                    products: [],
                },
                paint: {
                    total: 0,
                    products: [],
                },
                hardware: {
                    total: 0,
                    products: [],
                },
                workforce: {
                    total: 0,
                    tasks: [],
                },
            });
        }
        else if(serviceCategory == "stone"){
            newServices.push({
                name: serviceName,
                type: serviceCategory,
                service_cost: 0,
                workforce_cost: 0,
                material_cost: 0,
                images: [],
                service_folder_id: 0,
                stone: {
                    total: 0,
                    material_total: 0,
                    workforce_total: 0,
                    products: [],
                },
            });
        }
        else if(serviceCategory == "remodeling"){
            newServices.push({
                name: serviceName,
                type: serviceCategory,
                service_cost: 0,
                workforce_cost: 0,
                material_cost: 0,
                images: [],
                service_folder_id: 0,
                panel: {
                    total: 0,
                    products: [],
                },
                paint: {
                    total: 0,
                    products: [],
                },
                hardware: {
                    total: 0,
                    products: [],
                },
                workforce: {
                    total: 0,
                    tasks: [],
                },
            });
        }

        
        
        setServices(newServices);
        setServiceName("");
        setServiceCategory("");
    }; 
    /*=======================================
    =======================================*/


    



    



    

    



    


    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    /*=======================================
        GET PROJECT ID AND ESTIMATE ID FROM URL
    =======================================*/
    let { project_id, quote_id } = useParams();
    /*=======================================
    =======================================*/
    const [quoteID, setQuoteID] = useState("");

    /*=======================================
        SET PROJECT SUBMENU
    =======================================*/
    useEffect(() => {
        
    }, []);
    /*=======================================
    =======================================*/




    /*=======================================
        DEFAULT VALUES FOR FORMVALUES
    =======================================*/
    const formValuesDefault = {
        project_id: project_id,
        quote_id: quote_id ? quote_id : "",
        services: [],
        millwork: {
            material_cost: 0,
            workforce_cost: 0,
            company_cost: 0,
            profit_percentage: 50,
            profit: 0,
            total: 0,
        },
        stone: {
            material_cost: 0,
            workforce_cost: 0,
            company_cost: 0,
            total: 0,
        },
        remodeling: {
            material_cost: 0,
            workforce_cost: 0,
            company_cost: 0,
            profit_percentage: 0,
            profit: 0,
            total: 0,
        },
        subtotal: 0,
        have_tax: true,
        tax: 0,
        total: 0,
        history: [],
        current_status:{
            status_name: "Unfinished quote",
            status_id: 1,
            date: moment().format("YYYY-MM-DD"),
            time: moment().format("HH:mm:ss"),
        },
        quotePDFinformation:"- Quotes is valid for 15 days \n- Changes must be submitted in writing or emailed \n- Changes of any description may delay the completion date of the project\n- Changes may add to the total cost of the project\n- A deposit of 50% is required \n - Milestone and balance payments to be paid according to contract \nThank you for the opportunity, we look forward to working with you. \nCaso Kitchens",
    };

    useEffect(() => {
        //IF SERVICES CHANGE, ADD TO FORMVALUES
        setFormValues({
            ...formValues,
            services: services,
        });
    }, [services]);

    /*=======================================
    =======================================*/

    const [formValues, setFormValues] = useState<any>(formValuesDefault);

    const [wasSubmited, setWasSubmited] = useState(false);
    const [wasModified, setWasModified] = useState(false);



    useEffect(() => {
        if(!wasSubmited){
            setWasModified(true);
        }
        else{
            setWasSubmited(false);
        }
        
    }, [services,sectionList]);
    
    /*=======================================
    =======================================*/

    /*=======================================
    CHECK FORM VALUES
    =======================================*/
    const handleCheckForm = () => {
        console.log(formValues);
        let errors = false;
        return errors; // REMOVE THIS LINE FOR VALIDATE FORM

        // VALIDATE FORM
        if(formValues.services.length == 0){
            enqueueSnackbar("Please add at least one service", { variant: "error" });
            errors = true;
        }

        //VALIDATE SERVICES
        for (let service of formValues.services) {
            if(service.type == "millwork"){
                if(service.panel.products.length == 0){
                    enqueueSnackbar("Please add at least one panel product", { variant: "error" });
                    errors = true;
                    break;
                } else {
                    for (let product of service.panel.products) {
                        if(product.product_name == "" || product.product_name == null || product.product_name == undefined){
                            enqueueSnackbar("Please select a panel product", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if(product.quantity == "" || product.quantity == null || product.quantity == undefined){
                            enqueueSnackbar("Please add a quantity", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if (product.quantity <= 0) {
                            enqueueSnackbar("Please add a quantity greater than 0", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if (product.section == "" || product.section == null || product.section == undefined) {
                            enqueueSnackbar("Please select a section", { variant: "error" });
                            errors = true;
                            break;
                        }
                    }
                    if(errors){
                        break;
                    }
                }
                if(service.paint.products.length == 0){
                    enqueueSnackbar("Please add at least one paint product", { variant: "error" });
                    errors = true;
                    break;
                } else {
                    for (let product of service.paint.products) {
                        if(product.product_name == "" || product.product_name == null || product.product_name == undefined){
                            enqueueSnackbar("Please select a paint product", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if(product.quantity == "" || product.quantity == null || product.quantity == undefined){
                            enqueueSnackbar("Please add a quantity", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if (product.quantity <= 0) {
                            enqueueSnackbar("Please add a quantity greater than 0", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if (product.section == "" || product.section == null || product.section == undefined) {
                            enqueueSnackbar("Please select a section", { variant: "error" });
                            errors = true;
                            break;
                        }
                    }
                    if(errors){
                        break;
                    }
                }
                if(service.hardware.products.length == 0){
                    enqueueSnackbar("Please add at least one hardware product", { variant: "error" });
                    errors = true;
                    break;
                } else {
                    for (let product of service.hardware.products) {
                        if(product.product_name == "" || product.product_name == null || product.product_name == undefined){
                            enqueueSnackbar("Please select a hardware product", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if(product.quantity == "" || product.quantity == null || product.quantity == undefined){
                            enqueueSnackbar("Please add a quantity", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if (product.quantity <= 0) {
                            enqueueSnackbar("Please add a quantity greater than 0", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if (product.section == "" || product.section == null || product.section == undefined) {
                            enqueueSnackbar("Please select a section", { variant: "error" });
                            errors = true;
                            break;
                        }
                    }
                    if(errors){
                        break;
                    }
                }
                if(service.workforce.tasks.length == 0){
                    enqueueSnackbar("Please add at least one workforce task", { variant: "error" });
                    errors = true;
                    break;
                } else {
                    for (let task of service.workforce.tasks) {
                        if(task.workforce_name == "" || task.workforce_name == null || task.workforce_name == undefined){
                            enqueueSnackbar("Please select a workforce task", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if(task.hours == "" || task.hours == null || task.hours == undefined){
                            enqueueSnackbar("Please add a quantity", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if (task.hours <= 0) {
                            enqueueSnackbar("Please add a quantity greater than 0", { variant: "error" });
                            errors = true;
                            break;
                        }
                    }
                    if(errors){
                        break;
                    }
                }
            } else if(service.type == "stone"){
                if(service.stone.products.length == 0){
                    enqueueSnackbar("Please add at least one stone product", { variant: "error" });
                    errors = true;
                    break;
                } else {
                    for (let product of service.stone.products) {
                        if(product.product_name == "" || product.product_name == null || product.product_name == undefined){
                            enqueueSnackbar("Please select a stone product", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if(product.quantity == "" || product.quantity == null || product.quantity == undefined){
                            enqueueSnackbar("Please add a quantity", { variant: "error" });
                            errors = true;
                            break;
                        }
                        if (product.quantity <= 0) {
                            enqueueSnackbar("Please add a quantity greater than 0", { variant: "error" });
                            errors = true;
                            break;
                        }
                    }
                    if(errors){
                        break;
                    }
                }
            } else if(service.type == "remodeling"){
                // if(service.material.products.length == 0){
                //     enqueueSnackbar("Please add at least one material product", { variant: "error" });
                //     errors = true;
                //     break;
                // } else {
                //     for (let product of service.material.products) {
                //         if(product.product_name == "" || product.product_name == null || product.product_name == undefined){
                //             enqueueSnackbar("Please select a material product", { variant: "error" });
                //             errors = true;
                //             break;
                //         }
                //     }
                //     if(errors){
                //         break;
                //     }
                // }
                // if(service.workforce.tasks.length == 0){
                //     enqueueSnackbar("Please add at least one workforce task", { variant: "error" });
                //     errors = true;
                //     break;
                // } else {
                //     for (let task of service.workforce.tasks) {
                //         if(task.workforce_name == "" || task.workforce_name == null || task.workforce_name == undefined){
                //             enqueueSnackbar("Please select a workforce task", { variant: "error" });
                //             errors = true;
                //             break;
                //         }
                //     }
                //     if(errors){
                //         break;
                //     }
                // }
            }
        }

        return errors;
    };
    /*=======================================
    =======================================*/


    /*=================================================
    SUMBIT FORM
    =================================================*/
    
    const handleSubmit = () => {
        console.log("submit", formValues);
        setWasSubmited(true);

        //formdata 
        var formdata = new FormData();
        formdata.append("params", JSON.stringify({
            quote_id: quote_id,
            formdata: formValues,
            sectionList: JSON.stringify(sectionList),
        }));

        axios
        .post(ProjectConfig.api_url, formdata, {
            params: {
                request: "add_edit_project_quote",
            },
        })
        .then((response) => {
            console.log("submit", response.data);
            if(quote_id){
                enqueueSnackbar("Quote saved", { variant: "success" });
            }
            else{
                setQuoteID(response.data.quote_id);
                enqueueSnackbar("Quote created", { variant: "success" });
                //remove navigate history
                navigate(`/projects/${project_id}/quotes/edit/${response.data.quote_id}`, { replace: true });
                
            }
            setFormValues(response.data.quote_data);
            setServices(response.data.quote_data.services);

            setWasModified(false);
        })
        .catch((err) => {
            enqueueSnackbar("Error saving quote "+err.code, { variant: "error" });
        });
    };






    
    /*=================================================
    ===================================================*/


    /*=================================================
    AUTO SAVE QUOTE
    =================================================*/
    useEffect(() => {
        if(wasModified && quoteID != "" && autosave){
            const timer = setTimeout(() => {
                handleSubmit();
            }, 20000);
            return () => clearTimeout(timer);
        }

    });



    
    const updateProductQuantity = (serviceIndex: any, category: any, itemIndex: any) => 
    {
        
        const newServices = [...services];
        let sheetsQuantity = 0;
        newServices[serviceIndex][category].products[itemIndex].measures.forEach((measure: any) => {
            let sheetstemp = parseFloat(measure.sheets) || 0;
            sheetsQuantity += sheetstemp;
        });

        //ROUND sheetsQuantity to the next integer
        sheetsQuantity = Math.ceil(sheetsQuantity);

        newServices[serviceIndex][category].products[itemIndex].quantity = sheetsQuantity;
        setServices(newServices);
        setCategoryTotal(category,serviceIndex);
    }



    /*=================================================
    GET QUOTE DETAILS (IF QUOTE ID EXISTS)
    =================================================*/
    useEffect(() => {
        
        if (quote_id) {
        axios
            
            .get(ProjectConfig.api_url, {
            params: {
                request: "quote_details",
                quote_id: quote_id,
            },
            })
            .then((response) => {
                console.log(response.data);
                setSectionList(response.data.section_list);
                setFormValues(response.data.quote_data);
                setServices(response.data.quote_data.services);
                setProjectInfo(response.data.project_info);
                setQuoteID(response.data.quote_id);
                setHaveTax(response.data.quote_data.have_tax);
                setMillworkProfitPercentage(response.data.quote_data.millwork.profit_percentage);
                setRemodelingProfitPercentage(response.data.quote_data.remodeling.profit_percentage);

                loadSectionDict(response.data.quote_data.services);
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }, []);
    /*=================================================
    ===================================================*/


    /*====================================================
    DIALOG SERVICE CREATOR
    ==================================================== */
    const [openDialogServiceCreator , setOpenDialogServiceCreator] = useState(false);

    const [serviceName, setServiceName] = useState("");
    const [serviceCategory, setServiceCategory] = useState("");

    const handleChangeCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceCategory((event.target as HTMLInputElement).value);
      };

    /*====================================================
    ==================================================== */
 


    /*====================================================
    handle History Status Change
    ==================================================== */
    const handleChangeHistory = (status: any) => {


        let newHistory = [...formValues.history];
        newHistory.push({
            status_id: status.status_id,
            status_name: status.status_name,
            date: moment().format("YYYY-MM-DD"),
            time: moment().format("HH:mm:ss"),
            user: "temp",
        });

        //update current_status
        let newCurrentStatus = {
            status_id: status.status_id,
            status_name: status.status_name,
            date: moment().format("YYYY-MM-DD"),
            time: moment().format("HH:mm:ss"),
        };
        

        setFormValues({
            ...formValues,
            history: newHistory,
            current_status: newCurrentStatus,
        });
        setWasModified(true);
    }

    const handleDeleteHistory = (index: any) => {
        let newHistory = [...formValues.history];
        newHistory.splice(index,1);
        setFormValues({
            ...formValues,
            history: newHistory,
        });
        setWasModified(true);
    }



    const startProject = () => {
        axios
        .post(ProjectConfig.api_url, null, {
            params: {
                request: "start_project",
                params: {
                    quote_id: quote_id,
                }
            },
        })
        .then((response) => {
            console.log(response.data);
            setOpenProductsSeparatedDialog(true);
            setStartProjectData(response.data);
        })
        .catch((err) => {
            console.log(err.data);
        });
    }

    const [startProjectData, setStartProjectData] = useState<any>({});

    const [openProductList, setOpenProductList] = useState(false);
    const [activeItemParams, setActiveItemParams] = useState<any>({});

    const[openProductEditor, setOpenProductEditor] = useState(false);


    const [openWorkforceList, setOpenWorkforceList] = useState(false);
    const [openWorkforceEditor, setOpenWorkforceEditor] = useState(false);

    const handlePopupEditorSubmit = (product: any) => {
        console.log("data",product);


        if(product.is_group){ //IS GROUP
            const data = product.details;
            console.log(data);

            // setProduct(
            //     data.products_group_name,
            //     data.products_group_id,
            //     data.products_group_sale_price,
            //     data.stone_fields,
            //     data.caso_code,
            //     activeItemParams.serviceIndex,
            //     activeItemParams.category,
            //     activeItemParams.itemIndex,
            //     data.product_type
            //     );
        }
        else{ //IS PRODUCT
            const data = product.details[0];

            setProduct(
                data.product_name,
                data.product_id,
                data.sale_price,
                data.stone_fields,
                data.caso_code,
                activeItemParams.serviceIndex,
                activeItemParams.category,
                activeItemParams.itemIndex,
                "product"
                );
        }

     



        setOpenProductEditor(false);
        setOpenProductList(false);
    }


    //SET TIMER TO AUTOSAVE

    


    const handleWorkforcePopupSubmit = (workforce: any) => {

        if(workforce!=null){
        
            setWorkforce(
                workforce.workforce_name,
                workforce.workforce_id,
                workforce.hour_cost,
                activeItemParams.serviceIndex,
                activeItemParams.itemIndex,
            );

            
        }

        setOpenWorkforceEditor(false);
        setOpenWorkforceList(false);
    }

    const [workforceCreatorName, setWorkforceCreatorName] = useState("");

    const [openProductsSeparatedDialog, setOpenProductsSeparatedDialog] = useState(false);




    //prevent back button from navigating away SHOW DIALOG
    useEffect(() => {
        if (wasModified) {
            //ON RELOAD OR BACK BUTTON
            window.onbeforeunload = function () {
                return true;
            }
        }
    }, []);
    

    const [autosave, setAutosave] = useState(false);


    const addSection = (section: string, serviceIndex: number, categoryIndex: any, itemIndex: number) => {
        setSectionsDict({
            ...sectionsDict,
            [section]: [
                ...sectionsDict[section] || [],
                {
                    serviceIndex: serviceIndex,
                    category: categoryIndex,
                    itemIndex: itemIndex
                }
            ]
        });
    }

    const removeItemFromSections = (section: string, serviceIndex: number, categoryIndex: any, itemIndex: number) => {
        const newSectionsDict: IDicctionary = {...sectionsDict};

        let index = 0;
        for (let item of newSectionsDict[section]) {
            if (item.serviceIndex === serviceIndex && item.category === categoryIndex && item.itemIndex === itemIndex) {
                newSectionsDict[section].splice(index, 1);
                break;
            }
            index++;
        }
        setSectionsDict({...newSectionsDict});
    }

    const loadSectionDict = (services: any) => {
        const newSectionsDict: IDicctionary = sectionsDict;
        for (let [serviceIndex, service] of services.entries()) {
            for (let category of categories[service.type]){
                const categoryIndex = category.category_var;
                for (let [itemIndex, item] of service[categoryIndex].products.entries()){
                    if (item.section !== '') {
                        newSectionsDict[item.section] = [
                            ...newSectionsDict[item.section] || [],
                            {
                                serviceIndex: serviceIndex,
                                category: categoryIndex,
                                itemIndex: itemIndex
                            }
                        ];
                    }
                }
            }
        };
        setSectionsDict({...newSectionsDict});
    };


    const setStoneWorkforceCost = (serviceIndex: any, itemIndex: any) => {
        const newServices = [...services];

        const sq_ft = newServices[serviceIndex].stone.products[itemIndex].sq_ft;

        const sink = Number(newServices[serviceIndex].stone.products[itemIndex].sink) * 500;
       

        const workforce_per_sq_ft = (newServices[serviceIndex].stone.products[itemIndex].destination=="countertop")? 75 : 45;

        newServices[serviceIndex].stone.products[itemIndex].workforce_cost = workforce_per_sq_ft*sq_ft + sink;

        setServices(newServices);
        setServiceTotal(serviceIndex);

        setCategoryTotal("stone",serviceIndex);
    }


    return (
    <Fragment>
        
        <div className="contentWithMiniDrawer">    

            <div className="contentwithMiniDrawer__content">

                <ISwitch 
                    label={autosave? "Autosave ON" : "Autosave OFF"}
                    checked={autosave}
                    onChange={()=>setAutosave(!autosave)}
                    name="autosave"
                />
                {/* <Chip
                    label={wasModified? "Modified" : "Saved"}
                    color={wasModified? "secondary" : "primary"}
                    /> */}

                <ProjectHeader>
                    <span 
                        className={"titleQuoteType" + (showQuoteView? " ActiveViewButton" : "")}
                        onClick={()=>showView("quote")}>Quote View</span>

                    <span className={"titleQuoteType" + (showExplodedView? " ActiveViewButton" : "")}
                        onClick={()=>showView("exploded")}>
                            Exploded View</span>

                    <span 
                        className={"titleQuoteType" + (showQuotePreview? " ActiveViewButton" : "")}
                        onClick={()=>showView("preview")}>PDF Preview</span>


                </ProjectHeader>
                <QuoteView 
                    showQuoteView={showQuoteView} 
                    sectionList={sectionList} 
                    setSectionList={setSectionList}
                    services={services}
                    categories={categories}
                    setProduct={setProduct}
                    setServices={setServices}
                    setWorkforce={setWorkforce}
                    updateProductQuantity={updateProductQuantity}
                    setOpenDialogServiceCreator={setOpenDialogServiceCreator}
                    setCategoryTotal={setCategoryTotal}
                    setServiceTotal={setServiceTotal}
                    setSection = {setSection}
                    updateQuoteText={updateQuoteText}
                    setOpenProductList={setOpenProductList}
                    setActiveItemParams={setActiveItemParams}
                    setOpenWorkforceList={setOpenWorkforceList}
                    setOpenWorkforceEditor={setOpenWorkforceEditor}
                    setWorkforceCreatorName={setWorkforceCreatorName}
                    showView={showView}
                    explodedItemAnchor={explodedItemAnchor}
                    setExplodedItemAnchor={setExplodedItemAnchor}
                    quoteItemAnchor={quoteItemAnchor}
                    setQuoteItemAnchor={setQuoteItemAnchor}
                    sectionsDict={sectionsDict}
                    setSectionsDict={setSectionsDict}
                    setQuoteTotal={setQuoteTotal}
                    formValues={formValues}
                    profitPercentages={{
                        millwork: millworkProfitPercentage,
                        remodeling: remodelingProfitPercentage
                    }}
                    setStoneWorkforceCost={setStoneWorkforceCost}
                    fixNumberValue={fixNumberValue}
                />
                <ExplodedView 
                    showExplodedView={showExplodedView} 
                    sectionList={sectionList} 
                    setSectionList={setSectionList}
                    services={services}
                    categories={categories}
                    setProduct={setProduct}
                    updateQuoteText={updateQuoteText}
                    setServices={setServices}
                    updateProductQuantity={updateProductQuantity}
                    setOpenDialogServiceCreator={setOpenDialogServiceCreator}
                    setOpenProductList={setOpenProductList}
                    showView={showView}
                    setActiveItemParams={setActiveItemParams}
                    explodedItemAnchor={explodedItemAnchor}
                    quoteItemAnchor={quoteItemAnchor}
                    setExplodedItemAnchor={setExplodedItemAnchor}
                    setQuoteItemAnchor={setQuoteItemAnchor}
                    setStoneWorkforceCost={setStoneWorkforceCost}
                    fixNumberValue={fixNumberValue}
                />
                <QuotePreview
                    
                    showQuotePreview={showQuotePreview}
                    quote_id={quote_id}
                    sectionList={sectionList} 
                    setSectionList={setSectionList}
                    services={services}
                    categories={categories}
                    setProduct={setProduct}
                    setServices={setServices}
                    updateProductQuantity={updateProductQuantity}
                    setOpenDialogServiceCreator={setOpenDialogServiceCreator}
                    setCategoryTotal={setCategoryTotal}
                    setServiceTotal={setServiceTotal}
                    projectInfo={projectInfo}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    handleSubmit={handleSubmit}
                    profitPercentages={{
                        millwork: millworkProfitPercentage,
                        remodeling: remodelingProfitPercentage
                    }}
                    
                />
            </div>

            




            <Dialog open={openDialogServiceCreator} onClose={()=>{setOpenDialogServiceCreator(false)}}>
                <DialogContent id="estimate_configuration" className="forced_box_editor">
                    <div className="DialogHeader">
                        <h2>ADD SERVICE</h2>
                        <span>Enter the service name and type to add this service.</span>
                    </div>
                    <TextField value={serviceName} label="Service name" fullWidth onChange={
                            (e) => {
                                setServiceName(e.target.value);
                            }}
                        />

                    <div className="radio_buttons_group">
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={serviceCategory}
                                className="type_radio_group"
                                onChange={handleChangeCategory}
                            >
                                <FormControlLabel value="millwork" control={<Radio />} label="Millwork" />
                                <FormControlLabel value="stone" control={<Radio />} label="Stone" />
                                <FormControlLabel value="remodeling" control={<Radio />} label="Remodeling" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div className="buttons">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={()=>{setOpenDialogServiceCreator(false)}}
                            >
                            Cancel
                            </Button>

                            <Button 
                            variant="contained" 
                            onClick={() => {
                                handleAddService();
                                setOpenDialogServiceCreator(false);
                            }}>
                            Add
                        </Button>
                    </div>
                </DialogContent>
            
            </Dialog>

            


            <SideDrawer
                drawerOpen={drawerOpen}
                label = {!showExplodedView? "QUOTE SUMMARY": "EXPLODED VIEW"}
                handleDrawerOpen = {handleDrawerOpen}
                handleDrawerClose = {handleDrawerClose}
            >
                <div className="drawer_in">
                    <div className="drawer_content" style={{ opacity: drawerOpen ? 1 : 0 }}>
                        <div className="box_header">
                            <span className="subtitle">
                                
                            </span>
                        </div>

                        
                        {!showExplodedView && (
                            <div id="quote_sidebar" className="sidebar_body">

                                {formValues.millwork.total>0 && (
                                    <div className="summary_section">
                                        <span id="SummaryTitleMillwork" className="quoteSummaryTitle">Millwork</span>

                                        <div className="summary_row">
                                            <div className="summary_row_left">Material</div>
                                            <div className="summary_row_right">
                                                ${formValues.millwork.material_cost.toLocaleString()} 
                                            </div>
                                        </div>
                                

                                        <div className="summary_row">
                                            <div className="summary_row_left">Workforce</div>
                                            <div className="summary_row_right">
                                                ${formValues.millwork.workforce_cost.toLocaleString()}
                                            </div>
                                        </div>

                                        <div className="summary_row summary_line_top">
                                            <div className="summary_row_left">Company cost</div>
                                            <div className="sidebar_body_row_value">
                                                ${formValues.millwork.company_cost.toLocaleString()}
                                            </div>
                                        </div>

                                        <div className="summary_row">
                                            <div className="summary_row_left">
                                                <TextField 
                                                    value={millworkProfitPercentage}
                                                    onChange={
                                                        (e: { target: { value: any; }; }) => {
                                                            setMillworkProfitPercentage(e.target.value);

                                                            
                                                        }}
                                                    name="millwork_profit_percentage"
                                                    size="small"
                                                    className="quote_profit_percentage"
                                                    
                                                    inputProps={{style: {
                                                        fontSize: '12px', 
                                                        textAlign: 'center',
                                                        width: '30px',
                                                        padding: '3px',
                                                    }
                                                    }}
                                                    
                                                    
                                                />
                                                % Profit
                                            </div>
                                            <div className="sidebar_body_row_value">
                                                ${formValues.millwork.profit.toLocaleString()}
                                            </div>
                                        </div>

                                        <div className="summary_row quote_total_row">
                                            <div className="summary_row_left">Total</div>
                                            <div className="sidebar_body_row_value">
                                                ${formValues.millwork.total.toLocaleString()}
                                            </div>
                                        </div>

                                    </div>
                                )}
                                {formValues.stone.total>0 && (
                                    <div className="summary_section">
                                        <span id="SummaryTitleStone" className="quoteSummaryTitle">Stone</span>

                                        <div className="summary_row">
                                            <div className="summary_row_left">Material</div>
                                            <div className="summary_row_right">
                                                ${formValues.stone.material_cost.toLocaleString()}
                                            </div>
                                        </div>

                                        <div className="summary_row">
                                            <div className="summary_row_left">Workforce</div>
                                            <div className="summary_row_right">
                                                ${formValues.stone.workforce_cost.toLocaleString()}
                                            </div>
                                        </div>

                                        <div className="summary_row summary_line_top">
                                            <div className="summary_row_left">Company cost</div>
                                            <div className="sidebar_body_row_value">
                                                ${formValues.stone.company_cost.toLocaleString()}
                                            </div>
                                        </div>

                                        <div className="summary_row quote_total_row">
                                            <div className="summary_row_left">Total</div>
                                            <div className="sidebar_body_row_value">
                                                ${formValues.stone.total.toLocaleString()}
                                            </div>
                                        </div>
                                        
                                    </div>
                                )}

                                {formValues.remodeling.total>0 && (
                                    <div className="summary_section">
                                        <span id="SummaryTitleRemodeling" className="quoteSummaryTitle">Remodeling</span>

                                        <div className="summary_row">
                                            <div className="summary_row_left">Material</div>
                                            <div className="summary_row_right">
                                                ${formValues.remodeling.material_cost.toLocaleString()}
                                            </div>
                                        </div>

                                        <div className="summary_row">
                                            <div className="summary_row_left">Workforce</div>
                                            <div className="summary_row_right">
                                                ${formValues.remodeling.workforce_cost.toLocaleString()}
                                            </div>
                                        </div>
                                        
                                        <div className="summary_row summary_line_top">
                                            <div className="summary_row_left">Company cost</div>
                                            <div className="sidebar_body_row_value">
                                                ${formValues.remodeling.company_cost.toLocaleString()}
                                            </div>
                                        </div>

                                        <div className="summary_row">
                                            <div className="summary_row_left">
                                                <TextField 
                                                    value={remodelingProfitPercentage}
                                                    onChange={
                                                        (e: { target: { value: any; }; }) => {
                                                            setRemodelingProfitPercentage(e.target.value);
                                                        }}
                                                    name="remodeling_profit_percentage"
                                                    size="small"
                                                    className="quote_profit_percentage"
                                                    
                                                    inputProps={{style: {
                                                        fontSize: '12px', 
                                                        textAlign: 'center',
                                                        width: '30px',
                                                        padding: '3px',
                                                    }
                                                    }}
                                                />
                                                % Profit
                                            </div>
                                            <div className="sidebar_body_row_value">
                                                ${formValues.remodeling.profit.toLocaleString()}
                                            </div>
                                        </div>

                                        <div className="summary_row quote_total_row">
                                            <div className="summary_row_left">Total</div>
                                            <div className="sidebar_body_row_value">
                                                ${formValues.remodeling.total.toLocaleString()}
                                            </div>
                                        </div>
                                        
                                    </div>
                                )}

                                <AppleSwitch 
                                    checked={haveTax}
                                    onChange={setHaveTax}
                                    label="Tax included"
                                />

                                
                                <div className="summary_section total_section">

                                    <div className="summary_row">
                                        <div className="summary_row_left">Subtotal</div>
                                        <div className="summary_row_right">
                                            ${(formValues.subtotal || 0).toLocaleString()}
                                        </div>
                                    </div>

                                    {haveTax && (
                                    <div className="summary_row">
                                        <div className="summary_row_left">Tax (10%)</div>
                                        <div className="summary_row_right">
                                            ${(formValues.tax || 0).toLocaleString()}
                                        </div>
                                    </div>
                                    )}

                                    <div className="summary_total">
                                        <div className="summary_total_label">Total Quote</div>
                                        <div className="summary_total_value">
                                            ${(formValues.total || 0).toLocaleString()}
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        )}
                        {showExplodedView && (
                            <div id="explodedViewImages">
                                {/* MAP IMAGES */}
                                {services.map((service:any, serviceIndex:any) => {
                                    return (
                                        <div key={serviceIndex}>
                                            <h2>{service.name}</h2>
                                            <div className="drawerImagesContainer">

                                                {quote_id && service.service_folder_id!=0 && (
                                                    <FilesBrowser 
                                                        folder_id={service.service_folder_id} 
                                                        view_type="thumbnail"
                                                        setLoading={setLoading}
                                                        with_drawer={false}
                                                    />
                                                )}
                                                {(!quote_id || service.service_folder_id==0) && (<div className='msg_save_quote'>
                                                    <SaveOutlinedIcon />
                                                    <p>Save the quote in order to upload files</p>
                                                </div>)}
                                                
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}

                        <QuoteStatus 
                            history={formValues.history}
                            onChange={handleChangeHistory}
                            currentStatus={formValues.current_status}
                            onDelete={handleDeleteHistory}
                            startProject={startProject}
                            quote_id={quote_id}
                            setOpenProductsSeparatedDialog={setOpenProductsSeparatedDialog}
                            setStartProjectData={setStartProjectData}
                        />

                    </div>

                            

                    <List>
                        <ListItem key="save" disablePadding sx={{ display: "block" }}>
                            <ListItemButton
                                sx={ListItemButtonStyles}
                                onClick={() => {
                                    if(!handleCheckForm()) {
                                        handleSubmit();
                                    }
                                }}
                            >
                                <ListItemIcon sx={ListItemIconStyles}>
                                <Badge color="secondary" variant="dot" invisible={wasModified ? false : true}>
                                    <SaveOutlinedIcon />
                                    </Badge>
                                </ListItemIcon>

                                <ListItemText
                                primary="SAVE"
                                sx={{ opacity: drawerOpen ? 1 : 0 }}
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </div>
            </SideDrawer>


            <Dialog
                open={openProductList}
                onClose={() => setOpenProductList(false)}
                maxWidth={false}
                >
                    <DialogContent>
                    <ProductList 
                        setLoading={setLoading}
                        popupItemParams = {activeItemParams}
                        setProduct = {setProduct}
                        setOpenPopup = {setOpenProductList}
                        setOpenProductEditor = {setOpenProductEditor}
                    />
                    </DialogContent>
            </Dialog>



            <Dialog 
                id='ProductEditorPopup'
                open = {openProductEditor}
                onClose={() => {
                    setOpenProductEditor(false);
                }}
                maxWidth={false}
                >
                    <DialogContent>
                        <ProductEditor
                            setLoading={setLoading}
                            setProduct = {setProduct}
                            popupItemParams = {activeItemParams}
                            onSubmit = {handlePopupEditorSubmit}
                            setOpenProductEditor = {setOpenProductEditor}
                        />
                    </DialogContent>
            </Dialog>



            <Dialog
                open={openWorkforceList}
                onClose={() => setOpenWorkforceList(false)}
                maxWidth={false}
                >
                    <WorkforceList 
                        setLoading={setLoading}
                        popupItemParams = {activeItemParams}
                        setWorkforce = {setWorkforce}
                        setOpenPopup = {setOpenWorkforceList}
                        
                    />
            </Dialog>

            <Dialog 
                open = {openWorkforceEditor}
                onClose={() => {
                    setOpenWorkforceEditor(false);
                }}
                maxWidth={false}
                >
                    <WorkforceEditor 
                        setLoading={setLoading}
                        setWorkforce = {setWorkforce}
                        popupItemParams = {activeItemParams}
                        onSubmit = {handleWorkforcePopupSubmit}
                        setOpenWorkforceEditor = {setOpenWorkforceEditor}
                        name = {workforceCreatorName}
                    />
            </Dialog>


            <Dialog 
                open = {openProductsSeparatedDialog}
                onClose={() => {
                    setOpenProductsSeparatedDialog(false);
                }}
                >
                    <DialogContent id="products_separated_dialog" className="forced_box_editor">

                    <h2>PROJECT STARTED</h2>
                    <DialogContentText style={{marginBottom:20}}>
                       The current quote has been started as a project. The available products were separated from inventory, and the products out of stock have been requested in a new order.
                    </DialogContentText>

                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product</TableCell>
                                    <TableCell align="right">Quantity</TableCell>
                                    <TableCell align="right">Separated</TableCell>
                                    <TableCell align="right">Ordered</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                            {startProjectData.products_separated && startProjectData.products_separated.map ((product:any, index:any) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{product.product_name}</TableCell>
                                        <TableCell align="right">{product.quantity}</TableCell>
                                        <TableCell align="right">{product.separated}</TableCell>
                                        <TableCell align="right">{product.pending}</TableCell>
                                    </TableRow>
                                )
                            })}
                            </TableBody>


                                    
                        </Table>

                        {startProjectData.order_id > 0 && (
                            <Chip 
                                icon={<FormatListNumberedRtlIcon />}
                                label={"Order # " +startProjectData.order_id + " created with pending products"} 
                                color="secondary"
                                sx={{width: "100%", marginTop: "10px", fontWeight: "300"}}
                            />
                            
                        )}


                        <div className="buttons">
                            {/* <Button
                                variant="outlined"
                                color="primary"
                                onClick={()=>{setOpenDialogServiceCreator(false)}}
                                >
                                Cancel
                                </Button> */}

                                <Button 
                                variant="contained"
                                component={Link}
                                to={"/projects/"+project_id}
                                >
                                Go to Project dashboard
                            </Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                component={Link}
                                to={"/orders/"}
                                >
                                    Go to Orders
                            </Button>

                        </div>
                        
                    </DialogContent>

                    
            </Dialog>



        </div>
    </Fragment>
    );
};

