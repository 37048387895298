import { ProjectConfig } from "Global";
import axios from "axios";
import Box from "elements/Box";
import ic_empty from 'icons/ic_empty.svg';
import { useEffect, useState } from "react";




export default function InventoryUsed (props: any) {

  const {data, setLoading, project_id} = props;
  const [outgoingInventory, setOutgoingInventory] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    axios.get(ProjectConfig.api_url,
    {
      params: {
        request: 'get_outgoing_list',
        params:{
          project_id: project_id
        }
      }
    })
      .then((response) => {
        setOutgoingInventory(response.data.list);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.data);
      })
      
  
  }, [])


  return (
   
    <Box title="OUTGOING INVENTORY" subtitle="Last retired items from inventory">
      <div className="stockPendingContent">
        {outgoingInventory.length >0 ?
          outgoingInventory.map((item: any, index: number) => (
            <div key={index}>
              {item.products.map((product: any, index: number) => (
              <div className="box_row_1" key={index}>
                <div className="box_column_1">
                  <div className="box_column_flex_column">
                    <span>{item.outgoing_date}</span>
                    <h3>{product.product_name}</h3>
                    {item.users.map((user: any, index: number) => (
                      <span key={index} className="outgoing_user">{user.name}</span>
                    ))}

                    
                  </div>
                </div>
                <div className="box_column_2">
                  <div className="box_column_flex_column flex_column_align_right">
                    <span className="box_column_label">Units</span>
                    <span className="box_column_value">{product.quantity}</span>
                  </div>
                </div>
              </div>
              ))}
            </div>
          ))
          :
          <div className="noProducts">
            <img src={ic_empty} alt=""/>
            <p>No products found</p>
          </div>
        }
      </div>

    </Box>
  );
};

