import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import AlertDialog from 'elements/AlertDialog';

export default function AutocompleteSections(props: any) {

  const {
    sectionsDict,
    setSectionsDict = () => {},
    setServices = () => {},
    onDeleteSection = () => {},
  } = props;

  
  const [openDeleteSection, setOpenDeleteSection] = useState(false);

  /* =================================================
    PRODUCT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface ISectionList {
      inputValue?: string,
      section_name?: string,
    };

  const filter = createFilterOptions<ISectionList>();

  const sectionList: any[] = props.sectionList? props.sectionList : [];
  const [isDeleting,setIsDeleting] = useState(false);
  const [deletingSection,setDeletingSection] = useState<string>('null');

  useEffect(() => {
    if(deletingSection === 'null') return;

    // const newServices = [...services];
    // for (let item of sectionsDict[deletingSection]) {
    //   if (newServices[item.serviceIndex][item.category].products[item.itemIndex]) {
    //     newServices[item.serviceIndex][item.category].products[item.itemIndex].section = '';
    //   }
    // }
    // const newSectionsDict = {...sectionsDict};
    // newSectionsDict[deletingSection] = [];

    // setServices(newServices);
    setServices((prevState: any) => {
      for (let item of sectionsDict[deletingSection]) {
        if (prevState[item.serviceIndex][item.category].products[item.itemIndex]) {
          prevState[item.serviceIndex][item.category].products[item.itemIndex].section = '';
        }
      }
      return [...prevState];
    });

    onDeleteSection(deletingSection);
    setDeletingSection('null');
    
    // setSectionsDict(newSectionsDict);
    setSectionsDict((prevState: any) => {
      return {
        ...prevState,
        [deletingSection]: [],
      };
    });
    setIsDeleting(false);
  }, [isDeleting]);

  const handleDeleteSection = () => {
    setIsDeleting(true);
    // const newSectionList = sectionList.filter((item: any) => item.section_name !== deletingSection);
    props.setSectionList((prevState: any) => {
      return prevState.filter((item: any) => item.section_name !== deletingSection);
    });
  }
  
  return (
    <>
      <Autocomplete
      value={props.section}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          props.setSection(newValue);
        } else if (newValue && newValue.inputValue) {
          props.setSection(
            newValue.inputValue,
            props.serviceIndex,
            props.category,
            props.itemIndex
          );
            
          // PUSH THE NEW SECTION TO ARRAY
          props.setSectionList([...props.sectionList, { section_name: newValue.inputValue }]);
        } else {
          props.setSection(
            (newValue === null ? '' : newValue.section_name),
            props.serviceIndex,
            props.category,
            props.itemIndex
          );
        }
          
        }}
        filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.section_name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            section_name: `Add "${inputValue}"`,
          });
        }
        
        return filtered;
      }}
      selectOnFocus
      clearOnBlur={false}
      handleHomeEndKeys
      fullWidth
      id="autocomplete_product"
      className='autocomplete_sections'
      options={sectionList}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          
          return option.inputValue;
        }
        // Regular option
        return option.section_name;
      }}
      renderOption={
        (props, option) => 
        <li {...props} className='autoCompleteRenderedOptions'>
          <span className='autocomplete_product_name'>{option.section_name}</span>
          <IconButton
            className={option.section_name.startsWith('Add')?'btn_delete_section':''}
            size="small"
            aria-label="delete"
            onClick={() => {
              setDeletingSection(option.section_name);
              setOpenDeleteSection(true);
            }}
          >
            <Delete />
          </IconButton>
        </li>
      }
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField 
        {...params} 
        label="Section" 
        InputLabelProps={{
            style: { fontSize: '12px' }
        }}
        size='small'
        InputProps={{
          ...params.InputProps,
          style: {fontSize: '12px'},
        }}/>
      )}
    />

    <AlertDialog
      open={openDeleteSection}
      setOpen={setOpenDeleteSection}
      subtitle='This action will remove the section of every item in the quote. Are you sure to continue?'
      send={handleDeleteSection}
    />
  </>
  );
}
