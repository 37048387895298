import {
  Autocomplete,
  Button,
  createFilterOptions,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Switch,
  SwitchProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from "icons/ic_dots.svg";
import {ProjectConfig} from "Global";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AutocompleteProduct from "../Projects/utils/AutoCompleteProduct";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import SupplierEditor from "components/Suppliers/SuppliersEditor";
import FilesBrowser from "components/Files/FilesBrowser";
import { AddOutlined, SaveOutlined } from "@mui/icons-material";
import AlertDialog from "elements/AlertDialog";



const fakeApiRemnants = [
  {
    remnant_id: '1',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 1,
  },
  {
    remnant_id: '2',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 1,
  },
  {
    remnant_id: '3',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 1,
  },
  {
    remnant_id: '4',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 1,
  },
  {
    remnant_id: '5',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 1,
  },
  {
    remnant_id: '6',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 1,
  },
  {
    remnant_id: '7',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 1,
  },
  {
    remnant_id: '8',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 1,
  },
  {
    remnant_id: '9',
    length: 60,
    width: 30,
    thickness: 2,
    quantity: 1,
  },
];

export default function ProductForm(props: any){

  const {
    setLoading,
    popupItemParams
  } = props;

  const formValues = props.formValues;
  const setFormValues = props.setFormValues;
  const productValues = props.productValues;
  const supplier_list = props.supplier_list;
  const index = props.index;
  const is_group = props.is_group;
  const [manualSalePrice, setManualSalePrice] = useState(false);



  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  
  //function updateProduct

  const updateProduct = (product: any) => {
    props.updateProduct(product, index);
  };
    

  /* =================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
  interface ISupplierList {
    inputValue?: string;
    supplier_id?: number;
    name: string;
  }
  const filter = createFilterOptions<ISupplierList>();
  const [supplier, setSupplier] = useState<ISupplierList | null>(null);

  /* ================================================= 
    ===================================================*/

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    updateProduct({
      //update the productValues index 
      ...productValues,
      [name]: value,
    });
  };



  const handleStoneFieldsChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    updateProduct({
      ...productValues,
      stone_fields: {
        ...productValues.stone_fields,
        [name]: value,
      },
    });
  };


  /*===================================================
  CALCULATE SALE PRICE / HANDLING PRICE INPUTS CHANGE
  ====================================================*/
  useEffect(() => {

    const unit_price = parseInt(productValues.unit_price.toString());
    let percentage_to_land = 0;
    let sale_price = 0;

    if(productValues.category === "stone"){
      const shipping: number = unit_price + 400;

			const duty = (shipping*10/100)+shipping;
			const vat = (duty*12/100)+duty;
			const landed = Math.round(vat+100);

      const price_with_manufacturing =  Math.round(landed * 35 / 100 + landed);
			const price_without_manufacturing = Math.round(landed * 60 / 100 + landed);

      const products = [...formValues.products];
      products[index] = {
        ...products[index],
        landed_cost: landed,
        percentage_to_land: percentage_to_land,
        sale_price: sale_price,
        stone_fields: {
          ...productValues.stone_fields,
          price_with_manufacturing: price_with_manufacturing,
          price_without_manufacturing: price_without_manufacturing,
        }
      };

      let group_price = 0;
      
      if(!formValues.products_group_manual_sale_price){
        
        formValues.products.map((product: any) => {
          group_price += product.unit_price * product.group_quantity;
        }
        );
      }
      else{
        group_price = formValues.products_group_sale_price;
      }

      setFormValues({ ...formValues, 
        products: products,
        products_group_sale_price: group_price
      }
      );

    }
    else{

      
      percentage_to_land = productValues.percentage_to_land;
      percentage_to_land = (percentage_to_land==0)? 25 : percentage_to_land;
      
      sale_price =(!productValues.manual_sale_price)? (unit_price * percentage_to_land/100 + unit_price)*2 : productValues.sale_price;

      const landed_cost = unit_price*(percentage_to_land/100) + unit_price;



      const products = [...formValues.products];
      products[index] = {
        ...products[index],
        landed_cost: landed_cost,
        percentage_to_land: percentage_to_land,
        sale_price: sale_price,
      };
      
      let group_price = 0;

      if(!formValues.products_group_manual_sale_price){

        formValues.products.map((product: any) => {
          group_price += product.unit_price * product.group_quantity;
        }
        );
      }
      else{
        group_price = formValues.products_group_sale_price;
      }

      setFormValues({ ...formValues, 
        products: products,
        products_group_sale_price: group_price
      }
      );
    }
    
  }, [productValues.unit_price, productValues.landed_cost, productValues.percentage_to_land, productValues.category, productValues.manual_sale_price, productValues.group_quantity, formValues.products_group_manual_sale_price, productValues.product_id]); 
   /*===================================================
  ====================================================*/


  useEffect(() => {
    const width = parseInt(productValues.stone_fields.width.toString());
    const length = parseInt(productValues.stone_fields.length.toString());

    const square_feet = Math.round((width * length / 144));
    const price_per_square_feet = Math.round(productValues.stone_fields.price_with_manufacturing / square_feet);

    updateProduct({
      ...productValues,
      stone_fields: {
        ...productValues.stone_fields,
        square_feet: square_feet,
        price_per_square_feet: price_per_square_feet,
      },
    });

  }, [productValues.stone_fields.width, productValues.stone_fields.length, productValues.stone_fields.price_with_manufacturing]);



  const handlePriceChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    updateProduct({
      ...productValues,
      [name]: value,
    });
    
    
  }

  useEffect(() => {
    setSupplier({
      name: productValues.supplier_name
    })
  }, [productValues.supplier_name]);

  //HANDLE SELECT PRODUCT CATEGORY
  const handleChange = (event: SelectChangeEvent) => {
    updateProduct({
      ...productValues,
      ["category"]: event.target.value as string,
    });
  };



  const handleOpenSupplierList = () => {
    setOpen(true);
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);




    /*=======================================
    PUSH NEW ITEM 
    =======================================*/
    const handleAddItem = () => {
        
      const newWoodDimensions = [...productValues.wood_dimensions];
      newWoodDimensions.push({
        width: 0,
        length: 0,
        thickness: 0,
        quantity: 0,
        boardFeet: 0,
      });
      
      updateProduct({
        ...productValues,
        wood_dimensions: newWoodDimensions,
      });


    }; 
    /*=======================================
    =======================================*/

    /*=======================================
    DELETE WOOD DIMENSION
    =======================================*/
    const handleDeleteWoodDimension = (index: number) => {
      const newWoodDimensions = [...productValues.wood_dimensions];
      newWoodDimensions.splice(index, 1);

      //STOCK QUANTITY = SUM OF WOOD DIMENSIONS BOARD FEET
      let product_stock = 0;
      newWoodDimensions.map((item: any) => {
        product_stock += parseInt(item.boardFeet);
      });

      
      updateProduct({
        ...productValues,
        wood_dimensions: newWoodDimensions,
        product_stock: product_stock,
      });

    }; 
    /*=======================================
    =======================================*/


    /*=======================================
    CHANGE WOOD DIMENSION
    =======================================*/
    const handleWoodDimensionsChange = (e: any, index: any) => {
      const { name, value } = e.target;
      const newWoodDimensions = [...productValues.wood_dimensions];
      newWoodDimensions[index][name] = value;

      const bf = Math.round(((newWoodDimensions[index].width * newWoodDimensions[index].length * newWoodDimensions[index].thickness) / 144) * newWoodDimensions[index].quantity * 100) / 100;
      newWoodDimensions[index].boardFeet = bf;
      
      updateProduct({
        ...productValues,
        wood_dimensions: newWoodDimensions,
      });

      //STOCK QUANTITY = SUM OF WOOD DIMENSIONS BOARD FEET
      let product_stock = 0;
      newWoodDimensions.map((item: any) => {
        product_stock += item.boardFeet;
      });
      updateProduct({
        ...productValues,
        product_stock: product_stock,
      });
    };
    /*=======================================
    =======================================*/


    const setProduct = (product_name:any, product_id:any) => {

      //get product data
      axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "product_details",
              product_id: [product_id],
            },
          })
          .then((response) => {
            console.log(response.data);
            //UPDATE product WITH RESPONSE
            updateProduct(response.data[0]);
            
          })
          .catch((err) => {
            console.log(err.data);
          });

      //update productValues.product_name
      
    }

    const addProduct = (product_name: any) => {
      
      updateProduct({
        ...productValues,
        ["product_id"]: null,
        ["product_name"]: product_name,
        ["group_quantity"]: 1,
      });

    }

  
  const [openSupplierEditor, setOpenSupplierEditor] = useState(false);


  const handleSupplierSubmit = (formdata: any) => {
    
    console.log(formdata);
    setOpenSupplierEditor(false);
    setSupplier({
      name: formdata.name,
    });
    updateProduct({
      ...productValues,
      ["supplier_id"]: formdata.supplier_id,
    });
  };

  const [supplierCreatorName, setSupplierCreatorName] = useState("");
  

  useEffect(() => {
    if(popupItemParams && typeof popupItemParams !== 'function'){
      updateProduct({
        ...productValues,
        ["category"]: popupItemParams.category,
      });
    }
  }, [popupItemParams]);


  /*=======================================
  STONE REMNANTS
  =======================================*/
  const [stoneRemnantsList, setStoneRemnantsList] = useState<any[]>([]);
  const [stoneRemnantsDeleteId, setStoneRemnantsDeleteId] = useState<string | null>(null);
  const [openAlertDeleteRemnant, setOpenAlertDeleteRemnant] = useState(false);

  const loadRemnants = () => {
    setLoading(true);
    setStoneRemnantsList([...fakeApiRemnants]);
    setLoading(false);
  };

  useEffect(() => {
    if (productValues.category === "stone")
      loadRemnants();
  }, [productValues.category]);

  useEffect(() => {
    if (stoneRemnantsDeleteId !== null) {
      setOpenAlertDeleteRemnant(true);
    }
  }, [stoneRemnantsDeleteId]);

  useEffect(() => {
    if (!openAlertDeleteRemnant) {
      setStoneRemnantsDeleteId(null);
    }
  }, [openAlertDeleteRemnant]);

  const handleDeleteRemnant = () => {
    setLoading(true);
    setOpenAlertDeleteRemnant(false);
    // next line is frontend only, the backend will delete the remnant on save
    setStoneRemnantsList(stoneRemnantsList.filter((item: any) => item.remnant_id !== stoneRemnantsDeleteId));
    setLoading(false);
  };

  const handleAddRemnant = () => {
    setLoading(true);
    setStoneRemnantsList([...stoneRemnantsList, {
      remnant_id: 'null',
      length: 0,
      width: 0,
      thickness: 0,
      quantity: 0,
    }]);
    setLoading(false);
  };

  useEffect(() => {
    if (stoneRemnantsList.length > 0) {
      updateProduct({
        ...productValues,
        stone_remnants: stoneRemnantsList,
      });
    }
  }, [stoneRemnantsList]);

  /*=======================================
  END STONE REMNANTS
  =======================================*/

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{ outline: "none" }}>
          <Box
            id="supplier_list"
            title="Suppliers"
            subtitle="List"
          >
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Supplier Name</TableCell>
                    <TableCell>TIN</TableCell>
                    <TableCell>Adresss</TableCell>
                    <TableCell>Payment Terms</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {supplier_list.map((supplier: any, i: any) => (
                    <TableRow
                      onClick={() => (
                        setSupplier({
                          name: supplier.name,
                        }),
                        updateProduct({
                          ...productValues,
                          ["supplier_id"]: supplier.supplier_id.toString(),
                        }),
                        handleClose()
                      )}
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell>{supplier.name}</TableCell>
                      <TableCell>{supplier.tin}</TableCell>
                      <TableCell>{supplier.address}</TableCell>
                      <TableCell>{supplier.payment_terms}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </Dialog>
    <Box
      className="product_form"

    >
      <div className="form">
        <div className="form_group">
          <div className='input_group'>
            <h2>Images</h2>
            {productValues.product_id && productValues.folder_id!=0 && productValues.folder_id!=undefined && (
            <FilesBrowser
                setLoading={setLoading}
                folder_id={productValues.folder_id} 
                view_type="thumbnail_gallery"
            />
            )}

            {(!productValues.product_id || productValues.folder_id==0 || productValues.folder_id==undefined) && (
            <div className='msg_save_quote'>
                <SaveOutlined />
                <p>Save the product to upload images</p>
            </div>
            )}
          </div>
          
          {is_group? (
            <Fragment>
            <AutocompleteProduct 
                category_filter="'panel','wood','stone','hardware','paint'"
                setProduct={setProduct} 
                product_name={productValues.product_name}
                addProduct={addProduct}
                hide_groups={true}
            />
            <TextField
              id="group_quantity"
              name="group_quantity"
              label="Group Quantity"
              type="number"
              required
              fullWidth
              value={productValues.group_quantity}
              onChange={handleInputChange}
            />
            </Fragment>
          ) : (
          <TextField
            id="product_name"
            name="product_name"
            label="Product Name"
            type="text"
            required
            fullWidth
            value={productValues.product_name}
            onChange={handleInputChange}
          />
          )}
          <div className="input_group">
            <h2>Product info</h2>

            <div className="input_row">
              
              <TextField
                id="caso_code"
                name="caso_code"
                label="Caso Code"
                type="text"
                required
                fullWidth
                value={productValues.caso_code}
                onChange={handleInputChange}
              />
              <TextField
                id="supplier_code"
                name="supplier_code"
                label="Supplier Code"
                type="text"
                required
                fullWidth
                value={productValues.supplier_code}
                onChange={handleInputChange}
              />
              
            </div>
          
            <div className="input_row">
              <FormControl fullWidth>
                <InputLabel id="lbl_product_category">Category</InputLabel>
                <Select
                  labelId="product_category"
                  id="product_category"
                  value={productValues.category}
                  label="Category"
                  onChange={handleChange}
                  disabled = {popupItemParams && typeof popupItemParams !== 'function'? true : false}
                >
                  
                  <MenuItem value={"panel"}>Panels and adhesives</MenuItem>
                  <MenuItem value={"paint"}>Paint</MenuItem>
                  <MenuItem value={"hardware"}>Hardware</MenuItem>
                  <MenuItem value={"wood"}>Wood</MenuItem>
                  <MenuItem value={"stone"}>Stone</MenuItem>
                  
                </Select>
              </FormControl>
              
              <Autocomplete
                value={supplier}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setSupplier({
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setSupplierCreatorName(newValue.inputValue);
                    setOpenSupplierEditor(true);
                    
                  } else {
                    setSupplier(newValue);
                    if (newValue?.supplier_id != null) {
                      updateProduct({
                        ...productValues,
                        ["supplier_id"]: newValue.supplier_id.toString(),
                      });
                    } else {
                      updateProduct({
                        ...productValues,
                        ["supplier_id"]: "",
                      });
                    }
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.name
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                fullWidth
                id="category"
                options={supplier_list}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                sx={{  }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Supplier"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <IconButton onClick={handleOpenSupplierList}
                          style={{ padding: "0px 5px" }}
                        >
                          <img src={ic_dots}></img>
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          
          <div className="input_group">
            <h2>Stock quantity</h2>
            <div className="input_row">
              <TextField
                id="product_stock"
                name="product_stock"
                label="Stock"
                type="number"
                required
                fullWidth
                //IF WOOD DISABLE
                {...(productValues.category === "wood" && { disabled: true })}

                InputProps={{
                  endAdornment: productValues.category === "wood" && (
                    <Tooltip title="Stock quantity is updated by Wood Dimensions">
                      <InputAdornment position="end">
                        BF
                      </InputAdornment>
                    </Tooltip>
                  ),
                }}


          
                value={productValues.product_stock}
                onChange={handleInputChange}
              />

              <TextField
                id="minimum_stock"
                name="minimum_stock"
                label="Minimum Stock"
                type="number"
                required
                fullWidth
                value={productValues.minimum_stock}
                onChange={handleInputChange}
              />
            </div>
          </div>
          
          <div className="input_group">
            <h2>Price</h2>
            <div className="input_row">
              
              <TextField
                id="unit_price"
                name="unit_price"
                label="Unit Price"
                type="number"
                required
                fullWidth
                value={productValues.unit_price}
                onChange={handlePriceChange}
              />

              {productValues.category !== "stone" && (
                <TextField
                  id="percentage_to_land"
                  name="percentage_to_land"
                  label="Percentage to land"
                  type="number"
                  required
                  fullWidth
                  value={productValues.percentage_to_land}
                  onChange={handlePriceChange}
                />)
              }

              <TextField
                id="landed_cost"
                name="landed_cost"
                label="Landed Cost"
                type="number"
                required
                fullWidth
                value={productValues.landed_cost}
                disabled
              />

              
              {productValues.category !== "stone" && (
                <Fragment>
                  <TextField
                    id="sale_price"
                    name="sale_price"
                    label="Sale Price"
                    type="number"
                    required
                    fullWidth
                    onChange={handleInputChange}
                    value={productValues.sale_price}
                    disabled={!productValues.manual_sale_price}
                  />
                  <FormControlLabel
                    checked={productValues.manual_sale_price}
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        onChange={(e) => {
                          updateProduct({
                            ...productValues,
                            ["manual_sale_price"]: e.target.checked,
                          });
                        }}
                      />
                    }
                    label="Manual Price"
                  />
                </Fragment>
              )}
             
            </div>
          </div>



          <TextField
            id="product_description"
            name="product_description"
            label="Product Description"
            type="text"
            size="small"
            required
            fullWidth
            multiline
            rows={4}
            value={productValues.product_description}
            onChange={handleInputChange}
          />


          {productValues.category === "stone" && (
            <div className="stone_fields">
              <div className="input_row">
                <TextField
                  id="price_with_manufacturing"
                  name="price_with_manufacturing"
                  label="Price with manufacturing"
                  type="number"
                  required
                  fullWidth
                  value={productValues.stone_fields.price_with_manufacturing}
                  disabled
                />
              
                <TextField
                  id="price_without_manufacturing"
                  name="price_without_manufacturing"
                  label="Price without manufacturing"
                  type="number"
                  required
                  fullWidth
                  value={productValues.stone_fields.price_without_manufacturing}
                  disabled
                />
              </div>
              <h2>Stone Dimensions</h2>
              <div className="input_row">
                <TextField
                  id="width"
                  name="width"
                  label="Width"
                  type="number"
                  required
                  fullWidth
                  value={productValues.stone_fields.width}
                  onChange={handleStoneFieldsChange}
                />

                <TextField
                  id="length"
                  name="length"
                  label="Length"
                  type="number"
                  required
                  fullWidth
                  value={productValues.stone_fields.length}
                  onChange={handleStoneFieldsChange}
                />

                <TextField
                  id="square_feet"
                  name="square_feet"
                  label="Square Feet"
                  type="number"
                  required
                  fullWidth
                  value={productValues.stone_fields.square_feet}
                  disabled
                />

                <TextField
                  id="price_per_square_feet"
                  name="price_per_square_feet"
                  label="Price per square feet"
                  type="number"
                  required
                  fullWidth
                  value={productValues.stone_fields.price_per_square_feet}
                  disabled
                />

              </div>

              <h2>Stone Remnants</h2>
              <div className="stone_remnants">
                {stoneRemnantsList.map((item: any, indexRemnant: any) => {
                  const bf = Math.round(((item.width * item.length * item.thickness) / 144) * item.quantity * 100) / 100;

                  return (
                    <div className="stone_remnant_row" key={indexRemnant}>
                      <TextField
                        id="length"
                        name="length"
                        label="Length"
                        type="number"
                        size="small"
                        required
                        fullWidth
                        value={item.length}
                        onChange={(e) => {
                          setStoneRemnantsList((prevState: any) => {
                            return [
                              ...prevState.slice(0, indexRemnant),
                              {
                                ...prevState[indexRemnant],
                                length: e.target.value,
                              },
                              ...prevState.slice(indexRemnant + 1),
                            ]
                          });
                        }}
                      />

                      <TextField
                        id="width"
                        name="width"
                        label="Width"
                        type="number"
                        size="small"
                        required
                        fullWidth
                        value={item.width}
                        onChange={(e) => {
                          setStoneRemnantsList((prevState: any) => {
                            return [
                              ...prevState.slice(0, indexRemnant),
                              {
                                ...prevState[indexRemnant],
                                width: e.target.value,
                              },
                              ...prevState.slice(indexRemnant + 1),
                            ]
                          });
                        }}
                      />

                      <TextField
                        id="thickness"
                        name="thickness"
                        label="Thickness"
                        type="number"
                        size="small"
                        required
                        fullWidth
                        value={item.thickness}
                        onChange={(e) => {
                          setStoneRemnantsList((prevState: any) => {
                            return [
                              ...prevState.slice(0, indexRemnant),
                              {
                                ...prevState[indexRemnant],
                                thickness: e.target.value,
                              },
                              ...prevState.slice(indexRemnant + 1),
                            ]
                          });
                        }}
                      />

                      <TextField
                        id="quantity"
                        name="quantity"
                        label="Quantity"
                        type="number"
                        size="small"
                        required
                        fullWidth
                        value={item.quantity}
                        onChange={(e) => {
                          setStoneRemnantsList((prevState: any) => {
                            return [
                              ...prevState.slice(0, indexRemnant),
                              {
                                ...prevState[indexRemnant],
                                quantity: e.target.value,
                              },
                              ...prevState.slice(indexRemnant + 1),
                            ]
                          });
                        }}
                      />

                      {/* <div className="boardFeet_value">
                        {bf+" BF"}
                      </div> */}

                      <div className="delete_icon">
                        <IconButton
                          onClick={() => {
                            setStoneRemnantsDeleteId(item.remnant_id);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}

                <Button
                  className="item_add_button"
                  fullWidth
                  onClick={() => {
                    handleAddRemnant();
                  }}
                  startIcon={<AddOutlined />}
                >
                  Add remnant
                </Button>
              </div>
            </div>
          )}

          {productValues.category === "wood" && (
            <div className="wood_fields">
              <div className="input_group">
              <h2>Wood Dimensions</h2>
                <div className="wood_dimensions">
                  <div className="wood_dimensions_left">
                    {productValues.wood_dimensions.map((item: any, index: any) => (
                      <div className="wood_dimension_row" key={index}>
                        <TextField
                          id="length"
                          name="length"
                          label="Length"
                          type="number"
                          size="small"
                          required
                          fullWidth
                          value={item.length}
                          onChange={(e) => handleWoodDimensionsChange(e, index)}
                        />

                        <TextField
                          id="width"
                          name="width"
                          label="Width"
                          type="number"
                          size="small"
                          required
                          fullWidth
                          value={item.width}
                          onChange={(e) => handleWoodDimensionsChange(e, index)}
                        />

                        <TextField
                          id="thickness"
                          name="thickness"
                          label="Thickness"
                          type="number"
                          size="small"
                          required
                          fullWidth
                          value={item.thickness}
                          onChange={(e) => handleWoodDimensionsChange(e, index)}
                        />

                        <TextField
                          id="quantity"
                          name="quantity"
                          label="Quantity"
                          type="number"
                          size="small"
                          required
                          fullWidth
                          value={item.quantity}
                          onChange={(e) => handleWoodDimensionsChange(e, index)}
                        />

                        
                        

                        <div className="boardFeet_value">
                          {item.boardFeet+" BF"} 
                        </div>

                        <div className="delete_icon">
                          <IconButton onClick={() => handleDeleteWoodDimension(index)}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </div>
                        
                      </div>
                    ))}
                  </div>
                  <div className="wood_dimensions_right">
                  </div>
                </div>

                <Button 
                  className="item_add_button"
                  onClick={()=>{
                      handleAddItem()
                  }}>
                  Add dimension
                </Button>

              </div>
            </div>
          )}

                    

        </div>
      </div>
    </Box>
    
    <Dialog
      open={openSupplierEditor} >
      
      <SupplierEditor
        setLoading={setLoading}
        onSubmit={handleSupplierSubmit}
        name={supplierCreatorName}
      />
    </Dialog>

    <AlertDialog
      open={openAlertDeleteRemnant}
      setOpen={setOpenAlertDeleteRemnant}
      send={handleDeleteRemnant}
      title="Delete remnant"
      subtitle="Are you sure you want to delete this remnant?"
    />



    </Fragment>
  );
};

