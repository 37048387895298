import React, { useContext, } from 'react';
import { IServiceType, ITypeCost, QuotesContext } from '../QuoteContextProvider';
import { CircularProgress, IconButton, TextField, } from '@mui/material';
import AppleSwitch from 'elements/AppleSwitch';
import QuoteTypeSummaryBox from './QuoteTypeSummaryBox';

export default function QuoteDrawerContent ( props: any ) {

    const {
        fixNumberValue,
        quoteCost,
        services_types,
        updateQuoteCostValue,
        loadingFields,
    } = useContext(QuotesContext);

    return (
        <>
            <div id="quote_sidebar" className="sidebar_body">
                {services_types.map((type: IServiceType) => (
                    <div key={type.serviceType}>
                        <QuoteTypeSummaryBox type={type} />
                    </div>
                ))}

                <AppleSwitch 
                    checked={quoteCost.have_tax}
                    onChange={(e: boolean) => {
                        updateQuoteCostValue(
                            {
                                have_tax: e,
                            }
                        );
                    }}
                    label="Tax included"
                />

                <div className="summary_section total_section">
                    <div className="summary_row">
                        <div className="summary_row_left">Subtotal</div>
                        <div className="summary_row_right">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="0.8rem" /> :
                                <>${(quoteCost.subtotal_cost || 0).toLocaleString()}</>
                            }
                        </div>
                    </div>

                    {quoteCost.have_tax && (
                    <div className="summary_row">
                        <div className="summary_row_left">Tax ({quoteCost.tax_percentage}%)</div>
                        <div className="summary_row_right">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="0.8rem" /> :
                                <>${(quoteCost.tax_cost || 0).toLocaleString()}</>
                            }
                        </div>
                    </div>
                    )}

                    <div className="summary_total">
                        <div className="summary_total_label">Total Quote</div>
                        <div className="summary_total_value">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="1.2rem" /> :
                                <>${(quoteCost.total_cost || 0).toLocaleString()}</>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}