import axios from 'axios';
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';

import { ProjectConfig} from 'Global';
import Box from 'elements/Box';
import ProjectHeader from '../utils/ProjectHeader';
import StockPending from './StockPending';
import CostChart from './CostChart';
import InventoryUsed from './InventoryUsed';
import ProjectProgress from './ProjectProgress';
import FilesBrowser from 'components/Files/FilesBrowser';
import { PaymentList } from 'components/Payment/PaymentList';
import { Business, Factory } from '@mui/icons-material';
import { Typography } from '@mui/material';

import ScheduleView from 'components/Tasks/ScheduleView';

/* REMOVE NEXT LINES UNTIL THE MARK*/
const defaultData = {
  workforce: [2000, 1500, 2000, 2000, 2000, 4000],
  products: [4000, 2000, 5000, 3000, 9000, 10000],
}

const fakeAPI = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June'],
  datasets: [
    {
      label: 'Workforce',
      data: defaultData.workforce,
    },
    {
      label: 'Prodcuts',
      data: defaultData.products,
    },
    {
      label: 'Project',
      data: defaultData.products.map( (item: any, index) => defaultData.workforce[index] + defaultData.products[index]),
    },
  ],
};
/* THE MARK */

const defaultValues = {
  labels: [''],
  datasets: [
    {
      label: '',
      data: [0],
    },
  ],
};


const fakeAPIProgress = {
  indicators: [
    {
      id: 1,
      title: 'Design',
      progress: 100,
      image: <Factory sx={{width: '30px'}} />,
      total_tasks: 7,
      completed_tasks: 7,
    },
    {
      id: 2,
      title: 'Manufactoring',
      progress: 80,
      image: <Factory sx={{width: '30px'}} />,
      total_tasks: 7,
      completed_tasks: 6,
    },
    {
      id: 3,
      title: 'Hardware assembly',
      progress: 50,
      image: <Factory sx={{width: '30px'}} />,
      total_tasks: 4,
      completed_tasks: 2,
    },
    {
      id: 4,
      title: 'Finishing',
      progress: 0,
      image: <Factory sx={{width: '30px'}} />,
      total_tasks: 6,
      completed_tasks: 0,
    },
    {
      id: 5,
      title: 'Installation',
      progress: 0,
      image: <Factory sx={{width: '30px'}} />,
      total_tasks: 8,
      completed_tasks: 0,
    },
    {
      id: 6,
      title: 'Stone',
      progress: 0,
      image: <Factory sx={{width: '30px'}} />,
      total_tasks: 3,
      completed_tasks: 0,
    }
  ],
  tasks: {
    1: {
      title: 'Design',
      pending: [],
      completed: [
        {
          task_id: 1,
          task_name: 'Initial measurements',
          assigned: true,
        },
        {
          task_id: 2,
          task_name: 'Design proposal',
          assigned: false,
        },
        {
          task_id: 3,
          task_name: 'Design approval',
        },
        {
          task_id: 4,
          task_name: 'Manufactoring',
          assigned: false,
        },
        {
          task_id: 5,
          task_name: 'Quality control',
          assigned: false,
        },
        {
          task_id: 6,
          task_name: 'Packaging',
          assigned: false,
        },
        {
          task_id: 7,
          task_name: 'Shipping',
          assigned: false,
        },
      ],
    },
    2: {
      title: 'Manufactoring',
      pending: [
        {
          task_id: 8,
          task_name: 'Delivery',
          assigned: false,
        },
      ],
      completed: [
        {
          task_id: 9,
          task_name: 'Delivery',
          assigned: false,
        },
        {
          task_id: 10,
          task_name: 'Shipping',
          assigned: true,
        },
        {
          task_id: 11,
          task_name: 'Packaging',
          assigned: false,
        },
        {
          task_id: 12,
          task_name: 'Quality control',
          assigned: false,
        },
        {
          task_id: 13,
          task_name: 'Packaging',
          assigned: false,
        },
        {
          task_id: 14,
          task_name: 'Quality control',
          assigned: false,
        },
      ],
    },
    3: {
      title: 'Hardware assembly',
      pending: [
        {
          task_id: 15,
          task_name: 'Hardware assembly',
          assigned: true,
        },
        {
          task_id: 16,
          task_name: 'Quality control',
          assigned: false,
        },
      ],
      completed: [
        {
          task_id: 17,
          task_name: 'Hardware assembly',
          assigned: true,
        },
        {
          task_id: 18,
          task_name: 'Quality control',
          assigned: false,
        },
      ],
    },
    4: {
      title: 'Finishing',
      pending: [
        {
          task_id: 19,
          task_name: 'Finishing',
          assigned: true,
        },
        {
          task_id: 21,
          task_name: 'Quality control',
          assigned: false,
        },
        {
          task_id: 20,
          task_name: 'Packaging',
          assigned: false,
        },
        {
          task_id: 22,
          task_name: 'Shipping',
          assigned: false,
        },
        {
          task_id: 23,
          task_name: 'Installation',
          assigned: false,
        },
        {
          task_id: 24,
          task_name: 'Stone',
          assigned: false,
        },
      ],
      completed: [],
    },
    5: {
      title: 'Installation',
      pending: [
        {
          task_id: 26,
          task_name: 'Installation',
          assigned: true,
        },
        {
          task_id: 25,
          task_name: 'Installation',
          assigned: true,
        },
        {
          task_id: 27,
          task_name: 'Quality control',
          assigned: false,
        },
        {
          task_id: 28,
          task_name: 'Stone',
          assigned: false,
        },
        {
          task_id: 29,
          task_name: 'Stone',
          assigned: false,
        },
        {
          task_id: 30,
          task_name: 'Stone',
          assigned: false,
        },
        {
          task_id: 31,
          task_name: 'Stone',
          assigned: false,
        },
        {
          task_id: 32,
          task_name: 'Stone',
          assigned: false,
        },
      ],
      completed: [],
    },
    6: {
      title: 'Stone',
      pending: [
        {
          task_id: 36,
          task_name: 'Stone',
          assigned: true,
        },
        {
          task_id: 34,
          task_name: 'Stone',
          assigned: true,
        },
        {
          task_id: 33,
          task_name: 'Calibration',
          assigned: false,
        },
      ],
      completed: [],
    },
  },
};

const defaultProgress = {
  indicators: [],
  tasks: {},
};

const defaultSchedule = {
  schedule_start_date: new Date(),
  schedule_end_date: new Date(),
  months_quantity: 0,
  tasks: [],
};

export default function ProjectDashboard (props: any) {

  
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {props.setLoading(loading);}, [loading]);
  
  //GET PROJECT ID FROM URL
  let { project_id } = useParams();

  const [ProjectDashboard, setProjectDashboard] = useState<any>({});

  const [stockPending, setStockPending] = useState<any>([]);
  const [costData, setCostData] = useState<any>({...defaultValues});
  const [workedHours, setWorkedHours] = useState<number>(0);
  const [workersAssigned, setWorkersAssigned] = useState<number>(0);
  const [daysFromStart, setDaysFromStart] = useState<number>(0);
  const [daysUntilDeadline, setDaysUntilDeadline] = useState<number>(0);
  const [progressData, setProgressData] = useState<any>({...defaultProgress});

  const [quotationsCost, setQuotationsCost] = useState<number>(0);
  const [projectExpenses, setProjectExpenses] = useState<number>(0);
  const [pendingPayment, setPendingPayment] = useState<number>(0);

  const [extraProductsPayment, setExtraProductsPayment] = useState<number>(0);
  const [subContractsPayment, setSubContractsPayment] = useState<number>(0);
  const [extraServicesPayment, setExtraServicesPayment] = useState<number>(0);

  useEffect(() => {
      loadData();
  }, []);

  const loadData = () => {
    setLoading(true);

    axios.get(ProjectConfig.api_url,
    {
      params: {
        request: 'project_dashboard',
        project_id: project_id,
      }
    })
      .then((response) => {
        console.log(response.data); 
        
        setStockPending(response.data.stockPending);
        setWorkedHours(response.data.total_worked_hours);
        setDaysFromStart(response.data.project_dates.days_from_start);
        setDaysUntilDeadline(response.data.project_dates.days_until_deadline);
        setPendingPayment(response.data.pending_payment);
        setProjectExpenses(response.data.project_expenses);
        setWorkersAssigned(response.data.workers_assigned);
        setQuotationsCost(response.data.total_project_cost);

        setProgressData({...fakeAPIProgress});setCostData({...fakeAPI}); return; // TODO: REMOVE THIS LINE
        setCostData(response.data.costData);
        setProgressData(response.data.progressData);
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  return (
    <div id='DashboardContainer'>
      
      <ProjectHeader />

      <div className='dashboardWrapper'>
        <div className='dashboard_flex_container'>
          <div className='dashboard_left_column graph_column'>
            <Box
              title="FINANCIAL"
              subtitle="COSTS AND PAYMENTS"
              className="transparent_box"
              id="dashboard_finaltial_container"
            >
              <div id="dashboard_payment_container" className="graph_row">
                  <Box className='graph_top_boxes'>
                    <div className="graph_top_number">${quotationsCost.toLocaleString()}</div>
                    <div className="graph_top_text">QUOTATIONS COST</div>
                  </Box>
                  <Box className='graph_top_boxes'>
                    <div className="graph_top_number">${projectExpenses.toLocaleString()}</div>
                    <div className="graph_top_text">PROJECT EXPENSES</div>
                  </Box>
                  <Box className='graph_top_boxes'>
                    <div className="graph_top_number">${pendingPayment.toLocaleString()}</div>
                    <div className="graph_top_text">PENDING PAYMENT</div>
                  </Box>
              </div>

              <div className="graph_row" id="dashboard_payment_indicators">
                  <div className="graph_top_container">
                      <div className="graph_top_progress_bar">
                          <div style={{height: '100%'}} />
                      </div>

                      <Box className='graph_top_boxes'>
                          <div className="graph_top_icon displayer_row">
                              <span>EXTRA PRODUCTS</span>
                              <span>${extraProductsPayment.toLocaleString()}</span>
                          </div>
                      </Box>
                  </div>

                  <div className="graph_top_container">
                      <div className="graph_top_progress_bar">
                          <div style={{height: '100%'}} />
                      </div>

                      <Box className='graph_top_boxes'>
                          <div className="graph_top_icon displayer_row">
                              <span>SUB CONTRACTS</span>
                              <span>${subContractsPayment.toLocaleString()}</span>
                          </div>
                      </Box>
                  </div>

                  <div className="graph_top_container">
                      <div className="graph_top_progress_bar">
                          <div style={{height: '100%'}} />
                      </div>

                      <Box className='graph_top_boxes'>
                          <div className="graph_top_icon displayer_row">
                              <span>EXTRA SERVICES</span>
                              <span>${extraServicesPayment.toLocaleString()}</span>
                          </div>
                      </Box>
                  </div>
              </div>

              <div className='graph_row'>
                <CostChart
                  data={costData}
                />

                <div className='graph_column' id="dashboard_payments_list_container">
                  <PaymentList
                    project_id={project_id}
                    setLoading={props.setLoading}
                    callback={loadData}
                  />
                </div>
              </div>
            </Box>

            <div className='graph_row'>
              <Box
                title="PROGRESS"
                subtitle="MAIN PROJECT INDICATORS"
                className="transparent_box"
                id="dashboard_progress_box"
              >
                <ProjectProgress
                  data={progressData}
                  workedHours={workedHours}
                  workersAssigned={workersAssigned}
                  daysFromStart={daysFromStart}
                  daysUntilDeadline={daysUntilDeadline}
                />
              </Box>
            </div>

            <div className='graph_row'>
              <Box
                title="FILES"
                subtitle="MAIN PROJECT FILE BROWSER"
                className="transparent_box"
                id="dashboard_files_box"
              >
                <FilesBrowser
                  setLoading={props.setLoading}
                  setMenuTopLeftContent={() => {}}
                  with_drawer={false}
                />
              </Box>
            </div>
          </div>


          <div id="dashboard_right_column">
            <StockPending data={stockPending}/>
            <InventoryUsed project_id={project_id} setLoading={props.setLoading}/>
          </div>
        </div>

        <div id="schedule_dashboard" className='dashboard_flex_container'>
          <Box
            title={
              <div className='title-with-icon'>
                <Business />
                PROJECT SCHEDULE
              </div>
            }
            subtitle="LIST OF  TASK"
            className="transparent_box"
            id="dashboard_schedule_box"
          >
            <ScheduleView
              setLoading={props.setLoading}
              project_id={project_id}
            />

          </Box>
        </div>
      </div>
    </div>
  );
};

