import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, Chip, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ProjectConfig } from "Global";
import ProjectHeader from '../utils/ProjectHeader';
import AlertDialog from 'elements/AlertDialog';
import EditIcon from '@mui/icons-material/Edit';
import { CopyAll, ExpandLess, ExpandMore } from '@mui/icons-material';



export default function ProjectQuotes (props: any) {
    useEffect(() => {props.setLoading(false);}, []);
    
  const [loading, setLoading] = useState(false); 
  const [quote_list, setQuoteList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = 1;

  const [page, setPage] = useState(currentPage);
  const [refreshList, setRefreshList] = useState(0);


  /*=======================================
    GET ID FROM URL
  =======================================*/
  let { project_id } = useParams();
  /*=======================================
  =======================================*/

  


  /*=========================================================
  DELETE PROJECT ESTIMATE
  =========================================================*/
  

  const handleDelete = (quote_id:number) => {
    setDeleteId(quote_id)
    setOpenAlert(false)
    deleteQuote()
  }

  const deleteQuote = () => {
    setLoading(true)
    axios.post(ProjectConfig.api_url, null, 
      {
        params: {
          request: 'delete_quote',
          quote_id: deleteId,
        }
      })
  
      .then((response) => {
        setRefreshList(refreshList + 1)
        setLoading(true)
        
      })
      .catch((err) => {
        console.log(err.data);
      })

  }

  /*=========================================================*/
  

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'quote_list',
          page: page,
          project_id: project_id,
        }
      })
        .then((response) => {
          console.log(response.data)
          setQuoteList(response.data.quote_list)
          setTotalPages(response.data.total_pages)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/

  const [openAlert, setOpenAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  
  return (
    <>
      <div id='QuoteList' className='moduleContainer'>

        <ProjectHeader>
          <Button component={Link} to="add" variant="contained">
            New Quote
          </Button>
        </ProjectHeader>
          <div id="product_list_container" className="flex_container">
            
              <Box id='quote_list' title="Quotes" subtitle="List"  >
              {!loading && <div id='loader'><CircularProgress /></div>}
              <TableContainer>
              <Table stickyHeader aria-label="sticky table"> 
                <TableHead>
                  <TableRow>
                    <TableCell>Quote ID</TableCell>
                    <TableCell>Subtotal</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {quote_list.map((quote: any, i: number) => {
                      // let estimate_products_quantity = JSON.parse(estimate.estimate_data).length;
                      // let total_low = "$ " + parseInt(JSON.parse(estimate.total_low)).toLocaleString();
                      // let total_mid = "$ " + parseInt(JSON.parse(estimate.total_mid)).toLocaleString();
                      // let total_high = "$ " + parseInt(JSON.parse(estimate.total_high)).toLocaleString();
                      // let total_design_fee = "$ " + parseInt(estimate.total_design_fee).toLocaleString();


                      return (
                        <TableRow
                          key={i}
                          hover
                          role="checkbox"
                        >
                          <TableCell>{quote.quote_id}</TableCell>
                          <TableCell>
                            ${quote.quote_data.subtotal.toLocaleString()}
                          </TableCell>
                          <TableCell>
                            <Chip label={quote.status_name} size='small'  className={"quoteStatus_"+quote.status_id} variant='outlined' />
                          </TableCell>
                        
                          <TableCell className="actionsColumn align_right">
                            <Button
                              component={Link}
                              to={`edit/${quote.quote_id}/V1`}
                              variant="contained"
                              color="primary"
                              size="small"
                              startIcon={<EditIcon />}
                            >
                              Edit
                            </Button>

                            <Button
                              component={Link}
                              to={`edit/${quote.quote_id}`}
                              variant="contained"
                              color="primary"
                              size="small"
                              startIcon={<EditIcon />}
                              sx={{ml: '10px'}}
                            >
                              Edit V.2
                            </Button>


                            <IconButton aria-label="delete"  color="primary" onClick={() => {
                              setOpenAlert(true)
                              setDeleteId(quote.quote_id)
                            }}>
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            
            { total_pages > 1 &&
              <Pagination count={total_pages} page={page}  onChange={(event, value) => {
                setPage(value)
              }
              } 
              color="primary"
              style={{"marginTop": "20px","alignSelf": "center"}}
              />
            }
            </Box>
  
          </div>
      </div>

      <AlertDialog
        title="Delete Quote"
        subtitle="Are you sure you want to delete this quote?"
        open={openAlert}
        setOpen={setOpenAlert}
        send={handleDelete}
      />

    </>
  );
};