import React, { useEffect, useState } from "react";
import Box from "elements/Box";
import { Button, Dialog, DialogContent, DialogContentText, IconButton, Pagination, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import axios from "axios";
import { ProjectConfig } from "Global";
import { Add, Check, Close, DeleteOutlined, RemoveRedEye } from "@mui/icons-material";
import Cookies from "universal-cookie";

import 'assets/css/main.css';
import AlertDialog from "elements/AlertDialog";
import { LocalizationProvider, MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import AutocompleteProducts from "elements/Autocompletes/AutocompleteProducts";
import AutocompleteMulti from "elements/AutocompleteMulti";
import AutocompleteProjects from "elements/Autocompletes/AutocompleteProjects";
import AutocompleteTask from "elements/Autocompletes/AutocompleteTask";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";

const cookies = new Cookies();

const defaultDatetime = {
    task_user_time_id: 'null',
    project: {
        project_id: null,
        project_name: "",
    },
    users: [],
    task: null,
    date: moment(new Date()).format("YYYY-MM-DD"),
    start_time: "00:00",
    end_time: "12:00",
};

const defaultProduct = {
    task_product_id: 'null',
    product_id: "",
    product_name: "",
    quantity: 0,
    date: moment(new Date()).format("YYYY-MM-DD"),
};

const defaultInventory = {
    task_user_inventory_id: 'null',
    project: {
        project_id: null,
        project_name: "",
    },
    user: null,
    task: null,
    used_inventory: [],
    deleted_products: [],
};

const defaultTaskItem = {
    task_id: null,
    task_name: "",
    project_name: "",
};

export default function SuperviseWorkersList( props: any ) {

    const {} = props;

    const [loading, setLoading] = useState(false);
    
    useEffect(() => { props.setLoading(loading); }, [loading]);

    const {enqueueSnackbar} = useSnackbar();
    
    const [searchParams, setSearchParams] = useSearchParams();

    const [pageTimes, setPageTimes] = useState(searchParams.get("pageTimes") || 1);
    const [list, setList] = useState<any[]>([]);
    const [totalPagesTimes, setTotalPagesTimes] = useState(1);

    const [pageInventory, setPageInventory] = useState(searchParams.get("pageInventory") || 1);
    const [inventoryList, setInventoryList] = useState<any[]>([]);
    const [totalPagesInventory, setTotalPagesInventory] = useState(1);

    const [pageCompleted, setPageCompleted] = useState(searchParams.get("pageCompleted") || 1);
    const [completedList, setCompletedList] = useState<any[]>([]);
    const [totalPagesCompleted, setTotalPagesCompleted] = useState(1);

    const [openAlertWorkerTime, setOpenAlertWorkerTime] = useState(false);
    const [openDialogWorkerTime, setOpenDialogWorkerTime] = useState(false);
    const [openAlertProductInventory, setOpenAlertProductInventory] = useState(false);
    const [openDialogProductInventory, setOpenDialogProductInventory] = useState(false);
    const [openAlertCompleted, setOpenAlertCompleted] = useState(false);
    const [openDialogCompleted, setOpenDialogCompleted] = useState(false);

    const [selectedDatetime, setSelectedDatetime] = useState<any>({...defaultDatetime});
    const [selectedTaskUserTimeIdEdit, setSelectedTaskUserTimeIdEdit] = useState<any>(null);
    const [selectedTaskUserTimeIdDelete, setSelectedTaskUserTimeIdDelete] = useState<any>(null);
    const [selectedInventory, setSelectedInventory] = useState<any>({...defaultInventory});
    const [selectedInventoryIdEdit, setSelectedInventoryIdEdit] = useState<any>(null);
    const [selectedCompletedTask, setSelectedCompletedTask] = useState<any>({...defaultTaskItem});
    const [selectedCompletedTaskId, setSelectedCompletedTaskId] = useState<any>(null);


    const [frontend_task_product_id, setFrontend_task_product_id] = useState<any>(0);

    useEffect(() => {
        loadList();
        loadInventoryList();
        loadCompletedList();
    }, []);

    useEffect(() => {
        loadList();
        loadInventoryList();
        loadCompletedList();
    }, [pageTimes, pageInventory, pageCompleted]);

    const loadList = () => {
        setLoading(true);
        
        axios.get(ProjectConfig.api_url, {
            params: {
                request: "get_all_workers_time_list",
                isAdmin: true,
                params: {
                    page: pageTimes,
                }
            }
        })
        .then((response) => {
            console.log(response.data);
            setList(response.data.list);
            setTotalPagesTimes(response.data.total_pages);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const loadInventoryList = () => {
        setLoading(true);
        
        axios.get(ProjectConfig.api_url, {
            params: {
                request: "get_supervisor_inventory_list",
                isAdmin: true,
                params: {
                    page: pageInventory,
                }
            }
        })
        .then((response) => {
            console.log(response.data);
            setInventoryList(response.data.list);
            setTotalPagesInventory(response.data.total_pages);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const loadCompletedList = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_completed_tasks_by_user',
                params: {
                    page: pageCompleted,
                },
            },
        })
        .then((response) => {
            console.log(response.data);
            setCompletedList(response.data.list);
            setTotalPagesCompleted(response.data.total_pages);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if (selectedTaskUserTimeIdEdit === null) return;
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_supervisor_time_details',
                task_user_time_id: selectedTaskUserTimeIdEdit,
            },
        })
        .then((response) => {
            console.log(response.data);
            setSelectedDatetime(response.data);
            setSelectedTaskUserTimeIdEdit(null);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }, [selectedTaskUserTimeIdEdit]);

    const checkWorkerTime = () => {
        let errors = false;

        if (selectedDatetime.project.project_id === null) {
            errors = true;
            enqueueSnackbar("Debe seleccionar un proyecto", {variant: "error"});
        }
        if (selectedDatetime.users.length === 0) {
            errors = true;
            enqueueSnackbar("Debe seleccionar al menos un trabajador", {variant: "error"});
        }
        if (selectedDatetime.task === null) {
            errors = true;
            enqueueSnackbar("Debe seleccionar una tarea", {variant: "error"});
        }
        if (selectedDatetime.date === "") {
            errors = true;
            enqueueSnackbar("Debe seleccionar una fecha", {variant: "error"});
        }
        if (selectedDatetime.start_time === "") {
            errors = true;
            enqueueSnackbar("Debe seleccionar una hora de inicio", {variant: "error"});
        }
        if (selectedDatetime.end_time === "") {
            errors = true;
            enqueueSnackbar("Debe seleccionar una hora de fin", {variant: "error"});
        }
        if (selectedDatetime.start_time > selectedDatetime.end_time) {
            errors = true;
            enqueueSnackbar("La hora de inicio no puede ser mayor a la hora de fin", {variant: "error"});
        }

        return errors;
    }

    const handleConfirmWorkerTime = (justSave: boolean = false) => {
        setLoading(true);

        const params = (!justSave) ? {
            saveAndConfirm: true,
        } : {
            saveAndConfirm: false,
        };

        axios.post(ProjectConfig.api_url, selectedDatetime, {
            params: {
                ...params,
                request: "supervisor_add_time_to_task",
                isAdmin: true,
            }
        })
        .then((response) => {
            console.log(response.data);
            loadList();
            setSelectedDatetime({...defaultDatetime});
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
            setOpenDialogWorkerTime(false);
        });
    }

    const handleRejectWorkerTime = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: "delete_user_time",
                isAdmin: true,
                task_user_time_id: selectedTaskUserTimeIdDelete,
            }
        })
        .then((response) => {
            console.log(response.data);
            loadList();
            setSelectedTaskUserTimeIdDelete(null);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
            setOpenDialogWorkerTime(false);
        });
    }

    const checkProduct = (product: any) => {
        let errors = false;

        if (product.product_id === null || product.product_id === "") {
            errors = true;
            enqueueSnackbar("Debe seleccionar un producto", {variant: "error"});
        }
        if (product.quantity === "" || product.quantity === null) {
            errors = true;
            enqueueSnackbar("Debe ingresar una cantidad", {variant: "error"});
        }
        if (product.quantity <= 0) {
            errors = true;
            enqueueSnackbar("La cantidad debe ser mayor a 0", {variant: "error"});
        }
        if (product.date === "") {
            errors = true;
            enqueueSnackbar("Debe seleccionar una fecha", {variant: "error"});
        }

        return errors;
    }

    const checkInventory = () => {
        let errors = false;

        if (selectedInventory.project.project_id === null) {
            errors = true;
            enqueueSnackbar("Debe seleccionar un proyecto", {variant: "error"});
        }
        if (selectedInventory.user === null) {
            errors = true;
            enqueueSnackbar("Debe seleccionar un trabajador", {variant: "error"});
        } else if (selectedInventory.user.user_id === null) {
            errors = true;
            enqueueSnackbar("Debe seleccionar un trabajador", {variant: "error"});
        }
        if (selectedInventory.task === null) {
            errors = true;
            enqueueSnackbar("Debe seleccionar una tarea", {variant: "error"});
        } else if (selectedInventory.task.task_id === null) {
            errors = true;
            enqueueSnackbar("Debe seleccionar una tarea", {variant: "error"});
        }
        if (selectedInventory.used_inventory.length === 0) {
            errors = true;
            enqueueSnackbar("Debe agregar al menos un producto", {variant: "error"});
        }
        for (let product of selectedInventory.used_inventory) {
            if (checkProduct(product)) {
                errors = true;
                break;
            }
        }

        return errors;
    }

    const handleConfirmInventory = (justSave: boolean = false) => {
        setLoading(true);

        const params = (!justSave) ? {
            saveAndConfirm: true,
        } : {
            saveAndConfirm: false,
        };

        axios.post(ProjectConfig.api_url, {...selectedInventory}, {
            params: {
                ...params,
                request: "confirm_workerstime_inventory",
                isAdmin: true,
            }
        })
        .then((response) => {
            console.log(response.data);
            loadInventoryList();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
            setOpenDialogProductInventory(false);
        });
    }

    useEffect(() => {
        loadInventoryDetails();
    }, [selectedInventoryIdEdit]);

    const loadInventoryDetails = () => {
        if (selectedInventoryIdEdit === null) return;
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_supervisor_inventory_details',
                params: {
                    task_id: selectedInventoryIdEdit.task_id,
                    user_id: selectedInventoryIdEdit.user_id,
                },
            },
        })
        .then((response) => {
            console.log(response.data);
            setSelectedInventory({
                ...response.data,
                deleted_products: [],
            });
            setSelectedInventoryIdEdit(null);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const rejectTask = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: 'change_task_status',
                task_id: selectedCompletedTaskId,
                task_status_id: 1,
            }
        })
        .then((response) => {
            console.log(response.data);
            loadCompletedList();
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const confirmTask = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: 'change_task_status',
                task_id: selectedCompletedTaskId,
                task_status_id: 3,
            }
        })
        .then((response) => {
            console.log(response.data);
            loadCompletedList();
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <div className="moduleContainer" id="superviseWorkersContainer">
                <div className="moduleWrapper">
                    <div className="moduleHeader buttons_top ">
                        <h2>TASK TO CONFIRM</h2>
                    </div>

                    <div className="moduleContent">
                        <Box className="box100">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Project</TableCell>
                                        <TableCell>Task</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {completedList.map((item: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.project_name}</TableCell>
                                        <TableCell>{item.task_name}</TableCell>
                                        <TableCell>
                                            <div className="table_actions">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedCompletedTaskId(item.task_id);
                                                        setOpenDialogCompleted(true);
                                                    }}
                                                    startIcon={<Check />}
                                                >
                                                    Approve
                                                </Button>

                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedCompletedTaskId(item.task_id);
                                                        setOpenAlertCompleted(true);
                                                    }}
                                                    startIcon={<Close />}
                                                >
                                                    Reject
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            {totalPagesCompleted > 1 &&
                            <Pagination
                                count={totalPagesCompleted}
                                page={Number(pageCompleted)}
                                onChange={(event, value) => {
                                    setPageCompleted(value)
                                    setSearchParams({
                                        ...searchParams,
                                        pageCompleted: value.toString()
                                    })
                                }}
                                color="primary"
                                size="small"
                                sx={{ "& .MuiPagination-ul": { justifyContent: "center" } }}
                            />
                            }
                        </Box>
                    </div>
                </div>

                <div className="moduleWrapper">
                    <div className="moduleHeader buttons_top">
                        <h2>WORKERS TIME</h2>

                        <div className="moduleButtons">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                onClick={() => {
                                    setSelectedDatetime({...defaultDatetime});
                                    setOpenDialogWorkerTime(true)
                                }}
                            >
                                Add
                            </Button>
                        </div>
                    </div>

                    <div className="moduleContent">
                        <Box className="box100">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Project</TableCell>
                                        <TableCell>Worker</TableCell>
                                        <TableCell>Task</TableCell>
                                        <TableCell>Time</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {list.map((item: any, index: number) => (
                                    <>
                                        <TableRow key={index}>
                                            <TableCell colSpan={5} className="tableDate">{item.date}</TableCell>
                                        </TableRow>

                                        {item.times.map((task: any, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell>{task.project_name}</TableCell>
                                            <TableCell>{task.worker_name}</TableCell>
                                            <TableCell>{task.task_name}</TableCell>
                                            <TableCell>{task.start} to {task.end}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    color="secondary"
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedTaskUserTimeIdEdit(task.task_user_time_id);
                                                        setOpenDialogWorkerTime(true);
                                                    }}
                                                >
                                                    <RemoveRedEye />
                                                </IconButton>

                                                <IconButton
                                                    color="secondary"
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedTaskUserTimeIdDelete(task.task_user_time_id);
                                                        setOpenAlertWorkerTime(true);
                                                    }}
                                                >
                                                    <DeleteOutlined />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        ))}
                                    </>
                                    ))}
                                </TableBody>
                            </Table>

                            {totalPagesTimes > 1 &&
                            <Pagination
                                count={totalPagesTimes}
                                page={Number(pageTimes)}
                                onChange={(event, value) => {
                                    setPageTimes(value)
                                    setSearchParams({
                                        ...searchParams,
                                        pageTimes: value.toString()
                                    })
                                }}
                                color="primary"
                                size="small"
                                sx={{ "& .MuiPagination-ul": { justifyContent: "center" } }}
                            />
                            }
                        </Box>
                    </div>
                </div>

                <div className="moduleWrapper">
                    <div className="moduleHeader buttons_top">
                        <h2>USED INVENTORY</h2>

                        <div className="moduleButtons">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                onClick={() => {
                                    setSelectedInventory({...defaultInventory});
                                    setOpenDialogProductInventory(true)
                                }}
                            >
                                Add
                            </Button>
                        </div>
                    </div>

                    <div className="moduleContent">
                        <Box className="box100">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Project</TableCell>
                                        <TableCell>Worker</TableCell>
                                        <TableCell>Task</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {inventoryList.map((item: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.project_name}</TableCell>
                                        <TableCell>{item.worker_name}</TableCell>
                                        <TableCell>{item.task_name}</TableCell>
                                        <TableCell>{item.date}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="secondary"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedInventoryIdEdit({
                                                        task_id: item.task_id,
                                                        user_id: item.user_id,
                                                    });
                                                    setOpenDialogProductInventory(true);
                                                }}
                                            >
                                                <RemoveRedEye />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            {totalPagesInventory > 1 &&
                            <Pagination
                                count={totalPagesInventory}
                                page={Number(pageInventory)}
                                onChange={(event, value) => {
                                    setPageInventory(value)
                                    setSearchParams({
                                        ...searchParams,
                                        pageInventory: value.toString()
                                    })
                                }}
                                color="primary"
                                size="small"
                                sx={{ "& .MuiPagination-ul": { justifyContent: "center" } }}
                            />
                            }
                        </Box>
                    </div>
                </div>
            </div>

            <Dialog
                open={openDialogWorkerTime}
                onClose={() => setOpenDialogWorkerTime(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogContent className="forced_box_editor">
                    <div className="dialog_header">
                        <h2>CHECK WORKED TIME</h2>
                    </div>

                    <div className="input_group">
                        <div className="input_row">
                            <AutocompleteProjects
                                fullWidth
                                setLoading={setLoading}
                                value={selectedDatetime?.project}
                                handleChange={(value: any) => {
                                    setSelectedDatetime({
                                        ...selectedDatetime,
                                        project: value,
                                    });
                                }}
                                />
                        </div>

                        <div className="input_row">
                            <AutocompleteTask
                                fullWidth
                                setLoading={setLoading}
                                project_id={selectedDatetime?.project.project_id}
                                value={selectedDatetime?.task}
                                handleChange={(value: any) => {
                                    setSelectedDatetime({
                                        ...selectedDatetime,
                                        task: value,
                                    });
                                }}
                                
                            />
                        </div>

                        <div className="input_row">
                            <AutocompleteMulti
                                setSelectedItems={(items: any) => {
                                    setSelectedDatetime({
                                        ...selectedDatetime,
                                        users: items,
                                    });
                                }}
                                selectedItems={selectedDatetime.users}
                                limit={1}
                                style={{width: '100%'}}
                            />
                        </div>
                        
                        <DialogContentText>
                            <strong>Time</strong>
                        </DialogContentText>

                        <div className="input_row">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MobileDatePicker
                                    className='filters_datePicker'
                                    label={"Date"}
                                    value={selectedDatetime.date}
                                    showToolbar={false}
                                    closeOnSelect
                                    onChange={(newValue) => {
                                        newValue && setSelectedDatetime({
                                            ...selectedDatetime,
                                            date: newValue.format('YYYY-MM-DD')
                                        });
                                    }}
                                    renderInput={(params) => <TextField sx={{width: '50%'}} {...params} />}
                                />

                                <MobileTimePicker
                                    label="From"
                                    ampm={false}
                                    ampmInClock={false}
                                    value={moment(selectedDatetime.date + " " + selectedDatetime.start_time)}
                                    closeOnSelect
                                    onChange={(newValue) => {
                                        newValue && setSelectedDatetime({
                                            ...selectedDatetime,
                                            start_time: newValue.format('HH:mm'),
                                        });
                                    }}
                                    renderInput={(params) => <TextField sx={{width: '25%'}} {...params} />}
                                    />

                                <MobileTimePicker
                                    label="Untill"
                                    ampm={false}
                                    ampmInClock={false}
                                    value={moment(selectedDatetime.date + " " + selectedDatetime.end_time)}
                                    closeOnSelect
                                    onChange={(newValue) => {
                                        newValue && setSelectedDatetime({
                                            ...selectedDatetime,
                                            end_time: newValue.format('HH:mm'),
                                        });
                                    }}
                                    renderInput={(params) => <TextField sx={{width: '25%'}} {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>

                    <div className="buttons">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setOpenDialogWorkerTime(false)
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (!checkWorkerTime()) {
                                    handleConfirmWorkerTime(true);
                                }
                            }}
                        >
                            Save
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (!checkWorkerTime()) {
                                    handleConfirmWorkerTime();
                                }
                            }}
                        >
                            Save and confirm
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <AlertDialog
                open={openAlertWorkerTime}
                setOpen={setOpenAlertWorkerTime}
                subtitle="Are you sure you want to delete this item?"
                send={() => {
                    handleRejectWorkerTime();
                }}
            />

            <Dialog
                id="dialog_product_inventory"
                open={openDialogProductInventory}
                onClose={(_, reason: string) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        setOpenDialogProductInventory(false);
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogContent className="forced_box_editor">
                    <div className="dialog_header">
                        <h2>CHECK USED INVENTORY</h2>
                    </div>

                    <div className="input_group">
                        <div className="input_row">
                            <AutocompleteProjects
                                fullWidth
                                setLoading={setLoading}
                                value={selectedInventory?.project}
                                handleChange={(value: any) => {
                                    setSelectedInventory({
                                        ...selectedInventory,
                                        project: value,
                                    });
                                }}
                            />
                        </div>

                        <div className="input_row">
                            <AutocompleteTask
                                fullWidth
                                setLoading={setLoading}
                                project_id={selectedInventory?.project.project_id}
                                value={selectedInventory?.task || null}
                                handleChange={(value: any) => {
                                    setSelectedInventory({
                                        ...selectedInventory,
                                        task: value,
                                    });
                                }}
                                
                            />
                        </div>

                        <div className="input_row">
                            <AutocompleteMulti
                                setSelectedItems={(items: any) => {
                                    setSelectedInventory({
                                        ...selectedInventory,
                                        user: items[0],
                                    });
                                }}
                                selectedItems={selectedInventory.user ? [selectedInventory.user] : []}
                                limit={1}
                                style={{width: '100%'}}
                            />
                        </div>

                        <DialogContent sx={{p: '10px 0'}}>
                            <strong>PRODUCTS</strong>
                        </DialogContent>
                        
                        {selectedInventory.used_inventory.length !== 0 && (
                        <div
                            id="products_list"
                            className="form input_group"
                        >
                            {selectedInventory.used_inventory.map((item: any, index: number) => (
                            <div className="input_row" key={index}>
                                <AutocompleteProducts
                                    size='small'
                                    fullWidth
                                    setLoading={setLoading}
                                    value={item}
                                    handleChange={(value: any) => {
                                        let new_used_inventory = [...selectedInventory.used_inventory];
                                        const new_product = {
                                            ...new_used_inventory[index],
                                            product_id: value.product_id,
                                            product_name: value.product_name,
                                        };
                                        new_used_inventory[index] = new_product;

                                        setSelectedInventory({
                                            ...selectedInventory,
                                            used_inventory: [...new_used_inventory],
                                        });
                                    }}
                                />

                                <TextField
                                    label="Quantity"
                                    type="number"
                                    size='small'
                                    fullWidth
                                    variant="outlined"
                                    value={item.quantity || ''}
                                    onChange={(event: any) => {
                                        if (!isNaN(event.target.value)) {
                                            let new_used_inventory = [...selectedInventory.used_inventory];
                                            const new_product = {
                                                ...new_used_inventory[index],
                                                quantity: Number(event.target.value),
                                            };
                                            new_used_inventory[index] = new_product;

                                            setSelectedInventory({
                                                ...selectedInventory,
                                                used_inventory: [...new_used_inventory],
                                            });
                                        }
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker className='filters_datePicker'
                                        label={"Date"}
                                        value={item.date}
                                        showToolbar={false}
                                        closeOnSelect
                                        onChange={(newValue) => {
                                            if (newValue) {
                                                let new_used_inventory = [...selectedInventory.used_inventory];
                                                const new_product = {
                                                    ...new_used_inventory[index],
                                                    date: newValue.format('YYYY-MM-DD'),
                                                };
                                                new_used_inventory[index] = new_product;

                                                setSelectedInventory({
                                                    ...selectedInventory,
                                                    used_inventory: [...new_used_inventory],
                                                });
                                            }
                                        }}
                                        renderInput={(params) => <TextField size='small' fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            
                                <IconButton
                                    color="secondary"
                                    size="small"
                                    
                                    onClick={() => {
                                        let new_inventory = {...selectedInventory};
                                        if (item.task_product_id !== 'null') {
                                            new_inventory.deleted_products = [
                                                ...new_inventory.deleted_products,
                                                item.task_product_id,
                                            ];
                                        }

                                        const new_used_inventory = new_inventory.used_inventory.filter((usedItem: any, usedIndex: number) => {
                                            return usedIndex !== index;
                                        });
                                        
                                        setSelectedInventory({...new_inventory, used_inventory: new_used_inventory});
                                    }}
                                >
                                    <DeleteOutlined/>
                                </IconButton>
                            </div>
                            ))}
                        </div>
                        )}

                        <div className="form input_group" style={{marginBottom: '10px'}}>
                            <div className="input_row">
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    startIcon={<Add />}
                                    onClick={() => {
                                        setSelectedInventory({
                                            ...selectedInventory,
                                            used_inventory: [
                                                ...selectedInventory.used_inventory,
                                                {
                                                    ...defaultProduct,
                                                    frontend_task_product_id: frontend_task_product_id,
                                                }
                                            ],
                                        });

                                        setFrontend_task_product_id(frontend_task_product_id + 1);
                                    }}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="buttons">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setOpenDialogProductInventory(false)
                            }
                        }>
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                console.log(checkInventory());
                                if (!checkInventory()) {
                                    handleConfirmInventory(true);
                                }
                            }}
                        >
                            Save
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (!checkInventory()) {
                                    handleConfirmInventory(false);
                                }
                            }
                        }>
                            Save and confirm
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <AlertDialog
                open={openAlertCompleted}
                setOpen={setOpenAlertCompleted}
                subtitle="Are you sure you want to reject this task as completed?"
                send={rejectTask}
            />

            <AlertDialog
                open={openDialogCompleted}
                setOpen={setOpenDialogCompleted}
                subtitle="Are you sure you want to approve this task as completed?"
                send={confirmTask}
            />
        </>
    );
}