import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IProductQuoteView, IStoneFields, QuotesContext } from '../QuoteContextProvider';
import { Badge, CircularProgress, Dialog, DialogContent, IconButton, InputAdornment, TableCell, TableRow, TextField, Tooltip, debounce } from '@mui/material';
import AutocompleteProduct from 'components/Projects/utils/AutoCompleteProduct';
import ProductList from "components/Products/ProductList";
import AutocompleteSections from 'components/Projects/utils/AutoCompleteSections';
import { Circle, DeleteOutlineOutlined } from '@mui/icons-material';
import ProductEditor from 'components/Products/ProductEditor';

const defaultValues: IProductQuoteView = {
    product_name: "",
    caso_code: "",
    quantity: 0,
    section: "",
    unit_price: 0,
    total_price: 0,
};

export default function QuoteProductRow ( props: any ) {

    const {
        categoriesFilters,
        updateProduct,
        deleteProduct,
        sectionList,
        setSectionList,
        deleteSection,
        productDict,
        buildUniqueId,
        fixNumberValue,
        setOpenProductList,
        setOpenProductEditor,
        setSelectedItem,
        setInputValueToDialogs,
        productAddedTrigger,
        setLoadingFields,
    } = useContext(QuotesContext)

    const {
        serviceIndex,
        category,
        itemIndex,
        key = undefined,
    } = props;

    const [item, setItem] = useState<IProductQuoteView>(defaultValues);
    const refQuantityHandler = useRef<any>();

    const [updatedQuantity, setUpdatedQuantity] = useState<boolean>(true);

    useEffect(() => {
        if (props) {
            const productList = productDict[buildUniqueId(serviceIndex, category)];
            if (productList) {
                updateItem(productList[itemIndex]);
            } else {
                initValues();
            }
        };
    }, [props]);

    const initValues = () => {
        setItem({...defaultValues});
        setUpdatedQuantity(true);
        setLoadingFields(false);
    };

    const updateItem = (updatedItem: IProductQuoteView) => {
        if (!updatedQuantity && updatedItem.quantity !== item.quantity) return;

        setItem((prevState) => ({
            ...prevState,
            ...updatedItem,
        }));
        setUpdatedQuantity(true);
        setLoadingFields(false);
    };

    const onChangeQuantity = () => {
        handleValueChange(item.quantity, 'quantity');
    }

    useEffect(() => {
        refQuantityHandler.current = onChangeQuantity;
    }, [onChangeQuantity]);

    const overrideQuantity = () => {
        if (updatedQuantity) return;

        refQuantityHandler.current?.();
    }

    const handleProductChange = (newProduct: any) => {
        const newProductChanges: IProductQuoteView = {
            product_id: newProduct.product_id,
            product_name: newProduct.product_name,
            caso_code: newProduct.caso_code,
            unit_price: newProduct.sale_price,
        };

        if (category === 'stone' && newProduct.stone_fields) {
            const stone_fields: IStoneFields = {
                price_with_manufacture: newProduct.stone_fields.price_with_manufacturing,
                price_without_manufacture: newProduct.stone_fields.price_without_manufacturing,
            };

            newProductChanges.stone_fields = stone_fields;
        }
        
        updateProduct(newProductChanges, serviceIndex, category, itemIndex);
    };

    const handleValueChange = (value: any, field: string) => {
        const newProductChanges = {
            [field]: value,
        };

        updateProduct(newProductChanges, serviceIndex, category, itemIndex);
    };

    return (
        <>
            <tr key={key}>
                <td className="th_product table_quotes_td">
                    <AutocompleteProduct
                        setProduct={handleProductChange}
                        product_name={item.product_name}
                        caso_code={item.caso_code}
                        category_filter={categoriesFilters[category]?.category_filter}
                        dotsClick={() => {
                            setSelectedItem({
                                serviceIndex: serviceIndex,
                                category: category,
                                itemIndex: itemIndex,
                            });
                            setOpenProductList(true);
                        }}
                        infoToShow={item.product_description || undefined}
                        productAsObj={true}
                        addProduct={(e: any) => {
                            if (typeof e === 'string') setInputValueToDialogs(e);
                            setSelectedItem({
                                serviceIndex: serviceIndex,
                                category: category,
                                itemIndex: itemIndex,
                            });
                            setOpenProductEditor(true);
                        }}
                        addedTrigger={productAddedTrigger}
                        withImageViewerButton={true}
                    />
                </td>

                <td className="th_quantity table_quotes_td">
                    <TextField 
                        label="Quantity" 
                        fullWidth
                        size='small'
                        InputLabelProps={{
                            style: { fontSize: '12px' }
                        }}
                        value={item.quantity}
                        InputProps={{
                            style: {
                                fontSize: '12px',
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    {!updatedQuantity && true ? // loadingFields ?
                                        <Tooltip title={"Press enter or leave the field to confirm"}>
                                            <Circle
                                                color="secondary"
                                                className='field_badge'
                                            />
                                        </Tooltip>
                                        :
                                        null
                                    }
                                </InputAdornment>
                            )
                        }}
                        onChange={ (e) => {
                            setItem((prevState: IProductQuoteView) => ({
                                ...prevState,
                                quantity: fixNumberValue(e.target.value, true, false, true),
                            }));

                            setUpdatedQuantity(false);
                        }}
                        onBlur={overrideQuantity}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                overrideQuantity();
                            }
                        }}

                    />
                </td>

                <td className="th_quote_section table_quotes_td">
                    <AutocompleteSections
                        setSectionList={setSectionList}
                        setSection={(section: any) => handleValueChange(section, 'section')}
                        section={item.section}
                        sectionList={sectionList}
                        onDeleteSection={deleteSection}
                    />
                </td>
                
                
                
                <td className="align_right"> ${item.unit_price} </td>
                <td className="align_right"> ${((item.unit_price || 0) * (item.quantity || 0)).toLocaleString()} </td>

                
                
                <td className="align_right">
                    <IconButton
                        className="btn_delete_row"
                        aria-label="delete"
                        color="primary"
                        onClick={() => deleteProduct(serviceIndex, category, itemIndex)}
                    >
                        <DeleteOutlineOutlined />
                    </IconButton>
                </td>
            </tr>
        </>
    );
    
}