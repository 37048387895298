import { Autocomplete, Button, createFilterOptions, Dialog, DialogContent, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from 'icons/ic_dots.svg';
import { ProjectConfig } from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import AutocompleteMulti from "elements/AutocompleteMulti";
import ProjectHeader from "components/Projects/utils/ProjectHeader";
import TaskDialog from "./TaskDialog";

import 'assets/css/main.css';
import { useSnackbar } from "notistack";
import AutocompleteProjects from "elements/Autocompletes/AutocompleteProjects";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    style={props.size == 'small' ? {} : {}}/>
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const defaultValues: any = {
  task_id: "null",
  project_id: "",
  task_name: "",
  task_start_date: null,
  task_deadline: null,
  task_stage_id: "",
  is_subcontract: false,
  subcontract_value: "0",
  users: [],
  task_status_id: "0",
};

export default function TaskEditor (props: any) {
  const {
    setLoading,
    setMenuTopLeftContent,
    asPopup = false,
    with_set_top_menu = false,
  } = props;

  useEffect(() => {setLoading(false);}, []);

  const {enqueueSnackbar, closeSnackbar } = useSnackbar();

  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let { project_id, task_id } = useParams();
  task_id = props.task_id ? props.task_id : task_id;
  /*=======================================
  =======================================*/

  const [formValues, setFormValues] = useState({...defaultValues, task_id: task_id? task_id : "null",})
  
  
  const [openConflicts, setOpenConflicts] = useState(false);
  const [totalConflicts, setTotalConflicts] = useState(0);
  const [conflictedTaskList, setConflictedTaskList] = useState<any[]>([]);
  
  const [withEstamate, setWithEstamate] = useState(false);
  const [estimateHours, setEstimateHours] = useState(0);

  useEffect(() => {
    if (project_id) {
      setFormValues({
        ...formValues,
        ["project_id"]: project_id,
      });

      setProject({
        project_name: '',
        project_id: Number(project_id),
      })
    }
  }, [project_id]);
  
  const setUsers = (items: any) => {
    setFormValues({
      ...formValues,
      users: items,
    });
  }

  useEffect(() => {
    if (withEstamate && formValues.task_start_date) {
      if (estimateHours <= 0) {
        setEstimateHours(1);
      }
      const hoursBussinessDay = 10;
      const hoursSaturnday = 7;
      const hoursWeek = hoursBussinessDay * 5 + hoursSaturnday;

      const numWeeks = Math.trunc(estimateHours / hoursWeek);
      let remainingHours = estimateHours - (numWeeks * hoursWeek);

      const addWeeks = (date: Date, amountWeeks: number) => {
        date.setDate(date.getDate() + amountWeeks * 7);
        return date;
      }

      const addDays = (date: Date, ammountDays: number) => {
        date.setDate(date.getDate() + ammountDays);
        return date;
      }
      let deadline = new Date(formValues.task_start_date.replace(/-/g, '\/'));

      deadline = addWeeks(deadline, numWeeks);

      while (remainingHours > 0) {
        if (deadline.getDay() === 6) {
          if (remainingHours > hoursSaturnday) {
            deadline = addDays(deadline, 2);
          }
          remainingHours -= hoursSaturnday;
        } else if (deadline.getDay() === 0) {
          deadline = addDays(deadline, 1);
        } else if (deadline.getDay() > 0 && deadline.getDay() < 6) {
          if (remainingHours > hoursBussinessDay){
            deadline = addDays(deadline, 1);
          }
          remainingHours -= hoursBussinessDay;
        }
      }
      
      setFormValues({
        ...formValues,
        task_deadline: deadline,
      });
    }
  }, [estimateHours, formValues.task_start_date, withEstamate]);

  const navigate = useNavigate();

  /* =================================================
  CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
  =================================================*/
  interface IProjectList {
    inputValue?: string,
    project_id?: number,
    project_name: string,
  };
  const filter = createFilterOptions<IProjectList>();

  //setClientList
  const [project_list, setProjectList] = useState<any []>([]);
  const [project, setProject] = useState<IProjectList | null>(null);
  
  useEffect(() => {
    setLoading(true)
    axios.get(ProjectConfig.api_url,
    {
      params: {
        request: 'project_list',
        params: {
          full_list: true,
        },
      }
    })
      .then((response) => {
        setProjectList(response.data.list)
        setLoading(false)
          
      })
      .catch((err) => {
        console.log(err.data);
      })
  }, [])
  /* ================================================= 
  ===================================================*/

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    
    setLoading(true)
    if (formValues.is_subcontract && formValues.subcontract_value === "") {
      alert("Please enter a subcontract value")
      return
    }
    
    axios.post(ProjectConfig.api_url, [{...formValues, with_estimate: withEstamate, estimate_hours: estimateHours}],
      {
        params: {
          request: 'add_edit_task',
        }
      })
      .then((response) => {
        console.log("response",response.data);
        if (response.data.status === "success") {
          const callback = props.onSubmitCallback ? props.onSubmitCallback() : navigate(-1)
        } else {
          setConflictedTaskList([...response.data.conflicts]);
          setTotalConflicts(response.data.total_conflicts);
          setOpenConflicts(true);
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  const handleOpenClientList = () => {
    setOpen(true);
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (task_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "task_details",
            task_id: task_id,
          },
        })
        .then((response) => {
          console.log("res",response.data)
          setFormValues(response.data);
          setProject({
            project_name: project_list.find(obj => {
                return obj.project_id === response.data.project_id; 
              }).project_name,
          });
          setWithEstamate(response.data.with_estimate);
          setEstimateHours(response.data.estimate_hours);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, [project_list, task_id]);

  const handleCheckForm = () => {
    let errors = false;

    /* VALIDATE THE FIELDS 
    task_name
    task_start_date
    task_deadline
    project_id
    task_stage_id
    is_subcontract
    subcontract_value
    users
    task_status_id
    */

    // VALIDATE TASK NAME
    if (formValues.task_name === "") {
      errors = true;
      enqueueSnackbar("Please enter a task name", { variant: "error" });
    }

    // VALIDATE DATES
    if (formValues.task_start_date === "" || formValues.task_deadline === "" || formValues.task_start_date === null || formValues.task_deadline === null) {
      errors = true;
      enqueueSnackbar("Please enter a start date and deadline", { variant: "error" });
    } else {
      const startDate = new Date(formValues.task_start_date.replace(/-/g, '\/')).getTime();
      
      if (typeof formValues.task_deadline === "string" && startDate > new Date(formValues.task_deadline.replace(/-/g, '\/')).getTime()) {
        errors = true;
        enqueueSnackbar("The deadline must be after the start date", { variant: "error" });
      } else if (typeof formValues.task_deadline === "object" && startDate > formValues.task_deadline.getTime()) {
        errors = true;
        enqueueSnackbar("The deadline must be after the start date", { variant: "error" });
      }
    }

    // VALIDATE STAGE
    if (formValues.task_stage_id === "") {
      errors = true;
      enqueueSnackbar("Please select a stage", { variant: "error" });
    }

    // VALIDATE PROJECT
    if (formValues.project_id === "") {
      errors = true;
      enqueueSnackbar("Please select a project", { variant: "error" });
    }

    // VALIDATE STATUS
    if (formValues.task_status_id === "") {
      errors = true;
      enqueueSnackbar("Please select a status", { variant: "error" });
    }

    // VALIDATE USERS
    if (formValues.users.length === 0) {
      errors = true;
      enqueueSnackbar("Please select at least one user", { variant: "error" });
    }

    // VALIDATE SUBCONTRACT
    if (formValues.is_subcontract && (formValues.subcontract_value === "" || formValues.subcontract_value === null || formValues.subcontract_value === undefined || formValues.subcontract_value === "0")) {
      errors = true;
      enqueueSnackbar("Please enter a subcontract value", { variant: "error" });
    }

    // VALIDATE ESTIMATE
    if (formValues.withEstamate && formValues.estimateHours === "") {
      errors = true;
      enqueueSnackbar("Please enter an estimate", { variant: "error" });
    }

    return errors;
  }

  return (
    <>
    <div>
      {!asPopup &&
        <ProjectHeader />
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{"& .MuiDialog-paper": {width: "fit-content", maxWidth: "unset",}}}
      >
        <DialogContent sx={{outline:'none', p: 0, maxWidth: 'unset', width: 'fit-content'}}>
          <Box id='project_list' title="Clients" subtitle="List"  >
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Project name</TableCell>
                  <TableCell>Client</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {project_list.map((project,i) => (
                    <TableRow 
                      onClick={() => (
                      setProject({
                        project_name: project.project_name,
                      }),
                      setFormValues({
                        ...formValues,
                        ["project_id"]: project.project_id.toString()
                      }),
                      handleClose()
                      )} 
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell>{project.project_name}</TableCell>
                      <TableCell>{project.client_name}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Box>
        </DialogContent>
      </Dialog>
    
    <form>
      <Box id="project_editor" className="box_editor" title="Task" subtitle="Edit"  >
        <div className='form'>
          <div className='input_group'>
            <TextField 
              id="task_name"
              name="task_name"
              label="Task Name"
              type="text"
              required
              fullWidth
              value={formValues.task_name}
              onChange={handleInputChange}
            />
            </div>
            {project_id == null &&
            <div className='input_group'>
                <AutocompleteProjects
                    fullWidth
                    setLoading={setLoading}
                    value={project}
                    handleChange={(value: any) => {
                        setProject(value);
                        setFormValues({
                            ...formValues,
                            project_id: value.project_id,
                        });
                    }}
                />  
            </div>
            }

          <div className='input_group'>
            
            <div className="input_row">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  label="Start date"
                  inputFormat="MMMM DD, yyyy"
                  value={formValues.task_start_date}
                  InputProps={{
                    endAdornment: (
                      <EventIcon />
                    ),
                  }}
                  showToolbar={false}
                  closeOnSelect 
                  onChange={(newValue) => {
                    setFormValues({
                      ...formValues,
                      ["task_start_date"]: (newValue).format('YYYY-MM-DD')
                    });
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>

              <TextField
                  id="task_estimated_hours"
                  name="task_estimated_hours"
                  label="Estimated hours"
                  type="number"
                  required
                  fullWidth
                  disabled={!withEstamate}
                  value={formValues.task_estimated_hours}
                  onChange={(e) => {
                    handleInputChange(e)
                    setEstimateHours(Number(e.target.value))
                  }}
                  InputProps={{
                    endAdornment: (
                        <IOSSwitch
                          disabled={false}
                          checked={withEstamate}
                          sx={{ m: 1 }}
                          onChange={(e) => {
                            setWithEstamate(e.target.checked)
                          }
                        }
                        />
                    ),
                  }}
              />

              <LocalizationProvider dateAdapter={AdapterMoment}>

                <MobileDatePicker
                  label="Deadline"
                  inputFormat="MMMM DD, yyyy"
                  value={formValues.task_deadline}
                  disabled={!formValues.task_start_date || formValues.task_start_date == null || withEstamate}
                  InputProps={{
                    endAdornment: (
                      <EventIcon />
                    ),
                  }}
                  showToolbar={false}
                  closeOnSelect 
                  onChange={(newValue) => {
                    setFormValues({
                      ...formValues,
                      ["task_deadline"]: (newValue).format('YYYY-MM-DD')
                    });
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />

              </LocalizationProvider>
            </div>

            <div className="input_row">
              <FormControl fullWidth>
                <InputLabel id="lbl_product_category">Stage</InputLabel>
                <Select
                  labelId="product_category"
                  id="task_stage_id"
                  value={formValues.task_stage_id}
                  label="Stage"
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      ["task_stage_id"]: e.target.value
                    });
                  }}
                >

                  <MenuItem value={1}>Design</MenuItem>
                  <MenuItem value={2}>Manufacturing</MenuItem>
                  <MenuItem value={3}>Hardware Assembly</MenuItem>
                  <MenuItem value={4}>Finishing</MenuItem>
                  <MenuItem value={5}>Installation</MenuItem>
                  <MenuItem value={6}>Stone</MenuItem>

                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="lbl_task_status">Status</InputLabel>
                <Select
                  labelId="lbl_task_status"
                  id="task_status_id"
                  value={formValues.task_status_id}
                  label="Status"
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      ["task_status_id"]: e.target.value
                    });
                  }}
                >

                  <MenuItem value={0}>Not Started</MenuItem>
                  <MenuItem value={1}>In Progress</MenuItem>
                  <MenuItem value={2}>Completed</MenuItem>

                </Select>
              </FormControl>
            </div>

            <FormControlLabel
              checked={formValues.is_subcontract}
              
              control={
                <IOSSwitch 
                  sx={{ m: 1 }}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      ["is_subcontract"]: e.target.checked
                    });
                  }
                  }
                />
            }
              label="Subcontract"
            />

            <TextField 
              id="subcontract_value"
              name="subcontract_value"
              label="Subcontract Value"
              type="number"
              fullWidth
              value={formValues.subcontract_value}
              onChange={handleInputChange}
              sx={{ display: !formValues.is_subcontract ? "none" : "block" }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />

            <h2>Workers Assigned</h2>

              <AutocompleteMulti
                setSelectedItems={setUsers}
                selectedItems={formValues.users}
                fullWidth
              />
          </div>
        </div>

        <div className='buttons'>
          <Button
            onClick={() => {
              const c = props.onSubmitCallback? props.onSubmitCallback() : navigate(-1);
            }}
            variant="outlined"
            className="button_2"
          >
            Cancel
          </Button>

          <Button
            onClick={ () => {
              if (!handleCheckForm()) {
                handleSubmit()
              }
            }}
            variant="contained"
            className="button_1">Submit</Button>
        </div>
      </Box>
    </form>
    </div>
    
    <TaskDialog
      open={openConflicts}
      setOpen={setOpenConflicts}
      list={conflictedTaskList}
      setLoading={setLoading}
      totalConflicts={totalConflicts}
      onSubmitCallback={props.onSubmitCallback}
    />
    </>
    
  );
};


