import { Button, TextField } from '@mui/material';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import ACSupplierList from 'elements/ACSupplierList';
import Box from 'elements/Box';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

const defaultValues = {
    appliance_id: 'null',
    name: '',
    reference: '',
    price: 0,
    supplier_id: 0,
};

export default function ApplianceEditor( props:any ) {
    
    const {} = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const { appliance_id = null } = useParams();

    const [formValues, setFormValues] = useState({...defaultValues});

    useEffect(() => {
        if (appliance_id) {
            loadData();
        }
    }, []);

    const loadData = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: "get_appliance_details",
                appliance_id: appliance_id,
            }
        })
        .then((response) => {
            console.log(response.data);
            setFormValues({...response.data});
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleCheckForm = () => {

        let error = false;

        if (formValues.name === '') {
            error = true;
            enqueueSnackbar('Name is required', { variant: 'error' });
        }
        if (formValues.reference === '') {
            error = true;
            enqueueSnackbar('Reference is required', { variant: 'error' });
        }
        if (formValues.price === 0) {
            error = true;
            enqueueSnackbar('Price is required', { variant: 'error' });
        }
        if (formValues.supplier_id === 0) {
            error = true;
            enqueueSnackbar('Supplier is required', { variant: 'error' });
        }
        if (formValues.price < 0) {
            error = true;
            enqueueSnackbar('Price must be greater than 0', { variant: 'error' });
        }

        return error;
    };

    const handleSubmit = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, formValues, {
            params: {
                request: 'add_edit_appliance',
            },
        })
        .then((response) => {
            console.log(response.data);

            if (formValues.appliance_id === 'null') {
                enqueueSnackbar('Appliance added', { variant: 'success' });
            } else {
                enqueueSnackbar('Appliance saved', { variant: 'success' });
            }
            navigate('/appliance', {replace: true});
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <form>
            <Box id="appliance_editor" className="box_editor" title="Appliance" subtitle="Edit">
                <div className='form'>
                    <div className='form__row'>
                        <TextField 
                            id="appliances_name"
                            name="name"
                            label="Name"
                            fullWidth
                            type="text"
                            required
                            value={formValues.name}
                            onChange={handleInputChange}
                        />
                    </div>
                    
                    <div className='form__row'>
                        <TextField 
                            id="appliances_reference"
                            name="reference"
                            label="Reference"
                            fullWidth
                            type="text"
                            required
                            value={formValues.reference}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className='form__row'>
                        <TextField 
                            id="appliances_price"
                            name="price"
                            label="Price"
                            fullWidth
                            type="number"
                            required
                            value={formValues.price}
                            onChange={handleInputChange}
                        />
                    </div>
                    
                    <div className='form__row'>
                        <ACSupplierList
                            onChange={handleInputChange}
                            value={formValues.supplier_id}
                        />
                    </div>
                </div>

                <div className='buttons'>
                    <Button
                        onClick={() => {
                            navigate('/appliance', {replace: true})
                        }}
                        variant="outlined"
                        className="button_2"
                    >
                        Back
                    </Button>
                    
                    <Button
                        onClick={() => {
                            if(handleCheckForm()) return;
                            handleSubmit();
                        }}
                        variant="contained"
                        className="button_1"
                    >
                        Submit
                    </Button>
                </div>
            </Box>
        </form>
    );
}