import React from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import {
    TextField,
    Link
} from '@mui/material';

import Box from 'elements/Box';
import { ProjectConfig } from 'Global';
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();

export default function FormLogin( props: any ) {

    const {
        loading,
        logo,
        setLoading,
        setState,
        email,
        setEmail,
    } = props;

    const navigate = useNavigate();
    
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    /*================================================
    SIGN IN (SUBMIT)
    ================================================*/
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      setLoading(true);
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "sign_in",
            email: data.get('email'),
            password: data.get('password'),
          },
        })
        .then((response) => {
          
          if(response.data.status === "success"){
            
            enqueueSnackbar(response.data.message, { variant: 'success' });
            const user_data = response.data.user_data;
            console.log(user_data);

            /*================================================
            SET COOKIES
            ================================================*/
            cookies.set('ltkn', user_data.ltkn, { path: '/', maxAge: (86400 * 365), domain: window.location.hostname });
            cookies.set('user_id', user_data.user_id, { path: '/', maxAge: (86400 * 365), domain: window.location.hostname });
            cookies.set('user_name', user_data.user_name, { path: '/', maxAge: (86400 * 365), domain: window.location.hostname });
            cookies.set('role_id', user_data.role_id, { path: '/', maxAge: (86400 * 365), domain: window.location.hostname });

            cookies.set('update_version', user_data.role_id, { path: '/', maxAge: (86400 * 365), domain: window.location.hostname });
            /*================================================
            ================================================*/

            localStorage.setItem('user_id', user_data.user_id);
            localStorage.setItem('user_name', user_data.user_name);
            localStorage.setItem('user_image', user_data.image);
            localStorage.setItem('role_id', user_data.role_id);


            navigate('/', { replace: true });
          }
          else
          {
            enqueueSnackbar(response.data.message, { variant: 'error' });
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.data);
        });

    
  };
  /*================================================
  ================================================*/


    return(
        <Box id='loginBox'>
          <img className='loginLogo' src={logo}></img>

          <p>Enter your email address and password to access your account.</p>
          <form onSubmit={handleSubmit} className='form'>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type="text"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              />
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              />
            <LoadingButton
              size="small"
              type="submit"
              loading={loading}
              fullWidth
              variant="contained"
              sx={{ fontSize: '13px', padding: '10px 0px'}}
              >
              Sign In
            </LoadingButton>

            <Link
                component="button"
                variant="body2"
                onClick={() => {
                  setState('forgotPass')
                }}>
              Forgot password?
            </Link>
          </form>
        </Box>
    );
}