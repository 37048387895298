import React, { useContext } from "react";
import { Badge, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { SaveOutlined } from "@mui/icons-material";

import { QuotesContext } from "./QuoteContextProvider";

export default function QuoteDrawerButtons ( props:any ) {

    const {
        handleSubmit = () => {},
        wasModified = false,
    } = useContext(QuotesContext);

    const {
        drawerOpen = false,
    } = props;

    const ListItemIconStyles = {
        minWidth: 0,
        mr: drawerOpen ? 3 : "auto",
        justifyContent: "center",
    };

    const ListItemButtonStyles = {
        minHeight: 48,
        justifyContent: drawerOpen ? "initial" : "center",
        px: 2.5,
    };

    return (
        <>
            <List>
                <ListItem key="save" disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                        sx={ListItemButtonStyles}
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        <ListItemIcon sx={ListItemIconStyles}>
                            <Badge
                                color="secondary"
                                variant="dot"
                                invisible={wasModified ? false : true}
                            >
                                <SaveOutlined />
                            </Badge>
                        </ListItemIcon>

                        <ListItemText
                            primary="SAVE"
                            sx={{ opacity: drawerOpen ? 1 : 0 }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </>
    );

}