import Box from 'elements/Box';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ChartDataLabels,
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    datalabels: {
      display: false,
    },
  },
};

const stylesData = [
  {
    borderColor: 'rgb(168, 193, 134)',
    backgroundColor: 'rgba(168, 193, 134, 0.3)',
    borderWidth: 2,
    fill: true,
    lineTension: 0.4,
  },
  {
    borderColor: 'rgb(230, 153, 75)',
    backgroundColor: 'rgba(230, 153, 75, 0.3)',
    borderWidth: 2,
    fill: true,
    lineTension: 0.4,
  },
  {
    borderColor: 'rgb(64, 142, 243)',
    backgroundColor: 'rgba(64, 142, 243, 0.3)',
    borderWidth: 2,
    fill: true,
    lineTension: 0.4,
  },
]

export default function CostChart (props: any) {
  
  const {} = props;

  const decoratorData = (data: any) => {
    return {
      labels: data.labels,
      datasets: data.datasets.map((item: any, index: number) => {
        return {...item, ...stylesData[index],};
      }),
    };
  };

  const [data, setData] = useState<any>({...decoratorData(props.data)});

  useEffect(() => {
    setData({...decoratorData(props.data)});
  }, [props.data]);

  useEffect(() => {
    if (data.labels.length <= 1) {
      setData({
        ...data,
        labels: [...data.labels, ...data.labels.map((item: any) => item + ' ')],
        datasets: data.datasets.map((item: any) => {
          return {
            ...item,
            data: [
              ...item.data,
              ...item.data
            ],
          };
        }),
      });
    }
  }, [data]);
  
  return (
    <Box id="financial_graph">
        <Line options={options} data={data} style={{maxWidth: '100%', minWidth: '500px'}} />
    </Box>
  );
};