import React, { useContext, useEffect, useState } from 'react';
import Box from 'elements/Box';
import { IProductExplodedView, IStoneFields, QuotesContext } from '../QuoteContextProvider';
import ExplodedMeasureRow from './ExplodedMeasureRow';
import AutocompleteProduct from 'components/Projects/utils/AutoCompleteProduct';
import { Badge, Button, CircularProgress, Tooltip } from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';

const defaultValues: IProductExplodedView = {
    product_name: "",
    caso_code: "",
    quantity: 0,
    measures: [],
    unit_price: 0,
    total_price: 0,
};

export default function ExplodedProductBox ( props: any ) {

    const {
        buildUniqueId,
        productDict,
        measureDict,
        updateProduct,
        addMeasureToItem,
        categoriesFilters,
        setOpenProductList,
        setOpenProductEditor,
        setSelectedItem,
        setInputValueToDialogs,
        productAddedTrigger,
        loadingFields,
        loadingFieldsDict,
    } = useContext(QuotesContext);

    const {
        serviceIndex,
        category,
        itemIndex,
        serviceType,
        key = undefined,
    } = props;

    const [item, setItem] = useState<IProductExplodedView>(defaultValues);

    useEffect(() => {
        if (props) {
            const productList = productDict[buildUniqueId(serviceIndex, category)];
            if (productList) {
                const measureList = measureDict[buildUniqueId(serviceIndex, category)];
                if (measureList) {
                    setItem({
                        ...productList[itemIndex],
                        measures: measureList[itemIndex],
                    });
                } else {
                    setItem({
                        ...productList[itemIndex],
                        measures: [],
                    });
                }
            } else {
                setItem({...defaultValues});
            }
        }
    }, [props]);
    
    const handleMatch = item.quantity == Math.ceil((item.measures || []).reduce( (subTotal: number, measure: any) => subTotal + (parseFloat(measure.sheets || 0)), 0));

    const handleProductChange = (newProduct: any) => {
        const newProductChanges: IProductExplodedView = {
            product_id: newProduct.product_id,
            product_name: newProduct.product_name,
            caso_code: newProduct.caso_code,
            unit_price: newProduct.sale_price,
        };

        if (category === 'stone' && newProduct.stone_fields) {
            const stone_fields: IStoneFields = {
                price_with_manufacture: newProduct.stone_fields.price_with_manufacturing,
                price_without_manufacture: newProduct.stone_fields.price_without_manufacturing,
            };

            newProductChanges.stone_fields = stone_fields;
        }

        updateProduct(newProductChanges, serviceIndex, category, itemIndex);
    };


    return (
        <>
            <div key={key} className="explodedItem">
                <Box className="sectionProductRow">
                    <div className="sectionProductRowHeader">
                        <div className="sectionProductRowHeaderRight">
                            <AutocompleteProduct
                                setProduct={handleProductChange}
                                product_name={item.product_name}
                                caso_code={item.caso_code}
                                category_filter={categoriesFilters[category]?.category_filter}
                                dotsClick={() => {
                                    setSelectedItem({
                                        serviceIndex: serviceIndex,
                                        category: category,
                                        itemIndex: itemIndex,
                                    });
                                    setOpenProductList(true);
                                }}
                                infoToShow={item.product_description || undefined}
                                productAsObj={true}
                                addProduct={(e: any) => {
                                    if (typeof e === 'string') setInputValueToDialogs(e);
                                    setSelectedItem({
                                        serviceIndex: serviceIndex,
                                        category: category,
                                        itemIndex: itemIndex,
                                    });
                                    setOpenProductEditor(true);
                                }}
                                addedTrigger={productAddedTrigger}
                                withImageViewerButton={true}
                            />
                        </div>
                    </div>
                    
                    <div className="sectionProductRowBody">
                        <div className="sectionProductRowBodyFlex">
                            <table className="measureRowtable">
                                <thead>
                                    <tr>
                                        <th style={{width: '200px'}}>Measure</th>
                                        <th>Quantity</th>
                                        {serviceType === 'stone' && <th>Sq Ft</th>}
                                        <th>Description</th>
                                        <th>{serviceType !== 'stone' ? "Sheets" : "Slabs"}</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {item.measures?.map((measure: any, measureIndex: number) => (
                                        <ExplodedMeasureRow
                                            serviceIndex={serviceIndex}
                                            category={category}
                                            itemIndex={itemIndex}
                                            measureIndex={measureIndex}
                                            serviceType={serviceType}
                                            key={measureIndex}
                                        />
                                    ))}
                                </tbody>
                            </table>

                            <div className="measureRowQuantity">
                                <Tooltip title={ handleMatch ? "" : "Warning: quantity in the quote view and the sum of sheets do not match"}>
                                    <Badge
                                        color="secondary"
                                        badgeContent={<PriorityHigh sx={{width: '12px', height: '12px', m: '0 -6px'}} />}
                                        invisible={handleMatch}
                                    >
                                        <div className="measureRowQuantityValue">
                                            {loadingFields && loadingFieldsDict[`${buildUniqueId(serviceIndex, category)}_${itemIndex}`] ?
                                                <CircularProgress color="secondary" size="2rem" />
                                            :
                                                item.quantity
                                            }
                                        </div>
                                    </Badge>
                                </Tooltip>
                                
                                <div className="measureRowQuantityLabel">
                                    { "Total " + (serviceType !== 'stone' ? "Sheets" : "Slabs")}
                                </div>
                            
                                <div className="measureRowQuantityPrice">
                                {loadingFields && loadingFieldsDict[`${buildUniqueId(serviceIndex, category)}_${itemIndex}`] ?
                                    <CircularProgress color="secondary" size="1rem" />
                                :
                                    <>${((item.unit_price || 0) * (item.quantity || 0)).toLocaleString()}</>
                                }
                                </div>
                            </div>
                        </div>

                        <Button
                            className="measure_add_button"
                            variant="outlined"
                            onClick={() => {
                                addMeasureToItem(serviceIndex, category, itemIndex, serviceType);
                            }}
                        >
                            Add measure
                        </Button>
                    </div>
                </Box>
            </div>
        </>
    );

}