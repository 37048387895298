import React, { useState, useEffect } from 'react';
import { Save } from '@mui/icons-material';
import {
    Button,
    MenuItem,
    TextField,
} from '@mui/material';
import axios from 'axios';
import Box from 'elements/Box';
import { useSnackbar } from 'notistack';
import { ProjectConfig } from 'Global';

const defaultValues = {
    board_id: 'null',
    board_name: '',
    status_id: '0',
};

export function NotesForm( props: any ) {

    const {
        setOpen = () => {},
        onSubmit = () => {},
    } = props;

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const {enqueueSnackbar } = useSnackbar();

    const [formValues, setFormValues] = useState<any>({...defaultValues});

    useEffect(() => {
        if (props.formValues) {
            setFormValues(props.formValues);
        }
    }, [props.formValues]);

    const handleSubmit = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, formValues, {
            params: {
                request: 'add_edit_notes_boards',
            }
        })
        .then((response) => {
            console.log(response.data);
            enqueueSnackbar('Saved successfully', { variant: 'success' });
            setOpen(false);
            onSubmit();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const handleInputChanges = (e: any) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    return (
        <>
            <Box
                className="forced_box_editor box100"
                title="Notes"
                subtitle="Editor"
            >
                <div className="input_group">
                    <div className="input_row">
                        <TextField
                            fullWidth
                            label="Board Name"
                            variant="outlined"
                            value={formValues.board_name}
                            onChange={(e) => {
                                handleInputChanges(e);
                            }}
                            name="board_name"
                        />
                    </div>

                    {/* <div className="input_row">
                        <TextField
                            fullWidth
                            select
                            label="Status"
                            variant="outlined"
                            value={formValues.status_id}
                            onChange={(e) => {
                                handleInputChanges(e);
                            }}
                            name="status_id"
                        >
                            <MenuItem value="1">Active</MenuItem>
                            <MenuItem value="0">Inactive</MenuItem>
                        </TextField>
                    </div> */}
                </div>

                <div className="buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Save />}
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        Save
                    </Button>
                </div>
            </Box>
        </>
    )
}