import React, { useContext, useState } from "react";
import axios from "axios";
import { Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AlertDialog from 'elements/AlertDialog';
import { ProjectConfig } from "Global";
import { History } from "@mui/icons-material";

{/* <MenuItem value={1}>Pending</MenuItem>
<MenuItem value={2}>Sent</MenuItem>
<MenuItem value={3}>Declined</MenuItem>
<MenuItem value={4}>Approved</MenuItem>
<MenuItem value={5}>Pending</MenuItem>
<MenuItem value={6}>Needs measurements</MenuItem>
<MenuItem value={7}>Needs revision</MenuItem>
<MenuItem value={8}>Pending client information</MenuItem>
<MenuItem value={9}>Design process</MenuItem>
<MenuItem value={10}>Schedule meeting</MenuItem> */}

const arrayStatus = [
    { name: "Unfinished quote" },
    { name: "Sent" },
    { name: "Declined" },
    { name: "Approved" },
    { name: "Pending" },
    { name: "Needs measurements" },
    { name: "Needs revision" },
    { name: "Pending client information" },
    { name: "Design process" },
    { name: "Schedule meeting" },
    { name: "Start Project" },
]

const defaultStatus = {
    status_id: '',
    status_name: '',
};

export default function QuoteStatus(props:any){

    const {
        onDelete,
        onChange,
        currentStatus,
        history,
    } = props;

    const [showStatusHistory, setShowStatusHistory] = useState(false);
    const [status, setStatus] = useState(currentStatus);
    const [openAlert, setOpenAlert] = useState(false);

    const startProject = () => {
        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: "start_project",
                params: {
                    quote_id: props.quote_id,
                }
            },
        })
            .then((response) => {
                console.log(response.data);
                props.setOpenProductsSeparatedDialog(true);
                props.setStartProjectData(response.data);
            })
            .catch((err) => {
                console.log(err.data);
            });
    }


    /*===================================================
    ====================================================
        RENDER =
    ====================================================
    ====================================================*/


    const handleStartProject = () => {
        onChange(status)
        setShowStatusHistory(false);
        startProject();
    }

    return (
        <>
            <div id="quoteStatusContainer">
                <h2 className='titleWIcon'><History /> Status History</h2>
                <Button
                    variant="outlined"
                    onClick={() => setShowStatusHistory(true)}
                >
                    Change Status
                </Button>
                
                {history.reverse().map((item:any, index:number) => {
                    return (
                        <div className="quoteStatusItem" key={index}>
                            <div className="quoteStatusItem__number">
                                {index+1}
                            </div>
                            <div className="quoteStatusItem__right">
                                <div className="quoteStatusItem__content">
                                    <div className="quoteStatusItem__status">
                                        {item.status_name}
                                    </div>
                                    <div className="quoteStatusItem__date">
                                        {item.date}
                                    </div>
                                </div>
                                    
                                <div className="quoteStatusItem__actions">
                                <IconButton aria-label="delete"  color="primary" onClick={() => onDelete(index)}>
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                                </div>
                                        
                            </div>
                            
                        </div>
                    )
                })}
            </div>

            <Dialog
                open={showStatusHistory}
                onClose={()=>{setShowStatusHistory(false)}}
            >
                <DialogContent
                    id="estimate_configuration"
                    className="forced_box_editor"
                >
                    <div className="DialogHeader">
                        <h2>CHANGE QUOTE STATUS</h2>
                        <span>Select the new status for this quote and click save. This process will be logged.</span>
                    </div>

                    <FormControl fullWidth>
                        <InputLabel id="lbl_product_category">Stage</InputLabel>

                        <Select
                            labelId="product_category"
                            id="task_stage_id"
                            value={status.status_id}
                            label="Stage"
                            onChange={(e) => {
                                const value = e.target.value;
                                    const content = arrayStatus[value-1].name;
                                    setStatus({
                                        status_id: value,
                                        status_name: content
                                    })
                                    console.log("status",status);
                            }}
                        >
                            <MenuItem value={1}>Unfinished quote</MenuItem>
                            <MenuItem value={2}>Sent</MenuItem>
                            <MenuItem value={3}>Declined</MenuItem>
                            <MenuItem value={4}>Approved</MenuItem>
                            <MenuItem value={5}>Pending</MenuItem>
                            <MenuItem value={6}>Needs measurements</MenuItem>
                            <MenuItem value={7}>Needs revision</MenuItem>
                            <MenuItem value={8}>Pending client information</MenuItem>
                            <MenuItem value={9}>Design process</MenuItem>
                            <MenuItem value={10}>Schedule meeting</MenuItem>
                            <MenuItem value={11}>Start Project</MenuItem>
                        </Select>
                    </FormControl>

                    <div className="buttons">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={()=>{setShowStatusHistory(false)}}
                            >
                            Cancel
                        </Button>

                        <Button 
                            variant="contained" 
                            onClick={() => {
                                if(status.status_id != 11){
                                    onChange(status)
                                    setShowStatusHistory(false);
                                }else{
                                    setOpenAlert(true);
                                }
                            }}>
                            Save
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle="Inventory stock will be updated if you change the status to 'Start Project'.
                Are you sure you want to continue?"
                send={handleStartProject}
            />
        </>
    
    );
};