import React, { useContext } from 'react';
import { QuotesContext } from '../QuoteContextProvider';
import QuoteServiceBox from './QuoteServiceBox ';
import { Button } from '@mui/material';

export default function QuoteView ( props: any ) {

    const {
        serviceDict,
        setOpenNewService,
    } = useContext(QuotesContext);
    
    /*====================================================
    ====================================================
    RENDER 
    ====================================================
    ====================================================*/
    return (
        <>
            <div id="QuoteContainter">
                {Object.keys(serviceDict).map((service:any, serviceIndex:number) => {
                    return (
                        <div key={serviceIndex}>
                            <QuoteServiceBox
                                serviceIndex={serviceIndex}
                            />
                        </div>
                    )
                })}

                <Button
                    className="dashedButton"
                    onClick={()=>{
                        setOpenNewService(true);
                    }}
                >
                    Add service
                </Button>
            </div>
        </>
    )
    
}