import Card from '../../elements/Card';
import axios from 'axios';
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Button,IconButton } from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Info, Settings } from '@mui/icons-material';

export default function ProjectsMenu (props: any) {

  const {project_id} = props;

  //check active menu 
  const checkActiveMenu = (href: string) => {
    if(window.location.pathname == href){
      return true;
    }else{
      return false;
    }
  }

  return (
    <>

      <Button
        className="btn btn-primary"
        component={Link}
        to={`/projects/${project_id}`}
        color={checkActiveMenu(`/projects/${project_id}`)? 'primary' : 'secondary'}
        startIcon={<EqualizerIcon />}
        size='small'
      >
        Dashboard
      </Button>

      <Button
        className="btn btn-primary"
        component={Link}
        to={`/projects/${project_id}/notes`}
        color={checkActiveMenu(`/projects/${project_id}/notes`)? 'primary' : 'secondary'}
        startIcon={<ViewKanbanIcon />}
        size='small'
        
      >
        Notes
      </Button>

      <Button
        className="btn btn-primary"
        component={Link}
        to={`/projects/${project_id}/estimates`}
        color={checkActiveMenu(`/projects/${project_id}/estimates`)? 'primary' : 'secondary'}
        startIcon={<SwapVerticalCircleIcon />}
        size='small'
      >
        Estimates
      </Button>

      <Button
        className="btn btn-primary"
        component={Link}
        to={`/projects/${project_id}/quotes`}
        color={checkActiveMenu(`/projects/${project_id}/quotes`)? 'primary' : 'secondary'}
        startIcon={<RequestQuoteIcon />}
        size='small'
      >
        Quotes
      </Button>

      <Button
        className="btn btn-primary"
        component={Link}
        to={`/projects/${project_id}/files`}
        color={checkActiveMenu(`/projects/${project_id}/files`)? 'primary' : 'secondary'}
        startIcon={<AttachFileIcon />}
        size='small'
      >
        Files
      </Button>

      <Button
        className="btn btn-primary"
        component={Link}
        to={`/projects/${project_id}/orders`}
        color={checkActiveMenu(`/projects/${project_id}/orders`)? 'primary' : 'secondary'}
        startIcon={<ReceiptLongIcon />}
        size='small'
        disabled
      >
        Orders
      </Button>

      {/* <Button
        className="btn btn-primary"
        component={Link}
        to={`/projects/${project_id}/client`}
        color={checkActiveMenu(`/projects/${project_id}/client`)? 'primary' : 'secondary'}
        startIcon={<PersonIcon />}
        size='small'
      >
        Client Info
      </Button> */}

      <Button
        className="btn btn-primary"
        component={Link}
        to={`/projects/${project_id}/tasks`}
        color={checkActiveMenu(`/projects/${project_id}/tasks`)? 'primary' : 'secondary'}
        startIcon={<AssignmentIndIcon />}
        size='small'
      >
        Tasks
      </Button>

      <Button
        className="btn btn-primary"
        component={Link}
        to={`/projects/${project_id}/project_information/list`}
        color={checkActiveMenu(`/projects/${project_id}/project_information/list`)? 'primary' : 'secondary'}
        startIcon={<Info />}
        size='small'
        disabled
      >
        Information
      </Button>

      <Button
        className="btn btn-primary"
        component={Link}
        to={`/projects/${project_id}/config`}
        color={checkActiveMenu(`/projects/${project_id}/config`)? 'primary' : 'secondary'}
        startIcon={<Settings />}
        size='small'
      >
        Config
      </Button>
     
    </>
  );
};

