import axios from "axios";
import { Fragment, Key, useEffect, useState } from "react";
import Box from "elements/Box";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ProjectConfig } from "Global";


export default function EstimateProductsEditor (props: any) {
  useEffect(() => {props.setLoading(false);}, []);

  let { estimate_product_id } = useParams();

  //ARRAY OF PRODUCTS
  const defaultValues = {
    estimate_product_id: estimate_product_id ? estimate_product_id : 0,
    estimate_product_name: "",
    estimate_product_prices: {
      linear: {
        low: "",
        mid: "",
        high: "",
        design_fee: [
          {
            name: "1-20 Ft",
            value: "",
          },
          {
            name: "21-40 Ft",
            value: "",
          },
          {
            name: "41-60 Ft",
            value: "",
          },
          {
            name: "61-80 Ft",
            value: "",
          },
          {
            name: "81-100 Ft",
            value: "",
          },
          {
            name: "101-120 Ft",
            value: "",
          },
          {
            name: "121-140 Ft",
            value: "",
          },
          {
            name: "141_160 Ft",
            value: "",
          },
        ],
      },
      square: {
        low: "",
        mid: "",
        high: "",
        design_fee: [
          {
            name: "1-20 Ft",
            value: "",
          },
          {
            name: "21-40 Ft",
            value: "",
          },
          {
            name: "41-60 Ft",
            value: "",
          },
          {
            name: "61-80 Ft",
            value: "",
          },
          {
            name: "81-100 Ft",
            value: "",
          },
          {
            name: "101-120 Ft",
            value: "",
          },
          {
            name: "121-140 Ft",
            value: "",
          },
          {
            name: "141-160 Ft",
            value: "",
          },
        ],
      },
    },
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [ChangeFlag, setChangeFlag] = useState(false);
  const navigate = useNavigate();

  //GET PROJECT ID FROM URL

  useEffect(() => {
    if (estimate_product_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "estimate_product_detail",
            estimate_product_id: estimate_product_id,
          },
        })
        .then((response) => {
          console.log(response.data);
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);

  const handleSubmit = () => {
    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "add_edit_estimate_product",
          formdata: formValues,
        },
      })
      .then((response) => {
        console.log(response.data);
        navigate(-1);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  return (
    <div id="ProductList">
      <div id="product_list_container" className="flex_container">
        <Box
          id="estimate_products_editor"
          className="box_editor"
          title="Estimate Product"
          subtitle="Editor"
        >
          <TextField
            id="estimate_product_name"
            label="Product Name"
            variant="outlined"
            value={formValues.estimate_product_name}
            onChange={(e) => {
              let newPrices = formValues;
              newPrices.estimate_product_name = e.target.value;
              setFormValues(newPrices);
              //TEMP FLAG BECAUSE THE PRICE ARRAY IS NOT UPDATED
              {
                ChangeFlag ? setChangeFlag(false) : setChangeFlag(true);
              }
            }}
          />
          <h2>Linear</h2>
          <div className="input_row">
            <div className="estimatePriceInput">
              <TextField
                id="linear_low"
                key="linear_low"
                label="Low"
                type="number"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formValues.estimate_product_prices.linear.low}
                onChange={(e) => {
                  let newPrices = formValues;
                  newPrices.estimate_product_prices.linear.low = e.target.value;
                  setFormValues(newPrices);
                  //TEMP FLAG BECAUSE THE PRICE ARRAY IS NOT UPDATED
                  {
                    ChangeFlag ? setChangeFlag(false) : setChangeFlag(true);
                  }
                }}
              />
            </div>

            <div className="estimatePriceInput">
              <TextField
                id="linear_mid"
                key="linear_mid"
                label="Mid"
                type="number"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formValues.estimate_product_prices.linear.mid}
                onChange={(e) => {
                  let newPrices = formValues;
                  newPrices.estimate_product_prices.linear.mid = e.target.value;
                  setFormValues(newPrices);
                  //TEMP FLAG BECAUSE THE PRICE ARRAY IS NOT UPDATED
                  {
                    ChangeFlag ? setChangeFlag(false) : setChangeFlag(true);
                  }
                }}
              />
            </div>

            <div className="estimatePriceInput">
              <TextField
                id="linear_high"
                key="linear_high"
                label="High"
                type="number"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formValues.estimate_product_prices.linear.high}
                onChange={(e) => {
                  let newPrices = formValues;
                  newPrices.estimate_product_prices.linear.high = e.target.value;
                  setFormValues(newPrices);
                  //TEMP FLAG BECAUSE THE PRICE ARRAY IS NOT UPDATED
                  {
                    ChangeFlag ? setChangeFlag(false) : setChangeFlag(true);
                  }
                }}
              />
            </div>
          </div>
          <h2>Linear Design Fee</h2>
          <div className="input_row input_row_wrap">
            {formValues.estimate_product_prices.linear.design_fee.map(
              (size, i) => {
                return (
                  <div className="designFee_input">
                    <TextField
                      id={`l_design_fee_${i}`}
                      key={`l_design_fee_${i}`}
                      label={size.name}
                      type="number"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={size.value} 
                      onChange={(e) => {
                        let newPrices = formValues;
                        newPrices.estimate_product_prices.linear.design_fee[i].value = e.target.value;
                        setFormValues(newPrices);
                        //TEMP FLAG BECAUSE THE PRICE ARRAY IS NOT UPDATED
                        {
                          ChangeFlag ? setChangeFlag(false) : setChangeFlag(true);
                        }
                      }
                      }

                    />
                  </div>
                );
              }
            )}
          </div>

          <h2>Square</h2>
          <div className="input_row">
            <div className="estimatePriceInput">
              <TextField
                id="square_low"
                key="square_low"
                label="Low"
                type="number"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formValues.estimate_product_prices.square.low}
                onChange={(e) => {
                  let newPrices = formValues;
                  newPrices.estimate_product_prices.square.low = e.target.value;
                  setFormValues(newPrices);
                  //TEMP FLAG BECAUSE THE PRICE ARRAY IS NOT UPDATED
                  {
                    ChangeFlag ? setChangeFlag(false) : setChangeFlag(true);
                  }
                }}
              />
            </div>

            <div className="estimatePriceInput">
              <TextField
                id="square_mid"
                key="square_mid"
                label="Mid"
                type="number"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formValues.estimate_product_prices.square.mid}
                onChange={(e) => {
                  let newPrices = formValues;
                  newPrices.estimate_product_prices.square.mid = e.target.value;
                  setFormValues(newPrices);
                  //TEMP FLAG BECAUSE THE PRICE ARRAY IS NOT UPDATED
                  {
                    ChangeFlag ? setChangeFlag(false) : setChangeFlag(true);
                  }
                }}
              />
            </div>

            <div className="estimatePriceInput">
              <TextField
                id="square_high"
                key="square_high"
                label="High"
                type="number"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formValues.estimate_product_prices.square.high}
                onChange={(e) => {
                  let newPrices = formValues;
                  newPrices.estimate_product_prices.square.high = e.target.value;
                  setFormValues(newPrices);
                  //TEMP FLAG BECAUSE THE PRICE ARRAY IS NOT UPDATED
                  {
                    ChangeFlag ? setChangeFlag(false) : setChangeFlag(true);
                  }
                }}
              />
            </div>
          </div>

          <h2>Square Design Fee</h2>
          <div className="input_row input_row_wrap">
            {formValues.estimate_product_prices.square.design_fee.map(
              (size, i) => {
                return (
                  <div className="designFee_input">
                    <TextField
                      id={`s_design_fee_${i}`}
                      key={`s_design_fee_${i}`}
                      label={size.name}
                      type="number"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={size.value}
                      onChange={(e) => {
                        let newPrices = formValues;
                        newPrices.estimate_product_prices.square.design_fee[i].value = e.target.value;
                        setFormValues(newPrices);
                        //TEMP FLAG BECAUSE THE PRICE ARRAY IS NOT UPDATED
                        {
                          ChangeFlag ? setChangeFlag(false) : setChangeFlag(true);
                        }
                      }
                      }
                    />
                  </div>
                );
              }
            )}
          </div>

          <div className="buttons">
            <Button
              onClick={() => navigate(-1)}
              variant="outlined"
              className="button_2"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className="button_1"
            >
              Submit
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
};