import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Button,
    Dialog,
    DialogContent,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import {
    Add,
    DeleteOutline,
    RemoveRedEye
} from '@mui/icons-material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';

import { ProjectConfig } from 'Global';
import Box from 'elements/Box';
import AlertDialog from 'elements/AlertDialog';
import ic_empty from 'icons/ic_empty.svg';

const defaultValues = {
    payroll_payment_id: "null",
    value: "0",
    date: moment().format('YYYY-MM-DD'),
};

export function PayrollPaymentList( props: any ) {

    const {
        handleReload = () => {},
    } = props;

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { props.setLoading(loading) }, [loading]);

    const { enqueueSnackbar } = useSnackbar();

    const [payrollId, setPayrollId] = useState(null);
    const [list, setList] = useState<any[]>([]);
    const [formValues, setFormValues] = useState<any>({...defaultValues});

    const [selectedId, setSelectedId] = useState<any>(null);
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [selectedDeleteId, setSelectedDeleteId] = useState<any>(null);
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    const [pendingPayment, setPendingPayment] = useState<any>(null);
    const [currentPayrollPaymentValue, setCurrentPayrollPaymentValue] = useState(0);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (payrollId) {
            loadData();
        }
    }, [payrollId]);

    useEffect(() => {
        if (props.payroll_id) {
            setPayrollId(props.payroll_id);
        }
    }, [props.payroll_id]);

    useEffect(() => {
        if (selectedId) {
            loadDetails();
        }
    }, [selectedId]);

    const loadData = () => {
        if (!payrollId) return;
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_payroll_payments_list',
                payroll_id: payrollId,
            }
        })
        .then((response) => {
            console.log(response);

            setList([...response.data.list]);
            loadPendingPayment();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const loadDetails = () => {
        if (!selectedId) return;
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_payroll_payments_details',
                payroll_payment_id: selectedId,
            }
        })
        .then((response) => {
            console.log(response);

            setFormValues({...response.data});
            setCurrentPayrollPaymentValue(response.data.value);
            setSelectedId(null);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const loadPendingPayment = () => {
        if (!payrollId) return;
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_payroll_balance',
                payroll_id: payrollId,
            }
        })
        .then((response) => {
            console.log(response);

            setPendingPayment(response.data.balance);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const fixNumberValue = (
        value: any,
        canDecimal = true,
        canNegative = true,
        canZero = true,
    ): Number | string => {
        
        //if value start with 0 and after has a number, remove 0
        if (value[0] === "0" && value[1] !== ".") {
            value = value.slice(1);
        } else if (canDecimal && value[0] === ".") {
            value = "0" + value;
        }

        if (!canNegative) {
            if (value[0] === "-") {
            value = value.slice(1);
            }
        }

        let formatRegex = /^\d*$/;
        if (canDecimal) {
            formatRegex = /^\d*\.?\d*$/;
        }

        if (!formatRegex.test(value)) {
            return 0;
        }

        //if value is empty set 0
        if (value === "") {
            if (canZero) {
                value = 0;
            } else {
                value = 1;
            }
        }

        if (Number(value).toString() === "NaN" || Number(value).toString() === "Infinity") {
            return canZero ? 0 : 1;
        }

        if (Number(value).toString() === value.toString()) {
            return Number(value);
        }

        return value;
    }

    const checkform = () => {
        let errors = false;

        if (formValues.value === '') {
            enqueueSnackbar('The value is required', { variant: 'error' });
            errors = true;
            return errors;
        }

        if (Number(formValues.value) <= 0) {
            enqueueSnackbar('The value must be greater than 0', { variant: 'error' });
            errors = true;
        }
            
        if (Number(formValues.value) > Number(pendingPayment) + Number(currentPayrollPaymentValue)) {
            enqueueSnackbar('The value is greater than the pending payment', { variant: 'error' });
            errors = true;
        }
        
        return errors;
    }

    const handleSubmit = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, formValues, {
            params: {
                request: 'add_edit_payroll_payments',
            },
        })
        .then((response) => {
            console.log(response);

            setFormValues({...defaultValues});
            setOpenForm(false);
            loadData();
            handleReload();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleDelete = () => {
        setLoading(true);

        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: 'delete_payroll_payment',
                payroll_payment_id: selectedDeleteId,
            },
        })
        .then((response) => {
            console.log(response);

            setSelectedDeleteId(null);
            loadData();
            loadDetails();
            handleReload();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <div
                id="payrollPaymentContainer"
                className="moduleContainer box100"
            >
                <div className="moduleWrapper">
                    <div className="moduleHeader buttons_top">
                        <h2>PAYMENTS</h2>

                        <div className="moduleButtons buttons_top">
                            <Button
                                color="primary"
                                variant='contained'
                                size="small"
                                startIcon={ <Add /> }
                                onClick={() => {
                                    setFormValues({
                                        ...defaultValues,
                                        payroll_id: payrollId,
                                    });
                                    setCurrentPayrollPaymentValue(0);
                                    setOpenForm(true);
                                }}
                            >
                                Add
                            </Button>
                        </div>   
                    </div>

                    <div className="moduleContent">
                        {list.length === 0 ?
                        <>
                            <div className="box_header">
                                <h2></h2>
                            </div>
        
                            <div className="emptyBox">
                                <img src={ic_empty} alt=""/>
                                <p>No payments found</p>
                            </div>
                        </>
                        :
                        <Table className="box100">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {list.map((item: any, index: number) => (
                                <TableRow key={index}>
                                    <TableCell>{item.payroll_payment_id}</TableCell>
                                    <TableCell>${item.value.toLocaleString()}</TableCell>
                                    <TableCell>{item.date}</TableCell>

                                    <TableCell
                                        align='right'
                                    >
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedId(item.payroll_payment_id);
                                                setOpenForm(true);
                                            }}
                                        >
                                            <RemoveRedEye />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedDeleteId(item.payroll_payment_id);
                                                setOpenDelete(true);
                                            }}
                                        >
                                            <DeleteOutline />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        }
                    </div>
                </div>
            </div>

            <Dialog
                open={openForm}
                onClose={() => {
                    setOpenForm(false);
                }}
                fullWidth
                maxWidth='sm'
            >
                <DialogContent
                    className="forced_box_editor"
                >
                    <div className="moduleHeader">
                        <h2>Payment</h2>
                    </div>

                    <div className="input_group">
                        <TextField
                            label="Value"
                            fullWidth
                            type="number"
                            value={formValues.value}
                            onChange={(e) => {
                                if (Number(e.target.value) > 0 || e.target.value === '') {
                                    setFormValues({
                                        ...formValues,
                                        value: formValues.value = fixNumberValue(e.target.value, false, false, true),
                                    });
                                }
                            }}
                        />

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                className='filters_datePicker'
                                label={"Date"}
                                value={moment(formValues.date)}
                                showToolbar={false}
                                closeOnSelect
                                onChange={(newValue) => {
                                    newValue && setFormValues({
                                        ...formValues,
                                        date: newValue.format('YYYY-MM-DD')
                                    });
                                }}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                    </div>

                    <div className="buttons">
                        <Button
                            color="primary"
                            variant='outlined'
                            size="small"
                            onClick={() => {
                                setOpenForm(false);
                            }}
                        >
                            Cancel
                        </Button>
                        
                        <Button
                            color="primary"
                            variant='contained'
                            size="small"
                            onClick={() => {
                                if (!checkform()) {
                                    handleSubmit();
                                }
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <AlertDialog
                open={openDelete}
                setOpen={setOpenDelete}
                subtitle="Are you sure you want to delete this payment?"
                send={() => {
                    handleDelete();
                }}
            />
        </>
    );
}