import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ProjectConfig } from "Global";
import ProjectHeader from './utils/ProjectHeader';
import AlertDialog from 'elements/AlertDialog';

export default function ProjectEstimates (props: any) {

  const {setLoading, setMenuTopLeftContent} = props;

  useEffect(() => {setLoading(false);}, []);
  
  const [estimates_list, setEstimatesList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteEstimateId, setDeleteEstimateId] = useState(null);

  /*=======================================
    GET ID FROM URL
  =======================================*/
  let { project_id } = useParams();


  /*=======================================
  =======================================*/


  /*=========================================================
  DELETE PROJECT ESTIMATE
  =========================================================*/

  const handleDelete = () => {
    axios.post(ProjectConfig.api_url, null,
    {
      params: {
        request: 'delete_project_estimate',
        estimate_id: deleteEstimateId,
      }
    })

    .then((response) => {
      console.log(response.data);
      setDeleteEstimateId(null);
      setRefreshList(refreshList + 1);
    })
    .catch((err) => {
      console.log(err.data);
    })
  }
  /*=========================================================*/
  

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    loadData();
    localStorage.setItem('page', page.toString())
  }, [page, refreshList])
  /*=========================================================*/

  const loadData = () => {
    setLoading(true)
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'project_estimates_list',
          page: page,
          project_id: project_id,
        }
      })
        .then((response) => {
          console.log(response.data);
          setEstimatesList(response.data.project_estimates_list)
          // setTotalPages(response.data.total_pages) // DISCOMMENT THIS LINE WHEN API IS READY
          setTotalPages((response.data.project_estimates_list.length == 10 ? page + 1: page)) // REMOVE THIS LINE WHEN API IS READY
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          setLoading(false)
        });
  }

  
  return (
    <>
      <div id='ProductList'>
        
          <ProjectHeader>
            <Button component={Link} to="add" variant="contained">
              New Estimate
            </Button>
          </ProjectHeader>

          <div id="product_list_container" className="flex_container">
            
              <Box id='product_list' title="Estimates" subtitle="List"  >
              <TableContainer>
              <Table stickyHeader aria-label="sticky table"> 
                <TableHead>
                  <TableRow>
                    <TableCell>Estimate Name</TableCell>
                    <TableCell>Product Qty</TableCell>
                    <TableCell>Estimate cost</TableCell>
                    <TableCell>Design Fee</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {estimates_list.map((estimate,i) => {
                      let estimate_products_quantity = JSON.parse(estimate.estimate_data).length;
                      let total_low = "$ " + parseInt(JSON.parse(estimate.total_low)).toLocaleString();
                      let total_mid = "$ " + parseInt(JSON.parse(estimate.total_mid)).toLocaleString();
                      let total_high = "$ " + parseInt(JSON.parse(estimate.total_high)).toLocaleString();
                      let total_design_fee = "$ " + parseInt(estimate.total_design_fee).toLocaleString();


                      return (
                        <TableRow 
                          
                          key={i}
                          hover
                          role="checkbox"
                          className="cursor_pointer"
                        >
                          <TableCell
                            onClick={()=> {window.location.href = `estimates/edit/${estimate.estimate_id}`}}
                          >
                            {estimate.estimate_name}
                          </TableCell>
                          
                          <TableCell
                            onClick={()=> {window.location.href = `estimates/edit/${estimate.estimate_id}`}}
                          >
                            {estimate_products_quantity}
                          </TableCell>

              
                          <TableCell
                            onClick={()=> {window.location.href = `estimates/edit/${estimate.estimate_id}`}}
                          >
                            <div className='tablecell_flex'>
                              <div className='estimate_price_value epv_low'>{total_low}</div>
                              <div className='estimate_price_value epv_mid'>{total_mid}</div>
                              <div className='estimate_price_value epv_high'>{total_high}</div>
                            </div>
                          </TableCell>

                          <TableCell
                            onClick={()=> {window.location.href = `estimates/edit/${estimate.estimate_id}`}}
                          >
                            {total_design_fee}
                          </TableCell>

                          <TableCell> 
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              onClick={() => {
                                setDeleteEstimateId(estimate.estimate_id);
                                setOpenDeleteDialog(true);
                              }}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            
            { total_pages > 1 &&
            <Pagination
              count={total_pages}
              page={page}
              onChange={(event, value) => {
                setPage(value)
              }} 
              color="primary"
              style={{"marginTop": "20px","alignSelf": "center"}}
            />
            }
            </Box>
  
          </div>
      </div>

      <AlertDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        subtitle="Are you sure you want to delete this estimate?"
        send={() => handleDelete()}
      />
    </>
  );
};

