import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip
} from '@mui/material';

import {
    IService,
    IServiceCost,
    ITypeCost,
    QuotesContext
} from '../QuoteContextProvider';
import { Circle } from '@mui/icons-material';

export default function QuoteServiceSummaryBox( props: any ) {

    const {
        fixNumberValue,
        costDict,
        serviceDict,
        services_types,
        loadingFields,
        setLoadingFields,
        updateServiceCost,
    } = useContext(QuotesContext);

    const {
        serviceIndex,
    } = props;

    const [service, setService] = useState<IService | null>(null);
    const [serviceCost, setServiceCost] = useState<IServiceCost | null>(null);
    const [companyCost, setCompanyCost] = useState<number>(0);
    const [profit, setProfit] = useState<number>(0);
    const [totalCost, setTotalCost] = useState<number>(0);

    const refPercentageHandler = useRef<any>();
    const refTotalCostHandler = useRef<any>();

    const [updatedPercentage, setUpdatedPercentage] = useState<boolean>(true);
    const [updatedTotalCost, setUpdatedTotalCost] = useState<boolean>(true);

    useEffect(() => {
        if (serviceIndex !== undefined && serviceIndex !== null) {
            if (serviceDict[serviceIndex] && costDict[serviceIndex]) {
                setServiceCost(costDict[serviceIndex]);
                setService(serviceDict[serviceIndex]);
            } else {
                setServiceCost(null);
                setService(null);
            }

            setUpdatedPercentage(true);
            setUpdatedTotalCost(true);
            setLoadingFields(false);

        } else {
            setServiceCost(null);
            setService(null);
        }
    }, [serviceIndex, costDict]);

    useEffect(() => {
        if (serviceCost !== null) {
            const company_cost = (serviceCost.material_cost || 0) + (serviceCost.workforce_cost || 0);
            let profit = 0;
            
            if (service && services_types.find((type: any) => type.serviceType === service.serviceType).with_profit) {
                profit = (((serviceCost.percentage && serviceCost.percentage > 0) ? serviceCost.percentage : 0) / 100) * company_cost;
            } else {
                if (serviceCost.increases && serviceCost.increases > 0) {
                    profit = serviceCost.increases;
                } else if (serviceCost.discounts && serviceCost.discounts > 0) {
                    profit = serviceCost.discounts * -1;
                }
            }
            setCompanyCost(company_cost);
            setProfit(profit);
            setTotalCost(company_cost + profit);
        } else {
            setCompanyCost(0);
            setProfit(0);
            setTotalCost(0);
        }
    }, [serviceCost]);

    const onChangePercentage = () => {
        handleValueChange(serviceCost?.percentage, 'percentage');
    }

    const onChangeTotalCost = () => {
        if (service === null) return;

        if (services_types.find((type: any) => type.serviceType === service.serviceType).with_profit) {
            handleValueChange(
                ((totalCost / companyCost) - 1) * 100,
                'percentage'
            );
        } else {
            if (totalCost > companyCost) {
                handleValueChange(
                    totalCost - companyCost,
                    'increases'
                );
            } else {
                handleValueChange(
                    companyCost - totalCost,
                    'discounts'
                );
            }
        }
    }

    useEffect(() => {
        refPercentageHandler.current = onChangePercentage;
    }, [onChangePercentage]);

    useEffect(() => {
        refTotalCostHandler.current = onChangeTotalCost;
    }, [onChangeTotalCost]);

    const overridePercentage = () => {
        if (updatedPercentage) return;

        refPercentageHandler.current?.();
    };

    const overrideTotalCost = () => {
        if (updatedTotalCost) return;

        refTotalCostHandler.current?.();
    };

    const handleValueChange = (value: any, field: string) => {
        const newServiceCost = {
            [field]: value,
        };

        updateServiceCost(newServiceCost, serviceIndex);
    }

    return (
        <>
            {(
                serviceCost === null ||
                service === null ||
                !(totalCost > 0)
            ) ?
                <CircularProgress color="secondary" size="3rem" />
            : (
            <>
                <div className="summary_section-service">
                    <span
                        className={`quoteSummaryTitle SummaryTitle-${service.serviceType}`}
                    >
                        {service.name}
                    </span>

                    <div className="summary_row">
                        <div className="summary_row_left">Material</div>
                        <div className="summary_row_right">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="0.8rem" /> :
                                <>${(serviceCost.material_cost || 0).toLocaleString()}</>
                            }
                        </div>
                    </div>

                    <div className="summary_row">
                        <div className="summary_row_left">Workforce</div>
                        <div className="summary_row_right">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="0.8rem" /> :
                                <>${(serviceCost.workforce_cost || 0).toLocaleString()}</>
                            }
                        </div>
                    </div>

                    <div className="summary_row summary_line_top">
                        <div className="summary_row_left">Company cost</div>
                        <div className="sidebar_body_row_value">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="0.8rem" /> :
                                <>${(companyCost).toLocaleString()}</>
                            }
                        </div>
                    </div>

                    {services_types.find((type: any) => type.serviceType === service.serviceType).with_profit &&
                    <div className="summary_row">
                        <div className="summary_row_left">
                            <TextField
                                name={`${service.name}_profit_percentage`}
                                size="small"
                                className="quote_profit_percentage"
                                InputLabelProps={{
                                    style: { fontSize: '12px' }
                                }}
                                InputProps={{
                                    style: {
                                        fontSize: '12px',
                                        width: '60px',
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {!updatedPercentage && true ?
                                                <Tooltip title={"Press enter or leave the field to confirm"}>
                                                    <Circle
                                                        color="secondary"
                                                        className='field_badge'
                                                    />
                                                </Tooltip>
                                                :
                                                null
                                            }
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    style: {
                                        fontSize: '12px', 
                                        textAlign: 'end',
                                        width: '30px',
                                        padding: '3px',
                                    }
                                }}
                                value={serviceCost.percentage}
                                onChange={(e: { target: { value: any; }; }) => {
                                    setServiceCost({
                                        ...serviceCost,
                                        percentage: fixNumberValue(e.target.value, false, false, true),
                                    });
    
                                    setUpdatedPercentage(false);
                                }}
                                onBlur={overridePercentage}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        overridePercentage();
                                    }
                                }}
                            />
                            % Profit
                        </div>
                        
                        <div className="sidebar_body_row_value">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="0.8rem" /> :
                                <>${(profit || 0).toLocaleString()}</>
                            }
                        </div>
                    </div>
                    }

                    {((serviceCost.increases || 0) > 0) && (
                        <div className="summary_row">
                            <div className="summary_row_left"> Profit </div>
                            <div className="summary_row_right">
                                {loadingFields ?
                                    <CircularProgress color="secondary" size="0.8rem" /> :
                                    <>${(serviceCost.increases || 0).toLocaleString()}</>
                                }
                            </div>
                        </div>
                    )}

                    {((serviceCost.discounts || 0) > 0) && (
                        <div className="summary_row">
                            <div className="summary_row_left"> Discount </div>
                            <div className="summary_row_right">
                                {loadingFields ?
                                    <CircularProgress color="secondary" size="0.8rem" /> :
                                    <>${(serviceCost.discounts || 0).toLocaleString()}</>
                                }
                            </div>
                        </div>
                    )}

                    <div className="summary_row quote_total_row">
                        <div className="summary_row_left">Total</div>
                        <div className="sidebar_body_row_value">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="0.8rem" /> :
                                <div className="box100 align_right">
                                    $
                                    <TextField
                                        name={`${service.name}_total_cost`}
                                        size="small"
                                        className="quote_total_cost box50"
                                        InputLabelProps={{
                                            style: { fontSize: '12px' }
                                        }}
                                        InputProps={{
                                            style: {
                                                fontSize: '12px',
                                            },
                                            startAdornment: (
                                                <InputAdornment position="end">
                                                    {!updatedTotalCost && true ?
                                                        <Tooltip title={"Press enter or leave the field to confirm"}>
                                                            <Circle
                                                                color="secondary"
                                                                className='field_badge'
                                                            />
                                                        </Tooltip>
                                                        :
                                                        null
                                                    }
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            style: {
                                                fontSize: '12px', 
                                                textAlign: 'end',
                                                padding: '3px',
                                            }
                                        }}
                                        value={totalCost}
                                        onChange={(e: { target: { value: any; }; }) => {
                                            setTotalCost(fixNumberValue(e.target.value, false, false, true));
                                            setUpdatedTotalCost(false);
                                        }}
                                        onBlur={overrideTotalCost}
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                overrideTotalCost();
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
            )}
        </>
    );

};