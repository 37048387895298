import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Close, Edit, OpenInNew, Save, Send } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogContent,
    IconButton,
    MenuItem,
    TextField,
} from '@mui/material';
import moment from 'moment';

import Box from 'elements/Box';
import 'assets/css/main.css';
import { ProjectConfig } from 'Global';
import FilesBrowser from 'components/Files/FilesBrowser';
import AppAvatar from 'elements/AppAvatar';
import LoadingCircle from 'elements/LoadingCircle';
import Cookies from 'universal-cookie';

const defaultValues = {
    note_id: 'null',
    column_id: "null",
    title : "",
    description : "",
    created_at : moment(new Date()).format("YYYY-MM-DD"),
    last_update : moment(new Date()).format("YYYY-MM-DD"),
    user_id : "null",
    status_id : "0",

    img: '',
};

const defaultMessage = {
    note_message_id : "null",
    note_id : "null",
    message : "",
    created_at : moment(new Date()).format("YYYY-MM-DD"),
    user_id : "null",
    deleted : "0"
};

const cookies = new Cookies();
    
export default function NoteDetailedForm( props: any ) {
    
    const {
        onSubmit = () => {},
        setOpen = () => {},
        onUpdated = () => {},
        open,
    } = props;

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const [formValues, setFormValues] = useState<any>({...defaultValues});
    const [formMessage, setFormMessage] = useState<any>({...defaultMessage});
    const [list, setList] = useState<any[]>([]);

    const [editTitle, setEditTitle] = useState<boolean>(false);
    const [editDescription, setEditDescription] = useState<boolean>(false);

    const [openLightBox, setOpenLightBox] = useState(false);
    const [imageLightbox, setImageLightBox] = useState("");
    const [loadingImg, setLoadingImg] = useState(true);

    useEffect(() => {
        if (props.formValues) {
            setFormValues(props.formValues);
        }
    }, [props.formValues]);

    useEffect(() => {
        if (formValues.note_id !== 'null') {
            loadDetails();
        }
    }, [formValues.note_id]);

    useEffect(() => {
        if (!open) {
            setFormValues({...defaultValues});
            setList([]);
        }
    }, [open]);

    const loadDetails = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_note_details',
                note_id: formValues.note_id,
            }
        })
        .then((response) => {
            console.log(response.data);

            setFormValues({
                ...formValues,
                description: response.data.description,
                title: response.data.title,
                folder_id: response.data.folder_id,
                cover_image: response.data.cover_image,
            });
            setList(response.data.messages);
            loadData();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }
    
    const loadData = () => {
        setLoading(true);

        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'get_notes_messages_list',
                note_id: formValues.note_id,
            }
        })
        .then((response) => {
            console.log(response.data);

            setList(response.data.list);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleSubmitMessage = () => {
        setLoading(true);

        const formData = {
            ...formMessage,
            note_id: formValues.note_id,
            user_id: localStorage.getItem('user_id'),
        }

        axios.post(ProjectConfig.api_url, formData, {
            headers: {
                'LTKN': cookies.get('ltkn'),
            },
            params: {
                request: 'add_edit_notes_messages',
            }
        })
        .then((response) => {
            console.log(response.data);
            setFormMessage({...defaultMessage});
            loadData();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const handleOpenPopupImage = (imageSource: string) => {
        window.open('/viewer?img=' + imageSource, '_blank', 'scrollbars=yes')
    }

    const [uploadProgress, setUploadProgress] = useState(0);
    const [forceRefresh, setForceRefresh] = useState(false);

    const handleUploadFiles = (selected_files: any, folder_id: any, folderLevel: number) => {
        console.log("selected_files",selected_files, "folder_id", folder_id, "folderLevel", folderLevel);
        
        //MULTIPLE FILES UPLOAD
        const formData = new FormData();
        for (let i = 0; i < selected_files.length; i++) {
          formData.append("files["+i+"]", selected_files[i]);
        }
        formData.append("request", "upload_files");
        formData.append("folder_id", folder_id);
    
        //add className to the progress bar
        const progressBar = document.getElementById("progressBar_"+folderLevel);
        //add className to the progress bar
        progressBar?.classList.add("show_progress_bar");

    
        //¨*UPLOAD FILES AND SHOW PROGRESS
        axios
          .post(ProjectConfig.api_url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              setUploadProgress(
                  Math.round((progressEvent.loaded / (progressEvent.total || progressEvent.loaded)) * 100)
              );
            },
          })
          .then((response) => {
            console.log(response);
            //REFRESH FILE LIST
            //ADD DATA LIKE A CHILD OF THE PARENT FOLDER
            progressBar?.classList.remove("show_progress_bar");
            setUploadProgress(0);
            setForceRefresh(!forceRefresh);

            loadDetails();
            onUpdated();
          })
          .catch((err) => {
            console.log(err.data);
          });
    
    
      }

    return (
        <>
            <div
                className="dialog dropzone dropzone_with_overlay"
                onDrop={(e) => {
                    e.preventDefault();
                    handleUploadFiles(e.dataTransfer.files, formValues.folder_id, 0);
                    e.currentTarget.classList.remove("dropzone_active");
                }} 
                onDragOver={(e) => {
                    e.preventDefault();
                    e.currentTarget.classList.add("dropzone_active");
                }}
            >
                <div
                    className="dropzone_overlay"                    
                    onDragLeave={(e) => {
                        e.preventDefault();
                        e.currentTarget.parentElement?.classList.remove("dropzone_active");
                    }}
                >
                    <span>DROP THE FILES</span>
                </div>

                <IconButton
                    onClick={() => {
                        setOpen(false);
                    }}
                    size='small'
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '10px',
                        color: '#fff',
                        backgroundColor: '#666',
                        width: '25px',
                        height: '25px',
                        zIndex: 1,

                        '&:hover': {
                            backgroundColor: '#aaa',
                        }
                    }}
                >
                    <Close />
                </IconButton>

                {formValues.cover_image &&
                <Button
                    id='note_detailed_form_banner_container'
                    component='div'
                    onClick={() => {
                        setOpenLightBox(true)
                        setImageLightBox(formValues.cover_image)
                    }}
                >
                    <img src={formValues.cover_image} />
                </Button>
                }
                
                <Box
                    title={
                        editTitle ?
                        <TextField
                            label={formValues.title ? '' : "Title"}
                            variant="standard"
                            autoFocus
                            fullWidth
                            inputProps={{
                                className: 'card_title',
                            }}
                            value={formValues.title}
                            onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    title: e.target.value
                                });
                            }}
                            onBlur={() => {
                                onSubmit(formValues, false);
                                setEditTitle(false);
                            }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    onSubmit(formValues, false);
                                    setEditTitle(false);
                                }
                            }}
                        />
                        :
                        <>
                            <span
                                className='card_title'
                                onClick={() => {
                                    setEditTitle(true);
                                }}
                            >
                                {formValues.title}
                            </span>
                            
                            <IconButton
                                onClick={() => {
                                    setEditTitle(true);
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </>
                    }
                    className='forced_box_editor box100 padding-30 gap-20'
                    id="note_detailed_form_editor"
                >
                    <div className='input_group'>
                        <div className='input_row'>
                            <span className='card_title'>Description</span>

                            <IconButton
                                onClick={() => {
                                    setEditDescription(true);
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </div>

                        {editDescription || formValues.description === '' ?
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                size='small'
                                value={formValues.description}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        description: e.target.value
                                    });
                                }}
                                onFocus={(e) => {
                                    setEditDescription(true);
                                }}
                            />

                            <div className='flex_row flex_end gap-10'>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        setEditDescription(false);
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Save />}
                                    onClick={() => {
                                        onSubmit(formValues, false);
                                        setEditDescription(false);
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </>
                        :
                        <div className='input_row'>
                            <p
                                className='card_info'
                                onClick={() => {
                                    setEditDescription(true);
                                }}
                            >
                                {formValues.description}
                            </p>
                        </div>
                        }

                        {(formValues.folder_id && formValues.folder_id !== null && formValues.folder_id !== "0") &&
                            <>
                                <div className='input_row'>
                                    <span className='card_title'>Files</span>
                                </div>

                                <div className='input_row'>
                                    <FilesBrowser
                                        folder_id={formValues.folder_id}
                                        setLoading={props.setLoading}
                                        with_drawer={false}
                                        view_type={'thumbnail_list'}
                                        onSubmitEnds={() => {
                                            loadDetails();
                                            onUpdated();
                                        }}
                                        withoutParamsSearch
                                        uploadProgress={uploadProgress}
                                        triggerRefresh={forceRefresh}
                                    />
                                </div>
                            </>
                        }
                    </div>

                    <div className='input_group'>
                        <div className='input_row'>
                            <span className='card_title'>Activity</span>
                        </div>

                        <div className='input_row'>
                            <AppAvatar
                                name={localStorage.getItem('user_name')} 
                                image={localStorage.getItem('user_image')}
                                size={40}
                            />

                            <TextField
                                label="Comment"
                                placeholder="Write a comment..."
                                variant="outlined"
                                fullWidth
                                sx={{ mt: 2, mb: 2, borderColor: '#E3E3E3' }}
                                multiline
                                rows={1}
                                size='small'
                                value={formMessage.message}
                                onChange={(e) => {
                                    setFormMessage({
                                        ...formMessage,
                                        message: e.target.value
                                    });
                                }}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmitMessage();
                                    }
                                }}
                            />

                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={() => {
                                    handleSubmitMessage();
                                }}
                            >
                                <Send />
                            </IconButton>
                        </div>

                        <div id="message-container">
                            {list.map((item: any, index: number) => (
                                <div className='input_row' key={index}>
                                    <div className='avatar-container'>
                                        <AppAvatar
                                            name={item.user.name} 
                                            image={item.user.image}
                                            size={40}
                                        />
                                    </div>

                                    <div className='input_column gap-off'>
                                        <div className='message_info gap-10'>
                                            <span className='input_label'>{item.user.name}</span>
                                            <span className='input_label'>{item.created_at}</span>
                                        </div>

                                        <p className='message'>{item.message}</p>

                                        <div className='message_buttons gap-10'>
                                            <a>Reply</a>
                                            <a>Delete</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Box>
            </div>

            <Dialog
                id="imageLightbox" 
                open={openLightBox} 
                onClose={() => setOpenLightBox(false)}
                maxWidth="lg"
            >
                <DialogContent className="forced_box_editor">
                    <LoadingCircle
                        loading={loadingImg}
                    />
                    <img
                        src={imageLightbox}
                        onLoad={() => setLoadingImg(false)}
                    />

                    <div className="buttons">
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                setOpenLightBox(false)
                            }}
                        >
                            Close
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleOpenPopupImage(imageLightbox)
                            }}
                            startIcon={<OpenInNew />}
                        >
                            Open in new tab
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}