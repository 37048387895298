import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, AvatarGroup, Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import ProjectHeader from 'components/Projects/utils/ProjectHeader';
import AppAvatar from 'elements/AppAvatar';
import AlertDialog from 'elements/AlertDialog';


export default function OutgoingList (props: any) {

  const {setLoading, setMenuTopLeftContent} = props;

  
  useEffect(() => {setLoading(false);}, []);

  const [outgoing_list, setOutgoingList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = 1;


  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const {project_id} = useParams<any>();


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    loadData();
  }, [page, refreshList])
  /*=========================================================*/

  const loadData = () => {
    setLoading(true)

    axios.get(ProjectConfig.api_url,
    {
      params: {
        request: 'get_outgoing_list',
        params: {
          project_id: project_id? project_id : null,
          page: page,
        },
      }
    })
      .then((response) => {
        console.log(response.data); 
        setOutgoingList(response.data.list)
        setTotalPages(response.data.total_pages)
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => {
        setLoading(false)
      });
  }

  
  const [openAlert, setOpenAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(0);


  const handleDelete = (quote_id:number) => {
    setDeleteId(quote_id)
    setOpenAlert(false)
    deleteOutgoing()
  }


  const deleteOutgoing = () => {
    setLoading(true)
    axios.post(ProjectConfig.api_url, null, 
      {
        params: {
          request: 'delete_outgoing',
          outgoing_id: deleteId,
        }
      })
  
      .then((response) => {
        console.log(response.data)
        setRefreshList(refreshList + 1)
        setLoading(true)
        
      })
      .catch((err) => {
        console.log(err.data);
      })

  }


  
  return (
    <>
    <div id='ProductList'>

      <ProjectHeader>
        <Button component={Link} to="add" variant="contained">
          New Outgoing
        </Button>
      </ProjectHeader>

      

        <div id="outgoing_list_container" className="flex_container">
          
            <Box id='outgoing_list' title="Outgoings" subtitle="List"  >
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Project</TableCell>
                  <TableCell>Date / Time</TableCell>
                  <TableCell>Users</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {outgoing_list.map((outgoing,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell>{outgoing.project_name}</TableCell>

                      <TableCell>

                      <div className='flexTd'>
                        <span className='flexTd_bold'>{moment(outgoing.outgoing_date).format('MMMM DD, YYYY')}</span>
                        <span className='flexTd_normal'>{moment(outgoing.outgoing_date).format('HH:mm')}</span>
                      </div>
                        
                      </TableCell>
                      <TableCell>
                      <AvatarGroup max={4}>
                        {outgoing.users.map((user:any, i:number) => (
                          <Tooltip title={user.name} key={i}>
                            <AppAvatar name={user.name} size={40} />
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                      </TableCell>

                      <TableCell align='right'>
                        <Button
                          component={Link}
                          to={`/inventory/outgoing/edit/${outgoing.outgoing_id}`}
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{marginRight: '10px'}}
                        >
                          Edit
                        </Button>

                        <IconButton aria-label="delete"  color="primary" onClick={
                          () => {
                            setOpenAlert(true)
                            setDeleteId(outgoing.outgoing_id)
                          }
                        }>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
 
        </div>
    </div>

    <AlertDialog
        title="Delete Outgoing"
        subtitle="Are you sure you want to delete this outgoing? This action returns the products to the inventory and cannot be undone."
        open={openAlert}
        setOpen={setOpenAlert}
        send={handleDelete}
      />

    </>
  );
};


